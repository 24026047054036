import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-detalhes-contato-chat',
  templateUrl: './detalhes-contato-chat.component.html',
  styleUrls: ['./detalhes-contato-chat.scss']
})
export class DetalhesContatoChatComponent implements OnInit {
  @Input() chatData; 
  selector: string;

  constructor(
    public modal: NgbActiveModal,
    public translate: TranslateService,
    private elemRef: ElementRef,
) {
    this.selector = elemRef.nativeElement.tagName.toLowerCase();
}

  ngOnInit(): void {
    //console.log(this.chatData);   
    document.getElementsByTagName(this.selector)[0]['style']['zoom'] = '0.85'  
  }

  close(){
    this.modal.close()
  }


}
