import { Component, EventEmitter, Input, OnInit, Output, forwardRef, OnDestroy } from "@angular/core";
import Quill from "quill";
import * as Emoji from "quill-emoji";
import "quill-mention";
Quill.register("modules/emoji", Emoji);
import ImageResize from "quill-image-resize-module";
Quill.register("modules/ImageResize", ImageResize);
import { ImageDrop } from "quill-image-drop-module";
Quill.register("modules/imageDrop", ImageDrop);
import MagicUrl from "quill-magic-url";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { QuillConfigModel } from "src/app/models/quill/quillConfig.model";
Quill.register("modules/magicUrl", MagicUrl);
import ToolbarItem from 'quill-toolbar-item';
import { TranslateService } from "@ngx-translate/core";
Quill.register('modules/toolbar-custom-button', ToolbarItem);
Quill.register('modules/toolbar-custom-selector', ToolbarItem);
let fonts = Quill.import("attributors/style/font");
fonts.whitelist = ["initial", "sans-serif", "serif", "monospace", "poppins", "arial", "times-new-roman", "helvetica", "times", "courier-new", "verdana", "courier", "arial-narrow"];
Quill.register(fonts, true);
import "quill-paste-smart";

@Component({
  selector: "app-text-editor",
  templateUrl: "./text-editor.component.html",
  styleUrls: ["./text-editor.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(()=> TextEditorComponent),
      multi: true
    }
  ]
})
export class TextEditorComponent implements ControlValueAccessor, OnInit {
  @Input() textEditorClass?: string;
  @Input() textEditorConfig?: QuillConfigModel = new QuillConfigModel();
  @Input() textEditorDisabled?: boolean;
  @Input() textEditorHTML?: string;
  @Input() textEditorId?: string;
  @Input() textEditorIcon?: string;
  @Input() textEditorLabel?: string;
  @Input() textEditorLabelIcon?: string;
  @Input() textEditorLabelType?: string;
  @Input() textEditorLabelTooltip?: string;
  @Input() textEditorLabelTooltipPlacement?: string;
  @Input() textEditorRequired?: boolean;
  @Input() set textEditorHeight (value) {
    this.setCustomEditorHeight(value);
  };
  @Output() textEditorValueChange = new EventEmitter<any>();
  @Output() textEditorOnFocus = new EventEmitter<any>();
  @Output() textEditorOnBlur = new EventEmitter<any>();
  @Output() editorInstance = new EventEmitter<any>();
  textEditorValue: any;
  customEditorHeight: number;
  editorPlaceholder: string =  '';
  onChanged: (value: any) => void;
  onTouched: () => void;

  constructor(
    private translate: TranslateService
  ) {
  }

  ngOnInit(): void {
    this.setEditorPlaceholder(this.textEditorConfig.imageDrop);
  }

  setEditorPlaceholder(image_drop: boolean): void {
    if(image_drop) {
      this.editorPlaceholder = this.translate.instant('INSIRA_TEXTO_IMAGEM');
    } else {
      this.editorPlaceholder = this.translate.instant('INSIRA_TEXTO');
    }
  }

  writeValue(value: any): void {
    if (value != undefined) {      
      this.textEditorValue = value;
    };
  }

  registerOnChange(fn: any): void {
    this.onChanged = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  onFormChange(_value: any) {
    this.writeValue(_value);
    this.onChanged(_value);
  }

  onSelectionChanged = (event) => {
    if (event.oldRange == null) {
      this.onFocus();
    }
    if (event.range == null) {
      this.onBlur();
    }
  };

  onContentChanged = (event) => { 
    this.onChanged(event);
  };

  onEditorCreated = (event) => {
    this.editorInstance.emit(event);
  }

  onFocus = () => {
  };

  onBlur = () => {
  };

  setCustomEditorHeight(value: string): void {
    setTimeout(() => {
      if(value) {
        document.getElementsByClassName('ql-editor')[0]['style'].height = value + 'px';
      } else {
        document.getElementsByClassName('ql-editor')[0]['style'].height = '185px';
      }
    }, 800);
  }
}
