import { HttpHeaders, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { TokenService } from '../core/token/token.service';

@Injectable({
  providedIn: 'root'
})
export class WhiteLabelService {
  private apiUrl = environment.apiGestorUrlLocal;
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', 'authorization': this.tokenService.getToken(), 'session': this.tokenService.getSessionUsuario() }),
    params: {}
  };

  constructor(private http: HttpClient, private tokenService: TokenService) { }

  getPersonalizacaoPortalAntigo(): Observable<any> {
    this.httpOptions.params = "";
    return this.http.get(`${this.apiUrl}api/personalizacao/empresa`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  getPersonalizacaoPortalNovo(): Observable<any> {
    this.httpOptions.params = "";
    return this.http.get(`${this.apiUrl}api/personalizacao-novo/empresa`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  getPersonalizacaoClient(): Observable<any> {
    this.httpOptions.params = "";
    return this.http.get(`${this.apiUrl}api/personalizacao/agente/empresa`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  getPersonalizacaoGestor(): Observable<any> {
    this.httpOptions.params = "";
    return this.http.get(`${this.apiUrl}api/personalizacao/gestor`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  adicionaPersonalizacaoPortalAntigo(payload): Observable<any> {
    this.httpOptions.params = "";
    return this.http.post(`${this.apiUrl}api/personalizacao`, payload, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  adicionaPersonalizacaoPortalNovo(payload): Observable<any> {
    this.httpOptions.params = "";
    return this.http.post(`${this.apiUrl}api/personalizacao-novo`, payload, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  adicionaPersonalizacaoClient(payload): Observable<any> {
    this.httpOptions.params = "";
    return this.http.post(`${this.apiUrl}api/personalizacao/agente`, payload, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  atualizaPersonalizacaoPortalAntigo(payload): Observable<any> {
    this.httpOptions.params = "";
    return this.http.put(`${this.apiUrl}api/personalizacao`, payload, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  atualizaPersonalizacaoPortalNovo(payload): Observable<any> {
    this.httpOptions.params = "";
    return this.http.put(`${this.apiUrl}api/personalizacao-novo`, payload, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  atualizaPersonalizacaoClient(payload): Observable<any> {
    this.httpOptions.params = "";
    return this.http.put(`${this.apiUrl}api/personalizacao/agente`, payload,
      { headers: new HttpHeaders({ 'authorization': this.tokenService.getToken(), 'session': this.tokenService.getSessionUsuario() }) })
      .pipe(
        catchError(this.handleError)
      );
  }

  atualizaPersonalizacaGestor(payload): Observable<any> {
    this.httpOptions.params = ""
    return this.http.put(`${this.apiUrl}api/personalizacao/gestor`, payload,
      { headers: new HttpHeaders({ 'authorization': this.tokenService.getToken(), 'session': this.tokenService.getSessionUsuario() }) })
      .pipe(
        catchError(this.handleError)
      );
  }

  atualizaLoginImagemPortalAntigo(imagem): Observable<any> {
    this.httpOptions.params = "";
    return this.http.put(`${this.apiUrl}api/personalizacao/logo-login`, imagem,
      { headers: new HttpHeaders({ 'authorization': this.tokenService.getToken(), 'session': this.tokenService.getSessionUsuario() }) })
      .pipe(
        catchError(this.handleError)
      );
  }

  atualizaLoginImagemPortalNovo(imagem): Observable<any> {
    this.httpOptions.params = "";
    return this.http.put(`${this.apiUrl}api/personalizacao-novo/logo-login`, imagem,
      { headers: new HttpHeaders({ 'authorization': this.tokenService.getToken(), 'session': this.tokenService.getSessionUsuario() }) })
      .pipe(
        catchError(this.handleError)
      );
  }

  deletaLoginImagemAntigo(): Observable<any> {
    this.httpOptions.params = "";
    return this.http.delete(`${this.apiUrl}api/personalizacao/logo-login`,
      { headers: new HttpHeaders({ 'authorization': this.tokenService.getToken(), 'session': this.tokenService.getSessionUsuario() }) })
      .pipe(
        catchError(this.handleError)
      );
  }

  deletaLoginImagemNovo(): Observable<any> {
    this.httpOptions.params = "";
    return this.http.delete(`${this.apiUrl}api/personalizacao-novo/logo-login`,
      { headers: new HttpHeaders({ 'authorization': this.tokenService.getToken(), 'session': this.tokenService.getSessionUsuario() }) })
      .pipe(
        catchError(this.handleError)
      );
  }

  iconesOffline(): Observable<any> {
    this.httpOptions.params = "";
    return this.http.get(`${this.apiUrl}api/personalizacao/offline/dropdownlist`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  iconesOnline(): Observable<any> {
    this.httpOptions.params = "";
    return this.http.get(`${this.apiUrl}api/personalizacao/online/dropdownlist`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  removePersonalizacaoPortalAntigo(): Observable<any> {
    this.httpOptions.params = "";
    return this.http.delete(`${this.apiUrl}api/personalizacao`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  removePersonalizacaoPortalNovo(): Observable<any> {
    this.httpOptions.params = "";
    return this.http.delete(`${this.apiUrl}api/personalizacao-novo/`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  removePersonalizacaoAgente(): Observable<any> {
    this.httpOptions.params = "";
    return this.http.delete(`${this.apiUrl}api/personalizacao/agente`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  salvarIconeOnline(imagem): Observable<any> {
    this.httpOptions.params = "";
    return this.http.put(`${this.apiUrl}api/personalizacao/agente/icone-online`, imagem,
      { headers: new HttpHeaders({ 'authorization': this.tokenService.getToken(), 'session': this.tokenService.getSessionUsuario() }) })
      .pipe(
        catchError(this.handleError)
      );
  }

  salvarIconeOffline(imagem): Observable<any> {
    this.httpOptions.params = "";
    return this.http.put(`${this.apiUrl}api/personalizacao/agente/icone-offline`, imagem,
      { headers: new HttpHeaders({ 'authorization': this.tokenService.getToken(), 'session': this.tokenService.getSessionUsuario() }) })
      .pipe(
        catchError(this.handleError)
      );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('Ops! Ocorreu um erro:', error.error.message);
    } else {
      console.error(
        `Backend retornou o código ${error.status}, ` +
        `o body é: ${error.error}`);
    }
    return throwError(
      error.error);
  }
}
