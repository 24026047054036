import { Component, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { ChatService } from 'src/app/services/chat.service';
import { environment } from 'src/environments/environment';
import { ModalAberturaTicketComponent } from '../modal-abertura-ticket/modal-abertura-ticket.component';

@Component({
  selector: 'app-modal-chat-conciliar',
  templateUrl: './modal-chat-conciliar.component.html',
  styleUrls: ['./modal-chat-conciliar.component.scss']
})
export class ModalChatConciliarComponent implements OnInit, OnDestroy {
  @Input() chatData;
  cliente: any = {
    id: '',
    text: ''
  };
  chamado: any = {
    id: ''
  };
  selector: string;
  newSubs: Subscription;
  fechar: boolean;

  constructor(
    public activeModal: NgbActiveModal,
    private toastr: ToastrService,
    public translate: TranslateService,
    private modalService: NgbModal,
    private chatService: ChatService,
    private elemRef: ElementRef,
  ) {
    this.selector = elemRef.nativeElement.tagName.toLowerCase();
  }

  ngOnInit(): void {
    document.getElementsByTagName(this.selector)[0]['style']['zoom'] = '0.85';
    if(this.chatData.cliente) {
      this.cliente = this.chatData.cliente;
    }
  }

  ngOnDestroy(): void {
    this.newSubs?.unsubscribe();
  }

  abrirNovoTicket(): void {
    const modalRef = this.modalService.open(ModalAberturaTicketComponent, {
      scrollable: false,
      windowClass: "modal-custom-milvus",
      size: "grande",
      keyboard: false,
      backdrop: "static",
    });

    modalRef.componentInstance.chamado_origem_id = 16;
    modalRef.componentInstance.chatData = this.chatData;
    modalRef.result.then((result) => {      
      if (result) {
        this.chamado.id = result.chamado.id;
        this.chamado = {...result.chamado, fechar: result.fechar};
        this.associarTicket();
      }
    })
  }

  associarTicket(): void {
    let data = {
      "chamado_id": this.chamado.id
    }
    this.newSubs = this.chatService.vincularChamado(this.chatData._id, data)
      .subscribe((res) => {
        this.toastr.show(this.translate.instant('ASSOCIADO_SUCESSO'), "", environment.toastrConfig("success"));
        this.close(this.chamado);
      }, (error) => {
        this.toastr.show(error, "", environment.toastrConfig("danger"));
      })

  }

  close(result?) {
    if (result) {
      var data = {
        codigo: this.retornaCodigoChamado(this.chamado.text),
        id: this.chamado.id,
        descricao: this.chamado.text,
        fechar: false
      }
      this.activeModal.close(data);
    }
    this.activeModal.close();
  }

  retornaCodigoChamado(texto): number {
    if (texto) {
      var arr = texto.split(" - ");
      return Number(arr[0].replace('#', ''));
    }
  }

  selecionaCliente(cliente): void {
    if(cliente) {
      this.cliente = cliente;
    } else {
      this.cliente = {
        id: '',
        text: ''
      };
    }
  }

  selecionaChamado(chamado): void {
    if(chamado) {
      this.chamado = chamado;
    } else {
      this.chamado = {
        id: '',
        text: ''
      };
    }
  }
}
