import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { CategoriesMultiLevelDropdownComponent } from "./dropdown/categories-ml-dropdown.components";
import { NgbAccordionModule, NgbTooltipModule } from "@ng-bootstrap/ng-bootstrap";
import { CategoriesMultiLevelSelectComponent } from "./select/categories-ml-select";
import { TranslateModule } from "@ngx-translate/core";
import { PipesModule } from "src/app/shared/validators/pipes/pipes.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { Ng2SearchPipeModule } from "ng2-search-filter";

@NgModule({
    imports: [
      CommonModule,
      FormsModule,
      ReactiveFormsModule,
      BsDropdownModule.forRoot(),
      NgbAccordionModule,
      TranslateModule,
      PipesModule,
      NgbTooltipModule,
      Ng2SearchPipeModule
    ],
    declarations: [ CategoriesMultiLevelDropdownComponent, CategoriesMultiLevelSelectComponent ],
    exports: [ CategoriesMultiLevelDropdownComponent, CategoriesMultiLevelSelectComponent ],
    providers: []
  })
  export class CategoriesMultiLevelModule {}