import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { TokenService } from "../core/token/token.service";

@Injectable({
  providedIn: "root",
})
export class SoftwareService {
  private apiUrl = environment.apiGestorUrlLocal;
  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      authorization: this.tokenService.getToken(),
      session: this.tokenService.getSessionUsuario()
    }),
    params: {},
  };
  constructor(private http: HttpClient, private tokenService: TokenService) {}
  
  /* DROPDOWNS */
  getSoftwares(params): Observable<any> {
    this.httpOptions.params = params
      return this.http.get(`${this.apiUrl}api/softwares/dropdown`, this.httpOptions)
        .pipe(
          catchError(this.handleError)
        );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('Ops! Ocorreu um erro:', error.error.message);
    } else {
      console.error(
        `Backend retornou o código ${error.status}, ` +
        `o body é: ${error.error}`);
    }
    return throwError(
      error.error);
  }
}
