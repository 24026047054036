import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { TokenService } from '../core/token/token.service';

@Injectable({
    providedIn: 'root'
})
export class GerenciadorLicencaService {
    private apiUrl = environment.apiGestorUrlLocal;
    httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json', 'authorization': this.tokenService.getToken(), 'session': this.tokenService.getSessionUsuario() }),
        params: {}
    };

    constructor(private http: HttpClient, private tokenService: TokenService) { }

    getTodosSoftwares(params, filtros): Observable<any> {
        this.httpOptions.params = params
        return this.http.post(`${this.apiUrl}api/gerenciador/licencas/software/listagem`, filtros, this.httpOptions)
            .pipe(
                catchError(this.handleError)
            );
    }

    getAllSoftwareByCliente(params, filtros): Observable<any> {
        this.httpOptions.params = params;
        return this.http.post(`${this.apiUrl}api/gerenciador/licencas/software/buscaSoftwareCliente`, filtros, this.httpOptions)
            .pipe(
                catchError(this.handleError)
            );
    }

    getSoftwareById(filtros): Observable<any> {
        this.httpOptions.params = "";
        return this.http.post(`${this.apiUrl}api/gerenciador/licencas/software/edit`, filtros, this.httpOptions)
            .pipe(
                catchError(this.handleError)
            )
    }

    getSoftwareByIdCliente(filtros): Observable<any> {
        this.httpOptions.params = "";
        return this.http.post(`${this.apiUrl}api/gerenciador/licencas/software`, filtros, this.httpOptions)
            .pipe(
                catchError(this.handleError)
            )
    }

    getBuscaLicencas(params, filtros): Observable<any> {
        this.httpOptions.params = params;
        return this.http.post(`${this.apiUrl}api/gerenciador/licencas/buscaLicencas`, filtros, this.httpOptions)
            .pipe(
                catchError(this.handleError)
            )
    }

    getContadoresLicenca(filtros): Observable<any> {
        this.httpOptions.params = "";
        return this.http.post(`${this.apiUrl}api/gerenciador/licencas/software/contadores`, filtros, this.httpOptions)
            .pipe(
                catchError(this.handleError)
            )
    }

    alterarControleLicenca(payload): Observable<any> {
        this.httpOptions.params = "";
        return this.http.put(`${this.apiUrl}api/gerenciador/licencas/controle-software`, payload, this.httpOptions)
            .pipe(
                catchError(this.handleError)
            )
    }

    atualizaAnexoLicenca(payload): Observable<any> {
        return this.http.put(`${this.apiUrl}api/gerenciador/licencas/atualiza/anexo`, payload, { headers: new HttpHeaders({ 'authorization': this.tokenService.getToken(), 'session': this.tokenService.getSessionUsuario() }) })
            .pipe(
                catchError(this.handleError)
            )
    }

    createLicenca(payload): Observable<any> {
        return this.http.post(`${this.apiUrl}api/gerenciador/licencas`, payload, { headers: new HttpHeaders({ 'authorization': this.tokenService.getToken(), 'session': this.tokenService.getSessionUsuario() }) })
            .pipe(
                catchError(this.handleError)
            )
    }

    deleteLicenca(payload): Observable<any> {
        const _httpOptions = {
            headers: new HttpHeaders({ 'authorization': this.tokenService.getToken(), 'session': this.tokenService.getSessionUsuario() }),
            body: payload
        }
        return this.http.delete(`${this.apiUrl}api/gerenciador/licencas`, _httpOptions)
            .pipe(
                catchError(this.handleError)
            )
    }

    getDispositivosByLicenca(filtro): Observable<any> {
        this.httpOptions.params = "";
        return this.http.post(`${this.apiUrl}api/gerenciador/licencas/dispositivo/`, filtro, this.httpOptions)
            .pipe(
                catchError(this.handleError)
            )
    }

    criaOuAtualizaLicenca(payload): Observable<any> {
        return this.http.post(`${this.apiUrl}api/gerenciador/licencas`, payload, { headers: new HttpHeaders({ 'authorization': this.tokenService.getToken(), 'session': this.tokenService.getSessionUsuario() }) })
            .pipe(
                catchError(this.handleError)
            )
    }

    obterSoftwareCliente(payload): Observable<any> {
        this.httpOptions.params = "";
        return this.http.post(`${this.apiUrl}api/gerenciador/licencas/obter-software`, payload, this.httpOptions)
            .pipe(
                catchError(this.handleError)
            )
    }

    getLicencasInstaladasBySoftware(params, filtros): Observable<any> {
        this.httpOptions.params = params;
        return this.http.post(`${this.apiUrl}api/gerenciador/licencas/software/listagem-dispositivos`, filtros, this.httpOptions)
            .pipe(
                catchError(this.handleError)
            )
    }

    atualizaLicenca(payload): Observable<any> {
        return this.http.put(`${this.apiUrl}api/gerenciador/licencas`, payload, { headers: new HttpHeaders({ 'authorization': this.tokenService.getToken(), 'session': this.tokenService.getSessionUsuario() }) })
            .pipe(
                catchError(this.handleError)
            )
    }

    dropdownLicencas(payload):  Observable<any> {
        this.httpOptions.params = ""
        return this.http.post(`${this.apiUrl}api/gerenciador/licencas/dropdown`, payload, this.httpOptions)
            .pipe(
                catchError(this.handleError)
            )
    }

    atribuirLicencaDispositivo(payload): Observable<any> {
        this.httpOptions.params = ""
        return this.http.post(`${this.apiUrl}api/gerenciador/licencas/atribui-dispositivo`, payload, this.httpOptions)
            .pipe(
                catchError(this.handleError)
            )
    }

    private handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            console.error('Ops! Ocorreu um erro:', error.error.message);
        } else {
            console.error(
                `Backend retornou o código ${error.status}, ` +
                `o body é: ${error.error}`);
        }
        return throwError(
            error.error);
    }

}