import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { TokenService } from '../core/token/token.service';

@Injectable({
  providedIn: 'root'
})
export class ClienteContratoService {
  private apiUrl = environment.apiGestorUrlLocal;
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', 'authorization': this.tokenService.getToken(), 'session': this.tokenService.getSessionUsuario() }),
    params: {}
  };

  constructor(private tokenService: TokenService, private http: HttpClient) { }

  getListagemContratos(params, filtros): Observable<any> {
    this.httpOptions.params = params;
    return this.http.post(`${this.apiUrl}api/clientes/contratos/listagem`, filtros, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  getDropdownPeriodoContrato(): Observable<any> {
    this.httpOptions.params = "";
    return this.http.get(`${this.apiUrl}api/clientes/contratos/dropdown/periodo`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  getDropdownDiaVencimentoContrato(): Observable<any> {
    this.httpOptions.params = "";
    return this.http.get(`${this.apiUrl}api/clientes/contratos/dropdown/data-vencimento`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  getDropdownDiaFaturamentoContrato(): Observable<any> {
    this.httpOptions.params = "";
    return this.http.get(`${this.apiUrl}api/clientes/contratos/dropdown/dias-faturamento`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  getDropdownGatilhoIntegracao(): Observable<any> {
    this.httpOptions.params = "";
    return this.http.get(`${this.apiUrl}api/clientes/contratos/dropdown/gatilho-integracao`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  getDropdownServicosIntegracao(params): Observable<any> {
    this.httpOptions.params = params;
    return this.http.get(`${this.apiUrl}api/clientes/contratos/dropdown/servicos`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  getDropdownCatalogoServicos(): Observable<any> {
    this.httpOptions.params = "";
    return this.http.get(`${this.apiUrl}api/catalogo-servico/dropdown`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  adicionarContrato(contrato): Observable<any> {
    this.httpOptions.params = "";
    return this.http.post(`${this.apiUrl}api/clientes/contratos`, contrato, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  atualizarContrato(contrato): Observable<any> {
    this.httpOptions.params = "";
    return this.http.put(`${this.apiUrl}api/clientes/contratos`, contrato, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  inserirCobrancas(contrato_id, cobrancas): Observable<any> {
    this.httpOptions.params = "";
    return this.http.put(`${this.apiUrl}api/clientes/contratos/cobranca/${contrato_id}`, cobrancas, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  atualizarCobranca(contrato_id, cobranca): Observable<any> {
    this.httpOptions.params = "";
    return this.http.put(`${this.apiUrl}api/clientes/contratos/cobranca/alterar/${contrato_id}`, cobranca, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  obterContrato(contrato_id): Observable<any> {
    this.httpOptions.params = "";
    return this.http.get(`${this.apiUrl}api/clientes/contratos/${contrato_id}`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  getListagemCobrancas(filtros, params): Observable<any> {
    this.httpOptions.params = params;
    return this.http.post(`${this.apiUrl}api/clientes/contratos/cobranca/listagem`, filtros, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  excluirTipoCobranca(contrato_id, tipo_cobranca): Observable<any> {
    return this.http.request('delete', `${this.apiUrl}api/clientes/contratos/cobranca/${contrato_id}`, {
      body: tipo_cobranca,
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'authorization': this.tokenService.getToken(), 'session': this.tokenService.getSessionUsuario() }),
    })
      .pipe(
        catchError(this.handleError)
      );
  }

  excluirContratoCliente(contrato_id): Observable<any> {
    this.httpOptions.params = "";
    return this.http.delete(`${this.apiUrl}api/clientes/contratos/${contrato_id}`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  encerrarContratoCliente(contrato_id): Observable<any> {
    this.httpOptions.params = "";
    return this.http.put(`${this.apiUrl}api/clientes/contratos/encerrar-contrato/${contrato_id}`, '', this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  reenviarIntegracao(contratos): Observable<any> {
    this.httpOptions.params = "";
    return this.http.put(`${this.apiUrl}api/clientes/contratos/reenviar-integracao`, contratos, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  verificarCobrancaExisteCliente(contrato): Observable<any> {
    this.httpOptions.params = "";
    return this.http.post(`${this.apiUrl}api/clientes/contratos/validar-cobranca`, contrato, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('Ops! Ocorreu um erro:', error.error.message);
    } else {
      console.error(
        `Backend retornou o código ${error.status}, ` +
        `o body é: ${error.error}`);
    }
    return throwError(
      error.error);
  }
}
