import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { filter } from 'rxjs/internal/operators/filter';
import { environment } from 'src/environments/environment';
import swal from "sweetalert2";

@Component({
  selector: 'input-tags-padrao',
  templateUrl: './input-tags-padrao.component.html',
  styleUrls: ['./input-tags-padrao.component.scss']
})
export class InputTagsPadraoComponent implements OnInit {

  @Input() set model(value: any){
    if(value == undefined || value === "") return

    this.setaModel(value)

    if(this.selectedValuesCategoryTag.length === 0){
      this.setaModelCategoryTag(value)
    }
    
  }

  @Input() sendAsArray;
  @Input() entidade: string;
  @Input() required;

  @Output() selectChange = new EventEmitter();
  ctrl: Object = {
    selectedValue: {},
    itens: []
  };

  @Output() showError = new EventEmitter();
  
  selectedValues = [];
  selectedValuesCategoryTag = [];
  invalid;

  constructor(public translate: TranslateService, private toastr: ToastrService) { }

  ngOnInit(): void {
    //console.log(this.ctrl)
  }
  
  setaModel(valor) {
    setTimeout(() => {
      document.getElementById('tagsInput').classList.remove('ng-invalid');
      if(this.selectedValues.length > 0) {
        if(typeof(valor) === 'string') {
          if(this.selectedValues.indexOf(valor) !== -1) {
            this.toastr.show(this.translate.instant('EMAIL_JA_ADICIONADO'), '', environment.toastrConfig('danger'));
          } else {
            this.selectedValues.push({display: valor, value: valor});
            document.getElementById('tagsInput').classList.remove('ng-invalid');
          }
        } else {
          valor.forEach(item => {
            if(this.selectedValues.indexOf(item) !== -1) {
              this.toastr.show(this.translate.instant('EMAIL_JA_ADICIONADO'), '', environment.toastrConfig('danger'));
            } else {
              this.selectedValues.push({display: item, value: item});
              document.getElementById('tagsInput').classList.remove('ng-invalid');
            }
          });
        }
      } else {
        if(typeof(valor) === 'string') {
          this.selectedValues = [{display: valor, value: valor}];
        } else {
          valor.forEach(item => {
            this.selectedValues.push({display: item, value: item});
          });
        }
        document.getElementById('tagsInput').classList.remove('ng-invalid');
      }
    }, 1000);
  }

  setaModelCategoryTag(value) {
    setTimeout(() => {
      if(this.selectedValuesCategoryTag.length > 0) {
        if(typeof(value) === 'string') {
          if(this.selectedValuesCategoryTag.indexOf(value) !== -1) {
            this.toastr.show(this.translate.instant('EMAIL_JA_ADICIONADO'), '', environment.toastrConfig('danger'));
          } else {
            this.selectedValuesCategoryTag.push({display: value, value: value});
            document.getElementById('tagsInputCategoria').classList.remove('ng-invalid');
          }
        } else {
          value.forEach(item => {
            if(this.selectedValuesCategoryTag.indexOf(item) !== -1) {
              this.toastr.show(this.translate.instant('EMAIL_JA_ADICIONADO'), '', environment.toastrConfig('danger'));
            } else {
              this.selectedValuesCategoryTag.push({display: item, value: item});
              document.getElementById('tagsInputCategoria').classList.remove('ng-invalid');
            }
          });
        }
      } else {
        if(typeof(value) === 'string') {
          this.selectedValuesCategoryTag = [{display: value, value: value}];
        } else {
          value.forEach(item => {
            this.selectedValuesCategoryTag.push({display: item, value: item});
          });
        }
        document.getElementById('tagsInputCategoria').classList.remove('ng-invalid');
      }
    }, 1000);
  }

  onAdding(event):void {
    if(this.sendAsArray && this.sendAsArray === true) {
      var tagsArray = [];
      this.selectedValues.forEach(element => {
        tagsArray.push(element.value);
      });

      this.selectChange.emit(tagsArray);
    } else {
      var tags = "";
      this.selectedValues.forEach(element => {
        if(tags.length > 0) {
          tags += ','+element.value
        } else {
          tags += element.value
        }
      });

      this.selectChange.emit(tags);
    }
    
    if(this.selectedValues.length === 0) {
      document.getElementById('tagsInput').classList.add('ng-invalid');
    } else {
      document.getElementById('tagsInput').classList.remove('ng-invalid');
    }
  }

  onRemove(event): void {
    this.onAdding(event);
  }
  
  onAddingTag(tag):Observable<any>{
    let pattern = new RegExp(
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/
    );
    this.invalid = tag.match(pattern);
    if(this.invalid === null){
      swal.fire('Email Inválido','','error');
    }   
    return of(tag).pipe(
        filter(() => {
          let pattern = new RegExp(
            /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/
          );
          this.invalid = tag.match(pattern);
          return this.invalid ? true : false;
        })
      );
    } 

    onAddingCategoryTag(event):void {
      if(this.sendAsArray && this.sendAsArray === true) {
        var tagsArray = [];
        this.selectedValuesCategoryTag.forEach(element => {
          tagsArray.push(element.value);
        });
  
        this.selectChange.emit(tagsArray);
      } else {
        var tags = "";
        this.selectedValuesCategoryTag.forEach(element => {
          if(tags.length > 0) {
            tags += ','+element.value
          } else {
            tags += element.value
          }
        });
  
        this.selectChange.emit(tags);
      }
      
      if(this.selectedValuesCategoryTag.length === 0) {
        document.getElementById('tagsInputCategoria').classList.add('ng-invalid');
      } else {
        document.getElementById('tagsInputCategoria').classList.remove('ng-invalid');
      }
    }
  
    onRemoveCategoryTag(event): void {
      this.onAddingCategoryTag(event);
    }

}
