import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SelectPadraoComponent } from "./select-padrao/select-padrao.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { SelectDispositivoComponent } from "./select-dispositivo/select-dispositivo.component";
import { NgSelectModule } from "@ng-select/ng-select";
import { selectFiltroAvancadoComponent } from "./select-filtro-avancado/select-filtro-avancado.component";
import { SelectTreePadraoComponent } from "./select-tree-padrao/select-tree-padrao.component";
import { TreeviewModule } from "ngx-treeview";
import { SelectServicoComponent } from "./select-servico/select-servico.component";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { HttpClient } from "@angular/common/http";
import { SelectEnderecoComponent } from "./select-endereco/select-endereco.component";
import { SelectChamadoComponent } from "./select-chamado/select-chamado.component";
import { SelectPastaScriptComponent } from "./select-pasta-script/select-pasta-script.component";
import { SelectAntivirusComponent } from "./select-antivirus/select-antivirus.component";
import { SelectSoftwareComponent } from "./select-software/select-software.component";
import { SelectTecnicoOnOffComponent } from "./select-tecnicosOnOff/select-tecnicosonoff.component";
import { SelectPerfisComponent } from "./select-perfis/select-perfis.component";
import { SelectComponent } from "./select/select.component";

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    SelectPadraoComponent,
    SelectComponent,
    SelectDispositivoComponent,
    selectFiltroAvancadoComponent,
    SelectTreePadraoComponent,
    SelectServicoComponent,
    SelectEnderecoComponent,
    SelectChamadoComponent,
    SelectAntivirusComponent,
    SelectSoftwareComponent,
    SelectPastaScriptComponent,
    SelectTecnicoOnOffComponent,
    SelectPerfisComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    TreeviewModule.forRoot(),
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  exports: [
    SelectPadraoComponent,
    SelectComponent,
    SelectDispositivoComponent,
    selectFiltroAvancadoComponent,
    SelectTreePadraoComponent,
    SelectServicoComponent,
    SelectEnderecoComponent,
    SelectChamadoComponent,
    SelectAntivirusComponent,
    SelectSoftwareComponent,
    SelectPastaScriptComponent,
    SelectTecnicoOnOffComponent,
    SelectPerfisComponent,
  ],
  providers: [],
})
export class SelectsModule {}
