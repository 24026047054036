import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { TokenService } from '../core/token/token.service';

@Injectable({
  providedIn: 'root'
})
export class FaturamentoService {
  private apiUrl = environment.apiGestorUrlLocal;
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', 'authorization': this.tokenService.getToken(), 'session': this.tokenService.getSessionUsuario()}),
    params: {}
  };
  
  constructor(private http: HttpClient, private tokenService: TokenService) { }

  adicionarParcela(parcela): Observable<any> {
    this.httpOptions.params = "";
    return this.http.post(`${this.apiUrl}api/contratos/parcelas`, parcela, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  listagemFaturas(params, payload): Observable<any> {
    this.httpOptions.params = params;
    return this.http.post(`${this.apiUrl}api/faturas/listagem/meses`, payload, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  faturaPrevia(): Observable<any> {
    this.httpOptions.params = "";
    return this.http.get(`${this.apiUrl}api/faturas/previa`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  exportarFatura(id): Observable<any> {
    let customHttpOptions = {
      headers: this.httpOptions.headers,
      params: {},
    };
    customHttpOptions['responseType'] = "Blob"
    this.httpOptions.params = "";
    return this.http.post(`${this.apiUrl}api/faturas/exportar/pdf/${id}`, '', customHttpOptions)
    .pipe(
      catchError(this.handleError)
    );
  }

  getNotaFiscal(id): Observable<any> {
    this.httpOptions.params = "";
    return this.http.get(`${this.apiUrl}api/faturas/nota-fiscal/${id}`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  getBoleto(id): Observable<any> {
    this.httpOptions.params = "";
    return this.http.get(`${this.apiUrl}api/faturas/boleto/${id}`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('Ops! Ocorreu um erro:', error.error.message);
    } else {
      console.error(
        `Backend retornou o código ${error.status}, ` +
        `o body é: ${error.error}`);
    }
    return throwError(
      error.error);
  }
}
