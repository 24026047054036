import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { ChatService } from 'src/app/services/chat.service';
import { ClienteContatoService } from 'src/app/services/cliente-contato.service';
import { DadosEmpresaService } from 'src/app/services/dados-empresa.service';
import Editor from "src/assets/ckeditor5-41.3.0/build/ckeditor";
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-modal-contatos',
  templateUrl: './modal-contatos.component.html',
  styleUrls: ['./modal-contatos.component.scss']
})
export class ModalContatosComponent implements OnInit, OnDestroy {
  @Input() contato: any;
  @Input() solicitantes: any;
  @Input() origem_chat: boolean;
  @Input() tipo_solicitante_id: any;
  @Input() cliente_id: any;
  @Input() add_via_chat: boolean;
  @Input() multiplo: any;
  newSubs: Subscription;
  contatoForm: UntypedFormGroup = new UntypedFormGroup({
    descricao: new UntypedFormControl(null, Validators.required),
    cpf: new UntypedFormControl({value: null, disabled: true}),
    email: new UntypedFormControl(null),
    codigo_pais: new UntypedFormControl(null),
    codigo_pais_id: new UntypedFormControl(null),
    celular: new UntypedFormControl(null),
    app_celular: new UntypedFormControl([]),
    codigo_pais_telefone: new UntypedFormControl(null),
    codigo_pais_id_telefone: new UntypedFormControl(null),
    telefone: new UntypedFormControl(null),
    app_telefone: new UntypedFormControl([]),
    observacao: new UntypedFormControl(null),
    is_padrao: new UntypedFormControl(false),
    cliente_id: new UntypedFormControl(null),
    tipo_contato_id: new UntypedFormControl(1)
  });
  multiploForm: UntypedFormGroup = new UntypedFormGroup({
    cliente_id: new UntypedFormControl(null, Validators.required),
    contato_selecionado_id: new UntypedFormControl(null, Validators.required)
  });
  public Editor = Editor;
  instanciaEditor;
  public editorConfig;
  dropdownContatos: any = [];
  cliente: any;

  constructor(
    private modal: NgbActiveModal,
    private clienteContatoService: ClienteContatoService,
    private toastr: ToastrService,
    private translate: TranslateService,
    private chatService: ChatService,
    private dadosEmpresaService: DadosEmpresaService
  ) { }

  ngOnInit(): void {
    this.configuraEditor();
    if (!this.contato) {
      this.obterDados();
    }
  }

  ngOnDestroy(): void {
    this.newSubs?.unsubscribe();
  }

  close(result?): void {
    this.modal.close(result);
  }

  ddiCelular(ddi): void {
    this.contatoForm.patchValue({
      codigo_pais: ddi ? ddi.valor : null,
      codigo_pais_id: ddi ? ddi.id : null
    });
    
    if(ddi && ddi.valor === this.contato.celular.slice(0, ddi.valor.length)) {
      this.contatoForm.patchValue({
        celular: this.contato.celular.substring(ddi.valor.length)
      });
    } else {
      this.contatoForm.patchValue({
        celular: this.contato.celular
      });
    }
  }

  ddiTelefone(ddi): void {
    this.contatoForm.patchValue({
      codigo_pais_telefone: ddi ? ddi.valor : null,
      codigo_pais_id_telefone: ddi ? ddi.id : null
    });
  }

  configuraEditor(): void {
    this.editorConfig = {
      toolbar: {
        items: [
          'bold',
          'italic',
          'underline',
          'strikethrough',
          'subscript',
          'superscript',
        ]
      },
      language: 'pt-br',
    };
    
    if(this.add_via_chat) {
      this.contatoForm.patchValue(this.contato);

      if(!this.contato.id && this.contato.email) {
        this.contatoForm.patchValue({
          value: this.contato.email,
          disabled: true
        });
      }

      if(this.contato.from_chat_milvus) {
        this.contatoForm.get('email').addValidators(Validators.required);
      }
      
      if(!this.contato.id || this.contato.app_celular.indexOf(1) === -1) {
        this.contatoForm.patchValue({
          app_celular: [1]
        });
        this.contato.app_celular = [1];
      }

      if(this.contato.codigo_pais_id && this.contato.codigo_pais_id !== 0) {
        this.contatoForm.get('celular').disable();
      }
      
      if(this.solicitantes?.solicitante_cliente && (this.solicitantes?.solicitante_contato || this.solicitantes?.solicitante_operador)) {
        if(!this.contato?.cliente_id && this.contato?.id) {
          this.mudaTipoContato(2, true);
        }
      } else if(!this.solicitantes?.solicitante_cliente && (this.solicitantes?.solicitante_contato || this.solicitantes?.solicitante_operador)) {
        this.mudaTipoContato(2);
      }

      if(this.multiplo) {
        this.dropdownContatos = this.multiplo.contatos;
        if(this.dropdownContatos[0]?.id !== '__add__') {
          this.dropdownContatos.unshift({id: '__add__', text: this.translate.instant('CRIAR_NOVO_CONTATO')});
        }

        if(this.contato.cliente_id) {
          this.multiploForm.patchValue({
            cliente_id: this.contato.cliente_id
          });
        } else if(this.multiplo.clientes && this.multiplo.clientes.length === 1) {
          this.multiploForm.patchValue({
            cliente_id: this.multiplo.clientes[0].id
          });
        }
      }
    } else {
      if(this.contato?.id) {
        this.contatoForm.patchValue(this.contato);
        if(!this.contato.cliente_id) {
          this.mudaTipoContato(2);
        }
      } else if((this.solicitantes && !this.solicitantes.solicitante_cliente) || (this.tipo_solicitante_id && this.tipo_solicitante_id !== 1)) {
        this.mudaTipoContato(2);
      } else if(this.cliente_id) {
        this.contatoForm.patchValue({
          cliente_id: this.cliente_id
        });
      }

      if(this.contato?.codigo_pais) {
        this.contatoForm.patchValue(this.contato);
      }
    }
  }

  appsCelular(valor): void {
    this.contatoForm.patchValue({
      app_celular: valor
    });
  }

  appsTelefone(valor): void {
    this.contatoForm.patchValue({
      app_telefone: valor
    });
  }

  mudaTipoContato(valor, chat_b2b_b2c?): void {
    this.contatoForm.patchValue({'tipo_contato_id': valor});

    if(valor === 1) {
      this.contatoForm.get('cliente_id').addValidators(Validators.required);
      this.contatoForm.patchValue({'cliente_id': null});
      this.contatoForm.get('cpf').disable();
      this.contatoForm.patchValue({'cpf': null});
    } else {
      this.contatoForm.get('cpf').enable();
      this.contatoForm.get('cliente_id').removeValidators(Validators.required);
      this.contatoForm.patchValue({'cliente_id': null});
      
      if(this.contato) {
        this.contato.cliente_id = null;
      }
    }
  }

  mudaCliente(valor): void {
    this.contatoForm.patchValue({'cliente_id': valor?.id ? valor.id : valor});
    this.cliente = valor;
    if(!valor) {
      this.contatoForm.get('cliente_id').addValidators(Validators.required);
    }

    if(this.multiplo) {
      this.multiploForm.patchValue({'cliente_id': valor?.id ? valor.id : valor, 'contato_selecionado_id': null});
      if(valor) {
        this.dropdownContatos = this.multiplo.contatos.filter(contato => {
          if(valor.is_sem_cliente) {
            return !contato.cliente_id;
          } else {
            return contato.cliente_id === valor.id;
          }
        });
        
        if(this.dropdownContatos[0]?.id !== '__add__' ) {
          this.dropdownContatos.unshift({id: '__add__', text: this.translate.instant('CRIAR_NOVO_CONTATO')});
        }
      } else {
        this.dropdownContatos = this.multiplo.contatos;
      }
    }
  }

  onEditorReady(editorInstance): void {
    this.instanciaEditor = editorInstance;
  }

  salvarContato(conversar?): void {
    let payload = {
      clienteContato: this.contatoForm.getRawValue()
    };

    if(conversar) {
      if(!payload.clienteContato.celular && !payload.clienteContato.telefone) {
        this.mostraAviso(this.translate.instant('INICIAR_CONVERSA_AVISO_CONTATO'));
        return;
      } else if(payload.clienteContato.celular && !payload.clienteContato.telefone) {
        if(!payload.clienteContato.app_celular || payload.clienteContato.app_celular.indexOf(1) === -1) {
          this.mostraAviso(this.translate.instant('SELECIONAR_WPP_PARA_CONVERSAR'));
          return;
        } else {
          if(this.verificaDDI(payload)) return;
        }
      } else if(payload.clienteContato.telefone && !payload.clienteContato.celular) {
        if(!payload.clienteContato.app_telefone || payload.clienteContato.app_telefone.indexOf(1) === -1) {
          this.mostraAviso(this.translate.instant('SELECIONAR_WPP_PARA_CONVERSAR'));
          return;
        } else {
          if(this.verificaDDI(payload)) return;
        }
      } else {
        if((!payload.clienteContato.app_celular || payload.clienteContato.app_celular.indexOf(1) === -1) && (!payload.clienteContato.app_telefone || payload.clienteContato.app_telefone.indexOf(1) === -1)) {
          this.mostraAviso(this.translate.instant('SELECIONAR_WPP_PARA_CONVERSAR'));
          return;
        } else {
          if(this.verificaDDI(payload)) return;
        }
      }
    } else {
      if(this.verificaDDI(payload, this.add_via_chat)) return;
    }

    delete payload.clienteContato.tipo_contato_id;
    
    if(this.multiplo) {
      let payload_vinculo = null;
      if(this.multiploForm.value.contato_selecionado_id !== '__add__') {
        payload_vinculo = {
          chat_id: this.contato.chat_id,
          contato_id: this.multiploForm.value.contato_selecionado_id
        };
        
        this.newSubs = this.chatService.vincularChatContato(payload_vinculo)
          .subscribe({
            next: (results) => {
              this.toastr.show(this.translate.instant('SALVO_SUCESSO'), '', environment.toastrConfig('success'));
              this.close({ chat: results.contato, cliente: this.cliente });
            }, error: (error) => {
              console.error(error);
              Swal.fire({
                text: error,
                icon: 'warning',
                confirmButtonText: 'Ok'
              });
            }
          });
      } else {
        payload_vinculo = this.contatoForm.getRawValue();
        payload_vinculo.cliente_id = this.multiploForm.value.cliente_id.is_sem_cliente ? null : this.multiploForm.value.cliente_id;
        
        this.newSubs = this.clienteContatoService.createClienteContato({clienteContato: payload_vinculo})
          .subscribe({
            next: (result) => {
              if(this.add_via_chat) {
                let payload_secundario = {
                  chat_id: this.contato.chat_id,
                  contato_id: result.clienteContato.id
                };
                
                this.newSubs = this.chatService.vincularChatContato(payload_secundario)
                  .subscribe({
                    next: (results) => {
                      this.toastr.show(this.translate.instant('SALVO_SUCESSO'), '', environment.toastrConfig('success'));
                      this.close({ chat: result.clienteContato, cliente: this.cliente });
                    }, error: (error) => {
                      console.error(error);
                      Swal.fire({
                        text: error,
                        icon: 'warning',
                        confirmButtonText: 'Ok'
                      });
                    }
                  });
              } else {
                this.toastr.show(this.translate.instant('SALVO_SUCESSO'), '', environment.toastrConfig('success'));
                this.close({ chat: result.clienteContato, cliente: this.cliente });
              }
            }, error: (error) => {   
              console.error(error);     
              Swal.fire({
                text: error,
                icon: 'warning',
                confirmButtonText: 'Ok'
              });
            }
          });
      }
    } else {
      if(this.contato?.id) {
        payload.clienteContato.id = this.contato.id;
        this.newSubs = this.clienteContatoService.update(payload)
          .subscribe({
            next: (result) => {
              this.toastr.show(this.translate.instant('SALVO_SUCESSO'), '', environment.toastrConfig('success'));
              this.close({ chat: result.clienteContato, cliente: this.cliente, conversar: conversar });
            }, error: (error) => {
              console.error(error);
              Swal.fire({
                text: error,
                icon: 'warning',
                confirmButtonText: 'Ok'
              });
            }
          });
      } else {
        this.newSubs = this.clienteContatoService.createClienteContato(payload)
          .subscribe({
            next: (result) => {
              if(this.add_via_chat) {
                let payload_secundario = {
                  chat_id: this.contato.chat_id,
                  contato_id: result.clienteContato.id
                };
                
                this.newSubs = this.chatService.vincularChatContato(payload_secundario)
                  .subscribe({
                    next: (results) => {
                      this.toastr.show(this.translate.instant('SALVO_SUCESSO'), '', environment.toastrConfig('success'));
                      this.close({ chat: result.clienteContato, cliente: this.cliente, conversar: conversar });
                    }, error: (error) => {
                      console.error(error);
                      Swal.fire({
                        text: error,
                        icon: 'warning',
                        confirmButtonText: 'Ok'
                      });
                    }
                  });
              } else {
                this.toastr.show(this.translate.instant('SALVO_SUCESSO'), '', environment.toastrConfig('success'));
                this.close({ chat: result.clienteContato, cliente: this.cliente, conversar: conversar });
              }
            }, error: (error) => {   
              console.error(error);     
              Swal.fire({
                text: error,
                icon: 'warning',
                confirmButtonText: 'Ok'
              });
            }
          });
      }
    }

  }

  verificaDDI(payload, is_multiplo?): boolean {
    if(payload.clienteContato.celular && payload.clienteContato.celular.substring(0, 4) !== '0800' && !payload.clienteContato.codigo_pais) {
      this.toastr.show(this.translate.instant('FALTANDO_DDI_CELULAR'), '', environment.toastrConfig('danger'));
      return true;
    } else if(!is_multiplo && payload.clienteContato.telefone && payload.clienteContato.telefone.substring(0, 4) !== '0800' && !payload.clienteContato.codigo_pais_telefone) {
      this.toastr.show(this.translate.instant('FALTANDO_DDI_TELEFONE'), '', environment.toastrConfig('danger'));
      return true;
    } else {
      if(payload.clienteContato.celular && payload.clienteContato.celular.substring(0, 4) == '0800') {
        payload.clienteContato.codigo_pais = null
        payload.clienteContato.codigo_pais_id = null
      }
      if(payload.clienteContato.telefone && payload.clienteContato.telefone.substring(0, 4) == '0800') {
        payload.clienteContato.codigo_pais_telefone = null
        payload.clienteContato.codigo_pais_id_telefone = null
      }
      return false;
    }
  }

  mostraAviso(msg): void {
    Swal.fire({
      text: msg,
      icon: 'warning',
      confirmButtonText: 'Ok',
    });
  }

  verificaCampoCpf(valor): void {
    if(valor === '__add__') {
      this.contatoForm.get('cpf').enable();
    } else {
      this.contatoForm.get('cpf').disable();
    }
  }

  verificaDesabilitaFormMultiplo(): boolean {
    if(this.multiploForm.get('contato_selecionado_id').value === '__add__') {
      return this.multiploForm.invalid || this.contatoForm.invalid;
    } else {
      return this.multiploForm.invalid;
    }
  }

  obterDados() {
    this.newSubs = this.dadosEmpresaService.obterDados().subscribe(
      (result) => {
        this.newSubs = this.clienteContatoService.dropDownCodigoDdiPais()
          .subscribe({
            next: (results) => {
              let codigo_pais = results.dropDown.filter(item => item.id === result.empresa.codigo_pais_id);
              this.contato = {};
              this.contato['codigo_pais_id'] = result.empresa.codigo_pais_id ? result.empresa.codigo_pais_id : null;
              this.contato['codigo_pais'] = codigo_pais[0].valor ? codigo_pais[0].valor : null;
              this.contato['codigo_pais_id_telefone'] = result.empresa.codigo_pais_id ? result.empresa.codigo_pais_id : null;
              this.contato['codigo_pais_telefone'] = codigo_pais[0].valor ? codigo_pais[0].valor : null;
            }, error: (error) => {
              console.error(error);
              this.toastr.show(error?.error_msg ? error.error_msg : error, '', environment.toastrConfig('danger'));
            }
          })
      },
      (error) => {
        this.toastr.show(error, "", environment.toastrConfig("danger"));
        console.log(error)
      }
    )
  }
}
