import { Pipe } from '@angular/core';

@Pipe({
  name: 'ellipsis'
})
export class EllipsisPipe {
  transform(val, args: number|Array<any>) {
    if (args === undefined) {
      return val;
    }
    if(val){
      if(typeof(args) === 'object') {
        if(val.length > args[0]) {
          let _ellipsis = val.length - args[0];
          return '...' + val.substring(_ellipsis, val.length);
        } else {
          return val;
        }
      } else {
        if (val.length > args) {
          return val.substring(0, args) + '...';
        } else {
          return val;
        }
      }
    }

  }
}