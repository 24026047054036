<div class="input-group">
    <input *ngIf="!isSLA" type="text" #hourPicker placeholder="HH:MM" [(ngModel)]="hourValue" class="form-control" (change)="emitValue(hourValue)" [ngClass]="{'required': verificaRequerido()}" [disabled]="(disabled) ? disabled : 'false'" mask="00:m0 || 000:m0 || 0000:m0">
    <input *ngIf="isSLA" type="text" #hourPicker placeholder="HHHH:MM" [(ngModel)]="hourValue" class="form-control" (change)="emitValue(hourValue)" [ngClass]="{'required': verificaRequerido()}" [disabled]="(disabled) ? disabled : 'false'" mask="00:00 || 000:00 || 0000:m0" (keyup)="formatHourSla($event)">
    <div class="input-group-append" *ngIf="!disabled">
        <span class="input-group-text" *ngIf="!isSLA">
            <div class="dropdown" dropdown [dropup]="isDropup">
                <i class="far fa-clock" style="font-size: 16px; color: gray; cursor: pointer;" id="dropdownMenuButton" dropdownToggle></i>
                <div aria-labelledby="dropdownMenuButton" *dropdownMenu class="dropdown-menu" style="max-height: 200px; overflow-y: none; margin-left: -160px; margin-top: 11px;">
                    <div style="display: flex; justify-content: space-evenly;">
                        <div style="margin-right: 11px;">{{'HORAS' | translate}}</div>
                        <div>:</div>
                        <div>{{'MINUTOS' | translate}}</div>
                    </div> 
                    <div style="display: flex;">
                        <div style="max-height: 168px; overflow-y: auto; width: 96px; text-align: center; padding: 0px 7px 0px 7px;">
                            <a *ngFor="let hora of dropHoras" class="dropdown-item drop-horas" href="javascript:void(0)" (click)="setHours(hora, $event.target); $event.stopPropagation()">{{hora}}h</a>
                        </div>
                        <div style="max-height: 168px; overflow-y: auto; width: 95px; text-align: center; padding: 0px 7px 0px 7px;">
                            <a #M00 class="dropdown-item drop-minutos" href="javascript:void(0)" (click)="setMinutes('00', M00)">00m</a>
                            <a #M05 class="dropdown-item drop-minutos" href="javascript:void(0)" (click)="setMinutes('05', M05)">05m</a>
                            <a #M10 class="dropdown-item drop-minutos" href="javascript:void(0)" (click)="setMinutes('10', M10)">10m</a>
                            <a #M15 class="dropdown-item drop-minutos" href="javascript:void(0)" (click)="setMinutes('15', M15)">15m</a>
                            <a #M20 class="dropdown-item drop-minutos" href="javascript:void(0)" (click)="setMinutes('20', M20)">20m</a>
                            <a #M25 class="dropdown-item drop-minutos" href="javascript:void(0)" (click)="setMinutes('25', M25)">25m</a>
                            <a #M30 class="dropdown-item drop-minutos" href="javascript:void(0)" (click)="setMinutes('30', M30)">30m</a>
                            <a #M35 class="dropdown-item drop-minutos" href="javascript:void(0)" (click)="setMinutes('35', M35)">35m</a>
                            <a #M40 class="dropdown-item drop-minutos" href="javascript:void(0)" (click)="setMinutes('40', M40)">40m</a>
                            <a #M45 class="dropdown-item drop-minutos" href="javascript:void(0)" (click)="setMinutes('45', M45)">45m</a>
                            <a #M50 class="dropdown-item drop-minutos" href="javascript:void(0)" (click)="setMinutes('50', M50)">50m</a>
                            <a #M55 class="dropdown-item drop-minutos" href="javascript:void(0)" (click)="setMinutes('55', M55)">55m</a>
                        </div>
                    </div>
                </div>
            </div>
        </span>
    </div>
</div>
