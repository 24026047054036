import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { FiltroPadraoService } from './../../../../services/filtro-padrao.service';
import { OnInit } from '@angular/core';
import { ElementRef } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Component } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { QuillEditorComponent } from 'ngx-quill';
import { QuillButtonOptionsModel } from 'src/app/models/quill/quillButtonOptions.model';
import { QuillConfigModel } from 'src/app/models/quill/quillConfig.model';

@Component({
  selector: 'app-modal-enviar-feedback',
  templateUrl: './modal-enviar-feedback.component.html',
  styleUrls: ['./modal-enviar-feedback.component.scss']
})
export class ModalEnviarFeedbackComponent implements OnInit, OnDestroy {
  sendFeedbackForm: UntypedFormGroup = new UntypedFormGroup({
    description: new UntypedFormControl("", Validators.required),
  });
  selector: string;
  description: string = '';
  newSubs: Subscription;
  quillConfigEditor: QuillConfigModel;
  editor: QuillEditorComponent;
  textEditorDisabledImage = true;

  constructor(
    public modal: NgbActiveModal,
    private elemRef: ElementRef,
    private filtroPadraoService: FiltroPadraoService,
    private toastr: ToastrService,
    private translate: TranslateService
  ) { this.selector = this.elemRef.nativeElement.tagName.toLowerCase(); }

  ngOnInit(): void {
    document.getElementsByTagName(this.selector)[0]["style"]["zoom"] = "0.85";
    this.setQuillEditorConfig();
    this.eventPaste();  
  }

  ngOnDestroy(): void {
    this.textEditorDisabledImage = false;
    document.removeEventListener('paste', this.eventPaste, false); 
    this.newSubs?.unsubscribe();
  }

  eventPaste() {
    let _this = this;
    document.addEventListener('paste', function(event) {   
      var text = event.clipboardData.getData(
        "text/html"
      );
      const parser = new DOMParser();
      const doc = parser.parseFromString(text, "text/html");
      var image = doc.querySelector("img");
      if (image && _this.textEditorDisabledImage) {
        event.preventDefault();
        return;
      }    
    }, false);
  }

  setQuillEditorConfig() {
    this.quillConfigEditor = {
      magicUrl: true,
      imageDrop: false,
      ImageResize: { modules: ["Resize", "DisplaySize", "Toolbar"] },
      toolbar: {
        container: [
          ["bold", "italic", "underline", "strike"],
          /* [
            { align: [] },
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" },
          ], */

          //["code-block", "link", "image"],
        ],
        handlers: { emoji: function () {} },
      },
      "toolbar-custom-button": new QuillButtonOptionsModel(),
      "emoji-toolbar": true,
      "emoji-textarea": false,
      "emoji-shortname": true,

      // mention: {
      //   allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
      //   onSelect: (item, insertItem) => {
      //     const editor = this.editor.quillEditor;
      //     insertItem(item);
      //     editor.insertText(editor.getLength() - 1, "", "user");
      //   },
      //   source: (searchTerm, renderList) => {
      //     const values = [
      //       { id: 1, value: "Fredrik Sundqvist" },
      //       { id: 2, value: "Patrik Sjölin" },
      //     ];

      //     if (searchTerm.length === 0) {
      //       renderList(values, searchTerm);
      //     } else {
      //       const matches = [];

      //       values.forEach((entry) => {
      //         if (
      //           entry.value.toLowerCase().indexOf(searchTerm.toLowerCase()) !==
      //           -1
      //         ) {
      //           matches.push(entry);
      //         }
      //       });
      //       renderList(matches, searchTerm);
      //     }
      //   },
      // },
      keyboard: {
        bindings: {
          // shiftEnter: {
          //   key: 13,
          //   shiftKey: true,
          //   handler: (range, context) => {
          //     // Handle shift+enter
          //     console.log("shift+enter")
          //   }
          // },
          enter: {
            key: 13,
            handler: (range, context) => {
              return true;
            },
          },
        },
      },
    };
  }
  
  checkImage(html: string): boolean {
    return html && html.match(/<img/) && html.match(/<img/).length > 0;
  }

  checkFields(formName: string): boolean {
    if (this.sendFeedbackForm.get(formName).value) {
      let _cleanText = this.sendFeedbackForm.get(formName).value && this.sendFeedbackForm.get(formName).value.text ? this.sendFeedbackForm.get(formName).value.text.replaceAll('/n', '') : '';
      let _html = this.sendFeedbackForm.get(formName).value && this.sendFeedbackForm.get(formName).value.html ? this.sendFeedbackForm.get(formName).value.html: '';
      if((!_html || _cleanText.length <= 5) && !this.checkImage(_html)) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  enviarFeedback(feedback): void {
    this.newSubs = this.filtroPadraoService.sendFeedbackFiltros({description: feedback})
      .subscribe(
        (result) => {
          this.toastr.show(this.translate.instant('FEEDBACK_ENVIADO_SUCESSO'), '', environment.toastrConfig('success'));
          this.modal.close(true);
        }, (error) => {
          this.toastr.show(error.error, '', environment.toastrConfig('danger'));
        }
      )
  }
}
