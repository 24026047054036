<div class="custom-modal-top">
    <div class="custom-modal-header">
        <h6 class="custom-modal-title">
            {{ (!templateMessage ? 'INICIAR_CONVERSA_WPP' : 'TEMPLATES_DO_META') | translate}} 
            {{' ' + (data.number | mask: getMaskByCountry(data.number))}}
        </h6>
        <button type="button" class="custom-close" (click)="close()">
            <i class="mdi mdi-close"></i>
        </button>
    </div>
    <div class="custom-modal-body">
        <!-- Linha de alerta com ícone e texto -->
        <div *ngIf="isToShowSpecificMessageFromMeta" class="alert-message">
            <i class="fa-regular fa-circle-exclamation alert-icon"></i>
            <span class="alert-text">
                Pode ser que o destinatário ainda não respondeu ao seu template, aguarde a resposta. Ou sua
                conversa pode estar expirada, por favor, envie novamente um template para continuar a conversa.
            </span>
        </div>
        <ng-container *ngIf="data.provider_id === 4; else zapfy">
            <div class="custom-row divider-bottom">
                <div class="col-6 form-group">
                    <label>Template</label>
                    <select-padrao [entidade]="'selectWppOfficialTemplate'" [returnObj]="true" [params]="data.integration_id" (selectChange)="changeTemplateProperties($event)"></select-padrao>
                </div>
            </div>
            <div *ngIf="template_content" class="custom-row mt-2">
                <div class="col-12 form-group">
                    <label [translate]="'MENSAGEM'"></label>
                    <br>
                    <span class="">{{template_content}}</span>
                </div>
            </div>
        </ng-container>
        <ng-template #zapfy>
            <div class="custom-row">
                <div class="col-12 form-group">
                    <label [translate]="'MENSAGEM'"></label>
                    <textarea class="form-control mt-2" rows="10" [(ngModel)]="content.mensagem"></textarea>
                </div>
            </div>
        </ng-template>        
    </div>
    <div class="custom-modal-footer">
        <button type="button" class="btn btn-success badge-pill" [translate]="'INICIAR_CONVERSA'" (click)="close(content)" [disabled]="verifyDisabled()"></button>
    </div>
</div>