import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-label',
  templateUrl: './label.component.html',
  styleUrls: ['./label.component.scss']
})
export class LabelComponent implements OnInit {
  @Input() labelAutocomplete?: string;
  @Input() labelClass?: string;
  @Input() labelDisabled?: boolean = false;
  @Input() labelFor?: string;
  @Input() labelIcon?: string;
  @Input() labelModel?: any;
  @Input() labelName?: any;
  @Input() labelNgClass?: string;
  @Input() labelOptions?: string;
  @Input() labelPlaceholder?: string;
  @Input() labelPrefix?: string;
  @Input() labelReadonly?: boolean = false;
  @Input() labelRequired?: boolean = false;
  @Input() labelSuffix?: string;
  @Input() labelTooltip?: string;
  @Input() labelType: string = "base";

  ngOnInit() {    
  }

}
