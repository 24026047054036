<div class="modal-content">
  <div class="modal-header">
    <span *ngIf="!chatData">
      <h6
        class="modal-title"
        *ngIf="!verificaPai()"
        id="modal-title-default"
        [translate]="'NOVO_TICKET'"
      ></h6>
      <h6 class="modal-title" *ngIf="verificaPai()" id="modal-title-default">
        {{
          "NOVO_TICKET_VINCULADO"
            | translate : { codigo: abrir_ticket_pai.codigo }
        }}
      </h6>
    </span>
    <span *ngIf="chatData">
      <h6
        class="modal-title"
        *ngIf="!verificaChamadoVinculado(chatData)"
        id="modal-title-default"
        [translate]="'ABRIR_TICKET'"
      ></h6>
      <h6
        class="modal-title"
        *ngIf="verificaChamadoVinculado(chatData)"
        id="modal-title-default"
      >
        {{ "TICKET" | translate }} #{{ chamado.codigo }}
      </h6>
    </span>
    <button
      type="button"
      class="close"
      data-dismiss="modal"
      aria-label="Close"
      (click)="modal.close()"
    >
      <i class="mdi mdi-close"></i>
    </button>
  </div>
  <div class="modal-body">
    <div class="row mb-4" *ngIf="existe_bloqueio && chamado.cliente_id">
      <div class="col-12">
        <div
          class="w-100 label-block-danger"
          style="padding-left: 10px"
          [translate]="'CLIENTE_POSSUI_BLOQUEIO_CRIACAO'"
        ></div>
      </div>
    </div>
    <div class="row mb-4" *ngIf="existe_alerta && chamado.cliente_id">
      <div class="col-12">
        <div
          class="w-100 label-block-warning"
          style="padding-left: 10px"
          [translate]="'CLIENTE_POSSUI_INADIMPLENCIA'"
        ></div>
      </div>
    </div>
    <div
      class="row mb-4"
      *ngIf="
        verificaVisualizacao(16) &&
        verificaRequerido(16) &&
        business_unit &&
        business_unit.length === 0
      "
    >
      <div class="col-12">
        <div
          class="w-100 label-block-danger"
          style="padding-left: 10px"
          [translate]="'CLIENTE_SEM_UNIDADE_NEGOCIO'"
        ></div>
      </div>
    </div>
    <div
      class="row mb-4"
      *ngIf="
        verificaVisualizacao(16) &&
        !verificaRequerido(16) &&
        business_unit &&
        business_unit.length === 0
      "
    >
      <div class="col-12">
        <div
          class="w-100 label-block-warning"
          style="padding-left: 10px"
          [translate]="'AVISO_CLIENTE_SEM_UNIDADE_NEGOCIO'"
        ></div>
      </div>
    </div>
    <div class="row">
      <div class="col-8">
        <div class="row">
          <div>
            <div class="form-group">
              <label>{{labelsCamposSistema.nome_cliente}}</label>
              <select-padrao
                id="modal-abertura-ticket-cliente"
                [entidade]="'selectTipoSolicitante'"
                [customItem]="chamado.cliente_id"
                [required]="true"
                [returnObj]="true"
                [model]="chamado.cliente_id"
                [disabled]="verificaChamadoVinculado(chatData)"
                (selectChange)="
                  chamado.cliente_id = $event;
                  getInfos($event, true);
                  getUltimosTickets($event);
                  validacaoGatilho();
                "
              ></select-padrao>
            </div>
          </div>          
        </div>
        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <label>{{labelsCamposSistema.assunto_chamado}}</label>
              <input
                id="modal-abertura-ticket-assunto"
                type="text"
                class="form-control"
                [disabled]="verificaChamadoVinculado(chatData)"
                [ngClass]="{ required: verifyAssunto() }"
                [(ngModel)]="chamado.assunto"
                placeholder="{{ 'ASSUNTO' | translate }}"
                maxlength="100"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <form [formGroup]="newTicketForm" class="col-12">
            <div class="form-group">
              <label>{{labelsCamposSistema.descricao}}</label>
              <app-text-editor
                id="modal-abertura-ticket-descricao"
                [textEditorDisabled]="verificaChamadoVinculado(chatData)"
                formControlName="description"
                [textEditorConfig]="quillConfigEditor"
                [textEditorRequired]="verifyDescricao()"
              >
              </app-text-editor>
            </div>
          </form>
        </div>
        <span *ngIf="chatData">
          <div class="row">
            <form [formGroup]="newTicketForm" class="col-12">
              <div class="form-group">
                <label>
                  {{'REALIZADO' | translate}}
                  <span class="chat-info-transferencia">{{'NECESSARIO_PREENCHER_SALVAR_FINALIZAR' | translate}}</span>
                </label>
                <app-text-editor
                  id="modal-abertura-ticket-realizado"
                  formControlName="accomplished"
                  [textEditorConfig]="quillConfigEditor"
                  [textEditorRequired]="false">
                </app-text-editor>
              </div>
            </form>
          </div>
          <div class="row">
            <div class="col-3">
              <div class="form-group">
                <label [translate]="'TIPO_HORAS'"></label>
                <select
                  class="form-control"
                  [(ngModel)]="chat.atendimento.is_externo"
                  id="modal-abertura-ticket-tipo-horas"
                >
                  <option value="false" [translate]="'INTERNO'"></option>
                  <option value="true" [translate]="'EXTERNO'"></option>
                </select>
              </div>
            </div>
            <div class="col-3">
              <div class="form-group">
                <label [translate]="'HORARIO_COMERCIAL'"></label>
                <select
                  class="form-control"
                  [(ngModel)]="chat.atendimento.is_comercial"                  
                  id="modal-abertura-ticket-horario-comercial"
                >
                  <option value="true" [translate]="'SIM'"></option>
                  <option value="false" [translate]="'NAO'"></option>
                </select>
              </div>
            </div>
            <div class="col-3">
              <div class="form-group">
                <label [translate]="'HORAS'"></label>
                <hourpicker-padrao                
                  id="modal-abertura-ticket-horas"
                  [model]="chat.atendimento.total_horas"
                  [required]="false"
                  (hourChange)="chat.atendimento.total_horas = $event"
                ></hourpicker-padrao>
              </div>
            </div>
            <div class="col-3">
              <div class="form-group">
                <label [translate]="'PRIVADO'"></label>
                <div class="col-12 row">
                  <label class="switch">
                    <!-- <input type="checkbox" [checked]="checked" (change)="check($event)"/> -->
                    <input                                    
                      id="modal-abertura-ticket-privado"
                      type="checkbox"
                      [checked]="chat.is_privado"
                      (change)="chamado.is_privado = $event.target.checked"
                    />
                    <span class="slider round"></span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <label>
                  {{'MOTIVO' | translate}}
                  <span class="chat-info-transferencia">{{'NECESSARIO_PREENCHER_SALVAR_FINALIZAR' | translate}}</span>
                </label>
                <select-padrao     
                  id="modal-abertura-ticket-motivo"
                  [entidade]="'customContent'"
                  (selectChange)="getMotivo($event)"
                  [params]="tabulacaoLista"
                  [required]="false"
                  [returnObj]="false"
                ></select-padrao>
              </div>
            </div>
          </div>
        </span>

        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <label>{{labelsCamposSistema.chamado_anexo}}</label>
            </div>
          </div>
        </div>
        <div class="row" appDnd (fileDropped)="onFileDropped($event)">
          <div
            class="col-5 mb-4"
            *ngFor="let file of filesSelected; let i = index"
          >
            <button
              type="button"
              class="btn btn-sm-rounded-milvus bg-danger"
              style="position: absolute; right: 13px; top: -13px; color: white"
              data-dismiss="modal"
              aria-label="Close"
              (click)="excluirAnexo(i)"
            >
              <i class="fas fa-times"></i>
            </button>
            <div class="preview-upload-file">
              <div
                class="div-img-anexo"
                *ngIf="
                  file.type === 'image/jpeg' ||
                  file.type === 'image/png' ||
                  file.type === 'image/svg+xml'
                "
              >
                <img class="mt-4" [src]="file.url" style="width: 100px" />
              </div>
              <audio
                controls
                class="mt-4"
                *ngIf="file.type === 'audio/mp3'"
                style="width: 240px"
              >
                <source [src]="file.url" type="audio/mpeg" />
              </audio>
              <video
                controls
                class="mt-3"
                style="justify-self: center"
                *ngIf="file.type === 'video/mp4'"
                width="166"
                height="102"
              >
                <source [src]="file.url" type="video/mp4" />
              </video>
              <i
                *ngIf="file.type === 'application/pdf'"
                class="far fa-file-pdf icon-anexo mt-4"
              ></i>
              <i
                *ngIf="file.type === 'application/zip'"
                class="far fa-file-archive icon-anexo mt-4"
              ></i>
              <i
                *ngIf="file.type === 'application/vnd.ms-excel'"
                class="far fa-file-excel icon-anexo mt-4"
              ></i>
              <i
                *ngIf="
                  file.type !== 'image/jpeg' &&
                  file.type !== 'image/png' &&
                  file.type !== 'image/svg+xml' &&
                  file.type !== 'application/pdf' &&
                  file.type !== 'application/zip' &&
                  file.type !== 'application/vnd.ms-excel' &&
                  file.type !== 'audio/mp3' &&
                  file.type !== 'video/mp4'
                "
                class="far fa-file icon-anexo mt-4"
              ></i>
              <span style="margin-top: 0 !important; font-size: 13px; margin-left: 15px;" container=".modal-body" [ngbTooltip]="file.name">{{ file.name | ellipsis: 50 }}</span>
            </div>
            <button
              type="button"
              class="btn btn-sm-rounded-milvus btn-light"
              style="position: absolute; left: 20px; top: 135px; color: black"
              data-dismiss="modal"
              aria-label="Close"
              (click)="downloadAnexo(file)"
              *ngIf="
                file &&
                file.url &&
                file.url.changingThisBreaksApplicationSecurity
              "
            >
              <i class="fa fa-download"></i>
            </button>
          </div>
          <div class="col-5">
            <div
              class="inputFileArea"
              (click)="triggerFileInput()"
              container=".modal-body" [ngbTooltip]="tooltipAttachment"
              tooltipClass="tooltip_attachment"
            >
              <div class="anexo-label">
                <i _ngcontent-yvq-c100="" class="fas fa-paperclip fa-3x icon-chat-opt ng-star-inserted" style="margin-bottom: 20px;"></i>
                <span [translate]="'CLIQUE_ARRASTE_ARQUIVO'"></span>
              </div>
            </div>
            <input
              id="fileInputCriarChamado"
              type="file"
              accept="{{ uploadFileType }}"
              (change)="changeFile($event.target.files)"
              multiple
            />
          </div>
        </div>
      </div>
      <div class="col-4 divider-left">
        <!-- <div class="row" *ngIf="verificaVisualizacao(16)">
          <div class="form-group">
            <label [translate]="'UNIDADE_NEGOCIO'"></label>
            <select-padrao
              id="modal-abertura-ticket-unidade-negocio"
              [entidade]="'selectBusinessUnitCustom'"
              [returnObj]="true"
              [params]="business_unit"
              (selectChange)="chamado.endereco_id = $event; validacaoGatilho();"
            ></select-padrao>
          </div>
        </div> -->
        <div class="row mb-2">
          <div
            *ngIf="chamado.cliente_id "
            class="col-10"
          >
            <span *ngIf="chamado.cliente_id && chamado.cliente_id.text">
              <i *ngIf="chamado.cliente_id.tipo_solicitante_id === 1" class="fa-regular fa-apartment mr-2"></i>
              <i *ngIf="chamado.cliente_id.tipo_solicitante_id === 3" class="fa-regular fa-user-headset mr-2"></i>
              <i *ngIf="chamado.cliente_id.tipo_solicitante_id === 2" class="far fa-user mr-2"></i>
              {{ chamado.cliente_id.text }}
            </span>
          </div>
          <div
            *ngIf="chamado.cliente_id && chamado.cliente_id.text"
            class="col text-right"
          >
            <i
              *ngIf="chamado.cliente_id.tipo_solicitante_id === 1"
              container=".modal-body" ngbTooltip="{{ 'INFOS_CLIENTE' | translate }}"
              style="
                position: absolute;
                font-size: 25px;
                right: 18px;
                cursor: pointer;
              "
              class="fas fa-eye"
              (click)="abrirModalInfosCliente(chamado.cliente_id.id)"
              placement="left"
            ></i>
          </div>
          <div
            *ngIf="chamado.cliente_id && chamado.cliente_id.tipo_solicitante_id === 1 && showFieldSituacaoContrato"
            class="col-12"
          >
            <div class="progress-wrapper">
              <div class="progress-info">
                <div
                  class="progress"
                  container=".modal-body" ngbTooltip="{{ infosValidacaoCliente.limite_contrato + '%' }}"
                  tooltipClass="custom-bottom-tooltip"
                  style="width: 100%"
                >
                  <div
                    class="progress-bar bg-info"
                    [ngClass]="{
                      'bg-success': infosValidacaoCliente.limite_contrato < 24,
                      'bg-info':
                        infosValidacaoCliente.limite_contrato > 24 &&
                        infosValidacaoCliente.limite_contrato < 49,
                      'bg-warning':
                        infosValidacaoCliente.limite_contrato > 49 &&
                        infosValidacaoCliente.limite_contrato > 49 &&
                        infosValidacaoCliente.limite_contrato < 74,
                      'bg-danger': infosValidacaoCliente.limite_contrato > 74
                    }"
                    role="progressbar"
                    aria-valuenow="infosValidacaoCliente.limite_contrato"
                    aria-valuemin="0"
                    aria-valuemax="100"
                    [ngStyle]="{
                      width: infosValidacaoCliente.limite_contrato + '%'
                    }"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <div class="row">
          <div class="col-12">
            <div class="form-group seguir-ticket" *ngIf="!chamado.cliente_id">
              <label [translate]="'SEGUIR_TICKET'"></label>
              <label class="custom-toggle" (click)="$event.stopPropagation()">
                <input                  
                  id="modal-abertura-ticket-seguir-ticket"
                  class="custom-toggle-input"
                  type="checkbox"
                  [(ngModel)]="chamado.seguir_automaticamente"
                />
                <span
                  *ngIf="chamado.seguir_automaticamente === true"
                  class="custom-toggle-slider rounded-circle label-seguir-sim"
                  >{{ "SIM" | translate }}</span
                >
                <span
                  *ngIf="chamado.seguir_automaticamente === false"
                  class="custom-toggle-slider rounded-circle text-right label-seguir-nao"
                  >{{ "NAO" | translate }}</span
                >
              </label>
            </div>
          </div>
        </div>

        <div *ngFor="let campoCustom of camposCustomizaveis; trackBy:trackByIndex; let i = index;" class="row">
          <div class="col-12">
            <div *ngIf="campoCustom.is_visivel && verifyChaveDontShow(campoCustom.chave)" class="form-group">   
              <label> {{ campoCustom.label }} </label>

              <ng-container *ngIf="campoCustom.chave === '@Contatos'">
                <div class="row">
                  <div class="col-11">
                    <div class="form-group" style="margin-bottom: 0rem;">
                      <div *ngIf="campoCustom.valor && campoCustom.valor.length > 0 && chamado.cliente_id">
                        <select-padrao [entidade]="'selectClienteContatoB2c'" [params]="{solicitante_id: chamado.cliente_id.id, tipo_solicitante_id: chamado.cliente_id.tipo_solicitante_id}" [returnObj]="true" [limparModel]="novoContatoAdd" (selectChange)="adicionaContato($event)"></select-padrao>
                        <div class="mt-1" style="max-height: 162px; overflow-y: auto; width: 102%;">
                          <div *ngFor="let item of campoCustom.valor; let i = index" class="tag-simulada col-4" [ngClass]="{ 'required': (campoCustom.valor.length === 0), 'contato-tag-hard-bounce': item.bounce_id === 2}">
                            <div style="width: 108px;">
                              <div class="d-flex w-100 justify-content-between">
                                <span container=".modal-body" [ngbTooltip]="item.bounce_id === 2 ? (item.descricao + '. ' + translate.instant('CONTATO_HARD_BOUNCE')) : item.descricao" placement="bottom" style="text-overflow: ellipsis;  overflow: hidden; white-space: nowrap;">
                                  {{ item.descricao }}
                                </span>
                                <i *ngIf="chamado.status_id !== 4" class="fas fa-times" (click)="removeContato(item, i)"
                                  style="cursor: pointer"></i>
                              </div>
                              <div class="d-flex" *ngIf="item.email !== null && item.email !== ''">
                                <i class="far fa-envelope" 
                                  style="font-size: 12px; margin-top: 4px; margin-right: 4px;"></i>
                                <span container=".modal-body" [ngbTooltip]="item.email"
                                 style="cursor: pointer; text-decoration: underline; text-transform: lowercase; font-weight: 100; text-overflow: ellipsis;  overflow: hidden; white-space: nowrap;"
                                >
                                  {{item.email}}
                                </span>
                              </div>
                              <div class="d-flex" 
                                *ngIf="item.telefone"
                              >
                                <i 
                                  class="fas fa-phone" 
                                  container=".modal-body" [ngbTooltip]="
                                  item.telefone && item.telefone.substring(0, 4) == '0800'
                                  ? (item.telefone | mask : '0000 000 0000')
                                  : (item.telefone | mask : '(00) 0000-0000')" 
                                  style="font-size: 12px; margin-top: 4px; margin-right: 4px;" (click)="ligarNumeroContato(item)"
                                ></i>
                                <span
                                  container=".modal-body" [ngbTooltip]="
                                  item.telefone && item.telefone.substring(0, 4) == '0800'
                                    ? (item.telefone | mask : '0000 000 0000')
                                    : (item.telefone | mask : '(00) 0000-0000')"
                                  style="cursor: pointer; text-decoration: underline; text-transform: lowercase; font-weight: 100;"
                                >
                                  {{
                                    item.telefone && item.telefone.substring(0, 4) == '0800'
                                      ? (item.telefone | mask : '0000 000 0000')
                                      : (item.telefone | mask : '(00) 0000-0000')
                                  }}
                                </span>
                              </div>
                              <div class="d-flex" *ngIf="item.celular">
                                <i *ngIf="item.possui_whatsapp || (item.app_celular && item.app_celular.length)" class="fab fa-whatsapp" 
                                  style="font-size: 12px; margin-top: 4px; margin-right: 4px;"></i>
                                <i *ngIf="!item.possui_whatsapp && (!item.app_celular || !item.app_celular.length)" class="fas fa-mobile-alt" 
                                  style="font-size: 12px; margin-top: 4px; margin-right: 4px;"></i>
                                <span container=".modal-body" [ngbTooltip]="item.celular && item.celular.substring(0, 4) == '0800' ? (item.celular | mask : '0000 000 0000') : (item.celular | mask : '(00) 00000-0000')"
                                  style="cursor: pointer; text-decoration: underline; text-transform: lowercase; font-weight: 100;"
                                >
                                  {{item.celular && item.celular.substring(0, 4) == '0800' ? (item.celular | mask : '0000 000 0000') : (item.celular | mask : '(00) 00000-0000')}}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div *ngIf="!campoCustom.valor || campoCustom.valor.length === 0 || !chamado.cliente_id"
                        [ngClass]="{ 'required-contatos': campoCustom.is_obrigatorio }">
                        <span [translate]="'ADICIONAR_CONTATO'"></span>
                      </div>
                    </div>
                  </div>
                  <div class="col-1">
                    <i class="far fa-address-book" container=".modal-body" [ngbTooltip]="('ABRIR_CONTATOS_CLIENTE' | translate)" placement="left"
                      (click)="abrirModalContatos()" style="font-size: 20px; margin-left: -14px; cursor: pointer; margin-top: 12px;"></i>
                  </div>
                </div>
              </ng-container>

              <input
                *ngIf="campoCustom.tipo_campo_id === 2"
                id="modal-abertura-ticket-{{generateIdCampoCustom(campoCustom)}}"
                type="text"
                placeholder="{{ 'TEXTO' | translate }}"
                class="form-control"
                [(ngModel)]="campoCustom.valor"
                (change)="validacaoGatilho();"
                [ngClass]="{
                  required: verificaRequeridoCustomizaveis(campoCustom)
                }"
              />

              <datepicker-padrao
                id="modal-abertura-ticket-{{generateIdCampoCustom(campoCustom)}}"
                *ngIf="campoCustom.tipo_campo_id === 3 && campoCustom.chave === '@DataCriacao'"
                [model]="data"
                (dateChange)="chamado.data_criacao = formatData($event); campoCustom.valor = formatData($event); validacaoGatilho();"
                [disabled]="
                  !permissao.EditarDataCriacao ||
                  verificaChamadoVinculado(chatData)
                "
                [required]="campoCustom.is_obrigatorio && !data"
              >
              </datepicker-padrao>

              <datepicker-padrao
                *ngIf="campoCustom.tipo_campo_id === 3 && campoCustom.chave !== '@DataCriacao'"
                id="modal-abertura-ticket-{{generateIdCampoCustom(campoCustom)}}"
                [required]="campoCustom.is_obrigatorio && !campoCustom.valor"
                [model]="campoCustom.valor"
                (dateChange)="campoCustom.valor = formatData($event); validacaoGatilho();"
              ></datepicker-padrao>
              <!-- <input *ngIf="campoCustom.tipo_campo_id === 3" class="form-control required" type="datetime-local" [(ngModel)]="campoCustom.valor" [required]="verificaRequeridoCustomizaveis(campoCustom)"> -->

              <input
                *ngIf="campoCustom.tipo_campo_id === 4"
                type="text"
                id="modal-abertura-ticket-{{generateIdCampoCustom(campoCustom)}}"
                mask="0000000000"
                placeholder="{{ 'NUMERO' | translate }}"
                class="form-control"
                [(ngModel)]="campoCustom.valor"
                (blur)="validacaoGatilho();"
                [ngClass]="{
                  required: campoCustom.is_obrigatorio && !campoCustom.valor
                }"
              />

              <input
                *ngIf="campoCustom.tipo_campo_id === 6"
                type="text"
                id="modal-abertura-ticket-{{generateIdCampoCustom(campoCustom)}}"
                mask="separator.2"
                thousandSeparator=","
                separatorLimit="10000000"
                placeholder="{{ 'NUMERO' | translate }}"
                class="form-control"
                (blur)="validacaoGatilho();"
                [(ngModel)]="campoCustom.valor"
                [ngClass]="{
                  required: campoCustom.is_obrigatorio && !campoCustom.valor
                }"
              />

              <select-padrao 
                *ngIf="campoCustom.tipo_campo_id === 5"
                id="modal-abertura-ticket-{{generateIdCampoCustom(campoCustom)}}"
                [entidade]="'selectBooleanoRegra'"
                [model]="campoCustom.valor"
                [returnObj]="true"
                [required]="campoCustom.is_obrigatorio && !campoCustom.valor"
                (selectChange)="campoCustom.valor = $event; validacaoGatilho();">
              </select-padrao>

              <ng-container *ngIf="campoCustom.tipo_campo_id === 7">
                <select-padrao
                  *ngIf="campoCustom.chave && 
                  campoCustom.chave === '@PrioridadeChamado' && 
                  campoCustom.chave !== '@SubCategoria' && 
                  campoCustom.chave !== '@Categorias'"
                  [entidade]="'selectPrioridadeChamado'"
                  id="modal-abertura-ticket-{{generateIdCampoCustom(campoCustom)}}"
                  [required]="campoCustom.is_obrigatorio && !chamado.prioridade"
                  [returnObj]="true"
                  [model]="chamado.prioridade"
                  (selectChange)="setValuesPrioridade($event, campoCustom); validacaoGatilho();">
                </select-padrao>

                <ng-container 
                  *ngIf="campoCustom.chave && 
                    campoCustom.chave === '@Dispositivo' && 
                    campoCustom.chave !== '@SubCategoria' && 
                    campoCustom.chave !== '@Categorias'">
                  <select-padrao
                    id="modal-abertura-ticket-{{generateIdCampoCustom(campoCustom)}}"
                    *ngIf="chamado.cliente_id"
                    [entidade]="'selectDispositivoVinculadoB2c'"
                    [required]="campoCustom.is_obrigatorio && !chamado.dispositivo_vinculado"
                    [returnObj]="true"
                    [params]="{solicitante_id: chamado.cliente_id.id, tipo_solicitante_id: chamado.cliente_id.tipo_solicitante_id}"
                    [customPlaceholder]="!chamado.cliente_id.id ? (translate.instant('SELECIONE_CLIENTE')) : ''"
                    [model]="chamado.dispositivo_vinculado"
                    (selectChange)="setValuesDispositivo($event, campoCustom); validacaoGatilho();"
                  ></select-padrao>
                  <select
                    *ngIf="!chamado.cliente_id"
                    class="form-control"
                    [ngClass]="{ required: campoCustom.is_obrigatorio }"
                    style="font-size: 16px; color: #999999; height: 46px"
                  >
                    <option [translate]="'SELECIONE'" selected></option>
                  </select>
                </ng-container>

                <ng-container
                  *ngIf="campoCustom.chave && 
                  campoCustom.chave === '@Tecnico' && 
                  campoCustom.chave !== '@SubCategoria' && 
                  campoCustom.chave !== '@Categorias'">
                  <select-padrao
                    id="modal-abertura-ticket-{{generateIdCampoCustom(campoCustom)}}"
                    *ngIf="chamado.mesa_trabalho_id"
                    [entidade]="'selectTecnico'"
                    [required]="campoCustom.is_obrigatorio && !chamado.usuario_tecnico_id"
                    [customPlaceholder]="!chamado.mesa_trabalho_id.id ? (translate.instant('SELECIONE_MESA_ANTES')) : ''"
                    [params]="chamado.mesa_trabalho_id.id"
                    [model]="chamado.usuario_tecnico_id"
                    [returnObj]="true"
                    (selectChange)="setValuesTecnico($event, campoCustom); validacaoGatilho();"
                  ></select-padrao>
                  <select
                    *ngIf="!chamado.mesa_trabalho_id"
                    class="form-control"
                    [ngClass]="{ required: campoCustom.is_obrigatorio }"
                  ></select>
                </ng-container>
              
                <ng-container
                  *ngIf="campoCustom.chave && 
                  campoCustom.chave === '@Setor' && 
                  campoCustom.chave !== '@SubCategoria' && 
                  campoCustom.chave !== '@Categorias'">
                  <select-padrao
                    *ngIf="chamado.cliente_id"
                    id="modal-abertura-ticket-{{generateIdCampoCustom(campoCustom)}}"
                    [entidade]="'selectSetorB2c'"
                    [params]="{solicitante_id: chamado.cliente_id.id, tipo_solicitante_id: chamado.cliente_id.tipo_solicitante_id}"
                    [model]="chamado.setor_id"
                    [returnObj]="true"
                    [required]="campoCustom.is_obrigatorio && !chamado.setor_id"
                    (selectChange)="setValuesSetor($event, campoCustom); validacaoGatilho();"
                    [customPlaceholder]="!chamado.cliente_id.id ? (translate.instant('SELECIONE_CLIENTE')) : ''"
                  >
                  </select-padrao>
                  <select
                    *ngIf="!chamado.cliente_id"
                    class="form-control required"
                    style="font-size: 16px; color: #999999; height: 46px"
                    [ngClass]="{ required: campoCustom.is_obrigatorio }"
                  >
                    <option [translate]="'SELECIONE'" selected></option>
                  </select>
                </ng-container>

                <select-padrao
                  *ngIf="campoCustom.chave && 
                  campoCustom.chave === '@TipoChamado' && 
                  campoCustom.chave !== '@SubCategoria' && 
                  campoCustom.chave !== '@Categorias'"
                  [entidade]="'selectTipoChamado'"
                  id="modal-abertura-ticket-{{generateIdCampoCustom(campoCustom)}}"
                  [required]="campoCustom.is_obrigatorio && !chamado.tipo_chamado_id"
                  [params]="{ is_ativo: true }"
                  [model]="chamado.tipo_chamado_id"
                  [returnObj]="true"
                  (selectChange)="setValuesTipoChamado($event, campoCustom); validacaoGatilho();">
                </select-padrao>

                <select-padrao
                  *ngIf="campoCustom.chave && 
                  campoCustom.chave === '@MesaTrabalho' && 
                  campoCustom.chave !== '@SubCategoria' && 
                  campoCustom.chave !== '@Categorias'"
                  [entidade]="'selectMesaTrabalhoUpdateAndCreateTicket'"
                  id="modal-abertura-ticket-{{generateIdCampoCustom(campoCustom)}}"
                  [required]="campoCustom.is_obrigatorio && !chamado.mesa_trabalho_id"
                  [params]="{ is_ativo: true }"
                  [returnObj]="true"
                  [disabled]="block_desk"
                  [model]="chamado.mesa_trabalho_id"
                  (selectChange)="setValuesMesaTrabalho($event, campoCustom); validacaoGatilho();">
                </select-padrao>
              
                <ng-container 
                  *ngIf="campoCustom.chave && 
                  campoCustom.chave === '@Categorias'">
                  <select
                    *ngIf="!chamado.cliente_id"
                    class="form-control"
                    [ngClass]="{ required: campoCustom.is_obrigatorio }"
                    style="font-size: 16px; color: #999999; height: 46px"
                  >
                    <option [translate]="'SELECIONE'" selected disabled></option>
                  </select>
                  <cat-multi-select 
                    *ngIf="chamado.cliente_id" 
                    id="modal-abertura-ticket-{{generateIdCampoCustom(campoCustom)}}"
                    [b2c]="true"
                    [customer_id]="chamado.cliente_id.id" 
                    [params]="{is_ativo: true, solicitante_id: chamado.cliente_id.id, tipo_solicitante_id: chamado.cliente_id.tipo_solicitante_id}"
                    [required]="required_category.primary" 
                    [requiredSecondary]="required_category.secondary"
                    [primarySecondary]="true"
                    [model]="categoriesReceived"
                    (categoryChange)="changeCategory($event)">
                  </cat-multi-select>
                </ng-container>
              
                <ng-container *ngIf="campoCustom.chave && campoCustom.chave === '@EnderecoId'" >
                  <select
                    *ngIf="!chamado.cliente_id"
                    class="form-control"
                    [ngClass]="{ required: campoCustom.is_obrigatorio }"
                    style="font-size: 16px; color: #999999; height: 46px"
                  >
                    <option [translate]="'SELECIONE'" selected disabled></option>
                  </select>
                  <select-padrao
                    *ngIf="chamado.cliente_id" 
                    [entidade]="'selectBusinessUnitCustomB2c'"
                    id="modal-abertura-ticket-{{generateIdCampoCustom(campoCustom)}}"
                    [required]="campoCustom.is_obrigatorio"
                    [returnObj]="true"
                    [params]="{solicitante_id: chamado.cliente_id.id, tipo_solicitante_id: chamado.cliente_id.tipo_solicitante_id}"
                    [model]="chamado.endereco_id ? {id: chamado.endereco_id} : null"
                    (selectChange)="setValuesUnidadeDeNegocio($event, campoCustom); validacaoGatilho();"
                    >
                  </select-padrao>
                </ng-container>
                
                <ng-container
                  *ngIf="(!campoCustom.chave || checkIfKeyIsCustomFieldSelect(campoCustom.chave)) && (!campoCustom.tipo_campo_id || campoCustom.tipo_campo_id === 7)" >
                  <select-padrao                
                    id="modal-abertura-ticket-{{generateIdCampoCustom(campoCustom)}}"
                    [entidade]="'selectValorFormularioAberturaTicketMultiSelectTicket'" 
                    [params]="campoCustom.id"
                    [returnObj]="false" 
                    [model]="campoCustom.valor"
                    (selectChange)="setArrayInField(campoCustom, $event); validacaoGatilho();" 
                    [required]="campoCustom.is_obrigatorio"
                    [multiple]="true">
                  </select-padrao>
                </ng-container>
              </ng-container>
              
              
              <input 
                *ngIf="campoCustom.tipo_campo_id === 10"
                id="modal-abertura-ticket-{{generateIdCampoCustom(campoCustom)}}"
                type="text" mask="00.000.000/0000-00" class="form-control"
                [ngClass]="{required: campoCustom.is_obrigatorio && !campoCustom.valor}"
                [(ngModel)]="campoCustom.valor" [ngModelOptions]="{standalone: true}" 
                (ngModelChange)="onCampoCustomizadoChange(campoCustom)" 
                [showMaskTyped]="true" 
                [dropSpecialCharacters]="false"/>
              <!-- checkValue(campoCustom.valor, campoCustom.tipo_campo_id, campoCustom) -->
              <input 
                *ngIf="campoCustom.tipo_campo_id === 11" 
                id="modal-abertura-ticket-{{generateIdCampoCustom(campoCustom)}}"
                type="text" mask="000.000.000-00" class="form-control" 
                [ngClass]="{required: campoCustom.is_obrigatorio && !campoCustom.valor}" 
                [(ngModel)]="campoCustom.valor" [ngModelOptions]="{standalone: true}"  
                (ngModelChange)="onCampoCustomizadoChange(campoCustom)" 
                [showMaskTyped]="true" 
                [dropSpecialCharacters]="false"/>
              
              <textarea 
                *ngIf="campoCustom.tipo_campo_id === 12" 
                id="modal-abertura-ticket-{{generateIdCampoCustom(campoCustom)}}"
                type="text" rows="5" maxlength="768"
                class="form-control"
                [(ngModel)]="campoCustom.valor"
                [ngClass]="{
                  required: campoCustom.is_obrigatorio && !campoCustom.valor
                }"
                (blur)="validacaoGatilho();"
                placeholder="{{ 'TEXTO' | translate }}">
              </textarea>
              
              <input 
                *ngIf="campoCustom.tipo_campo_id === 13" 
                id="modal-abertura-ticket-{{generateIdCampoCustom(campoCustom)}}"
                type="email" 
                class="form-control" 
                [(ngModel)]="campoCustom.valor" 
                [ngClass]="{required: campoCustom.is_obrigatorio && !campoCustom.valor}"
                [ngModelOptions]="{standalone: true}" 
                (ngModelChange)="onCampoCustomizadoChange(campoCustom)"
                placeholder="{{ 'EMAIL' | translate }}"
              />
              
              <input 
                *ngIf="campoCustom.tipo_campo_id === 14" 
                id="modal-abertura-ticket-{{generateIdCampoCustom(campoCustom)}}"
                type="text" 
                class="form-control" 
                mask="00 0000-0000||000 0000-0000" 
                [(ngModel)]="campoCustom.valor" 
                [ngClass]="{required: campoCustom.is_obrigatorio && !campoCustom.valor}"
                [ngModelOptions]="{standalone: true}" 
                (ngModelChange)="onCampoCustomizadoChange(campoCustom)" 
                [showMaskTyped]="true" [dropSpecialCharacters]="false"
                />
              
              <input 
                *ngIf="campoCustom.tipo_campo_id === 15" 
                id="modal-abertura-ticket-{{generateIdCampoCustom(campoCustom)}}"
                type="text" mask="00 00000-0000||00 0000-0000" 
                [(ngModel)]="campoCustom.valor" 
                [ngClass]="{required: campoCustom.is_obrigatorio && !campoCustom.valor}"
                [ngModelOptions]="{standalone: true}" 
                class="form-control" 
                (ngModelChange)="onCampoCustomizadoChange(campoCustom)"  
                [showMaskTyped]="true" [dropSpecialCharacters]="false"
              />
              
              <input 
                *ngIf="campoCustom.tipo_campo_id === 16" 
                id="modal-abertura-ticket-{{generateIdCampoCustom(campoCustom)}}"
                type="url" class="form-control" 
                [(ngModel)]="campoCustom.valor" 
                [ngClass]="{required: campoCustom.is_obrigatorio && !campoCustom.valor}"
                [ngModelOptions]="{standalone: true}"                 
                (ngModelChange)="onCampoCustomizadoChange(campoCustom)" 
                placeholder="Url"
              />

              <hourpicker-padrao 
                *ngIf="campoCustom.tipo_campo_id === 17" 
                id="modal-abertura-ticket-{{generateIdCampoCustom(campoCustom)}}"
                [model]="campoCustom.valor"
                [required]="campoCustom.is_obrigatorio" 
                [dropSpecialCharacters]="false" 
                (hourChange)="campoCustom.valor = $event; validacaoGatilho();">
              </hourpicker-padrao>
              
              <datepicker-padrao 
                *ngIf="campoCustom.tipo_campo_id === 18" 
                id="modal-abertura-ticket-{{generateIdCampoCustom(campoCustom)}}"
                [model]="campoCustom.valor"
                [required]="campoCustom.is_obrigatorio" 
                (dateChange)="campoCustom.valor = $event; validacaoGatilho();"                 
                >
              </datepicker-padrao>             
              
            </div>
            <div *ngIf="campoCustom.is_visivel && campoCustom.chave === '@UltimosTickets' && chamado.cliente_id && chamado.cliente_id.tipo_solicitante_id === 1" class="row">
              <div class="col-12">
                <div class="form-group">
                  <label>{{campoCustom.label}}</label>
                  <div
                    *ngIf="
                      !chamado.cliente_id
                    "
                    class="w-100 label-block-warning"
                    style="padding-left: 13px"
                    [translate]="'SELECIONE_CLIENTE'"
                  ></div>
                  <table
                    *ngIf="
                      chamado.cliente_id 
                    "
                    class="table table-stripped"
                  >
                    <tr *ngFor="let chamado of ultimosChamados">
                      <td
                        style="border: none; padding-left: 4px; padding-right: 0px"
                      >
                        <span
                          *ngIf="chamado.status_id === 3"
                          class="btn btn-alert btn-mini-rounded-milvus"
                          container=".modal-body" ngbTooltip="{{ 'PAUSADO' | translate }}"
                          style="padding: 0; color: yellow"
                        >
                          <i class="fas fa-pause font-12" style="color: yellow;"></i>
                        </span>
                        <span
                          *ngIf="chamado.status_id === 1"
                          class="btn btn-warning btn-mini-rounded-milvus"
                          container=".modal-body" ngbTooltip="{{ 'A_FAZER' | translate }}"
                          style="padding: 0; color: #fb6340;"
                        >
                          <i class="fas fa-flag font-12" style=" color:#fb6340"></i>
                        </span>
                        <span
                          *ngIf="chamado.status_id === 2"
                          class="btn btn-success btn-mini-rounded-milvus"
                          container=".modal-body" ngbTooltip="{{ 'ATENDENDO' | translate }}"
                          style="padding: 0;"
                        >
                          <i class="fas fa-play font-12" style=" color:#2dce89"></i>
                        </span>
                        <span
                          *ngIf="chamado.status_id === 4"
                          class="btn btn-default btn-mini-rounded-milvus"
                          container=".modal-body" ngbTooltip="{{ 'FINALIZADO' | translate }}"
                          style="padding: 0"
                        >
                          <i class="fa fa-check font-12" style="color: #007A7D;"></i>
                        </span>
                        <span
                          *ngIf="chamado.status_id === 5"
                          class="btn btn-pink btn-mini-rounded-milvus"
                          container=".modal-body" ngbTooltip="{{ 'EM_CONFERENCIA' | translate }}"
                          style="padding: 0"
                        >
                          <i class="fas fa-redo-alt" style="color: grey;"></i>
                        </span>
                      </td>
                      <td
                        style="border: none; padding-left: 0px; padding-right: 0px"
                      >
                        <span
                          class="text-success"
                          style="cursor: pointer"
                          (click)="carregaOutroTicket(chamado.id)"
                          >#{{ chamado.codigo }}</span
                        >
                      </td>
                      <td
                        style="border: none; padding-left: 0px; padding-right: 0px"
                      >
                        <span>{{ chamado.assunto | ellipsis : 20 }}</span>
                      </td>
                      <td
                        style="border: none; padding-left: 0px; padding-right: 4px"
                        class="text-right"
                      >
                        {{ chamado.data_criacao | date : "dd/MM/yyyy HH:mm" }}
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <ng-container *ngIf="!isPreview; else preview">
      <button
      *ngIf="!is_retencao && !chatData"
      type="button"
      class="btn btn-success badge-pill"
      (click)="salvarEAbrir(chamado)"
      [disabled]="verificaCampos() || is_saving || preenchendoCampoCustom"
    >
      <span [translate]="'SALVAR_E_ABRIR'"></span>
    </button>
    <button
      *ngIf="!is_retencao && chatData"
      type="button"
      class="btn btn-danger badge-pill"
      (click)="salvarEFinaliza(chamado, chat)"
      [disabled]="is_saving || preenchendoCampoCustom"
      >
      <span [translate]="'ABRIR_TICKET_FINALIZAR'"></span>
    </button>
    <button
      *ngIf="!is_retencao && !verificaChamadoVinculado(chatData)"
      type="button"
      class="btn btn-success badge-pill"
      (click)="salvarChamado(chamado)"
      [disabled]="verificaCampos() || is_saving || preenchendoCampoCustom"
    >
      <i class="fas fa-save"></i>
      <span [translate]="'ABRIR_TICKET'"></span>
    </button>
    <button
      *ngIf="is_retencao && !chatData && !verificaChamadoVinculado(chatData)"
      type="button"
      class="btn btn-warning badge-pill"
      [disabled]="
        !chamado.cliente_id ||
        (required_default.assunto && !chamado.assunto)  ||
        verifyDescricao() ||
        !infosValidacaoCliente.pode_abrir_chamado ||
        existe_bloqueio || 
        is_saving || preenchendoCampoCustom
      "
      (click)="salvarChamado(chamado)"
    >
      <span [translate]="'SALVAR_E_RETENCAO'"></span>
    </button>
    </ng-container>
    <ng-template #preview>
      <button type="button" class="btn btn-success badge-pill" [disabled]="true">
        <span [translate]="'SALVAR_E_ABRIR'"></span>
      </button>
      <button type="button" class="btn btn-success badge-pill" [disabled]="true">
        <i class="fas fa-save"></i>
        <span [translate]="'SALVAR'"></span>
      </button>
    </ng-template>
  </div>
</div>

<ng-template #tooltipAttachment>
  <div class="d-grid">
    <span [translate]="'ANEXO_MAXIMO_ARQUIVOS'"></span>
    <span [translate]="'ANEXO_TAMANHO_ARQUIVO'"></span>
    <span [translate]="'ANEXO_ARQUIVOS_SUPORTADOS'"></span>
  </div>
</ng-template>
