<div class="modal-content modal-milvus">
  <div class="modal-header">
    <h6 class="modal-title" id="modal-title-default" [translate]="'DETALHES_CHAT_HISTORICO'"></h6>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="close()">
      <i class="mdi mdi-close"></i>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-6">
        <strong>{{'CONTATO' | translate}}:</strong><span>
          {{ chat.email_contato ? chat.email_contato : (translate.instant('NAO_POSSUI')) }}</span>
      </div>
      <div class="col-6">
        <strong>{{'TELEFONE' | translate}}:</strong><span> {{ chat.contato && chat.contato.celular ?
          (chat.contato.celular | mask:'(00) 0
          0000-0000') : (translate.instant('NAO_POSSUI'))}}</span>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <strong>{{'STATUS' | translate}}:</strong><span> {{chat.status ? (chat.status | translate):
          (translate.instant('NAO_POSSUI'))}}</span>
      </div>
      <div class="col-6">
        <strong>{{'DATA_CRIACAO' | translate}}:</strong><span> {{ chat.createdAt ?
          (chat.createdAt | date:'dd/MM/yyyy HH:mm') : (translate.instant('NAO_POSSUI'))}}</span>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <strong>{{'DATA_ULTIMA_INTERACAO' | translate}}:</strong><span> {{chat.data_ultima_interacao ?
          (chat.data_ultima_interacao |
          date:'dd/MM/yyyy HH:mm') : (translate.instant('NAO_POSSUI'))}}</span>
      </div>
      <div class="col-6">
        <strong>{{'FILA_ATENDIMENTO' | translate}}:</strong><span> {{chat.fila_atendimento ?
          chat.fila_atendimento.descricao : (translate.instant('NAO_POSSUI'))}}</span>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <strong>{{'DESCRICAO' | translate}}:</strong><span> {{chat.widget ? chat.widget.descricao :
          (translate.instant('NAO_POSSUI'))}}</span>
      </div>
      <div class="col-6">
        <strong>{{'OPERADOR' | translate}}:</strong><span> {{chat.tecnico_ativo ? chat.tecnico_ativo.text :
          (translate.instant('NAO_POSSUI'))}}</span>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <strong>{{'DATA_FINAL' | translate}}:</strong><span> {{chat.data_final ? (chat.data_final | date:'dd/MM/yyyy
          HH:mm') : (translate.instant('NAO_POSSUI'))}}</span>
      </div>
      <div class="col-6">
        <strong>{{'TEMPO_TOTAL' | translate}}:</strong><span> {{chat.tempo_total ? chat.tempo_total :
          (translate.instant('NAO_POSSUI'))}}</span>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <strong>{{'NOTA_AVALIACAO' | translate}}:</strong><span> {{chat.avaliacao ? chat.avaliacao.nota :
          (translate.instant('NAO_POSSUI'))}}</span>
      </div>
      <div class="col-6">
        <strong>{{'MOTIVO_AVALIACAO' | translate}}:</strong><span> {{chat.avaliacao ? chat.avaliacao.motivo :
          (translate.instant('NAO_POSSUI'))}}</span>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <strong>{{'DESCRICAO_FINAL' | translate}}:</strong><span> {{chat.descricao_finalizacao ?
          chat.descricao_finalizacao :
          (translate.instant('NAO_POSSUI'))}}</span>
      </div>
      <div class="col-6">
        <strong>{{'TABULACAO_FINAL' | translate}}:</strong><span> {{chat.tabulacao ? chat.tabulacao.text :
          (translate.instant('NAO_POSSUI'))}}</span>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <strong>{{'DATA_ATENDIMENTO' | translate}}:</strong><span> {{chat.data_inicio_atendimento ?
          (chat.data_inicio_atendimento | date:'dd/MM/yyyy HH:mm') :
          (translate.instant('NAO_POSSUI'))}}</span>
      </div>
      <div class="col-6">
        <strong>{{'DATA_SELECAO_FILA' | translate}}:</strong><span> {{chat.data_atendimento_fila ?(
          chat.data_atendimento_fila | date:'dd/MM/yyyy HH:mm') :
          (translate.instant('NAO_POSSUI'))}}</span>
      </div>
    </div>
  </div>
</div>