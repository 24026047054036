import { Component, Input, OnInit, Output, EventEmitter, OnDestroy } from "@angular/core";
import { Observable, Subscription } from "rxjs";

@Component({
    selector: 'cat-multi-dropdown',
    templateUrl: './categories-ml-dropdown.html',
    styleUrls: ['./categories-ml-dropdown.scss']
})
export class CategoriesMultiLevelDropdownComponent implements OnInit, OnDestroy {
    categories: any;
    @Input() set dropCategories(value) {
        this.originalCatArray = value;
        this.categories = value;
        this.classifyCategories(this.categories);
    };
    @Input() clearFilter: Observable<void>;
    @Input() select: boolean;
    @Input() selectOnlySecondary: boolean;
    @Input() showOnlyPrimary: boolean;
    @Input() showOnlySecondary: boolean;
    @Input() set primaryToFilter(value) {
        this.primaryId = value;
        if(value && this.originalCatArray) {
            this.searchPrimary(value, this.originalCatArray);
        }
    };
    @Input() selectOnlyLastSub: boolean;
    @Output() categoryChange = new EventEmitter();
    originalCatArray: any;
    categorySelected: any;
    searchCategories: string;
    preSelectedCategory: any;
    primaryId: any;
    newSubs: Subscription;

    ngOnInit(): void {
        if(this.categories) {
            if(!this.showOnlyPrimary && !this.showOnlySecondary) {
                this.classifyCategories(this.categories);
            } else if(this.showOnlyPrimary){
                this.removeSubs(this.categories);
            } else if(this.showOnlySecondary && this.selectOnlyLastSub) {
                this.categories.forEach(category => {
                    if(category.sub.length > 0) {
                        category.last_layer = false;
                    } else {
                        category.last_layer = true;
                    }
                });
            }
        }

        if(this.clearFilter) {
            this.newSubs = this.clearFilter.subscribe(() => {
                this.unselectAllCategories(this.categories);
            });
        }
    }

    ngOnDestroy(): void {
        this.newSubs?.unsubscribe();
    }

    classifyCategories(categories: any[], father_name?, father_id?): void {
        categories.forEach(category => {
            category.selected = false;
            category.layer = (father_name) ? 2 : 1;
            category.select_label = (father_name) ? father_name + '/' + category.nome : category.nome;
            category.path_ids = (father_id) ? father_id + '/' + category.id : category.id.toString();

            if(category.sub.length > 0) {
                this.classifyCategories(category.sub, category.select_label, category.path_ids);
                category.last_layer = false;
            } else {
                category.last_layer = true;
            }
        });
    }

    async setCategoryAsValue(category: any): Promise<void> {
        if(this.selectOnlyLastSub && !category.last_layer) {
            return;
        } else {
            if(this.selectOnlySecondary && category.layer > 1) {
                await this.unselectAllCategories(this.categories, category.id);
                category.selected = !category.selected;
                if(category.selected) {
                    if(this.select) {
                        this.categorySelected = [];
                        this.setCategoryToSelect(category, this.categories);
                    } else {
                        this.categorySelected = (category.layer > 1) ?
                            {categoria_secundaria_id: category.id, categoria_primaria_id: Number(category.path_ids.split('/')[0])} :
                            {categoria_primaria_id: category.id};
                        this.categoryChange.emit(this.categorySelected);
                    }
                } else {
                    this.categorySelected = null;
                    this.categoryChange.emit(this.categorySelected);
                }
            } else if(!this.selectOnlySecondary) {
                await this.unselectAllCategories(this.categories, category.id);
                category.selected = !category.selected;
                if(category.selected) {
                    if(this.select) {
                        this.categorySelected = [];
                        this.setCategoryToSelect(category, this.categories);
                    } else {
                        this.categorySelected = (category.layer > 1) ?
                            {categoria_secundaria_id: category.id, categoria_primaria_id: Number(category.path_ids.split('/')[0])} :
                            {categoria_primaria_id: category.id};
                        this.categoryChange.emit(this.categorySelected);
                    }
                } else {
                    this.categorySelected = null;
                    this.categoryChange.emit(this.categorySelected);
                }
            }
        }
    }

    setCategoryToSelect(category, categories): void {
        this.categorySelected = {id: category.id, text: category.select_label, path_ids: category.path_ids};
        this.categoryChange.emit(this.categorySelected);
    }

    async unselectAllCategories(categories, category_id?): Promise<void> {
        categories.forEach(category => {
            if(category_id !== category.id) {
                category.selected = false;
            }

            if(category.sub.length > 0) {
                this.unselectAllCategories(category.sub, category_id);
            }
        });
    }

    removeSubs(categories: any[]): void {
        categories.forEach(category => {
            category.selected = false;
            category.layer = 1;
            category.select_label = category.nome;
            category.path_ids = category.id.toString();
            category.sub = [];
        });
    }

    searchPrimary(primary_id: any, categories: any[]): void {
        const idToFilter = (primary_id.id) ? primary_id.id : primary_id;
        const findCategory = categories.filter(cat => cat.id === idToFilter);
        this.categories = findCategory[0].sub;
        this.classifyCategories(this.categories);
    }

    verifyExpandSecondaries(category: any): boolean {
        if(this.selectOnlyLastSub) {
            return category.layer === 1;
        } else {
            return true;
        }
    }
}