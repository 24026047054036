import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { TokenService } from '../core/token/token.service';
import { iGetDropDownEquipes } from '../interfaces/dashboards/dashboards-dashboard.interface';

@Injectable({
  providedIn: 'root'
})
export class EquipesService {
  private apiUrl = environment.apiGestorUrlLocal;
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', 'authorization': this.tokenService.getToken(), 'session': this.tokenService.getSessionUsuario()}),
    params: {}
  };

  constructor(private http: HttpClient, private tokenService: TokenService) { }

  getListagemEquipes(params, filtros): Observable<any> {
    this.httpOptions.params = params;
    return this.http.post(`${this.apiUrl}api/equipes/listagem`, filtros, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  getDropDownEquipes(params): Observable<iGetDropDownEquipes> {
    this.httpOptions.params = params;
    return this.http.get<iGetDropDownEquipes>(`${this.apiUrl}api/equipes/dropdownlist`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  getEquipeById(id): Observable<any> {
    this.httpOptions.params = "";
    return this.http.get(`${this.apiUrl}api/equipes/${id}`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  getSituacaoUser(userId): Observable<any> {
    this.httpOptions.params = {membro_equipe_id : userId};
    return this.http.get(`${this.apiUrl}api/equipes/situacao-usuario?`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  createEquipe(payload): Observable<any> {
    this.httpOptions.params = "";
    return this.http.post(`${this.apiUrl}api/equipes`, payload, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  updateEquipe(payload): Observable<any> {
    this.httpOptions.params = "";
    return this.http.put(`${this.apiUrl}api/equipes`, payload, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }
  
  deleteEquipe(id): Observable<any> {
    this.httpOptions.params = "";
    return this.http.delete(`${this.apiUrl}api/equipes/${id}`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  getListagemEquipesCliente(params, cliente_id, filtros): Observable<any> {
    this.httpOptions.params = params;
    return this.http.post(`${this.apiUrl}api/equipes/listagem/cliente/${cliente_id}`, filtros, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  deleteEquipesCliente(cliente_id, equipes): Observable<any> {
    this.httpOptions.params = "";
    return this.http.put(`${this.apiUrl}api/equipes/excluir/${cliente_id}`, equipes, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  setEquipesCliente(cliente_id, equipes): Observable<any> {
    this.httpOptions.params = "";
    return this.http.put(`${this.apiUrl}api/equipes/adicionar/${cliente_id}`, equipes, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('Ops! Ocorreu um erro:', error.error.message);
    } else {
      console.error(
        `Backend retornou o código ${error.status}, ` +
        `o body é: ${error.error}`);
    }
    return throwError(
      error.error);
  }
}
