<div class="modal-content modal-milvus">
    <div class="modal-header-milvus align-items-center">
        <div class="col-6 header-col">
            <span class="modal-title" id="modal-title-default">{{'HORAS_TRABALHADAS' | translate}}</span>
        </div>
        <div class="col-6 header-col">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="close()">
                <i class="mdi mdi-close"></i>
              </button>
        </div>
    </div>

    <div class="modal-body">
        <form [formGroup]="horaForm" class="row">
            <div class="col-lg-4 form-group">
                <label for="select-input" class="form-control-label">{{ 'HORAS_TOTAIS' | translate }}</label>
                <select class="form-control" id="select-input" formControlName="externo" [ngClass]="{'required': verificaRequerido('externo')}">
                    <option value="" selected>{{'SELECIONE' | translate}}</option>
                    <option [value]="false">{{'INTERNO' | translate}}</option>
                    <option [value]="true">{{'EXTERNO' | translate}}</option>
                </select>
            </div>
            <div class="col-lg-4 form-group">
                <label for="text-input" class="form-control-label">{{ 'HORAS' | translate }}</label>
                <input class="form-control" type="text" id="text-input" placeholder="HH" mask="99"
                [dropSpecialCharacters]="false" (focus)="focus = true" (blur)="focus = false" formControlName="hora" [ngClass]="{'required': verificaRequerido('hora')}">
            </div>

            <div class="col-lg-4 form-group">
                <label for="text-input" class="form-control-label">{{ 'MINUTOS' | translate }}</label>
                <input class="form-control" type="text" id="text-input" placeholder="MM" mask="m0"
                [dropSpecialCharacters]="false" (focus)="focus = true" (blur)="focus = false" formControlName="minuto" [ngClass]="{'required': verificaRequerido('minuto')}">
            </div>

            
            <div class="col-lg-12 form-group">
                <label for="text-input" class="form-control-label">{{ 'DESCRICAO' | translate }}</label>
            <textarea class="form-control" rows="3" type="text" id="text-input" placeholder="{{ 'DESCRICAO' | translate }}"
            (focus)="focus = true" (blur)="focus = false" style="resize: none;" formControlName="descricao" [ngClass]="{'required': verificaRequerido('descricao')}"></textarea>
            </div>
            
        </form>
    </div>

    <div class="modal-footer modal-footer-milvus">
        <button class="btn badge-pill btn-mv-primary" [disabled]="horaForm.invalid" (click)="onSubmit()">
            <i class="fas fa-save"></i>
            <span [translate]="'SALVAR'"></span>
        </button>
    </div>
</div>