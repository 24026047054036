<div *ngIf="buttonType === 'base'">
  <ng-template [ngIf]="buttonColor === 'success'" [ngIfElse]="infoButton">
    <button
        [id]="buttonFormControlName"
        [name]="buttonFormControlName"
        class="btn btn-success badge-pill"
        [readonly]="buttonReadonly"
        (click)="onButtonClick()"
      >
        <ng-content></ng-content>
      </button>
  </ng-template>
  <ng-template #infoButton>
    <ng-template [ngIf]="buttonColor === 'info'" [ngIfElse]="warningButton">
      <button
        [id]="buttonFormControlName"
        [name]="buttonFormControlName"
        class="btn btn-info badge-pill"
        [readonly]="buttonReadonly"
        (click)="onButtonClick()"
      >
        <ng-content></ng-content>
      </button>
    </ng-template>
  </ng-template>
  <ng-template #warningButton>
    <ng-template [ngIf]="buttonColor === 'warning'" [ngIfElse]="dangerButton">
      <button
        [id]="buttonFormControlName"
        [name]="buttonFormControlName"
        class="btn btn-warning badge-pill"
        [readonly]="buttonReadonly"
        (click)="onButtonClick()"
      >
        <ng-content></ng-content>
      </button>
    </ng-template>
  </ng-template>
  <ng-template #dangerButton>
    <ng-template [ngIf]="buttonColor === 'danger'" [ngIfElse]="customButton">
      <button
        [id]="buttonFormControlName"
        [name]="buttonFormControlName"
        class="btn btn-danger badge-pill"
        [readonly]="buttonReadonly"
        (click)="onButtonClick()"
      >
        <ng-content></ng-content>
      </button>
    </ng-template>
  </ng-template>
  <ng-template #customButton>
    <ng-template [ngIf]="buttonColor === 'custom'">
      <button
        [id]="buttonFormControlName"
        [name]="buttonFormControlName"
        class="btn btn-success badge-pill"
        [readonly]="buttonReadonly"
        (click)="onButtonClick()"
      >
        <ng-content></ng-content>
      </button>
    </ng-template>
  </ng-template>
</div>

<div *ngIf="buttonType === 'icon'">
  <ng-template [ngIf]="buttonAction === 'add'" [ngIfElse]="customButton">
    <button
      [id]="buttonFormControlName"
      [name]="buttonFormControlName"
      class="btn btn-sm btn-success"
      [ngClass]="{
        'small-circled-btn': buttonSize === 'small',
        'medium-circled-btn': buttonSize === 'medium',
        'large-circled-btn': buttonSize === 'large'
      }"
      [readonly]="buttonReadonly"
      (click)="onButtonClick()"
    >
      <em class="fas fa-plus"></em>
    </button>
  </ng-template>
  <ng-template #customButton>
    <ng-template [ngIf]="buttonAction === 'custom'" [ngIfElse]="deleteButton">
      <button
        [id]="buttonFormControlName"
        [name]="buttonFormControlName"
        [class]="buttonClass ? buttonClass : 'btn btn-sm btn-success'"
        [ngClass]="{
          'small-circled-btn': buttonSize === 'small',
          'medium-circled-btn': buttonSize === 'medium',
          'large-circled-btn': buttonSize === 'large'
        }"
        [readonly]="buttonReadonly"
        (click)="onButtonClick()"
      >
        <ng-content></ng-content>
      </button>
    </ng-template>
  </ng-template>
  <ng-template #deleteButton>
    <ng-template [ngIf]="buttonAction === 'delete'" [ngIfElse]="editButton">
      <button
        [id]="buttonFormControlName"
        [name]="buttonFormControlName"
        class="btn btn-sm btn-danger"
        [ngClass]="{
          'small-circled-btn': buttonSize === 'small',
          'medium-circled-btn': buttonSize === 'medium',
          'large-circled-btn': buttonSize === 'large'
        }"
        [readonly]="buttonReadonly"
        (click)="onButtonClick()"
      >
        <em class="far fa-trash-alt"></em>
      </button>
    </ng-template>
  </ng-template>
  <ng-template #editButton>
    <ng-template [ngIf]="buttonAction === 'edit'" [ngIfElse]="viewButton">
      <button
        [id]="buttonFormControlName"
        [name]="buttonFormControlName"
        class="btn btn-sm btn-success"
        [ngClass]="{
          'small-circled-btn': buttonSize === 'small',
          'medium-circled-btn': buttonSize === 'medium',
          'large-circled-btn': buttonSize === 'large'
        }"
        [readonly]="buttonReadonly"
        (click)="onButtonClick()"
      >
        <em class="fas fa-pen"></em>
      </button>
    </ng-template>
  </ng-template>
  <ng-template #viewButton>
    <ng-template [ngIf]="buttonAction === 'view'">
      <button
        [id]="buttonFormControlName"
        [name]="buttonFormControlName"
        class="btn btn-sm btn-darkgray"
        [ngClass]="{
          'small-circled-btn': buttonSize === 'small',
          'medium-circled-btn': buttonSize === 'medium',
          'large-circled-btn': buttonSize === 'large'
        }"
        [readonly]="buttonReadonly"
        (click)="onButtonClick()"
      >
        <em class="fas fa-eye"></em>
      </button>
    </ng-template>
  </ng-template>
</div>
