import { HttpHeaders, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { TokenService } from '../core/token/token.service';

@Injectable({
  providedIn: 'root'
})
export class ConfiguracaoContratosService {
  private apiUrl = environment.apiGestorUrlLocal;
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', 'authorization': this.tokenService.getToken(), 'session': this.tokenService.getSessionUsuario() }),
    params: {}
  };

  constructor(private http: HttpClient, private tokenService: TokenService) { }

  adicionarCobrancaClienteSemContrato(payload): Observable<any> {
    this.httpOptions.params = "";
    return this.http.post(`${this.apiUrl}api/configuracao-contrato/sem-contrato/cobranca`, payload, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  adicionarPrecosClienteSemContrato(payload): Observable<any> {
    this.httpOptions.params = "";
    return this.http.post(`${this.apiUrl}api/configuracao-contrato/sem-contrato`, payload, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  alterarCobrancaClienteSemContrato(payload): Observable<any> {
    this.httpOptions.params = "";
    return this.http.put(`${this.apiUrl}api/configuracao-contrato/sem-contrato/cobranca`, payload, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      )
  }

  atualizarConfiguracaoGeralContrato(payload): Observable<any> {
    this.httpOptions.params = "";
    return this.http.put(`${this.apiUrl}api/configuracao-contrato/geral`, payload, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      )
  }

  atualizarPrecosClienteSemContrato(payload): Observable<any> {
    this.httpOptions.params = "";
    return this.http.put(`${this.apiUrl}api/configuracao-contrato/sem-contrato`, payload, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      )
  }

  excluirCobrancaClienteSemContrato(id): Observable<any> {
    this.httpOptions.params = "";
    return this.http.delete(`${this.apiUrl}api/configuracao-contrato/sem-contrato/cobranca/${id}`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      )
  }

  obterConfiguracaoGeralContrato(): Observable<any> {
    this.httpOptions.params = "";
    return this.http.get(`${this.apiUrl}api/configuracao-contrato/geral`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  obterPrecosClienteSemContrato(): Observable<any> {
    this.httpOptions.params = "";
    return this.http.get(`${this.apiUrl}api/configuracao-contrato/sem-contrato`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  dropdownCategoriaPrimaria(): Observable<any> {
    this.httpOptions.params = "";
    return this.http.get(`${this.apiUrl}api/categoriaprimaria/dropdownlist`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  dropdownCategoriaSecundaria(params): Observable<any> {
    this.httpOptions.params = params;
    return this.http.get(`${this.apiUrl}api/categoriasecundaria/dropdownlist`,  this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  uploadImagem(file): Observable<any> {
    this.httpOptions.params = "";
    return this.http.post(`${this.apiUrl}api/base-conhecimento/adicionaArquivo`, file,
      { headers: new HttpHeaders({ 'authorization': this.tokenService.getToken(), 'session': this.tokenService.getSessionUsuario() }) })
      .pipe(
        catchError(this.handleError)
      );
  }


  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('Ops! Ocorreu um erro:', error.error.message);
    } else {
      console.error(
        `Backend retornou o código ${error.status}, ` +
        `o body é: ${error.error}`);
    }
    return throwError(
      error.error);
  }
}
