<nav class="sidenav navbar navbar-vertical fixed-left navbar-expand-xs navbar-light bg-white" id="sidenav-main"
  (mouseover)="onMouseEnterSidenav()" (mouseout)="onMouseLeaveSidenav()" *ngIf="!modo_tv" style="zoom:0.85" [ngClass]="{'sidenav-search-focus': searchFocus}">
  <perfect-scrollbar>
    <div class="scrollbar-inner">
      <div class="sidenav-header d-flex align-items-center">
        <a class="navbar-brand" href="javascript:void(0)" [routerLink]="[telaInicial]">
          <img width="135" src="assets/img/brand/Milvus_logo_completo_branco_compressed.png" class="navbar-brand-img"
            alt="..." />

        </a>
        <div class="ml-auto">
          <div class="sidenav-toggler">
            <img width="30" src="assets/img/brand/Milvus_logo_resumido_branco.png" class="navbar-brand-img">
          </div>
        </div>
      </div>
      <!-- Identificação visual de ambiente -->
      <div *ngIf="ambiente !== 'prod'" class="navbar-inner">
        <div class="collapse navbar-collapse">
          <ul class="navbar-nav">
            <ng-container *ngIf="ambiente === 'master'">
              <li class="nav-item">
                <div class="ambiente-master" *ngIf="!mouseExpanded">
                  <span>M</span>
                </div>
                <div class="ambiente-master" *ngIf="mouseExpanded">
                  <span>Master</span>
                </div>
              </li>
            </ng-container>
            <ng-container *ngIf="ambiente === 'release'">
              <li class="nav-item">
                <div class="ambiente-release" *ngIf="!mouseExpanded">
                  <span>R</span>
                </div>
                <div class="ambiente-release" *ngIf="mouseExpanded">
                  <span>Release</span>
                </div>
              </li>
            </ng-container>
            <ng-container *ngIf="ambiente === 'desenv'">
              <li class="nav-item">
                <div class="ambiente-desenv" *ngIf="!mouseExpanded">
                  <span>D</span>
                </div>
                <div class="ambiente-desenv" *ngIf="mouseExpanded">
                  <span>Desenv</span>
                </div>
              </li>
            </ng-container>
          </ul>
        </div>
      </div>
      <div class="navbar-inner ">
        <div class="collapse navbar-collapse" id="sidenav-collapse-main">
          <!-- Collapse header -->
          <ul class="navbar-nav">
            <!-- Search menu -->
            <li class="nav-item ">
              <div class="form-group nav-link">
                <div class="icon-search ">
                  <i class="fas fa-search"></i>
                </div>
                <div #inputSearch class="input-search hidden">
                  <div class="navbar-search navbar-search-light form-inline mr-sm-3 my-1" id="navbar-search-main">
                    <div class="form-group mb-0" [ngClass]="{ focused: focus === true }" style="height: 30px; margin-top: -7px;">
                        <div class="input-group input-group-merge" style="background-color: #F4F4F4">
                            <div class="input-group-prepend">
                                <span class="input-group-text"><i class="fas fa-search"></i></span>
                            </div>
                            <input class="form-control input-menu-search" placeholder="{{ 'BUSCAR' | translate }}" type="text" name="search"
                            [(ngModel)]="searchFilter" (ngModelChange)="filtrarMenuESubMenu($event)" name="busca_campos"
                            style=" width: 150px; height: 30px;" autocomplete="new-search" (focus)="searchFocus = true" (blur)="searchBlur()" />
                        </div>
                    </div>
                </div>
                </div>
              </div>
            </li>
            <li *ngFor="let menuitem of menuItems; trackBy:trackByIndex; let i = index" class="nav-item">
              <!--If is a single link-->
              <a routerLinkActive="active-link" [routerLinkActiveOptions]="{exact: true}" id="{{'menu'+i}}" [routerLink]="[menuitem.path]"
                *ngIf="menuitem.type === 'link'" class="nav-link menu-item" 
                >
                <i *ngIf="menuitem.icontype && menuitem.icontype !== ''" class="ni {{ menuitem.icontype }}"></i>
                <img *ngIf="menuitem.iconsvg" src="{{menuitem.iconsvg}}" class="svg-icon"
                  style="margin-right: 12px !important; margin-left: 2px !important;">
                <span *ngIf="verificaVisualizacao()" class="nav-link-text" style="cursor: pointer;">
                  {{ menuitem.title | translate }}
                </span>
              </a>
              <!--If it have a submenu-->
              <a routerLinkActive="active-link" [routerLinkActiveOptions]="{exact: false}" id="{{'menu'+i}}" data-toggle="collapse" *ngIf="menuitem.type === 'sub'" (click)="expandItens(menuitem, menuItems)"
                [attr.aria-expanded]="!menuitem.isCollapsed" [attr.aria-controls]="menuitem.collapse" class="nav-link menu-item"
                >
                <span [routerLink]="[menuitem.path]" style="display: none;"></span>
                <i *ngIf="menuitem.icontype && menuitem.icontype !== ''" class="ni {{ menuitem.icontype }}"></i>
                <img *ngIf="menuitem.iconsvg" src="{{menuitem.iconsvg}}" class="svg-icon"
                  style="margin-right: 12px !important; margin-left: 2px !important;">
                <span *ngIf="verificaVisualizacao()" class="nav-link-text" style="cursor: pointer;">
                  {{ menuitem.title | translate }}
                  <span *ngIf="menuitem.is_new" class="badge-new">BETA</span>
                </span>
              </a>
              <!--Display the submenu items-->
              <div id="{{ 'submenu'+i }}" class="collapse" *ngIf="menuitem.type === 'sub'"
                [collapse]="menuitem.isCollapsed" [isAnimated]="true">
                <ul class="nav nav-sm flex-column" *ngFor="let childitems of menuitem.children">
                    <li *ngIf="childitems.type === 'link'" class="nav-item" [routerLink]="[menuitem.path, childitems.path]">
                      <a #childItem id="{{menuitem.path+'/'+childitems.path | backslash}}" routerLinkActive="active" [routerLink]="[menuitem.path, childitems.path]" class="nav-link child-item">
                        {{ childitems.title | translate }}
                        <span *ngIf="childitems.is_new" class="badge-new">BETA</span>
                      </a>
                    </li>
                    <div class="group-list" *ngIf="childitems.type === 'group'">
                      <div class="group-title">
                        <span class="pt-2">{{childitems.title | translate}}</span>
                      </div>
                      <div class="group-nav-items">
                        <li class="nav-item" *ngFor="let grandsonitems of childitems.children" [routerLink]="[menuitem.path, grandsonitems.path]">
                          <a #grandsonItem id="{{menuitem.path+'/'+grandsonitems.path | backslash}}" [routerLink]="[menuitem.path, grandsonitems.path]" class="nav-link child-item" routerLinkActive="active">
                            {{ grandsonitems.title | translate }}
                            <span *ngIf="grandsonitems.is_new" class="badge-new">BETA</span>
                          </a>
                        </li>
                      </div>
                    </div>
                </ul>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </perfect-scrollbar>

</nav>