import { Component, OnInit, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-auth-layout",
  templateUrl: "./auth-layout.component.html",
  styleUrls: ["./auth-layout.component.scss"]
})
export class AuthLayoutComponent implements OnInit, OnDestroy {
  test: Date = new Date();
  public isCollapsed = true;

  constructor(private router: Router) {}

  ngOnInit() {
    if(this.router.routerState.snapshot.url === '/remote-access') {
      var navbar = document.getElementsByClassName("navbar-horizontal")[0];
      navbar.setAttribute("style","display:none");
    }
    if(this.router.routerState.snapshot.url.includes('/password-recovery')) {
      var navbar = document.getElementsByClassName("navbar-horizontal")[0];
      navbar.setAttribute("style","display:none");
    }

    /*var html = document.getElementsByTagName("html")[0];
    // html.classList.add("auth-layout");
    var body = document.getElementsByTagName("body")[0];
    body.classList.add("bg-white");
    var navbar = document.getElementsByClassName("navbar-horizontal")[0];
    navbar.classList.add("navbar-light");
    navbar.classList.add("navbar-transparent");*/
  }
  
  ngOnDestroy() {
    var html = document.getElementsByTagName("html")[0];
    // html.classList.remove("auth-layout");
    var body = document.getElementsByTagName("body")[0];
    body.classList.remove("bg-default");
    // var navbar = document.getElementsByClassName("navbar-horizontal")[0];
    // navbar.classList.remove("navbar-light");
    // navbar.classList.remove("navbar-transparent");

    // if(this.router.routerState.snapshot.url === '/remote-access') {
    //   var navbar = document.getElementsByClassName("navbar-horizontal")[0];
    //   navbar.setAttribute("style","display:flex");
    // }
    // if(this.router.routerState.snapshot.url.includes('/password-recovery')) {
    //   var navbar = document.getElementsByClassName("navbar-horizontal")[0];
    //   navbar.setAttribute("style","display:flex");
    // }
  }

  checkLocation() : any{
    if(location.pathname === '/remote-access'){
      return true
    } else {
      return false;
    }
  }
}
