import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  UntypedFormGroup,
} from "@angular/forms";
import { Component, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-input",
  templateUrl: "./input.component.html",
  styleUrls: ["./input.component.scss"],
  providers: [],
})
export class InputComponent {
  @Input() inputAutocomplete?: string;
  @Input() inputClass?: string;
  @Input() inputDisabled?: boolean;
  @Input() inputFormControlName?: string;
  @Input() inputFormGroup!: UntypedFormGroup;
  @Input() inputFormGroupClass?: string;
  @Input() inputId?: string;
  @Input() inputIcon?: string;
  @Input() inputLabel?: string;
  @Input() inputLabelIcon?: string;
  @Input() inputLabelType?: string;
  @Input() inputLabelTooltip?: string;
  @Input() inputLabelTooltipPlacement?: string;
  @Input() inputLengthValidator?: {
    count: 0;
    limit: 0;
    isOverLimit: false;
  };
  @Input() inputMax?: number;
  @Input() inputMaxLength?: number;
  @Input() inputMin?: number;
  @Input() inputName?: string;
  @Input() inputNgClass?: string;
  @Input() inputOptions?: any;
  @Input() inputPlaceholder?: string;
  @Input() inputPrefix?: string;
  @Input() inputReadonly?: boolean;
  @Input() inputRequired?: boolean;
  @Input() inputSuffix?: string;
  @Input() inputTooltip?: string;
  @Input() inputValue?: any;
  @Input() inputType: string = "base";
  @Output() inputValueChange = new EventEmitter<any>();

  onFormChange(formValue: any, formName: string) {
    if (formValue && formName) {
      let emitter = {
        formValue: formValue.value,
        formName: formName,
      };
      //this.inputFormGroup.controls[formName].setValue(formValue.value);
      this.inputFormGroup.get(formName).setValue(formValue.value)
      this.inputValueChange.emit(emitter);      
    }
  }
}
