<div class="modal-content modal-milvus">
    <div class="modal-header-milvus align-items-center">
        <div class="col-6 header-col">
            <span class="modal-title" id="modal-title-default" >{{'ENCERRAR_ATENDIMENTO' | translate}}</span>
        </div>
        <div class="col-6 header-col">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="close()">
                <i class="mdi mdi-close"></i>
              </button>
        </div>
    </div>

    <div class="modal-body">
        <div class="row text-center">
            <div class="col-md-12">
                <h3>{{'AVISO_ENCERRAMENTO_ATENDIMENTO' | translate}}</h3>
            </div>
        </div>
            <div class="row">
                <div class="col-md-12">
                    <label for="text-input" class="form-control-label">{{ 'TABULACAO' | translate }}</label>
                    <select-padrao [entidade]="'customContent'"  [params]="tabulacaoLista" [required]="true" [returnObj]="true" [model]="tabulacao" (selectChange)="changeTabulacao($event)"></select-padrao>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <label for="text-input" class="form-control-label">{{ 'COMENTARIO' | translate }}</label>
                    <textarea [(ngModel)]="comentario" class="form-control" rows="3" type="text" id="text-input" style="resize: none;" required [ngClass]="{'required': verificarComentario()}"></textarea>
                </div>
            </div>
    </div>
    <div class="modal-footer modal-footer-milvus d-flex justify-content-between">
        <button class="btn btn-default btn-md badge-pill" (click)="fecharFinalizar()" >
            <span [translate]="'CANCELAR'"></span>
        </button>
        <button class="btn  btn-success btn-md badge-pill" (click)="salvar()" >
            <span [translate]="'FINALIZAR'"></span>
        </button>
    </div>

</div>
