import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { TokenService } from "../core/token/token.service";

@Injectable({
  providedIn: "root",
})
export class CategoryCatalogueService {
  private apiUrl = environment.apiGestorUrlLocal;
  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      authorization: this.tokenService.getToken(),
      session: this.tokenService.getSessionUsuario()
    }),
    params: {},
  };

  constructor(private tokenService: TokenService, private http: HttpClient) {}

  getList(params, filters): Observable<any> {
    this.httpOptions.params = params;
    return this.http
      .post(
        `${this.apiUrl}api/categorias-multi-niveis/lista`,
        filters,
        this.httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  getNext(id: number): Observable<any> {
    this.httpOptions.params = "";
    return this.http
      .get(
        `${this.apiUrl}api/categorias-multi-niveis/secundaria-sub/${id}`,
        this.httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  getTree(payload?): Observable<any> {
    this.httpOptions.params = "";
    return this.http
      .post(`${this.apiUrl}api/categorias-multi-niveis/arvore`, payload, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  getTreeByCustomer(filters: any): Observable<any> {
    this.httpOptions.params = "";
    return this.http
      .post(`${this.apiUrl}api/categorias-multi-niveis/arvore-cliente`, filters, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  createPrimary(payload): Observable<any> {
    this.httpOptions.params = "";
    return this.http
      .post(`${this.apiUrl}api/categorias-multi-niveis/adiciona-categoria-primaria`, payload, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  updatePrimary(payload): Observable<any> {
    this.httpOptions.params = "";
    return this.http
      .put(`${this.apiUrl}api/categorias-multi-niveis/alterar-categoria-primaria`, payload, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  createSecondary(payload): Observable<any> {
    this.httpOptions.params = "";
    return this.http
      .post(`${this.apiUrl}api/categorias-multi-niveis/adiciona-categoria-secundaria`, payload, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  updateSecondary(payload): Observable<any> {
    this.httpOptions.params = "";
    return this.http
      .put(`${this.apiUrl}api/categorias-multi-niveis/alterar-categoria-secundaria`, payload, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  getDataConfigFromCategory(payload): Observable<any> {
    this.httpOptions.params = "";
    return this.http
      .post(`${this.apiUrl}api/categorias-multi-niveis/obter-dados`, payload, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  arvoreCategoriaB2c(payload): Observable<any> {
    this.httpOptions.params = null;
    return this.http
      .post(`${this.apiUrl}api/b2c/dropdown-categorias`, payload, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error("Ops! Ocorreu um erro:", error.error.message);
    } else {
      console.error(
        `Backend retornou o código ${error.status}, ` +
          `o body é: ${error.error}`
      );
    }
    return throwError(error.error);
  }
}
