<div class="modal-content modal-milvus">
    <div class="modal-header-milvus align-items-center">
        <div class="col-6 header-col">
            <span class="modal-title" id="modal-title-default">{{'ASSOCIAR_TICKET' | translate}}</span>
        </div>
        <div class="col-6 header-col">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="close()">
                <i class="mdi mdi-close"></i>
            </button>
        </div>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-12">
                <div class="form-group">
                    <label [translate]="'CLIENTE'"></label>
                    <select-padrao [entidade]="'selectClientes'" [model]="chatData?.cliente" [disabled]="chatData?.cliente ? true : false" [required]="true" [returnObj]="true" (selectChange)="selecionaCliente($event)"></select-padrao>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="form-group">
                    <label [translate]="'TICKET'"></label>
                    <select-padrao *ngIf="cliente.id; else semClienteSelecionado" [entidade]="'selectChamadoConciliar'" [params]="cliente.id" [required]="true" [returnObj]="true" (selectChange)="selecionaChamado($event)"></select-padrao>
                    <ng-template #semClienteSelecionado>
                        <select-padrao [entidade]="'customContent'" [required]="false" [params]="[]" [returnObj]="true"></select-padrao>
                    </ng-template>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer modal-footer-milvus">
        <button class="btn badge-pill btn-mv-primary" (click)="abrirNovoTicket()">
            <span [translate]="'NOVO_TICKET'"></span>
        </button>
        <button class="btn badge-pill btn-mv-primary" (click)="associarTicket()" [disabled]="!chamado.id">
            <span [translate]="'ASSOCIAR_TICKET'"></span>
        </button>
    </div>
</div>