import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
  }
import { PaginationComponent } from "./pagination.component";

@NgModule({
    imports: [
        CommonModule,
        
        TranslateModule.forChild({
            loader: {
              provide: TranslateLoader,
              useFactory: HttpLoaderFactory,
              deps: [HttpClient],
            },
          }),
    ],
    declarations: [
        PaginationComponent
    ],
    exports: [
        PaginationComponent
    ],
    providers: [
    ]
})
export class PaginationModule {}