import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'backslash'
})
export class BackslashPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): unknown {
    let valueSplitted = value.split('/');
    let valueFormatted = valueSplitted.join('-');
    return valueFormatted;
  }

}
