<div class="modal-content modal-milvus">
    <div class="modal-header-milvus align-items-center">
        <div class="col-6 header-col">
            <span class="modal-title" id="modal-title-default" *ngIf="!contato">{{'NOVO_CONTATO' | translate}}</span>
            <span class="modal-title" id="modal-title-default" *ngIf="contato">{{'DETALHES_CONTATO' | translate}}</span>
        </div>
        <div class="col-6 header-col">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="close()">
                <i class="mdi mdi-close"></i>
              </button>
        </div>
    </div>

    <div class="modal-body">
        <form *ngIf="!contato" [formGroup]="contatoForm">
            <div class="row">
                <div class="col-lg-6 form-group">
                    <label for="text-input" class="form-control-label">{{ 'CLIENTE' | translate }}</label>
                    <select-padrao [entidade]="'selectClientes'" [required]="true" [returnObj]="true" (selectChange)="contatoForm.patchValue({cliente: $event})"></select-padrao>
                </div>
                <div class="col-lg-6 form-group">
                    <label for="text-input" class="form-control-label">{{ 'DESCRICAO' | translate }}</label>
                    <input class="form-control" [ngClass]="{'required': verificaRequerido('descricao')}" type="text" id="text-input"
                    (focus)="focus = true" (blur)="focus = false" formControlName="descricao">
                </div>
            </div>

            <div class="row">
                <div class="col-lg-6 form-group">
                    <label for="text-input" class="form-control-label">{{ 'EMAIL' | translate }}</label>
                    <input class="form-control" type="text" id="text-input"
                    (focus)="focus = true" (blur)="focus = false" formControlName="email">
                </div>                
            </div>            

            <div class="row">
                <div class="col-lg-6 form-group">
                    <label for="text-input" class="form-control-label">{{ 'CELULAR' | translate }}</label>
                    <input class="form-control" type="text" id="text-input" mask="(00) 0000-0000||(00) 0 0000-0000"
                    (focus)="focus = true" (blur)="focus = false" formControlName="celular">
                </div>

                <div class="col-lg-6 form-group">
                    <label for="text-input" class="form-control-label">Whatsapp</label>
                    <select class="form-control" [ngClass]="{'required': verificaRequerido('whatsapp')}" id="select-input" formControlName="whatsapp">
                        <option value="" selected>{{'SELECIONE' | translate}}</option>
                        <option [value]="true">{{'SIM' | translate}}</option>
                        <option [value]="false">{{'NAO' | translate}}</option>
                    </select>
                </div>
            </div>

            <div class="row">  
                <div class="col-lg-6 form-group">
                    <label>{{'DDG' | translate}}</label>
                    <div>
                        <label class="switch">
                            <input type="checkbox" [checked]="is_ddg" (change)="checkDdg($event)"/>
                            <span class="slider round"></span>
                        </label>
                    </div>
                </div>

                <div class="col-lg-6 form-group" *ngIf="!is_ddg">
                    <label for="text-input" class="form-control-label">{{ 'TELEFONE' | translate }}</label>
                    <input class="form-control" type="text" id="text-input" mask="(00) 0000-0000||(00) 0 0000-0000"
                    (focus)="focus = true" (blur)="focus = false" formControlName="telefone">
                </div>    
                
                <div class="col-lg-6 form-group" *ngIf="is_ddg">
                    <label>DDG</label>
                    <div class="input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text" id="ddifixo">+55</span>
                        </div>
                        <input class="form-control" type="text" formControlName="telefone" mask="0000 000 0000" aria-describedby="ddifixo">
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-4 form-group">
                    <label for="select-input" class="form-control-label">{{ 'PADRAO' | translate }}</label>
                    <select class="form-control" [ngClass]="{'required': verificaRequerido('padrao')}" id="select-input" formControlName="padrao">
                        <option value="" selected>{{'SELECIONE' | translate}}</option>
                        <option [value]="true">{{'SIM' | translate}}</option>
                        <option [value]="false">{{'NAO' | translate}}</option>
                    </select>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12 form-group">
                    <label for="text-input" class="form-control-label">{{ 'OBSERVACAO' | translate }}</label>
                    <!-- <textarea class="form-control" rows="3" type="text" id="text-input" placeholder="{{ 'OBSERVACAO' | translate }}"
                        (focus)="focus = true" (blur)="focus = false" style="resize: none;" formControlName="observacao"></textarea> -->
                    <app-text-editor
                        formControlName="observacao"
                        [textEditorConfig]="quillConfigEditor"
                        [textEditorRequired]="false"
                    >
                  </app-text-editor>
                </div>
            </div>
        </form>
        <div *ngIf="contato">
            <div class="row d-flex align-items-center flex-column">
                <img class="user-foto" src="assets/img/theme/user.png"/>
                <span>{{contato.nome}}</span>
                <span>{{contato.telefone | mask:'(00) 0000-0000'||'(00) 0 0000-0000'}}</span>
                <span>{{contato.email}}</span>
            </div>
            <div class="row mt-2">
                <div class="col-6">
                  <strong>{{'NOME' | translate}}:</strong><span *ngIf="contato.nome"> {{contato.nome}}</span> <span *ngIf="!contato.nome"> {{'NAO_POSSUI' | translate}}</span>
                </div>
                <div class="col-6">
                  <strong>{{'EMAIL' | translate}}:</strong><span *ngIf="contato.email"> {{contato.email}}</span> <span *ngIf="!contato.email"> {{'NAO_POSSUI' | translate}}</span>
                </div>
            </div>
            <div class="row">
                <div class="col-6">
                  <strong>{{'TELEFONE' | translate}}:</strong>
                  <span *ngIf="contato.telefone"> {{contato.telefone | mask: (contato.telefone.length > 10 ? '(00) 00000-0000' : '(00) 0000-0000')}}</span> 
                  <span *ngIf="!contato.telefone"> {{'NAO_POSSUI' | translate}}</span>
                </div>
                <div class="col-6">
                    <strong>{{'CELULAR' | translate}}:</strong>
                    <span *ngIf="contato.celular"> {{contato.celular | mask: (contato.celular.length > 10 ? '(00) 00000-0000' : '(00) 0000-0000')}}</span> 
                    <span *ngIf="!contato.celular"> {{'NAO_POSSUI' | translate}}</span>
                  </div>
            </div>
        </div>
    </div>

    <div class="modal-footer modal-footer-milvus" *ngIf="!contato">
        <button class="btn badge-pill btn-success" (click)="onSubmit()" [disabled]="contatoForm.invalid">
            <i class="fas fa-save"></i>
            <span [translate]="'SALVAR'"></span>
        </button>
        <button class="btn badge-pill btn-success" (click)="onSubmitSet()" [disabled]="contatoForm.invalid">
            <i class="fas fa-save"></i>
            <span [translate]="'SALVAR_E_ASSOCIAR'"></span>
        </button>
    </div>
</div>
