import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hoursminutes'
})
export class HoursminutesPipe implements PipeTransform {

  transform(value: string): string {
    var _valor = value.replace(/\D/g, '');
    
    if(_valor.length === 2) {
      _valor += ":";
    } else if(_valor.length === 3) {
      _valor = [_valor.slice(0, 2), ":", _valor.slice(2)].join('');
    } else if(_valor.length === 4) {
      _valor = [_valor.slice(0, 2), ":", _valor.slice(2)].join('');
    } else if(_valor.length === 5) {
      _valor = [_valor.slice(0, 3), ":", _valor.slice(3)].join('');
    } else if(_valor.length === 6) {
      _valor = [_valor.slice(0, 4), ":", _valor.slice(4)].join('');
    } else if(_valor.length > 6) {
      _valor = [_valor.slice(0, 4), ":", _valor.slice(4)].join('');
      _valor = _valor.slice(0, _valor.length - 1);
    }

    return _valor;
  }

}
