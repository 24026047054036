<!-- <ng-container [formGroup]="textEditorFormGroup">
  <quill-editor
    [formControlName]="textEditorFormControlName"
    [placeholder]="'INSIRA_TEXTO_IMAGEM' | translate"
    [modules]="textEditorConfig"
    (onSelectionChanged)="onSelectionChanged($event)"
    (onContentChanged)="onContentChanged($event)"
    [ngClass]="{ required: textEditorRequired }"
    style="height: 100% !important; max-height: 400px !important"
  >
  <quill-view-html [content]="textEditorHTML"></quill-view-html>
  </quill-editor>
</ng-container> -->

<ng-container>
  <quill-editor
    [placeholder]="editorPlaceholder"
    [modules]="textEditorConfig"
    (onSelectionChanged)="onSelectionChanged($event)"
    (onContentChanged)="onContentChanged($event)"
    (onEditorCreated)="onEditorCreated($event)"
    [(ngModel)]="textEditorValue"
    [ngClass]="{ required: textEditorRequired }"
    [ngStyle]="{'height': customEditorHeight+'px !important'}"
    #editorMilvus
  >
  <quill-view-html [content]="textEditorHTML"></quill-view-html>
  </quill-editor>
</ng-container>