<div class="modal-content modal-milvus">
    <div class="modal-header">
        <h6 class="modal-title" id="modal-title-default">{{'SELECIONE_CONDICAO' | translate}}</h6>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="close()">
            <i class="mdi mdi-close"></i>
        </button>
    </div>
    <div class="modal-body">
        <div class="form-group">
            <label for="">{{'CAMPO' | translate}}</label>
            <select-padrao [model]="selectedField" [entidade]="'selectDropdownFiltroAvancado'" [required]="true"
                [returnObj]="true" (selectChange)="changeCampo($event)"></select-padrao>
        </div>
        <div class="form-group">
            <label for="" style="text-transform: capitalize;">{{'IGUAL' | translate}}</label>
        </div>
        <div class="form-group">
            <label [translate]="'VALOR'"></label>
            <select-padrao [model]="valor" *ngIf="selectedField.tipo === 'select' && selectedField.text !== 'Categorias'" [params]="listaValores"
                [entidade]="'select'" [required]="true" [returnObj]="true"[atributo]="atributo"
                (selectChange)="onSelectChange($event)">
            </select-padrao>

            <input *ngIf="selectedField.tipo === 'texto'" class="form-control" type="text" [(ngModel)]="valor">
        </div>
        <div class="form-group" *ngIf="selectedField.tipo === 'url' || selectedField.tipo === 'cnpj' || selectedField.tipo === 'cpf'">
            <input class="form-control" [type]="'text'" [maxlength]="selectedField.tipo === 'cpf' ? 11 : (selectedField.tipo === 'cnpj' ? 14 : 500)" [(ngModel)]="valor">
        </div>
        <div class="form-group" *ngIf="selectedField.tipo === 'hora'">
            <input class="form-control" type="time" [(ngModel)]="valor">
        </div>
        <div class="form-group" *ngIf="selectedField.tipo === 'booleano'">
            <select class="form-control" [(ngModel)]="valor">
                <option [value]="true">{{'SIM' | translate}}</option>
                <option [value]="false">{{'NAO' | translate}}</option>
            </select>
        </div>
        <div class="form-group">
            <input *ngIf="selectedField.tipo === 'decimal'" class="form-control" type="number" [(ngModel)]="valor">
            <input *ngIf="selectedField.tipo === 'inteiro'" class="form-control" type="number" [(ngModel)]="valor" pattern="[0-9]+">
        </div>
        <div *ngIf="selectedField.tipo === 'data_periodo' || selectedField.tipo === 'data'">
            <div style="display: grid;">
                <span class="text-center">                    
                    <datepicker-padrao [is_range]="true" [inline]="true" (dateChange)="valor = $event"></datepicker-padrao>
                </span>
            </div>
        </div>
        <div>
            <cat-multi-select *ngIf="selectedField.tipo === 'select' && selectedField.text === 'Categorias'"
                [required]="true" 
                [primarySecondary]="true"
                [selectOnlySecondary]="true"
                (categoryChange)="valor = $event">
            </cat-multi-select>
        </div>
    </div>
    <div class="modal-footer modal-footer-milvus">
        <button class="btn badge-pill btn-success" (click)="salvar()" [disabled]="verificaCampos()">
            {{'SALVAR' | translate}}
        </button>
    </div>
</div>