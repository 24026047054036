import { HttpHeaders, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { TokenService } from '../core/token/token.service';

@Injectable({
  providedIn: 'root'
})
export class SenhasService {
  private apiUrl = environment.apiGestorUrlLocal;
  private apiUrlNova = environment.apiGestorUrlNova;
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', 'authorization': this.tokenService.getToken(), 'session': this.tokenService.getSessionUsuario() }),
    params: {}
  };

  constructor(private http: HttpClient, private tokenService: TokenService) { }

  validaSenha(payload): Observable<any> {
    return this.http.post(`${this.apiUrl}api/senhas/valida`, payload, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  criarSenha(payload): Observable<any> {
    var data = {
      senha: payload
    }
    this.httpOptions.params = "";
    return this.http.post(`${this.apiUrl}api/senhas`, data, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  atualizarSenha(payload): Observable<any> {
    var data = {
      senha: payload
    }
    this.httpOptions.params = "";
    return this.http.put(`${this.apiUrl}api/senhas`, data, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }


  getSenha(id): Observable<any> {
    this.httpOptions.params = "";
    return this.http.get(`${this.apiUrl}api/senhas/${id}`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }


  removerSenha(id): Observable<any> {
    this.httpOptions.params = "";
    return this.http.delete(`${this.apiUrl}api/senhas/` + id, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  getAcoes(): Observable<any> {
    this.httpOptions.params = '';
    return this.http.get(`${this.apiUrl}api/senhas/dropdown-acao-expiracao`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  uploadImagem(file): Observable<any> {
    this.httpOptions.params = "";
    return this.http.post(`${this.apiUrl}api/base-conhecimento/adicionaArquivo`, file,
      { headers: new HttpHeaders({ 'authorization': this.tokenService.getToken(), 'session': this.tokenService.getSessionUsuario() }) })
      .pipe(
        catchError(this.handleError)
      );
  }

  sendRecoveryEmail(email): Observable<any> {
    this.httpOptions.params = "";
    return this.http.get(`${this.apiUrl}api/senhas/recuperar/${email}`,
      { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) })
      .pipe(
        catchError(this.handleError)
      );
  }

  redefinirSenha(token, email): Observable<any> {
    this.httpOptions.params = "";
    return this.http.get(`${this.apiUrl}api/senhas/redefinir/${token}&${email}`,
      { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) })
      .pipe(
        catchError(this.handleError)
      );
  }

  getListGroupPassword(params, filtros): Observable<any> {
    this.httpOptions.params = params;
    return this.http.post(`${this.apiUrlNova}manager/grouppassword/list`, filtros, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  setGroupPassword(payload): Observable<any> {
    this.httpOptions.params = '';
    return this.http.post(`${this.apiUrlNova}manager/grouppassword`, payload, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  updateGroupPassword(payload): Observable<any> {
    this.httpOptions.params = '';
    return this.http.put(`${this.apiUrlNova}manager/grouppassword`, payload, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  getGroupPasswordById(id): Observable<any> {
    this.httpOptions.params = '';
    return this.http.get(`${this.apiUrlNova}manager/grouppassword/${id}`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  dropdownGroupPassword(filter): Observable<any> {    
    this.httpOptions.params = filter;
    return this.http.get(`${this.apiUrlNova}manager/grouppassword/dropdown`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('Ops! Ocorreu um erro:', error.error.message);
    } else {
      console.error(
        `Backend retornou o código ${error.status}, ` +
        `o body é: ${error.error}`);
    }
    return throwError(error.error);
  }

}
