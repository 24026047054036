import { Component, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { QuillEditorComponent } from 'ngx-quill';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { TokenService } from 'src/app/core/token/token.service';
import { IntegrationService } from 'src/app/services/integration.service';
import { QuillButtonOptionsModel } from 'src/app/models/quill/quillButtonOptions.model';
import { QuillConfigModel } from 'src/app/models/quill/quillConfig.model';
import { TarefaService } from 'src/app/services/tarefa.service';
import { UsuariosService } from 'src/app/services/usuarios.service';
import { environment } from 'src/environments/environment';
import swal from "sweetalert2";
declare let moment: any;

@Component({
  selector: 'app-modal-reagendamento',
  templateUrl: './modal-reagendamento.component.html',
  styleUrls: ['./modal-reagendamento.component.scss']
})
export class ModalReagendamentoComponent implements OnInit, OnDestroy {
  rescheduleForm: UntypedFormGroup = new UntypedFormGroup({
    description: new UntypedFormControl("", Validators.required),
  });
  @Input() tarefa;
  @Input() esconde_selects;
  tecnico: any;
  hoje = new Date();
  selector: string;
  newSubs: Subscription;
  integracao_google: boolean = false;
  integracao_outlook: boolean = false;
  quillConfigEditor: QuillConfigModel;
  editor: QuillEditorComponent;
  descricao: any;

  constructor(
    private tarefaService: TarefaService,
    public activeModal: NgbActiveModal,
    private toastr: ToastrService,
    public translate: TranslateService,
    private elemRef: ElementRef,
    private usuarioService: UsuariosService,
    private tokenService: TokenService,
    private integrationService: IntegrationService
  ) {
    this.selector = this.elemRef.nativeElement.tagName.toLowerCase();
  }

  ngOnInit(): void {
    document.getElementsByTagName(this.selector)[0]['style']['zoom'] = '0.85';
    this.rescheduleForm.patchValue({
      description: this.tarefa.descricao_html
    });
    this.getTarefa();
    this.setQuillEditorConfig();
  }

  ngOnDestroy(): void {
    this.newSubs?.unsubscribe();
  }

  setQuillEditorConfig() {
    this.quillConfigEditor = {
      magicUrl: true,
      imageDrop: true,
      ImageResize: { modules: ["Resize", "DisplaySize", "Toolbar"] },
      toolbar: {
        container: [
          ["bold", "italic", "underline", "strike"],
          [
            { align: [] },
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" },
          ],

          ["code-block", "link", "image"],
        ],
        handlers: { emoji: function () {} },
      },
      "toolbar-custom-button": new QuillButtonOptionsModel(),
      "emoji-toolbar": true,
      "emoji-textarea": false,
      "emoji-shortname": true,

      // mention: {
      //   allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
      //   onSelect: (item, insertItem) => {
      //     const editor = this.editor.quillEditor;
      //     insertItem(item);
      //     editor.insertText(editor.getLength() - 1, "", "user");
      //   },
      //   source: (searchTerm, renderList) => {
      //     const values = [
      //       { id: 1, value: "Fredrik Sundqvist" },
      //       { id: 2, value: "Patrik Sjölin" },
      //     ];

      //     if (searchTerm.length === 0) {
      //       renderList(values, searchTerm);
      //     } else {
      //       const matches = [];

      //       values.forEach((entry) => {
      //         if (
      //           entry.value.toLowerCase().indexOf(searchTerm.toLowerCase()) !==
      //           -1
      //         ) {
      //           matches.push(entry);
      //         }
      //       });
      //       renderList(matches, searchTerm);
      //     }
      //   },
      // },
      keyboard: {
        bindings: {
          // shiftEnter: {
          //   key: 13,
          //   shiftKey: true,
          //   handler: (range, context) => {
          //     // Handle shift+enter
          //     console.log("shift+enter")
          //   }
          // },
          enter: {
            key: 13,
            handler: (range, context) => {
              return true;
            },
          },
        },
      },
    };
  }

  checkImage(html: string): boolean {
    return html && html.match(/<img/) && html.match(/<img/).length > 0;
  }

  checkFields(formName: string): boolean {
    if (this.rescheduleForm.get(formName).value) {
      let _cleanText = (this.rescheduleForm.get(formName).value.text)
        ? this.rescheduleForm.get(formName).value.text.replaceAll('/n', '')
        : this.rescheduleForm.get(formName).value.replaceAll('/n', '');
      let _html = this.rescheduleForm.get(formName).value 
        && this.rescheduleForm.get(formName).value.html 
        ? this.rescheduleForm.get(formName).value.html 
        : this.rescheduleForm.get(formName).value;
      if ((!_html || _cleanText.length <= 5) && !this.checkImage(_html)) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  close(result?) {
    this.activeModal.close(result);
  }

  changeTecnico(tecnico) {
    this.tecnico = tecnico;    
    this.hasChangeOperator = true;
    this.setInteracaoGoogleAgenda();
  }

  hasChangeOperator = false;

  setInteracaoGoogleAgenda() : void {
    let param = {
      userId: this.tecnico.id
    }
    this.newSubs = this.integrationService.validAuthTecnicoGoogle(param).subscribe(
      {
        next: (result) => {
          if (result && result.hasOwnProperty('active')) {
            this.integracao_google = result.active;
            if (!this.integracao_google) {
              this.validAuthTecnicoOutlook();
            } else {
              this.integracao_outlook = false;
              this.hasChangeOperator = false;
            }
          } else {            
            this.hasChangeOperator = false;
          }
          
        }, error: (error) => {
          console.error(error);
          this.toastr.show(error, '', environment.toastrConfig('danger'));  
        }
      }
    )
  }

  validAuthTecnicoOutlook() : void {
    let param = {
      userId: this.tecnico.id
    }
    this.newSubs = this.integrationService.validAuthTecnicoOutlook(param).subscribe(
      {
        next: (result) => {
          if (result && result.hasOwnProperty('active')) {
            this.integracao_outlook = result.active;
          } 
          this.hasChangeOperator = false;
        }, error: (error) => {
          console.error(error);
          this.toastr.show(error, '', environment.toastrConfig('danger'));  
        }
      }
    )
  }

  updateDescricao(event) {
    this.tarefa.descricao = event;
  }

  calculateFinalMeet(date, tempo_tarefa): string {
    if (date) {
      return moment(date).add(moment.duration(tempo_tarefa)).format("YYYY-MM-DD HH:mm:ss");
    }
  }

  setEventGoogleSchedule(payload: any, tarefa: any): void {
    payload['data_agendamento_google_inicial'] = moment(tarefa.data_agendamento).format("YYYY-MM-DDTHH:mm:ss");
    payload['data_agendamento_google_final'] = moment(this.calculateFinalMeet(tarefa.data_agendamento, tarefa.tempo_tarefa)).format("YYYY-MM-DDTHH:mm:ss");
    payload['local'] = tarefa.local ? tarefa.local : '';
    payload['convidados'] = tarefa.convidados === '' ? [] : tarefa.convidados;
    payload['google_meet'] = tarefa.hangout_link ? true : false; 
  }

  setEventOutlookCalendar(payload: any, tarefa: any): void {
    payload['data_agendamento_outlook_inicial'] = moment(tarefa.data_agendamento).format("YYYY-MM-DDTHH:mm:ss");
    payload['data_agendamento_outlook_final'] = moment(this.calculateFinalMeet(tarefa.data_agendamento, tarefa.tempo_tarefa)).format("YYYY-MM-DDTHH:mm:ss");
    payload['local'] = tarefa.local ? tarefa.local : '';
    payload['convidados'] = tarefa.convidados === '' ? [] : tarefa.convidados;
    payload['outlook_teams'] = tarefa.hangout_link ? true : false; 
  }

  salvar() {
    var _obj = {
      tarefa_id: this.tarefa.tarefa_id,
      tecnico_id: this.tecnico.id,
      tempo_tarefa: this.tarefa.tempo_tarefa,
      descricao: (this.rescheduleForm.value.description.text) ? this.rescheduleForm.value.description.text : this.rescheduleForm.value.description.replace(/(<([^>]+)>)/ig, ""),
      descricao_html: (this.rescheduleForm.value.description.html) ? this.rescheduleForm.value.description.html : this.rescheduleForm.value.description,
      data_agendamento: this.tokenService.convertDateTimezoneStockFormat(this.tarefa.data_agendamento),
      titulo: this.tarefa.titulo,
      integracao_google: this.integracao_google.toString(),
      tipo_tarefa_id: this.tarefa.tipo_tarefa_id && this.tarefa.tipo_tarefa_id.id ? this.tarefa.tipo_tarefa_id.id : null,
      integracao_outlook: this.integracao_outlook.toString(),

    };

    if (this.tarefa.cliente_id && this.tarefa.cliente_id.id) {
      _obj['cliente_id'] = this.tarefa.cliente_id.id;
    }
    if (this.tarefa.chamado_id && this.tarefa.chamado_id.id) {
      _obj['chamado_id'] = this.tarefa.chamado_id.id;
    }

    if (this.integracao_google) {
      this.setEventGoogleSchedule(_obj, this.tarefa);
    } else if (this.integracao_outlook) {
      this.setEventOutlookCalendar(_obj, this.tarefa);
    }

    if (this.tarefa.integracao_google === 'false' && this.tarefa.integracao_outlook === 'false') {
      _obj.data_agendamento = this.tokenService.convertDateTimezoneStockFormat(this.tarefa.data_agendamento);
      delete _obj['convidados'];
    }

    if (this.checkOperador()) {
      this.newSubs = this.tarefaService.reagendarTarefa(_obj)
      .subscribe(results => {
        this.toastr.show(this.translate.instant('REAGENDADO_SUCESSO'), '', environment.toastrConfig('success'));
        this.close(_obj);
      }, error => {
        this.checkError(error);
      });
    } else {
      swal.fire({
        text: this.translate.instant('AVISO_REAGENDAMENTO_INTEGRACAO'),
        icon: 'warning',
        showCancelButton: false,
        confirmButtonText: 'OK',
      })      
    }    
  }

  checkOperador() : boolean {   
    if (this.tarefa?.integracao_google.toString() === "true" && this.integracao_google.toString() === "true") {
      return true;
    } else if (this.tarefa?.integracao_outlook.toString() === "true" && this.integracao_outlook.toString() === "true") {
      return true;
    } else if (this.integracao_google.toString() === "false" && this.integracao_outlook.toString() === "false") {
      return true;
    } else if (this.tarefa?.integracao_google.toString() === "false" && this.tarefa?.integracao_outlook.toString() === "false") {
      return true;
    }
    return false;
  }

  checkError(error) {
    if (error && error.code === "FORBIDDEN" && error.details === "credentials not found" && error.status ===  403) {
      this.toastr.show(this.translate.instant('ERRO_REAGENDAR_INTEGRACAO_GOOOGLE_AGENDA'), '', environment.toastrConfig('danger'));         
    } else {
      console.error(error);
      this.toastr.show(error, '', environment.toastrConfig('danger'));      
    }
  }

  checkKindGoogle(kind): boolean {
    return kind && kind.includes('calendar#event');
  }

  checkKindOutlook(kind): boolean {
    return kind && kind.includes('outlook#calendar');
  }

  getTarefa() {
    this.newSubs = this.tarefaService.getTarefaById(this.tarefa.tarefa_id).subscribe(
      (result) => {        
        /* this.integracao_google = this.checkKindGoogle(result.tarefa.kind);
        this.integracao_outlook = this.checkKindOutlook(result.tarefa.kind); */
        this.tarefa.integracao_google = result.tarefa.id_google_agenda ? true : false;
        this.tarefa.integracao_outlook = result.tarefa.id_outlook_agenda ? true : false;
        this.getOperadortecnicoId(result.tarefa.tecnico_id);    
      }, (error) => {
        console.error(error);
        this.toastr.show(error, '', environment.toastrConfig('danger'));
      }
    )
  }

  verifica() {
    return (this.tarefa.data_agendamento === '' || this.tarefa.data_agendamento === undefined) ||
      (this.tecnico === undefined || this.tecnico.id === '')
  }

  getOperadortecnicoId(tecnicoId) {
    this.newSubs = this.usuarioService.getUsuarioById(tecnicoId).subscribe(
      (results) => {
       this.tecnico = results.usuario;     
        this.setInteracaoGoogleAgenda();
      },
      (error) => {
        this.toastr.show(error, "", environment.toastrConfig("danger"));
      }
    );
  }
}