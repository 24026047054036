import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { TokenService } from 'src/app/core/token/token.service';
declare let moment: any;

@Component({
  selector: 'datepicker-padrao',
  templateUrl: './datepicker-padrao.component.html',
  styleUrls: ['./datepicker-padrao.component.scss']
})
export class DatepickerPadraoComponent implements OnInit {
  selectedValue;
  @Input() set model(value){
    this.setDatepickerTime(value)
  };
  @Input() dataMinima;
  @Input() dataMaxima;
  @Input() required;
  @Input() disabled;
  @Input() disabledHours;
  @Input() is_range;
  @Input() inline;
  @Output() dateChange = new EventEmitter();
  @ViewChild('dp') dp : ElementRef;
  
  locale = 'pt-br';

  @Input() idNumber
  @Input() readonly: boolean;
  horas;
  minutos;
  uniqueID;

  constructor(
    private elem: ElementRef,
    private _localeService: BsLocaleService,
    private tokenService: TokenService
  ) { }

  ngOnInit(): void { 
    //Gera um ID unico para referenciar um único elemento à ser usado como pai na propr. [container] do datepicker  
    this.uniqueID = 'dp' + Math.random().toString(36).substring(2); //Formatado para string pois [container] só aceita string por enquanto. Concatenado com 'dp' para não causar erros de referência.
    this._localeService.use(this.locale);
    this.setHourInSelectedValue(this.selectedValue);
  }

  formatValorData(value) {
    return value && value.toString().includes(':') ? value : `${value} 00:00:00`
  }

  setHourInSelectedValue(value: Date) : void {
    if (this.dataMinima && !this.disabledHours && !this.disabled) {
      this.dataMinima.setHours(value.getHours());
      this.dataMinima.setMinutes(value.getMinutes());
      this.dataMinima.setSeconds(value.getSeconds());      
      //this.dateChange.emit(moment(this.dataMinima).format('YYYY-MM-DD HH:mm:ss'));      
      this.compareDate(this.dataMinima, value);
    }
  }

  compareDate(dataMinima, value) {
    if (dataMinima >= value) {
      this.dateChange.emit(moment(this.dataMinima).format('YYYY-MM-DD HH:mm:ss'));   
    } else  {
      this.dateChange.emit(moment(value).format('YYYY-MM-DD HH:mm:ss'));  
    } 
  }

  setDatepickerTime(value){    
    if(!this.is_range) {      
      this.selectedValue = value ? new Date(this.formatValorData(value)) : '';
      //this.selectedValue = value ? value : '';
    } else {
      this.selectedValue = value ? [new Date(value[0]), new Date(value[1])] : null;
    }    
  }

  emitValue(value): void {
    //if(value) {
      this.dateChange.emit(value ? value : null);
    //}
  }

  verificaRequerido(): boolean {
    if(this.required === true) {
      if(this.selectedValue) {
        if(this.selectedValue !== '' || this.selectedValue !== null) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  setHours(hour, elem): void {
    this.horas = hour;
    var dropHoras = document.getElementsByClassName('drop-horas');
    for(var i = 0; i < dropHoras.length; i++) {
      dropHoras[i].classList.remove('hora-selecionada');
    }
    elem.classList.add('hora-selecionada');
    
    if(this.selectedValue !== undefined && this.selectedValue !== null) {
      var oldVal = this.dp['_elementRef'].nativeElement.value.split(' ');
      let oldMin = oldVal[1].split(':')
      this.dp['_elementRef'].nativeElement.value = oldVal[0] + " " + hour + ":" + ((this.minutos) ? this.minutos : oldMin[1]);
      this.selectedValue.setHours(hour);
      this.emitValue(this.selectedValue);
    } else {
      this.selectedValue = new Date();
      this.selectedValue.setHours(hour);
      this.selectedValue.setMinutes(0);
      this.emitValue(this.selectedValue);
    }
  }

  setMinutes(min, elem): void {
    this.minutos = min;
    var dropHoras = document.getElementsByClassName('drop-minutos');
    for(var i = 0; i < dropHoras.length; i++) {
      dropHoras[i].classList.remove('hora-selecionada');
    }
    elem.classList.add('hora-selecionada');

    if(this.selectedValue !== undefined && this.selectedValue !== null) {
      var oldVal = this.dp['_elementRef'].nativeElement.value.split(' ');
      let oldHour = oldVal[1].split(':')
      this.dp['_elementRef'].nativeElement.value = oldVal[0] + " " + ((this.horas) ? this.horas : oldHour[0]) + ":" + min;
      this.selectedValue.setMinutes(min);
      this.emitValue(this.selectedValue);
    } else {
      this.selectedValue = new Date();
      this.selectedValue.setHours(0);
      this.selectedValue.setMinutes(min);
      this.emitValue(this.selectedValue);
    }
  }
}
