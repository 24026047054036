<div class="modal-content modal-milvus">
    <div class="modal-header">
        <h6 class="modal-title" id="modal-title-default">{{'AGENDAR' | translate}}</h6>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="close()">
            <i class="mdi mdi-close"></i>
        </button>
    </div>
    <div class="modal-body">
        <div>
            <div class="row">
                <div class="form-group col-8">
                    <label [translate]="'DATA_AGENDAMENTO'"></label>
                    <datepicker-padrao [model]="tarefa.data_agendamento" [required]="true" [dataMinima]="hoje" (dateChange)="tarefa.data_agendamento = $event"></datepicker-padrao>
                </div>
                <div class="form-group col-4">
                    <label [translate]="'TEMPO_EXECUTAR_TAREFA'"></label>                            
                    <hourpicker-padrao [model]="tarefa.tempo_tarefa" [required]="true" (hourChange)="tarefa.tempo_tarefa = $event"></hourpicker-padrao>
                </div>
            </div>
            
            <div class="form-group">
                <label [translate]="'OPERADOR'"></label>
                <select-padrao [entidade]="'selectTecnico'" [required]="true" [returnObj]="true" [model]="tecnico" (selectChange)="changeTecnico($event)"></select-padrao>
            </div>
            <div class="form-group" [formGroup]="rescheduleForm">
                <label [translate]="'DESCRICAO'"></label>
                <app-text-editor
                    formControlName="description"
                    [textEditorConfig]="quillConfigEditor"
                    [textEditorRequired]="checkFields('description')"
                    [textEditorHeight]="250"
                >
                </app-text-editor>
            </div>
        </div>
    </div>

    <div class="modal-footer modal-footer-milvus">
        <button class="btn badge-pill btn-success" (click)="salvar()" [disabled]="verifica() || checkFields('description') || hasChangeOperator">
            <i class="fas fa-save"></i> {{'REAGENDAR' | translate}}
        </button>
    </div>
</div>