import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { EmpresasService } from "src/app/services/empresas.service";
import { ToastrService } from "ngx-toastr";
import { environment } from "src/environments/environment";
import { FaturamentoService } from "src/app/services/faturamento.service";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-minha-fatura-plano',
  templateUrl: './minha-fatura-plano.component.html',
  styleUrls: ['./minha-fatura-plano.component.scss']
})
export class MinhaFaturaPlanoComponent implements OnInit, OnDestroy {
  configPagination = environment.getPaginationDefault();
  pagination;
  filtros = {
    filtro_body: {}
  };
  empresa: any;
  plano: any;
  faturaList: Array<Object> = [];
  selector: string;
  newSubs: Subscription;

  constructor(
    public activeModal: NgbActiveModal,
    private empresasService: EmpresasService,
    private toastr: ToastrService,
    private faturamentoService: FaturamentoService,
    public translate: TranslateService,
    private elemRef: ElementRef,
) {
    this.selector = elemRef.nativeElement.tagName.toLowerCase();
}

  ngOnInit(): void {
    document.getElementsByTagName(this.selector)[0]['style']['zoom'] = '0.85'
    this.getEmpresa()
    this.listFaturasDefault()
  }

  ngOnDestroy(): void {
    this.newSubs?.unsubscribe();
  }

  close(result?) {
    this.activeModal.close(result);
  }

  getEmpresa(){
    this.newSubs = this.empresasService.getEmpresa().subscribe(
      result => {
        //console.log(result)
        this.empresa = result.empresa
        this.getPlano(this.empresa.plano.id)
      },
      error => {
        this.toastr.show(error, "", environment.toastrConfig("danger"));
      }
    )
  }

  getPlano(id){
    this.newSubs = this.empresasService.getPlano(id).subscribe(
      result => {
        //console.log(result)
        this.plano = result.plano
      },
      error => {
        this.toastr.show(error, "", environment.toastrConfig("danger"));
      }
    )
  }

  listFaturasDefault(){
    let payload = {
      filtro_body: {
        qtde_meses: 12
      }
    }

    this.newSubs = this.faturamentoService.listagemFaturas(this.configPagination, payload).subscribe(
      result => {
        //console.log(result)
        this.pagination = result.meta.paginate;
        this.getFaturaPrevia(result.lista)
      },
      error => {
        this.toastr.show(error, "", environment.toastrConfig("danger"));
      }
    )
  }

  getFaturaPrevia(lista){
    this.newSubs = this.faturamentoService.faturaPrevia().subscribe(
      result => {
        //console.log(result)
        lista.unshift(result.fatura)
        this.faturaList = lista
      },
      error => {
        this.toastr.show(error, "", environment.toastrConfig("danger"));
      }
    )
  }

  getNotaFiscal(fatura){
    if (fatura.nota_fiscal_url) {
      window.open(fatura.nota_fiscal_url, '_blank');
      return;
    }

    this.newSubs = this.faturamentoService.getNotaFiscal(fatura.id).subscribe(
      result => {
        if (result === null || !result.link) {
          this.toastr.show(this.translate.instant('NOTA_NAO_GERADA'), "", environment.toastrConfig("danger"));
        }
        window.open(result.link, '_blank');
      },
      error => {
        this.toastr.show(error, "", environment.toastrConfig("danger"));
      }
    )
  }

  getBoleto(fatura){
    if (fatura.boleto_url) {
      window.open(fatura.boleto_url, '_blank');
      return;
    }

    this.newSubs = this.faturamentoService.getBoleto(fatura.id).subscribe(
      result => {
        if (result === null || !result.link) {
          this.toastr.show(this.translate.instant('BOLETO_NAO_GERADO'), "", environment.toastrConfig("danger"));
        }
        window.open(result.link, '_blank');
      },
      error => {
        this.toastr.show(error, "", environment.toastrConfig("danger"));
      }
    )
  }

  gerarFatura(id){
    this.newSubs = this.faturamentoService.exportarFatura(id).subscribe(
      result => {
        this.downloadPDF(result)
      },
      error => {
        this.toastr.show(error, "", environment.toastrConfig("danger"));
      }
    )
  }

  downloadPDF(data){
    var a = window.document.createElement("a");
    a.style.display = "none";
    var blob = new Blob([data], {
      type: "application/pdf"
    });
    a.href = window.URL.createObjectURL(blob);
    a.download = `${this.translate.instant('MINHA_FATURA')}.pdf`.replace(' ','_');
    window.document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  paginarListagem(pagina): void {
    this.configPagination.pagina = pagina;
    this.listFaturasDefault();
  }

}
