import { Component, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { TarefaService } from "src/app/services/tarefa.service";
import { environment } from "src/environments/environment";
import { ToastrService } from "ngx-toastr";
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-modal-tarefa-horas',
  templateUrl: './modal-tarefa-horas.component.html',
  styleUrls: ['./modal-tarefa-horas.component.scss']
})
export class ModalTarefaHorasComponent implements OnInit, OnDestroy {
  @Input() task: any;
  horaForm: UntypedFormGroup;
  focus:any;
  selector: string;
  newSubs: Subscription;

  constructor(
    public activeModal: NgbActiveModal,
    private _fb: UntypedFormBuilder,
    private tarefaService: TarefaService,
    private toastr: ToastrService,
    private elemRef: ElementRef,
  ) { 
    this.selector = elemRef.nativeElement.tagName.toLowerCase();
  }

  ngOnInit(): void {
    document.getElementsByTagName(this.selector)[0]['style']['zoom'] = '0.85';
    this.bulidForm()
    // console.log(this.task)
  }

  ngOnDestroy(): void {
    this.newSubs?.unsubscribe();
  }

  close(result?) {
    this.activeModal.close(result);
  }

  bulidForm() {
    this.horaForm = this._fb.group({
      externo: ["", Validators.required],
      hora: ["", Validators.required],
      minuto: ["", Validators.required],
      descricao: ["", Validators.required],
    });
  }

  verificaRequerido(campo): boolean {
    if(this.horaForm.get(campo).value !== '') {
      return false;
    } else {
      return true;
    }
  }

  onSubmit(){
    let payload = {
      objHorasTrabalhadas: {
        "chamado_id": this.task.chamado_id.id,
        "cliente_id": this.task.cliente_id.id,
        "descricao": this.horaForm.get('descricao').value,
        "hora": this.horaForm.get('hora').value,
        "is_externo": this.horaForm.get('externo').value.toString(),
        "minuto": this.horaForm.get('minuto').value,
        "tarefa_id": this.task.tarefa_id
      }
    }

    this.newSubs = this.tarefaService.adicionarHorasTrabalhadas(payload).subscribe(
      result => {
        this.close(true)
      }, error => {
        this.toastr.show(error, '', environment.toastrConfig('danger'));
      }
    )
    
  }

}
