import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FuncoesGlobaisService {

  constructor() { }

  regraDDG(lista) : any {
    const listagem = lista.map((element) => {
      if(element.celular){
        element.celular.substring(0, 4) == '0800' ? element.codigo_pais = null : element
      }
      if(element.telefone){
        element.telefone.substring(0, 4) == '0800' ? element.codigo_pais_telefone = null : element
      }
      return element
    })

    return listagem
  }
}
