<div class="modal-content modal-milvus">
    <div class="modal-header-milvus align-items-center">
        <div class="col-6 header-col">
            <span class="modal-title" id="modal-title-default">{{empresa.plano.text}}</span>
        </div>
        <div class="col-6 header-col">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="close()">
                <i class="mdi mdi-close"></i>
              </button>
        </div>
    </div>

    <div class="modal-body row">
        <!-- client addr info -->
        <div class="col-12">
            <span class="body-title">{{'DADOS_FATURAMENTO' | translate}}</span>
        </div>
        <div class="col-11 ml-3 mt-2">
            <div class="row">
                <span class="col-12"><strong>{{'CNPJ_CPF' | translate}}</strong> {{empresa.cnpj_cpf | mask:'CPF_CNPJ'}} <strong class="ml-2">{{empresa.nome_fantasia}}</strong></span>
            </div>
            <div class="row">
                <span class="col-12"><strong>{{'CEP' | translate}}</strong> {{empresa.cep | mask:'00000-000'}} {{empresa.endereco}}, {{empresa.numero}} <strong class="ml-2">{{'COMPLEMENTO' | translate}}</strong>
                    {{empresa.complemento}} <strong class="ml-2">{{'BAIRRO' | translate}}</strong> {{empresa.bairro}} <strong class="ml-2">{{'CIDADE' | translate}}</strong>
                    {{empresa.cidade}}<strong class="ml-2">{{'ESTADO' | translate}}</strong> {{empresa.uf}}</span>
            </div>
        </div>
        <!--  Plan data-->
        <div class="col-12 mt-3">
            <span class="body-title">{{empresa.plano.text}}</span>
        </div>
        <div class="col-12 d-flex mt-3" *ngIf="plano">
            <span class=" d-flex align-items-center">
                <div class="rounded-circle info-icon icon-shape btn-success mr-2">
                    <i class=" fas fa-dollar-sign"></i>
                </div>
                <p class="m-0" style="font-size: 14px;">{{'VALOR' | translate}} {{plano.valor | currency: plano.moeda.simbolo}}</p>
            </span>

            <span class=" d-flex align-items-center" *ngFor="let espec of plano.especificacoes">
                <div class="rounded-circle info-icon btn-darkgray icon-shape ml-4">
                    <i [class]="espec.icone"></i>
                </div>
                <p class="m-0" style="font-size: 14px;"> {{espec.qtde_maxima_item}} {{espec.tipo}}</p>
            </span>
        </div>
        <!-- Fatura list -->
        <div class="col-12 mt-3">
            <span class="body-title">{{'FATURAS' | translate}}</span>
        </div>
        <div class="col-12 mt-3">
            <div class=" table-responsive table-no-shadow  table-head-normal">
                <table class=" table align-items-center table-flush ">
                    <thead class=" thead-light">
                        <tr>
                            <th class="sort th" scope="col" [translate]="'TIPO'"></th>
                            <th class=" sort" scope="col" [translate]="'FATURADA'"></th>
                            <th class=" sort" scope="col" [translate]="'VENCIMENTO'"></th>
                            <th class="sort w-40" scope="col" [translate]="'DETALHAMENTO'"></th>
                            <th class=" sort" scope="col" [translate]="'PREVISTO'"></th>
                            <th class=" sort" scope="col" [translate]="'FINAL'"></th>
                            <th class=" sort" scope="col" [translate]="'PAGAMENTO'"></th>
                            <th class=" sort" scope="col" [translate]="'STATUS'"></th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody class=" list">
                        <tr *ngFor="let fatura of faturaList; let i = index">
                            <td>{{fatura.tipo | null}}</td>
                            <td>{{fatura.data_fatura | date:'dd/MM/yyyy' }}</td>
                            <td>{{fatura.data_vencimento | date:'dd/MM/yyyy'}}</td>
                            <td class="w-40" style="white-space: normal;">
                                {{'OBSERVACAO' | translate}}: {{fatura.observacao}}
                            </td>
                            <td>{{fatura.is_previa ? (fatura.valor_final | currency: fatura.moeda.simbolo) : ('FATURA_FECHADA' | translate)}}</td>
                            <td>{{fatura.is_previa ? (fatura.valor_final | null) : (fatura.valor_final | currency: fatura.moeda.simbolo)}}</td>
                            <td>{{fatura.data_pagamento ? (fatura.data_pagamento | date: 'dd/MM/yyyy') : ( fatura.data_pagamento | null)}}</td>
                            <td>
                                <span *ngIf="fatura.is_previa" class="label-nodata">{{'PREVIA' | translate}}</span>
                                <span *ngIf="!fatura.is_previa && fatura.status_pagamento_id === 1"
                                        class="label-warning">{{'PAGEMENTO_AGENDADO' | translate}}
                                </span>
                                <span *ngIf="!fatura.is_previa && fatura.status_pagamento_id === 2"
                                        class="label-success">{{'PAGO' | translate}}
                                </span>
                                <span *ngIf="!fatura.is_previa && fatura.status_pagamento_id === 3"
                                        class="label-danger">{{'ATRASADO' | translate}}
                                </span>
                                <span *ngIf="!fatura.is_previa && fatura.status_pagamento_id === 6"
                                        class="label-danger">{{'ATRASADO' | translate}}
                                </span>
                            </td>
                            <td class="text-right">
                                <button type="button" class="btn btn-sm-rounded-milvus btn-danger text-12" (click)="gerarFatura(fatura.id)"
                                [disabled]="fatura.status_pagamento_id !== 2 || fatura.is_previa">
                                    <i class="fas fa-file-invoice"></i>
                                </button>
                                <button type="button" class="btn btn-sm-rounded-milvus btn-alert text-12" (click)="getBoleto(fatura)"
                                [disabled]="fatura.status_pagamento_id == 2">    
                                    <i class="fas fa-barcode"></i>
                                </button>
                                <button type="button" class="btn btn-sm-rounded-milvus btn-success text-12" (click)="getNotaFiscal(fatura)"
                                [disabled]="fatura.is_previa">
                                    <i class="fas fa-receipt"></i>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <pagination *ngIf="faturaList.length > 0" [meta]="pagination" (pageChange)="paginarListagem($event)"></pagination>
        </div>
    </div>
</div>