<div class="modal-content modal-milvus">
    <div class="modal-header-milvus align-items-center">
        <div class="col-6 header-col">
            <span class="modal-title" id="modal-title-default">{{'LOCALIZACAO_OPERADOR' | translate}}</span>
        </div>
        <div class="col-6 header-col">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modal.close()">
                <i class="mdi mdi-close"></i>
              </button>
        </div>
    </div>

    <div class="modal-body">
        <div class="row">
            <div class="col-12">
                <div class="form-group">
                    <label [translate]="'FILTROS'"></label>
                    <div class="row">
                        <div class="col-6">
                            <label class="radio-box">
                                <input type="radio" name="radio" id="permissao"
                                    [checked]="filtros.filtro.regra_permissao" (click)="criaMapa(true)">
                                <span class="checkmark"></span>
                            </label>
                            <label style="font-size: 12px; font-weight: normal;" [translate]="'OPERADORES_COM_PERMISSAO'"></label>
                        </div>
                        <div class="col-6">
                            <label class="radio-box">
                                <input type="radio" name="radio" id="todos"
                                    [checked]="!filtros.filtro.regra_permissao" (click)="criaMapa(false)">
                                <span class="checkmark"></span>
                            </label>
                            <label style="font-size: 12px; font-weight: normal;" [translate]="'TODOS_OS_OPERADORES'"></label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div id="map-default" class="map-canvas" style="height: 600px;"></div>
            </div>
        </div>
    </div>  
</div>