<div class="modal-content">
    <div class="modal-header">
      <h6 class="modal-title" id="modal-title-default">{{'FINALIZAR' | translate}} {{'CHAT' | translate}}</h6>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modal.close()">
        <i class="mdi mdi-close"></i>
      </button>
    </div>
    <!-- body -->
    <div class="modal-body">
        <div class="row">
            <div class="col-12">
                <label class="radio-box">
                    <input type="radio" name="radio" id="apontar" [checked]="true" [(ngModel)]="opcao" [value]="'apontar'">
                    <span class="checkmark"></span>
                </label>
                <span>{{'APONTAR_MINUTOS_TICKET' | translate: {ticket: codigoChamado, tempo: tempo_tarefa} }}</span>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <label class="radio-box">
                    <input type="radio" name="radio" id="nao_apontar" [checked]="false" [(ngModel)]="opcao" [value]="'nao_apontar'">
                    <span class="checkmark"></span>
                </label>
                <span>{{'NAO_APONTAR_NENHUM_TEMPO_TICKET' | translate }}</span>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <label class="radio-box">
                    <input type="radio" name="radio" id="apontar_custom" [checked]="false" [(ngModel)]="opcao" [value]="'apontar_custom'" (click)="zerarHoras()">
                    <span class="checkmark"></span>
                </label>
                <span>{{'DEFINIR_TEMPO_TICKET' | translate: {ticket: codigoChamado} }}</span>
            </div>
        </div>
        <div class="row">
            <div *ngIf="opcao === 'apontar_custom'" class="col-6">
                <div class="form-group">
                    <label [translate]="'HORAS_TOTAIS'"></label>
                    <hourpicker-padrao [required]="true" (hourChange)="finalizar.total_horas = $event"></hourpicker-padrao>
                </div>
            </div>
            <div class="col-6">
                <div class="form-group">
                    <label [translate]="'ATENDIMENTO'"></label>
                    <select class="form-control" [(ngModel)]="finalizar.is_externo">
                        <option value="false" [translate]="'INTERNO'"></option>
                        <option value="true" [translate]="'EXTERNO'"></option>
                    </select>
                </div>
            </div>
            <div class="col-6">
                <div class="form-group">
                    <label [translate]="'HORARIO_COMERCIAL'"></label>
                    <select class="form-control" [(ngModel)]="finalizar.is_comercial">
                        <option value="true" [translate]="'SIM'"></option>
                        <option value="false" [translate]="'NAO'"></option>
                    </select>
                </div>
            </div>
            <div class="col-6">
                <div class="form-group">
                    <label [translate]="'QUANDO'"></label>
                    <datepicker-padrao [model]="finalizar.data_criacao" [required]="true" (dateChange)="finalizar.data_criacao = $event"></datepicker-padrao>
                </div>
            </div>
        </div>
    </div>
    <!-- footer -->
    <div class="modal-footer">
        <button type="button" class="btn btn-dark badge-pill" [translate]="'FINALIZAR'" (click)="finalizarTarefa()" [disabled]="verificaSeDesativa()"></button>
    </div>
</div>