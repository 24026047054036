import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { FiltroAvancadoService } from 'src/app/services/filtroavancado.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { ModalEditarFiltroAvancadoComponent } from '../modal-editar-filtro-avancado/modal-editar-filtro-avancado.component';
import { ModalNovoFiltroAvancadoComponent } from '../modal-novo-filtro-avancado/modal-novo-filtro-avancado.component';

@Component({
  selector: 'app-modal-filtro-avancado',
  templateUrl: './modal-filtro-avancado.component.html',
  styleUrls: ['./modal-filtro-avancado.component.scss']
})
export class ModalFiltroAvancadoComponent implements OnInit, OnDestroy {

  listaFiltrosAvancado = [];

  filtro = {
    filtro_body: {
    }
  }

  configPagination = environment.getPaginationDefault();
  pagination: any;

  searchFilter;
  selector: string;
  newSubs: Subscription;

  constructor(
    public activeModal: NgbActiveModal,
    public translate: TranslateService,
    private modalService: NgbModal,
    private filtroAvancadoService: FiltroAvancadoService,
    private toastr: ToastrService,
    private elemRef: ElementRef,
  ) {
    this.selector = elemRef.nativeElement.tagName.toLowerCase();
  }

  ngOnInit(): void {
    document.getElementsByTagName(this.selector)[0]['style']['zoom'] = '0.85';
    this.getListaFiltrosAvancado()
  }

  ngOnDestroy(): void {
    this.newSubs?.unsubscribe();
  }

  close(result?) {
    this.activeModal.close(result);
  }

  openModalNovoFiltroAvancado() {
    const modalRef = this.modalService.open(ModalNovoFiltroAvancadoComponent, {
      backdrop: "static",
      windowClass: 'modal-custom-milvus',
      keyboard: true,
      scrollable: false,
      size: "medio",
    });

    modalRef.result.then((result) => {
      this.getListaFiltrosAvancado();
    });
  }

  openModalEditarFiltroAvancado(filtro_avancado) {
    const modalRef = this.modalService.open(ModalEditarFiltroAvancadoComponent, {
      backdrop: "static",
      windowClass: 'modal-custom-milvus',
      keyboard: true,
      scrollable: false,
      size: "medio",
    });

    let data = {
      ...filtro_avancado
    }

    modalRef.componentInstance.filtro_avancado = data;

    modalRef.result.then((result) => {
      this.getListaFiltrosAvancado();
    })
  }

  removerFiltroAvancado(id) {
    Swal.fire({
      title: this.translate.instant('DESEJA_APAGAR'),
      icon: 'error',
      focusConfirm: true,
      showCancelButton: true,
      confirmButtonText: this.translate.instant('SIM'),
      cancelButtonText: this.translate.instant('NAO'),
    }).then((result) => {
      if (result.isConfirmed) {
        // console.log(id)
        this.newSubs = this.filtroAvancadoService.excluirFiltro(id).subscribe(
          (result) => {
            this.toastr.show(this.translate.instant('DELETADO_SUCESSO'), '', environment.toastrConfig('success'));
            this.getListaFiltrosAvancado()
          }, (error) => {
            console.log(error)
            this.toastr.show(error, '', environment.toastrConfig('danger'));
          }
        )
      }
    });
  }

  getListaFiltrosAvancado(filtro?) {
    if (filtro) {
      this.filtro.filtro_body = filtro
    }

    this.newSubs = this.filtroAvancadoService.listarFiltros(this.configPagination, this.filtro).subscribe(
      (result) => {
        //console.log(result)
        this.listaFiltrosAvancado = result.lista;
        this.pagination = result.meta.paginate;        
        
      }, (error) => {
        this.toastr.show(error, '', environment.toastrConfig('danger'));
        console.log(error)
      }
    )
  }

  paginarListagem(pagina): void {
    this.configPagination.pagina = pagina;
    this.getListaFiltrosAvancado();
  }

  favoritar(item) {
    item.is_favorito = !item.is_favorito
    var payload = item.filtro
    payload['_id'] = item.id;
    payload['nome_filtro'] = item.nome_filtro;
    payload['is_favorito'] = item.is_favorito;

    this.newSubs = this.filtroAvancadoService.atualizarFiltro(payload).subscribe(
      (result) => {
        this.toastr.show(this.translate.instant('SALVO_SUCESSO'), '', environment.toastrConfig('success'));
      }, (error) => {
        this.toastr.show(error, "", environment.toastrConfig("danger"));
        console.log(error)
      }
    )
  }

  filtrarApi(filtro) {
    var payload = {
      "filtro_body": {
        "nome_filtro": filtro,
        "is_favorito": "__all__"
      }
    }
    this.configPagination.pagina = 1
    this.newSubs = this.filtroAvancadoService.listarFiltros(this.configPagination, payload).subscribe(
      (result) => {
        // console.log(result)
        this.listaFiltrosAvancado = result.lista;
        this.pagination = result.meta.paginate;
      }, (error) => {
        this.toastr.show(error, '', environment.toastrConfig('danger'));
        console.log(error)
      }
    )
  }

  selecionaFiltro(filtro, nome?) {
    if (nome) {
      filtro.nome_filtro = nome;
    }
    //console.log(filtro)
    this.activeModal.close(filtro);
  }

}
