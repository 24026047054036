import { EventEmitter, Injectable, Output } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ILoginAutomaticoRequest, iLoginRequest, iLoginResponse } from '../interfaces/login/login.interface';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  private apiUrl = environment.apiGestorUrlLocal;
  private apiUrlNova = environment.apiGestorUrlNova;

  constructor(
    private http: HttpClient
  ) { }


  authenticathionLogin(payload : iLoginRequest | ILoginAutomaticoRequest): Observable<iLoginResponse> {
    return this.http.post<iLoginResponse>(`${this.apiUrlNova}manager/accounts/v2/login`, payload, { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) })
      .pipe(
        catchError(this.handleError)
      );
  }

  getLogin(user): Observable<any> {
    return this.http.post(`${this.apiUrl}api/login/`, user, { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) })
      .pipe(
        catchError(this.handleError)
      );
  }

  getSituacaoEmpresa(token): Observable<any> {
    return this.http.get(`${this.apiUrl}api/empresas/situacao`, { headers: new HttpHeaders({ 'authorization': token }) })
      .pipe(
        catchError(this.handleError)
      );
  }

  getTrialEmpresa(token): Observable<any> {
    return this.http.get(`${this.apiUrl}api/empresas/trial`, { headers: new HttpHeaders({ 'authorization': token }) })
      .pipe(
        catchError(this.handleError)
      );
  }

  buscaDataBloqueio(token): Observable<any> {
    return this.http.get(`${this.apiUrl}api/faturas/buscaDataBloqueio`, { headers: new HttpHeaders({ 'authorization': token }) })
      .pipe(
        catchError(this.handleError)
      );
  }

  buscarNotificacoes(token): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'authorization': token }),
      params: {}
    };
    return this.http.get(`${this.apiUrl}api/notificacao-sistema/buscar`, httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  resetPassword(payload): Observable<any> {
    return this.http.post(`${this.apiUrl}api/senhas/usuario/alterar-senha`, payload, { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) })
      .pipe(
        catchError(this.handleError)
      );
  }

  logout(user_session, token): Observable<any> {
    return this.http.delete(`${this.apiUrlNova}manager/accounts/v2/logout`, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'authorization': token, 'session': user_session }) })
      .pipe(
        catchError(this.handleError)
      );
  }

  encerraOutrasSessoes(token): Observable<any> {
    return this.http.delete(`${this.apiUrlNova}manager/accounts/remove-sessao`, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'authorization': token }) })
      .pipe(
        catchError(this.handleError)
      );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('Ops! Ocorreu um erro:', error.error.message);
    } else {
      console.error(
        `Backend retornou o código ${error.status}, ` +
        `o body é: ${error.error}`);
    }
    return throwError(
      error.error);
  }
}
