import { Component, EventEmitter, Input, Output } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";


@Component({
  selector: "app-chat-gpt",
  templateUrl: "./chat-gpt.component.html",
  styleUrls: ["./chat-gpt.component.scss"],
})
export class ChatGptComponent {
  @Input() chatGPTAutocomplete?: string;
  @Input() chatGPTClass?: string;
  @Input() chatGPTDisabled?: boolean;
  @Input() chatGPTFormControlName?: string;
  @Input() chatGPTFormGroup!: UntypedFormGroup;
  @Input() chatGPTFormGroupClass?: string;
  @Input() chatGPTId?: string;
  @Input() chatGPTIcon?: string;
  @Input() chatGPTLabel?: string;
  @Input() chatGPTLabelIcon?: string;
  @Input() chatGPTLabelType?: string;
  @Input() chatGPTLabelTooltip?: string;
  @Input() chatGPTLabelTooltipPlacement?: string;
  @Input() chatGPTLengthValidator?: {
    count: 0;
    limit: 0;
    isOverLimit: false;
  };
  @Input() chatGPTMax?: number;
  @Input() chatGPTMaxLength?: number;
  @Input() chatGPTMin?: number;
  @Input() chatGPTName?: string;
  @Input() chatGPTNgClass?: string;
  @Input() chatGPTOptions?: any;
  @Input() chatGPTPlaceholder?: string;
  @Input() chatGPTPrefix?: string;
  @Input() chatGPTReadonly?: boolean;
  @Input() chatGPTRequired?: boolean;
  @Input() chatGPTSuffix?: string;
  @Input() chatGPTTooltip?: string;
  @Input() chatGPTValue?: any;
  @Input() chatGPTType: string = "base";
  @Input() aiChat: boolean;
  @Output() chatGPTValueChange = new EventEmitter<any>();
  @Output() chatGPTButtonClicked = new EventEmitter<any>();


  constructor(
  public translate: TranslateService
  ) { }

  onFormChange(formValue: any, formName: string) {
    if (formValue && formName) {
      let emitter = {
        formValue: formValue.value,
        formName: formName,
      };
      this.chatGPTFormGroup.controls[formName].setValue(formValue.value);
      this.chatGPTValueChange.emit(emitter);
    }
  }

  onButtonClick() {
    if (this.chatGPTFormGroup.controls[this.chatGPTFormControlName].value) {
      this.chatGPTButtonClicked.emit(true);
    } else {
      this.chatGPTButtonClicked.emit(false);
    }
  }
}
