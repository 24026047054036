import { HttpHeaders, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { TokenService } from '../core/token/token.service'

@Injectable({
  providedIn: 'root'
})
export class DispositivoLocadoService {
  private apiUrl = environment.apiGestorUrlLocal;
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', 'authorization': this.tokenService.getToken(), 'session': this.tokenService.getSessionUsuario() }),
    params: {}
  };
  constructor(
    private http: HttpClient, private tokenService: TokenService
  ) { }

  getListagemDispositivoLocado(params, filtros): Observable<any> {
    this.httpOptions.params = params;
    return this.http.post(`${this.apiUrl}api/dispositivo-locado/listagem`, filtros, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  getDispositivoLocado(id): Observable<any> {
    this.httpOptions.params = '';
    return this.http.get(`${this.apiUrl}api/dispositivo-locado/${id}`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  getDispositivoHistorico(filtro): Observable<any> {
    this.httpOptions.params = '';
    return this.http.post(`${this.apiUrl}api/dispositivo-locado/historico`, filtro, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  createDispositivoLocado(payload): Observable<any> {
    this.httpOptions.params = '';
    return this.http.post(`${this.apiUrl}api/dispositivo-locado`, payload, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  updateDispositivoLocado(payload): Observable<any> {
    this.httpOptions.params = '';
    return this.http.put(`${this.apiUrl}api/dispositivo-locado`, payload, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  deleteById(id): Observable<any> {
    this.httpOptions.params = '';
    return this.http.delete(`${this.apiUrl}api/dispositivo-locado/${id}`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  locarDispositivo(payload): Observable<any> {
    this.httpOptions.params = '';
    return this.http.post(`${this.apiUrl}api/dispositivo-locado/locar`, payload, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  devolverDispositivo(payload): Observable<any> {
    this.httpOptions.params = '';
    return this.http.post(`${this.apiUrl}api/dispositivo-locado/devolver`, payload, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  /* dropdowns */
  getDispositivoTipos(): Observable<any> {
    this.httpOptions.params = '';
    return this.http.get(`${this.apiUrl}api/dispositivo-locado/dropdown-tipos`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  /* exports */
  exportDispositivoHistorico(payload, tipo): Observable<any> {
    let customHttpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'authorization': this.tokenService.getToken(), 'session': this.tokenService.getSessionUsuario() }),
      params: {},
    };
    customHttpOptions['responseType'] = "Blob"
    
    return this.http.post(`${this.apiUrl}api/dispositivo-locado/historico/exportar/${tipo}`, payload, customHttpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('Ops! Ocorreu um erro:', error.error.message);
    } else {
      console.error(
        `Backend retornou o código ${error.status}, ` +
        `o body é: ${error.error}`);
    }
    return throwError(
      error.error);
  }
}
