<ng-container [formGroup]="inputFormGroup">
  <div
    *ngIf="inputType === 'base'"
    [class]="inputFormGroupClass"
  >
    <label *ngIf="inputLabel" [for]="inputFormControlName" [translate]="inputLabel"></label>
    <input
      [formControlName]="inputFormControlName"
      [id]="inputId"
      [name]="inputName"
      type="text"
      class="form-control"
      [ngClass]="{'required': inputRequired}"
      [autocomplete]="inputAutocomplete"
      [placeholder]="inputPlaceholder"
      (change)="onFormChange($event.target, inputFormControlName)"
    />
  </div>

  <div
    *ngIf="inputType === 'number'"
    [class]="inputFormGroupClass"
  >
    <app-label
      *ngIf="inputLabel"
      [labelFor]="inputFormControlName"
      [labelName]="inputLabel"
      [labelType]="inputLabelType"
      [labelTooltip]="inputLabelTooltip"
      [labelTooltipPlacement]="inputLabelTooltipPlacement"
      [labelIcon]="inputLabelIcon"
      [labelOptions]="inputOptions.prefix"
    ></app-label>
    <input
      [formControlName]="inputFormControlName"
      [id]="inputFormControlName"
      [name]="inputFormControlName"
      type="number"
      class="form-control"
      [ngClass]="{'required': inputRequired}"
      [min]="inputMin"
      [max]="inputMax"
      [readonly]="inputReadonly"
      [required]="inputRequired"
      (change)="onFormChange($event.target, inputFormControlName)"
      (onKeyPress)="onKeyPress($event.target, inputFormControlName)"
    />
  </div>

  <div
    *ngIf="inputType === 'currency'"
    [class]="inputFormGroupClass"
  >
    <app-label
      *ngIf="inputLabel"
      [labelFor]="inputFormControlName"
      [labelName]="inputLabel"
      [labelType]="inputLabelType"
      [labelTooltip]="inputLabelTooltip"
      [labelTooltipPlacement]="inputLabelTooltipPlacement"
      [labelIcon]="inputLabelIcon"
      [labelOptions]="inputOptions.prefix"
    ></app-label>
    <input
      [formControlName]="inputFormControlName"
      [id]="inputFormControlName"
      [name]="inputFormControlName"
      [ngClass]="inputClass"
      [disabled]="inputDisabled"
      [readonly]="inputReadonly"
      [required]="inputRequired"
      class="form-control"
      currencyMask
      [options]="{
        prefix: inputOptions.prefix,
        thousands: inputOptions.thousands,
        decimal: inputOptions.decimal,
        precision: inputOptions.precision
      }"
      (keyup)="onFormChange($event.target, inputFormControlName)"
    />
  </div>

  <div
    *ngIf="inputType === 'textarea'"
    [class]="inputFormGroupClass"
  >
    <label [for]="inputFormControlName" [translate]="inputLabel"></label>
    <textarea
      [formControlName]="inputFormControlName"
      [id]="inputId"
      [name]="inputName"
      [autocomplete]="inputAutocomplete"
      [disabled]="inputDisabled"
      [readonly]="inputReadonly"
      [placeholder]="inputPlaceholder"
      class="form-control input-textarea"
      [ngClass]="{ required: inputRequired }"
      (change)="onFormChange($event.target, inputFormControlName)"
    ></textarea>
    <div *ngIf="inputLengthValidator" class="d-flex justify-content-end pt-2">
      {{ inputLengthValidator.count }}/{{ inputLengthValidator.limit }}
    </div>
  </div>
</ng-container>
