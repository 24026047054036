<div
  *ngIf="paginationType === 'base'"
  class="row"
>
  <div class="col-xl-6 d-flex justify-content-start align-items-center">
    <small *ngIf="!mini">{{
      "MOSTRANDO_PAGINACAO"
        | translate : { from: config.from, to: config.to, total: config.total }
    }}</small>
  </div>
  <div class="col-xl-6 d-flex justify-content-end align-items-center">
    <nav
      aria-label="Page navigation example"
      [ngClass]="{ 'pagination-mini': mini && mini === true }"
    >
      <ul
        class="pagination"
        style="height: 54px; margin-bottom: 0; align-items: center"
      >
        <li class="page-item" *ngIf="!dados_gerais">
          <a
            class="page-link"
            style="z-index: 0 !important"
            href="javascript:void(0)"
            (click)="setPage('first')"
          >
            <i class="fas fa-angle-double-left"></i>
          </a>
        </li>
        <li class="page-item" *ngIf="!dados_gerais">
          <a
            class="page-link"
            style="z-index: 0 !important"
            href="javascript:void(0)"
            aria-label="Previous"
            (click)="setPage('previous')"
          >
            <i class="fa fa-angle-left"></i>
            <span class="sr-only">Previous</span>
          </a>
        </li>
        <li
          *ngFor="let pagina of paginasVisiveis"
          class="page-item"
          [ngClass]="{ active: pagina === paginaAtual }"
        >
          <a
            class="page-link"
            style="z-index: 0 !important"
            href="javascript:void(0)"
            (click)="setPage(pagina)"
            >{{ pagina }}</a
          >
        </li>
        <li class="page-item" *ngIf="!dados_gerais">
          <a
            class="page-link"
            style="z-index: 0 !important"
            href="javascript:void(0)"
            aria-label="Next"
            (click)="setPage('next')"
          >
            <i class="fa fa-angle-right"></i>
            <span class="sr-only">Next</span>
          </a>
        </li>
        <li class="page-item" *ngIf="!dados_gerais">
          <a
            class="page-link"
            style="z-index: 0 !important"
            href="javascript:void(0)"
            (click)="setPage('last')"
          >
            <i class="fas fa-angle-double-right"></i>
          </a>
        </li>
      </ul>
    </nav>
  </div>
</div>

<div *ngIf="paginationType === 'syncfusion'">
  <div class="e-pagertemplate d-flex justify-content-between">
    <div id="totalPages" class="e-pagertemplatemessage col-xl-6 d-flex align-items-center">
      <small class="e-pagenomsg" *ngIf="!mini || mini === false">{{
        "MOSTRANDO_PAGINACAO"
          | translate
            : { from: config.from, to: config.to, total: config.total }
      }}</small>
    </div>
    <div class="control-section col-xl-6 d-flex justify-content-end">
      <nav
        aria-label="Page navigation example"
        [ngClass]="{ 'pagination-mini': mini && mini === true }"
      >
        <ul
          class="pagination"
          style="height: 54px; margin-bottom: 0; align-items: center"
        >
          <li class="page-item" *ngIf="!dados_gerais">
            <a
              class="page-link"
              style="z-index: 0 !important"
              href="javascript:void(0)"
              (click)="setPage('first')"
            >
              <i class="fas fa-angle-double-left"></i>
            </a>
          </li>
          <li class="page-item" *ngIf="!dados_gerais">
            <a
              class="page-link"
              style="z-index: 0 !important"
              href="javascript:void(0)"
              aria-label="Previous"
              (click)="setPage('previous')"
            >
              <i class="fa fa-angle-left"></i>
              <span class="sr-only">Previous</span>
            </a>
          </li>
          <li
            *ngFor="let pagina of paginasVisiveis"
            class="page-item"
            [ngClass]="{ active: pagina === paginaAtual }"
          >
            <a
              class="page-link"
              style="z-index: 0 !important"
              href="javascript:void(0)"
              (click)="setPage(pagina)"
              >{{ pagina }}</a
            >
          </li>
          <li class="page-item" *ngIf="!dados_gerais">
            <a
              class="page-link"
              style="z-index: 0 !important"
              href="javascript:void(0)"
              aria-label="Next"
              (click)="setPage('next')"
            >
              <i class="fa fa-angle-right"></i>
              <span class="sr-only">Next</span>
            </a>
          </li>
          <li class="page-item" *ngIf="!dados_gerais">
            <a
              class="page-link"
              style="z-index: 0 !important"
              href="javascript:void(0)"
              (click)="setPage('last')"
            >
              <i class="fas fa-angle-double-right"></i>
            </a>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</div>
