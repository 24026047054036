import { Pipe, PipeTransform } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { TokenService } from "src/app/core/token/token.service";

@Pipe({
  name: "timeLeft",
})
export class TimeLeftPipe implements PipeTransform {
  constructor(public translate: TranslateService, private tokenService: TokenService) {}
  transform(value: any): any {
    if (value) {
      let expiryDate = +new Date(value);
      let now = +new Date();
      let seconds = (expiryDate - now) / 1000;
      let sign = Math.sign(seconds);
      
      let suffix = this.translate.instant("RESTANTE");
      if (sign === -1) {
        seconds = Math.floor(seconds * sign);
        suffix = this.translate.instant("ATRAS");
      }
      const intervalDateTimes = {
        [this.translate.instant("ANO")]: 31536000,
        [this.translate.instant("MES")]: 2592000,
        [this.translate.instant("SEMANA")]: 604800,
        [this.translate.instant("DIA")]: 86400,
        [this.translate.instant("HORA")]: 3600,
        [this.translate.instant("MINUTO")]: 60,
        [this.translate.instant("SEGUNDO")]: 1,
      };
      const intervalNames = [
        this.translate.instant("ANO"),
        this.translate.instant("MES"),
        this.translate.instant("SEMANA"),
        this.translate.instant("DIA"),
        this.translate.instant("HORA"),
        this.translate.instant("MINUTO"),
        this.translate.instant("SEGUNDO"),
      ];
      const intervalPluralNames = [
        this.translate.instant("ANOS"),
        this.translate.instant("MESES"),
        this.translate.instant("SEMANAS"),
        this.translate.instant("DIAS"),
        this.translate.instant("HORAS"),
        this.translate.instant("MINUTOS"),
        this.translate.instant("SEGUNDOS"),
      ];
      let counter: number;
      for (let interval = 0; interval < intervalNames.length; interval++) {
        let intervalDateTimesList = Object.values(intervalDateTimes)
        counter = Math.floor(seconds / intervalDateTimesList[interval]);
        let toReturn: string = "";
        if (counter > 0) {
          toReturn += this.calculateTime(counter, intervalNames[interval], intervalPluralNames[interval]);
          let timeLeft = seconds - counter * intervalDateTimesList[interval];
          let index = interval + 1;
          if (index > 6) {
            return toReturn + suffix;
          }
          counter = Math.floor(timeLeft / intervalDateTimesList[index]);
          //suffix = counter === 1 ? suffix : this.translate.instant("RESTANTES");
          let hasAndConditional = (counter >= 1)? this.translate.instant("E") : '';
          toReturn =
            toReturn +
            " " +
            hasAndConditional +
            " " +
            this.calculateTime(counter, intervalNames[index], intervalPluralNames[index]) +
            " " +
            suffix;
          return toReturn.toLowerCase();
        }
      }
    }
    return value;
  }

  calculateTime(counter: number, timeUnit: string, plural?) {
    if (counter === 1) {
      return counter + " " + timeUnit;
    } else if (counter > 1) {
      let toReturn = counter + " " + plural;
      return toReturn;
    } else {
      return '';
    }
  }
}
