import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};


import { RouterModule } from "@angular/router";
import { CollapseModule } from "ngx-bootstrap/collapse";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TagInputModule } from "ngx-chips";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TreeviewModule } from "ngx-treeview";
import { FiltrosComponent } from "./filtros.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { PipesModule } from 'src/app/shared/validators/pipes/pipes.module';
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { DatepickerPadraoModule } from "../datepicker-padrao/datepicker-padrao.module";
import { CategoriesMultiLevelModule } from "../categories-multi-level/categories-multi-level.module";

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,    
    ReactiveFormsModule,
    CollapseModule.forRoot(),
    PerfectScrollbarModule,
    BsDropdownModule.forRoot(),
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    PipesModule,
    NgbModule,
    BsDatepickerModule.forRoot(),
    Ng2SearchPipeModule,
    DatepickerPadraoModule,
    CategoriesMultiLevelModule
  ],
  declarations: [
    FiltrosComponent
  ],
  exports: [
    FiltrosComponent,
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    }
  ]
})
export class FiltrosModule {}
