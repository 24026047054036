import { NgModule } from "@angular/core";
import { MatLegacyTooltipModule as MatTooltipModule } from "@angular/material/legacy-tooltip";
import {MatIconModule} from '@angular/material/icon';

const components = [MatTooltipModule, MatIconModule];

@NgModule({
  imports: [components],
  exports: [components],
  declarations: [],
})
export class AngularMaterialModule {}
