import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { TokenService } from '../core/token/token.service';
import { iGetDropDownMesasRequest } from '../interfaces/dashboards/dashboards-canais-atendimento.interface';
import { iGetDropDownMesas } from '../interfaces/dashboards/dashboards-dashboard.interface';

@Injectable({
  providedIn: 'root'
})
export class MesaTrabalhoService {
  private apiUrl = environment.apiGestorUrlLocal;
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', 'authorization': this.tokenService.getToken(), 'session': this.tokenService.getSessionUsuario() }),
    params: {}
  };

  constructor(private tokenService: TokenService, private http: HttpClient) { }

  getDropdownDistribuicaoAutomatica() : any {
    this.httpOptions.params = '';
    return this.http.get(`${this.apiUrl}api/mesa-trabalho/dropdownlist`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  setPayloadFiltroMesaTrabalho(payload: any) : any {
    if (this.tokenService.checkViewDeskTop()) {
      delete payload['filtrar_mesas_tecnico'];
    } else {
      payload['filtrar_mesas_tecnico'] = true;
    }
    return payload;
  }

  getDropDownMesas(params): Observable<iGetDropDownMesas> {
    this.httpOptions.params = params;
    return this.http.get<iGetDropDownMesas>(`${this.apiUrl}api/mesa-trabalho/dropdownlist`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  getDropDownMesasUpdateTicket(params): Observable<any> {
    this.setPayloadFiltroMesaTrabalho(params);
    this.httpOptions.params = params;
    return this.http.get(`${this.apiUrl}api/mesa-trabalho/dropdownlist`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  getListagemMesa(params, filtros): Observable<any> {
    this.httpOptions.params = params;
    return this.http.post(`${this.apiUrl}api/mesa-trabalho/listagem`, filtros, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  createMesa(payload): Observable<any> {
    this.httpOptions.params = "";
    return this.http.post(`${this.apiUrl}api/mesa-trabalho`, payload, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }
  
  updateMesa(payload): Observable<any> {
    this.httpOptions.params = "";
    return this.http.put(`${this.apiUrl}api/mesa-trabalho`, payload, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  deleteMesa(id): Observable<any> {
    this.httpOptions.params = "";
    return this.http.delete(`${this.apiUrl}api/mesa-trabalho/${id}`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  getDeskWorkingHours(desk_id: number): Observable<any> {
    this.httpOptions.params = "";
    return this.http.get(`${this.apiUrl}api/mesa-trabalho/expediente/${desk_id}`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  addDeskWorkingHours(payload: any): Observable<any> {
    this.httpOptions.params = "";
    return this.http.post(`${this.apiUrl}api/mesa-trabalho/expediente`, payload, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  updateDeskWorkingHours(payload: any): Observable<any> {
    this.httpOptions.params = "";
    return this.http.put(`${this.apiUrl}api/mesa-trabalho/expediente`, payload, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('Ops! Ocorreu um erro:', error.error.message);
    } else {
      console.error(
        `Backend retornou o código ${error.status}, ` +
        `o body é: ${error.error}`);
    }
    return throwError(
      error.error);
  }
}
