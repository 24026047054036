import { Component, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { CamposCustomizaveisService } from 'src/app/services/campos-customizaveis.service';
import { GatilhoChamadoService } from 'src/app/services/gatilho-chamado.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-condicao-filtro-avancado',
  templateUrl: './condicao-filtro-avancado.component.html',
  styleUrls: ['./condicao-filtro-avancado.component.scss']
})
export class CondicaoFiltroAvancadoComponent implements OnInit, OnDestroy {

  listaValores: Array<object> = []
  isText: boolean;
  inputType: string;
  requiredValidator: any = null;
  /* selects values */
  selectedField: any = {
    tipo: ''
  };
  selectedValue: any;
  atributo;
  
  valor: any;
  chave: any;
  tipoCampo

  @Input() filtroLista;
  selector: string;
  newSubs: Subscription;

  constructor(
    public activeModal: NgbActiveModal,
    private gatilhoChamadoService: GatilhoChamadoService,
    private toastr: ToastrService,
    private translate: TranslateService,
    private elemRef: ElementRef,
    private camposCustomizaveisService: CamposCustomizaveisService
  ) {
    this.selector = this.elemRef.nativeElement.tagName.toLowerCase();
  }

  ngOnInit(): void {
    document.getElementsByTagName(this.selector)[0]['style']['zoom'] = '0.85'
  }

  ngOnDestroy(): void {
    this.newSubs?.unsubscribe();
  }

  close(result?) {
    this.activeModal.close();
  }

  changeCampo(event) {
    if (event) {
      this.tipoCampo = event.tipo;
      if (this.selectedField !== event) {
        this.valor = ''
      }
      
      if (event.atributo === 'data_criacao') {
        event.atributo = 'filtro_data_criacao'
      }

      if (event.atributo === 'data_agendamento') {
        event.atributo = 'filtro_data_agendamento'
      }

      this.selectedField = event;
      if (event.tipo === 'select' && event.atributo.includes('personalizado')) {
        this.atributo = '@' + event.atributo;
        return;
      }
      if (!event.atributo.includes('personalizado')) {
        this.atributo = event.chave
      }
      if (event.tipo === 'select') {
        this.newSubs = this.camposCustomizaveisService.obterInformacoesRegra(this.atributo).subscribe(
          (result) => {
            if (result && result !== null) {
              this.listaValores = result && result.lista ? result.lista : result.opcoes ? result.opcoes : []
              this.inputType = result.retorno ? result.retorno.label : result.label
              this.chave = result.retorno ? result.retorno.chave : result.chave
            }
          }, (error) => {
            console.log(error);
            this.toastr.show(error, '', environment.toastrConfig('danger'));
          }
        )
      }
    }
  }

  isEmpty(obj) {
    for (var prop in obj) {
      if (obj.hasOwnProperty(prop))
        return false;
    }
    return true;
  }

  verificaCampos() {
    return this.selectedField.tipo !== 'decimal' && this.selectedField.tipo !== 'inteiro' ? this.isEmpty(this.valor) : this.valor === ''
  }

  onSelectChange(item) {
    this.valor = item;
  }

  onValorChange(value: any): void {
    this.valor = value;
  }

  verificaCampo(lista: Array<any>) {
    return lista.findIndex(x => 
      (x.campo.atributo === this.selectedField.atributo) && (x.campo.text.toLowerCase() === this.selectedField.text.toLowerCase())
      || (x.campo.atributo === 'categorias' && (this.selectedField.atributo === 'categoria_primaria' || this.selectedField.atributo === 'categoria_secundaria') )
    );
  }

  salvar() {
    if (!this.checkIfHasPrimaryAndCategories(this.filtroLista)) {
      if (this.verificaCampo(this.filtroLista) > -1) {
        Swal.fire({
          title: this.translate.instant('AVISO_CONDICAO_FILTRO_AVANCADO'),
          icon: 'error',
          focusConfirm: true,
          showCancelButton: true,
          confirmButtonText: this.translate.instant('ALTERAR'),
          cancelButtonText: this.translate.instant('CANCELAR'),
  
        }).then(
          (result) => {          
            if (result.isConfirmed) {
              let valor;
              if (this.selectedField.chave === '@Categorias') {
                valor = {
                  ids: this.valor.path_ids,
                  text: this.valor.text,
                  primary: this.valor.primary,
                  id: this.valor.id
                }
              } else {
                valor = this.valor;
              }
              var payload = {
                campo: this.selectedField,
                valor: valor
              }            
              this.activeModal.close(payload);
            }
          }
        )
      } else {
        var payload = {
          campo: this.selectedField,
          valor: this.valor
        }
  
        this.activeModal.close(payload);
      }
    }
  }

  checkIfHasPrimaryAndCategories(filtro: Array<any>): boolean {
    let _findPrimary = filtro.find(item => item.campo.atributo === 'categoria_primaria');
    let _findCategories = filtro.find(item => item.campo.atributo === 'categorias');
    if (_findPrimary && _findCategories) {
      if (this.selectedField.chave && this.selectedField.chave.includes("@CategoriaPrimaria")) {
        Swal.fire({
          title: this.translate.instant('REMOVA_CAMPO_CATEGORIAS_PRIMEIRO'),
          icon: 'warning',
          focusConfirm: true,
          showCancelButton: false,
          showConfirmButton: true  
        })
        return true;
      }  
      return false;    
    }
    return false;
  }
  
}