<div class="modal-content">
    <div class="modal-header">
        <h2>{{'DETALHES_CONTATO' | translate}}</h2>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="close()">
            <i class="mdi mdi-close"></i>
        </button>
    </div>
    <div class="modal-body">        
    <div >
        <div class="row mt-2">
            <div class="col-6 title">
              <strong>{{'NOME' | translate}}:</strong>
            </div>
            <div class="col-6 title">
              <strong>{{'EMAIL' | translate}}:</strong>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col-6 value">
                <span *ngIf="chatData.contato.descricao"> {{chatData.contato.descricao}}</span> 
                <span *ngIf="!chatData.contato.descricao"> {{'NAO_INFORMADO' | translate}}</span>            
            </div>   
            <div class="col-6 value">
                <span *ngIf="chatData.contato.email"> {{chatData.contato.email}}</span>
                <span *ngIf="!chatData.contato.email"> {{'NAO_INFORMADO' | translate}}</span>
            </div>         
        </div>
        <div class="row mt-2">
            <div class="col-12 title">
                <strong>{{'TELEFONE' | translate}}:</strong>
            </div>            
        </div>
        <div class="row mt-2">
            <div class="col-12 value">
                <span *ngIf="!chatData.is_whatsapp && chatData.contato.telefone"> {{chatData.contato.telefone | mask: '(00) 0000-0000'}}</span>
                <span *ngIf="!chatData.is_whatsapp && !chatData.contato.telefone"> {{'NAO_INFORMADO' | translate}}</span>
                <span *ngIf="chatData.is_whatsapp && !chatData.contato.telefone"> {{chatData.contato.celular | mask: '(00) 00000-0000'}}</span>
            </div>
        </div>
        
    </div>
    </div>
</div>
  