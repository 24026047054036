<div class="modal-content">
    <div class="modal-header">
      <h6 class="modal-title" id="modal-title-default" [translate]="'NOVO_GRUPO'"></h6>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modal.close()">
        <i class="mdi mdi-close"></i>
      </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-12">
                <div class="form-group">
                    <label [translate]="'NOME'"></label>
                    <input type="text" class="form-control" [(ngModel)]="nome">
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-success badge-pill" (click)="salvarGrupo()" [disabled]="verificaSeDesativa()">
          <i class="fas fa-save"></i>
          <span [translate]="'SALVAR'"></span>
        </button>
    </div>
</div>