<div class="modal-header">
    <h6 class="modal-title" id="modal-title-default">{{header | translate}}</h6>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="close()">
        <i class="mdi mdi-close"></i>
    </button>
</div>
<div class="modal-body">
    <div *ngIf="carregando" class="d-flex align-items-center justify-content-center h-100 w-100">
        <span class="loader"></span>
    </div>
    <iframe [ngStyle]="{'display': carregando ? 'none' : 'block'}" #iframe style="width: 100%; height: 100%;" frameborder="0"></iframe>
</div>