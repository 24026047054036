<div class="modal-content">
    <div class="modal-header">
        <h6 class="modal-title" id="modal-title-default" [translate]="'FEEDBACK_SOBRE_FILTROS'"></h6>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modal.close()">
            <i class="mdi mdi-close"></i>
        </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="sendFeedbackForm" class="row">
            <div class="col-12">
                <label [translate]="'MOTIVO_FEEDBACK_FILTROS'"></label>
                <!-- <editor-texto-padrao [customHeight]="510" [required]="true" (valueChange)="description = $event"></editor-texto-padrao> -->
                <!-- <app-text-editor
                    formControlName="description"
                    [textEditorConfig]="quillConfigEditor"
                    [textEditorRequired]="checkFields('description')"
                >
                </app-text-editor> -->

                <textarea class="form-control" style="resize: none;" type="text" id="text-input" placeholder="{{ 'OBSERVACAO' | translate }}" formControlName="description" rows="3" [ngClass]="{'required': checkFields('description')}"></textarea>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-success btn-md badge-pill" [disabled]="checkFields('description')" (click)="enviarFeedback(sendFeedbackForm.controls.description.value)" [translate]="'ENVIAR_FEEDBACK'"></button>
    </div>
</div>