<div class="modal-content">
    <div class="modal-header">
      <h6 *ngIf="infosCliente" class="modal-title" id="modal-title-default">{{'GERAL' | translate}} - {{infosCliente.nome_fantasia}}</h6>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modal.close()">
        <i class="mdi mdi-close"></i>
      </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-7">
                <div class="row">
                    <div class="col-12">
                        <div *ngIf="infosCliente">
                            <h3>{{'DADOS_BASICOS_CLIENTE' | translate}}</h3>
                            <div style="font-size: 14px;">
                                <label style="font-weight: bold;">Token: </label>
                                <span> {{infosCliente.token | null}}</span>
                                <i *ngIf="infosCliente.token" style="margin-left: 5px; cursor: pointer;" (click)="copyToClipboard(infosCliente.token)" class="far fa-copy"></i>
                            </div>
                            <div style="font-size: 14px;">
                                <label style="font-weight: bold;">{{'NOME_FANTASIA' | translate}}: </label>
                                <span> {{infosCliente.nome_fantasia | null}}</span>
                                <i *ngIf="infosCliente.nome_fantasia" style="margin-left: 5px; cursor: pointer;" (click)="copyToClipboard(infosCliente.nome_fantasia)" class="far fa-copy"></i>
                            </div>
                            <div style="font-size: 14px;">
                                <label *ngIf="infosCliente.cnpj_cpf.length > 11" style="font-weight: bold;">CNPJ: </label>
                                <label *ngIf="infosCliente.cnpj_cpf.length <= 11" style="font-weight: bold;">CPF: </label>
                                <span *ngIf="infosCliente.cnpj_cpf.length > 11"> {{infosCliente.cnpj_cpf | null | mask:'00.000.000/0000-00'}}</span>
                                <span *ngIf="infosCliente.cnpj_cpf.length <= 11"> {{infosCliente.cnpj_cpf | null | mask:'000.000.000-00'}}</span>
                                <i *ngIf="infosCliente.cnpj_cpf" style="margin-left: 5px; cursor: pointer;" (click)="copyToClipboard(infosCliente.cnpj_cpf)" class="far fa-copy"></i>
                            </div>
                            <div style="font-size: 14px;">
                                <label style="font-weight: bold;">{{'CLIENTE' | translate}} {{'OBSERVACAO' | translate}}: </label>
                                <span [innerHtml]="infosCliente.observacao | null"></span>
                                <i *ngIf="infosCliente.observacao" style="margin-left: 5px; cursor: pointer;" (click)="copyToClipboard(clienteContato.observacao)" class="far fa-copy"></i>
                            </div>
                        </div>
                        <hr style="margin: 0 0 7px 0px;">
                        <div *ngIf="clienteContato && clienteContato !== null">
                            <div style="font-size: 14px;">
                                <label style="font-weight: bold;">{{'CONTATO' | translate}} ({{'PADRAO' | translate}}): </label>
                                <span> {{clienteContato.descricao | null}}</span>
                                <i *ngIf="clienteContato.descricao" style="margin-left: 5px; cursor: pointer;" (click)="copyToClipboard(clienteContato.descricao)" class="far fa-copy"></i>
                            </div>
                            <div style="font-size: 14px;">
                                <label style="font-weight: bold;">Email ({{'PADRAO' | translate}}): </label>
                                <span> {{clienteContato.email | null}}</span>
                                <i *ngIf="clienteContato.email" style="margin-left: 5px; cursor: pointer;" (click)="copyToClipboard(clienteContato.email)" class="far fa-copy"></i>
                            </div>
                            <div style="font-size: 14px;">
                                <label style="font-weight: bold;">{{'TELEFONE' | translate}} ({{'PADRAO' | translate}}): </label>
                                <span> {{clienteContato.telefone | null}}</span>
                                <i *ngIf="clienteContato.telefone" style="margin-left: 5px; cursor: pointer;" (click)="copyToClipboard(clienteContato.telefone)" class="far fa-copy"></i>
                            </div>
                            <div style="font-size: 14px;">
                                <label style="font-weight: bold;">{{'CELULAR' | translate}} ({{'PADRAO' | translate}}): </label>
                                <span> {{clienteContato.celular | null}}</span>
                                <i *ngIf="clienteContato.celular" style="margin-left: 5px; cursor: pointer;" (click)="copyToClipboard(clienteContato.celular)" class="far fa-copy"></i>
                            </div>
                            <div style="font-size: 14px;">
                                <label style="font-weight: bold;">{{'CONTATO' | translate}} {{'OBSERVACAO' | translate}}: </label>
                                <span [innerHtml]="clienteContato.observacao | null"></span>
                                <i *ngIf="clienteContato.observacao" style="margin-left: 5px; cursor: pointer;" (click)="copyToClipboard(clienteContato.observacao)" class="far fa-copy"></i>
                            </div>
                        </div>
                        <div *ngIf="clienteContato === null || clienteContato === undefined">
                            <span style="font-size: 14px; font-weight: bold;">{{'CLIENTE_SEM_INFOS_CONTATO' | translate}}</span>
                        </div>
                        <hr style="margin: 0 0 7px 0px;">
                        <div *ngIf="clienteEndereco && clienteEndereco !== null">
                            <div style="font-size: 14px;">
                                <label style="font-weight: bold;">{{'LOGRADOURO' | translate}}: </label>
                                <span> {{clienteEndereco.tipologradouro | null}}</span>
                                <i *ngIf="clienteEndereco.tipologradouro" style="margin-left: 5px; cursor: pointer;" (click)="copyToClipboard(clienteEndereco.tipologradouro)" class="far fa-copy"></i>
                            </div>
                            <div style="font-size: 14px;">
                                <label style="font-weight: bold;">{{'NUMERO' | translate}}: </label>
                                <span> {{clienteEndereco.numero | null}}</span>
                                <i *ngIf="clienteEndereco.numero" style="margin-left: 5px; cursor: pointer;" (click)="copyToClipboard(clienteEndereco.numero)" class="far fa-copy"></i>
                            </div>
                            <div style="font-size: 14px;">
                                <label style="font-weight: bold;">{{'COMPLEMENTO' | translate}}: </label>
                                <span> {{clienteEndereco.complemento | null}}</span>
                                <i *ngIf="clienteEndereco.complemento" style="margin-left: 5px; cursor: pointer;" (click)="copyToClipboard(clienteEndereco.complemento)" class="far fa-copy"></i>
                            </div>
                            <div style="font-size: 14px;">
                                <label style="font-weight: bold;">{{'BAIRRO' | translate}}: </label>
                                <span> {{clienteEndereco.bairro | null}}</span>
                                <i *ngIf="clienteEndereco.bairro" style="margin-left: 5px; cursor: pointer;" (click)="copyToClipboard(clienteEndereco.bairro)" class="far fa-copy"></i>
                            </div>
                            <div style="font-size: 14px;">
                                <label style="font-weight: bold;">{{'CIDADE' | translate}}: </label>
                                <span> {{clienteEndereco.cidade | null}}</span>
                                <i *ngIf="clienteEndereco.cidade" style="margin-left: 5px; cursor: pointer;" (click)="copyToClipboard(clienteEndereco.cidade)" class="far fa-copy"></i>
                            </div>
                            <div style="font-size: 14px;">
                                <label style="font-weight: bold;">{{'ESTADO' | translate}}: </label>
                                <span> {{clienteEndereco.estado | null}}</span>
                                <i *ngIf="clienteEndereco.estado" style="margin-left: 5px; cursor: pointer;" (click)="copyToClipboard(clienteEndereco.estado)" class="far fa-copy"></i>
                            </div>
                            <div style="font-size: 14px;">
                                <label style="font-weight: bold;">CEP: </label>
                                <span> {{clienteEndereco.cep | mask:'00000-000'}}</span>
                                <i *ngIf="clienteEndereco.cep" style="margin-left: 5px; cursor: pointer;" (click)="copyToClipboard(clienteEndereco.cep)" class="far fa-copy"></i>
                            </div>
                        </div>
                        <div *ngIf="clienteEndereco === null || clienteEndereco === undefined">
                            <span style="font-size: 14px; font-weight: bold;">{{'CLIENTE_SEM_INFOS_ENDERECO' | translate}}</span>
                        </div>                        
                        <hr style="margin: 0 0 7px 0px;">
                        <div *ngIf="infosCliente">
                            <a *ngIf="permissao.EditarCliente; else apenasVisualizarCliente" (click)="goToCliente(infosCliente.id)" style="cursor: pointer; color: #2dce89;">{{infosCliente.razao_social}}</a>
                            <ng-template #apenasVisualizarCliente>
                                <span>{{infosCliente.razao_social}}</span>
                            </ng-template>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-5">
                <div class="row">
                    <div *ngIf="clienteContrato" class="col-12">
                        <h3>{{'INFOS_CONTRATOS' | translate}}</h3>
                        <div *ngFor="let cobranca of clienteContrato.cobrancas" style="font-size: 14px;">
                            - {{cobranca.descricao}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>