import { HttpHeaders, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { TokenService } from '../core/token/token.service';

@Injectable({
  providedIn: 'root'
})
export class DashboardModoTvService {

  private apiUrl = environment.apiGestorUrlLocal;
  private apiManager = environment.apiGestorUrlNova;
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', 'authorization': this.tokenService.getToken(), 'session': this.tokenService.getSessionUsuario() }),
    params: {}
  };

  constructor(private http: HttpClient, private tokenService: TokenService) { }

  buscarConfiguracoes(): Observable<any> {
    this.httpOptions.params = ''
    return this.http.get(`${this.apiUrl}api/modo-tv/configuracoes/`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  listarConfiguracoes(params, filtros): Observable<any> {
    this.httpOptions.params = params
    return this.http.post(`${this.apiUrl}api/modo-tv/configuracoes/list`, filtros, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  excluirConfiguracao(id): Observable<any> {
    this.httpOptions.params = ''
    return this.http.delete(`${this.apiUrl}api/modo-tv/configuracao/${id}`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  dropDown(): Observable<any> {
    this.httpOptions.params = ''
    return this.http.get(`${this.apiUrl}api/chamados/filtro-avancado/dropdownlist`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  dropDownData(param): Observable<any> {
    this.httpOptions.params = param
    return this.http.get(`${this.apiUrl}api/chamados/filtro-avancado/dropdown-data`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  dropdownListDashboard(): Observable<any> {
    this.httpOptions.params = ''
    return this.http.get(`${this.apiUrl}api/modo-tv/dashboards/dropdownlist`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  criarConfiguracao(payload): Observable<any> {
    this.httpOptions.params = ''
    return this.http.post(`${this.apiUrl}api/modo-tv/configuracao/`, payload, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  atualizarTemplates(payload): Observable<any> {
    this.httpOptions.params = ''
    return this.http.put(`${this.apiUrl}api/modo-tv/ordenacao/`, payload, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  obterConfiguracao(id): Observable<any> {
    this.httpOptions.params = "";
    return this.http.get(`${this.apiUrl}api/modo-tv/configuracao/${id}`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  atualizarConfiguracao(payload, id): Observable<any> {
    this.httpOptions.params = ''
    return this.http.put(`${this.apiUrl}api/modo-tv/configuracao/${id}`, payload, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  listTvGroups(filters: any, pagination: any): Observable<any> {
    this.httpOptions.params = pagination;
    return this.http.post(`${this.apiManager}manager/grouptv/list`, filters, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  createTvGroup(payload: any): Observable<any> {
    this.httpOptions.params = null;
    return this.http.post(`${this.apiManager}manager/grouptv`, payload, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  deleteTvGroup(payload: any): Observable<any> {
    this.httpOptions.params = null;
    return this.http.request('delete', `${this.apiManager}manager/grouptv`, {
      body: payload,
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'authorization': this.tokenService.getToken(), 'session': this.tokenService.getSessionUsuario() })
    }).pipe(
      catchError(this.handleError)
    );
  }

  getGroupById(group_id: number|string): Observable<any> {
    this.httpOptions.params = null;
    return this.http.get(`${this.apiManager}manager/grouptv/${group_id}`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }
  
  updateGroupTv(payload: any): Observable<any> {
    this.httpOptions.params = null;
    return this.http.put(`${this.apiManager}manager/grouptv`, payload, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  generatePublicLink(group_id: number): Observable<any> {
    this.httpOptions.params = null;
    return this.http.post(`${this.apiManager}manager/grouptv/${group_id}/generateUrl`, null, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('Ops! Ocorreu um erro:', error.error.message);
    } else {
      console.error(
        `Backend retornou o código ${error.status}, ` +
        `o body é: ${error.error}`);
    }
    return throwError(
      error.error);
  }

}
