import { Component, OnInit, HostListener, NgZone } from "@angular/core";
import { Router, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import { WebsocketService } from "src/app/core/websocket/websocket.service";

@Component({
  selector: "app-admin-layout",
  templateUrl: "./admin-layout.component.html",
  styleUrls: ["./admin-layout.component.scss"]
})
export class AdminLayoutComponent implements OnInit {
  //isMobileResolution: boolean;

  public socketConnect: any;
  public socketChat: any;
  this1 = this;

  constructor(private router: Router, public websocketService: WebsocketService, private zone: NgZone) {
    /*if (window.innerWidth < 1200) {
      this.isMobileResolution = true;
    } else {
      this.isMobileResolution = false;
    }*/
  }

  /*@HostListener("window:resize", ["$event"])
  isMobile(event) {
    if (window.innerWidth < 1200) {
      this.isMobileResolution = true;
    } else {
      this.isMobileResolution = false;
    }
  }*/
  
  ngOnInit() {
    // estabelecendo conexao com o socket
    this.socketConnect = this.websocketService.getSocket();
    if(!this.socketConnect) {
      this.socketConnect = this.websocketService.connect();
    }  
    
    this.zone.runOutsideAngular(() => {
      setInterval(() => {
        this.socketConnect.emit("ping_gestor", "Ping gestor..");
  
      }, 10000);    
    });
  }
}
