import { Pipe, PipeTransform } from '@angular/core';

const enumUnits = {
    'KB': 1,
    'MB': 2,
    'GB': 3,
    'TB': 4,
}

@Pipe({name: 'byteConverter'})
export class ByteConverterPipe implements PipeTransform {
  transform(bytes: any, unit: string): string {
    let result = this.convertBytes(parseFloat(bytes), unit)
    return `${result} ${unit}`;
  }

  convertBytes(bytes: number, unit: string): string {
    let convertedBytes = bytes / Math.pow(1024, enumUnits[unit]) // 1024 é a unidade básica para conversão
    let cleanedBytes = convertedBytes.toFixed(0) // removendo números dps da vírgula
    return cleanedBytes
  }

}