import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { ChecklistService } from 'src/app/services/checklist.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-modal-criar-grupo',
  templateUrl: './modal-criar-grupo.component.html',
  styleUrls: ['./modal-criar-grupo.component.scss']
})
export class ModalCriarGrupoComponent implements OnInit, OnDestroy {
  nome = "";
  selector: string;
  newSubs: Subscription;

  constructor(
    public modal: NgbActiveModal,
    private checkListService: ChecklistService,
    private toastr: ToastrService,
    public translate: TranslateService,
    private elemRef: ElementRef,
) {
    this.selector = elemRef.nativeElement.tagName.toLowerCase();
}

  ngOnInit(): void {
    document.getElementsByTagName(this.selector)[0]['style']['zoom'] = '0.85';
  }

  ngOnDestroy(): void {
    this.newSubs?.unsubscribe();
  }

  verificaSeDesativa(): boolean {
    if(this.nome.length < 4) {
      return true;
    } else {
      return false;
    }
  }

  salvarGrupo(): void {
    var _grupo = {
      descricao: this.nome
    };

    this.newSubs = this.checkListService.adicionarGrupo(_grupo)
      .subscribe(results => {
        this.toastr.show(this.translate.instant('SALVO_SUCESSO'), '', environment.toastrConfig('success'));
        this.modal.close();
      }, error => {
        console.log(error);     
        this.toastr.show(error, '', environment.toastrConfig('danger'));
      })
  }
}
