import { HttpHeaders, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { TokenService } from '../core/token/token.service';

@Injectable({
  providedIn: 'root'
})
export class CobrancasService {
  private apiUrl = environment.apiGestorUrlLocal;
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', 'authorization': this.tokenService.getToken(), 'session': this.tokenService.getSessionUsuario() }),
    params: {}
  };

  constructor(
    private http: HttpClient,
    private tokenService: TokenService
  ) { }

  atualizarDadosParcela(payload, id): Observable<any> {
    this.httpOptions.params = "";
    return this.http.put(`${this.apiUrl}api/contratos/parcelas/atualizar-dados/${id}`, payload, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  conferirParcela(id): Observable<any> {
    this.httpOptions.params = "";
    return this.http.put(`${this.apiUrl}api/contratos/parcelas/conferir/${id}`, null, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  atualizarContrato(payload): Observable<any> {
    this.httpOptions.params = "";
    return this.http.put(`${this.apiUrl}api/clientes/contratos`, payload, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  uploadImagem(file): Observable<any> {
    this.httpOptions.params = "";
    return this.http.post(`${this.apiUrl}api/base-conhecimento/adicionaArquivo`, file,
      { headers: new HttpHeaders({ 'authorization': this.tokenService.getToken(), 'session': this.tokenService.getSessionUsuario() }) })
      .pipe(
        catchError(this.handleError)
      );
  }


  obterContrato(id): Observable<any> {
    this.httpOptions.params = '';
    return this.http.get(`${this.apiUrl}api/clientes/contratos/${id}`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  reenviarIntegracaoBC(payload): Observable<any> {
    this.httpOptions.params = "";
    return this.http.put(`${this.apiUrl}api/contratos/parcelas/reenviar-integracao`, payload, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  adicionarParcela(payload): Observable<any> {
    this.httpOptions.params = "";
    return this.http.post(`${this.apiUrl}api/contratos/parcelas`, payload, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  dropdownServicos(): Observable<any> {
    this.httpOptions.params = '';
    return this.http.get(`${this.apiUrl}api/clientes/contratos/dropdown/servicos`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  dropdownGatilhos(): Observable<any> {
    this.httpOptions.params = ""
    return this.http.get(`${this.apiUrl}api/clientes/contratos/dropdown/gatilho-integracao`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  dropDownListClienteFaturamento(id): Observable<any> {
    this.httpOptions.params = ""
    return this.http.get(`${this.apiUrl}api/clientes/dropdownlist/matriz-filiais/${id}`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  dropdownMoedas(): Observable<any> {
    this.httpOptions.params = "";
    return this.http.get(`${this.apiUrl}api/empresas/moeda/dropdownlist`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  pagarParcela(id, descricao): Observable<any> {
    this.httpOptions.params = "";
    return this.http.put(`${this.apiUrl}api/contratos/parcelas/pagar/${id}`, descricao, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  verificaIntegracao(): Observable<any> {
    this.httpOptions.params = "";
    return this.http.get(`${this.apiUrl}api/configuracoes/integracao/bomcontrole`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  excluirParcela(id): Observable<any> {
    this.httpOptions.params = "";
    return this.http.delete(`${this.apiUrl}api/contratos/parcelas/${id}`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  listagemCobrancas(params, filtros): Observable<any> {
    this.httpOptions.params = params;
    return this.http.post(`${this.apiUrl}api/contratos/parcelas/listagem`, filtros, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  verificarPagamento(id): Observable<any> {
    this.httpOptions.params = "";
    return this.http.get(`${this.apiUrl}api/contratos/parcelas/verificar-pagamento/${id}`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  gerarPDF(id): Observable<any> {
    let customHttpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'authorization': this.tokenService.getToken(), 'session': this.tokenService.getSessionUsuario() }),
      params: {},
    };
    customHttpOptions['responseType'] = "blob"

    return this.http.get(`${this.apiUrl}api/contratos/parcelas/download-pdf/${id}`, customHttpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  enviarPdfEmail(id, listaEmails): Observable<any> {
    this.httpOptions.params = "";
    return this.http.post(`${this.apiUrl}api/contratos/parcelas/enviar-pdf-email/${id}`, listaEmails, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  obterParcela(id): Observable<any> {
    this.httpOptions.params = "";
    return this.http.get(`${this.apiUrl}api/contratos/parcelas/${id}`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }


  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('Ops! Ocorreu um erro:', error.error.message);
    } else {
      console.error(
        `Backend retornou o código ${error.status}, ` +
        `o body é: ${error.error}`);
    }
    return throwError(
      error.error);
  }

}
