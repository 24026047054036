import { AfterContentInit, AfterViewInit, ApplicationRef, Component, ElementRef, EventEmitter, Input, NgZone, OnChanges, OnDestroy, OnInit, Output, Renderer2, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ClienteService } from "src/app/services/cliente.service"
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { CategoriasService } from 'src/app/services/categorias.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalFiltroAvancadoComponent } from '../modais/modal-filtro-avancado/modal-filtro-avancado.component';
import { EquipesService } from 'src/app/services/equipes.service';
import { SimpleCrypt } from 'ngx-simple-crypt';
import { Subject, Subscription } from 'rxjs';
import { ClienteEnderecoService } from 'src/app/services/cliente-endereco.service';
import { SetorPadraoService } from 'src/app/services/setor-padrao.service';
import { DeviceService } from 'src/app/services/device.service';
import { ChatService } from 'src/app/services/chat.service';
import { MesaTrabalhoService } from 'src/app/services/mesa-trabalho.service';
import { LocalstorageService } from 'src/app/core/localstorage/localstorage.service';

@Component({
  selector: 'filtros',
  templateUrl: './filtros.component.html',
  styleUrls: ['./filtros.component.scss']
})
export class FiltrosComponent implements OnInit, AfterViewInit, AfterContentInit, OnDestroy, OnChanges {
  filtros: Object = {
    nome_filtro: ''
  };
  selectedTypeProjectFilter = false;
  arrayFiltrosRecebidos;
  arrayFiltrosOcultos = [];
  locale = 'pt-br';
  bsInlineValue;
  searchFilter;
  pagina = 1;
  tipoFiltroSelecionado = ""
  @Input() ticket_retidos_email
  cacheFiltro = []
  filtroCache: any = null;
  key: any;
  valor_categoria_primaria_id;
  additionalClients = [];
  newSubs: Subscription;
  public emitEventToCategoriesTree: Subject<void> = new Subject<void>();
  @Input() tipoFiltro;
  @Input() dataClassificacao;
  @Input() isRelatorios;
  @Input() clienteId;
  @Input() dropDownFields?: [];
  @Input() set arrayFiltros(value) {
    if (this.arrayFiltrosRecebidos && this.tipoFiltro !== 'listagemTicketsKamban' && this.tipoFiltro !== 'listagemTickets') {
      this.cleanFilters();
    }

    if (value && value.length > 0) {
      // remove initial hidden menu option - like 'projects'
      const arrFiltered = value.filter((el: any) => !el.isHidden);
      const arrHidden = value.filter((el: any) => el.isHidden);

      this.arrayFiltrosRecebidos = arrFiltered;
      this.arrayFiltrosOcultos = arrHidden;
    } else {
      this.arrayFiltrosRecebidos = [];
    }
  }
  @Output() filtrosChange = new EventEmitter();

  constructor(
    public translate: TranslateService,
    private elem: ElementRef,
    private renderer: Renderer2,
    private clienteService: ClienteService,
    private _localeService: BsLocaleService,
    private toastr: ToastrService,
    private categoriasService: CategoriasService,
    private modalService: NgbModal,
    private equipesService: EquipesService,
    private zone: NgZone,
    private customerAddressService: ClienteEnderecoService,
    private setorService: SetorPadraoService,
    private deviceService: DeviceService,
    private localStorageService: LocalstorageService,
    private mesaTrabalhoService: MesaTrabalhoService
  ) {
    this._localeService.use(this.locale);
  }

  ngOnInit(): void {
    document.onclick = (event) => {
      var elementos = event['target'];

      if (elementos['classList'] === undefined || elementos['classList'] === undefined) {
        var _outrosDropdowns = this.elem.nativeElement.querySelectorAll('.show-dropdown-filtros');
        var _iconsOutrosDropdowns = this.elem.nativeElement.querySelectorAll('.close-dropdown-icon');

        if (_outrosDropdowns !== undefined) {
          _outrosDropdowns.forEach((drop) => {
            drop.classList.remove('show-dropdown-filtros');
          });
        }

        if (_iconsOutrosDropdowns !== undefined) {
          _iconsOutrosDropdowns.forEach((icon) => {
            icon.classList.remove('close-dropdown-icon');
          });
        }
      } else if (!elementos['classList'].contains('nome-filtro') && !elementos['classList'].contains('dropdown-opt-filtros') && !elementos['classList'].contains('text-filters') && !elementos['classList'].contains('icon-filtro') && !elementos['classList'].contains('filtro-dropdown') && !elementos['id'].includes('inputText') && !elementos['id'].includes('searchDropdown') && !elementos['classList'].contains('accordion-button') && !elementos['classList'].contains('category-btn-not-child') && !elementos['classList'].contains('category-name') && !elementos['classList'].contains('search-drop-cat-multi') && !elementos['classList'].contains('opt-filtros')) {
        var _outrosDropdowns = this.elem.nativeElement.querySelectorAll('.show-dropdown-filtros');
        var _iconsOutrosDropdowns = this.elem.nativeElement.querySelectorAll('.close-dropdown-icon');

        if (_outrosDropdowns !== undefined) {
          _outrosDropdowns.forEach((drop) => {
            drop.classList.remove('show-dropdown-filtros');
          });
        }

        if (_iconsOutrosDropdowns !== undefined) {
          _iconsOutrosDropdowns.forEach((icon) => {
            icon.classList.remove('close-dropdown-icon');
          });
        }
      }
    };
  }

  ngOnDestroy(): void {
    this.newSubs?.unsubscribe();
  }

  ngAfterViewInit() {

  }

  ngAfterContentInit() {
    this.setFiltroCache();
    this.getDataFromTipoFiltro();
    this.zone.runOutsideAngular(() => {
      setTimeout(() => {
        if (this.tipoFiltro !== 'listagemDashboardTicket') {
          this.verificaLocalStorage();
        }
      }, 1000)
    });

    this.setSelecionadosClienteListagemTickets();
  }

  recuperarFiltros(filtroRecuperado: string) {
    if (filtroRecuperado === 'listagemDashboardTicket') {
      let filtroLocal = this.localStorageService.getStoredItemSimple('listagemDashboardTicketFiltros')
      if (filtroLocal) {
        this.filtros = filtroLocal;
        this.filtrosChange.emit(this.filtros);
        Object.keys(filtroLocal).forEach(eKey => {
          this.arrayFiltrosRecebidos.forEach(elem => {
            if (elem.model === eKey) {
              elem.selecionados = 1
            }
          })
        });
      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['tipoFiltro'] && changes['tipoFiltro'].currentValue !== changes['tipoFiltro'].previousValue) {
      const filtroRecuperado: string = changes['tipoFiltro'].currentValue
      this.recuperarFiltros(filtroRecuperado)
    }
    if (this.isRelatorios === 17) {
      setTimeout(() => {
        this.newSubs = this.setorService
          .dropDownSetores({ cliente_id: "__all__", is_ativo: true })
          .subscribe(
            (results) => {
              var _aux = this.arrayFiltrosRecebidos
              _aux.unshift({
                nome: this.translate.instant('TIPO_RELATORIO'),
                tipo: "dropdown",
                model: "tipo_relatorio",
                data: [
                  { id: '__por_operador__', text: this.translate.instant('OPERADOR') },
                  { id: '__por_equipe__', text: this.translate.instant('EQUIPE') }
                ],
                selecionados: 0,
                concatena: false,
                multiSelect: false
              });

              _aux.push({
                nome: this.translate.instant("SETOR"),
                tipo: "dropdown",
                model: "setor",
                data: results.dropDown,
                selecionados: 0,
                concatena: true,
                multiSelect: true
              })
              this.arrayFiltrosRecebidos = _aux
            },
            (error) => {
              this.toastr.show(
                error,
                "",
                environment.toastrConfig("danger")
              );
            }
          );
      }, 500);
    }

    if (this.isRelatorios === 211) {
      setTimeout(() => {
        this.newSubs = this.setorService
          .dropDownSetores({ cliente_id: "__all__", is_ativo: true })
          .subscribe(
            (results) => {
              var _aux = this.arrayFiltrosRecebidos
              _aux.push({
                nome: this.translate.instant("SETOR"),
                tipo: "dropdown",
                model: "filtro_setor",
                data: results.dropDown,
                selecionados: 0,
                concatena: false,
                multiSelect: false
              })
              this.arrayFiltrosRecebidos = _aux
            },
            (error) => {
              this.toastr.show(
                error,
                "",
                environment.toastrConfig("danger")
              );
            }
          );
      }, 500);
    }
  }

  setSelecionadosClienteListagemTickets() {
    if (this.tipoFiltro === 'listagemTickets') {
      this.arrayFiltrosRecebidos.filter((element) => {
        if (element.model === 'cliente') {
          element.selecionados = this.filtros[element.model] ? this.filtros[element.model].length : 0
        }
      })
    }
  }

  isMesaEquipe(filtro) {
    var _aux = { cursor: 'no-drop', opacity: 0.5 };

    if ((filtro.nome === this.translate.instant('MESAS') && this.tipoFiltroSelecionado !== '__por_mesa_trabalho__') || (filtro.nome === this.translate.instant('EQUIPES') && this.tipoFiltroSelecionado !== '__por_equipes__')) {
      return _aux;
    } else {
      return null
    }
  }

  isOperadorEquipe(filtro) {
    var _aux = { cursor: 'no-drop', opacity: 0.5 };

    if ((filtro.nome === this.translate.instant('OPERADOR') && this.tipoFiltroSelecionado !== '__por_operador__') || (filtro.nome === this.translate.instant('EQUIPE') && this.tipoFiltroSelecionado !== '__por_equipe__')) {
      return _aux;
    } else {
      return null
    }
  }

  isEmpty(obj) {
    for (var prop in obj) {
      if (obj.hasOwnProperty(prop))
        return false;
    }
    return true;
  }

  desmarcarTodos(index, filtro): void {
    if (typeof (this.filtros[filtro.model]) === 'object') {
      var valoresSelecionados = (typeof (this.filtros[filtro.model]) === 'object') ? Object.assign([], this.filtros[filtro.model]) : [this.filtros[filtro.model]];
      valoresSelecionados.forEach(id => {
        this.setValorFiltro(index, id, filtro, id);
      });
    } else if (typeof (this.filtros[filtro.model]) === 'number') {
      this.setValorFiltro(index, this.filtros[filtro.model], filtro, this.filtros[filtro.model]);
    } else {
      this.setValorFiltro(index, this.filtros[filtro.model], filtro, this.filtros[filtro.model]);
    }

    filtro.selecionados = 0;

    if (filtro && filtro.model === 'filtro_cliente') {
      this.setDefaultValueMenuUnidade();
      let unidadeObj = this.arrayFiltrosRecebidos.find((el: any) => el.model === 'filtro_endereco_id');
      if (unidadeObj !== undefined) {
        const index = this.arrayFiltrosRecebidos.indexOf(unidadeObj);
        if (index) {
          this.arrayFiltrosRecebidos[index].data = [{ id: '0', text: this.translate.instant("PRIMEIRO_SELECIONE_CLIENTE") }];
          this.arrayFiltrosRecebidos[index].showInputText = false;
        }
      }
    }
  }

  setFiltroCache() {
    if (this.tipoFiltro) {
      // check dashboard users status filters
      if (this.tipoFiltro === 'listagemDashboardUsersStatus') {
        this.buildDashBoardFilters(this.tipoFiltro);
      } else {
        // tickets filters
        const localStorageItem = localStorage.getItem(this.tipoFiltro);
        if (localStorageItem) {
          const filtersRecovered = JSON.parse(JSON.stringify(SimpleCrypt.decodeDefault("szHYxd7tz", localStorageItem)));
          if (filtersRecovered) {
            // is Project Type selected by cache
            if (filtersRecovered.filtro_tipo_chamado === 1) {
              this.checkIfIsTypeProjectSelected();
            }

            if (filtersRecovered.tipo_filtro && filtersRecovered.tipo_filtro !== undefined) {
              this.tipoFiltroSelecionado = filtersRecovered.tipo_filtro;
            }

            if (filtersRecovered.cliente_id === "__all__") {
              delete filtersRecovered.cliente_id;
            }
            // del empty field
            delete filtersRecovered.nome_filtro;

            this.filtroCache = {
              [this.tipoFiltro]: { ...filtersRecovered }
            };
          }
        }
      }
    }
  }

  buildDashBoardFilters(tipoFiltro: string) {
    const localStorageItem = localStorage.getItem(tipoFiltro);
    if (localStorageItem) {
      const listagemDashboardUsersStatus = JSON.parse(JSON.stringify(SimpleCrypt.decodeDefault("szHYxd7tz", localStorageItem)));
      if (listagemDashboardUsersStatus) {
        // del empty field
        delete listagemDashboardUsersStatus.nome_filtro;

        this.filtroCache = {
          listagemDashboardUsersStatus
        };
      }
    }
  };

  checkIfIsTypeProjectSelected() {
    this.selectedTypeProjectFilter = true;
    let projectObj = this.arrayFiltrosRecebidos.find((el: any) => el.model === 'filtro_projeto');

    if (!projectObj || projectObj === undefined) {
      const projectMenuObj = this.arrayFiltrosOcultos.find((el: any) => el.model === 'filtro_projeto');
      if (projectMenuObj) {
        projectMenuObj.selecionados = 0;
        this.arrayFiltrosRecebidos.splice(2, 0, projectMenuObj);
      }
    }
  }

  verificaLocalStorage() {
    if (!this.isEmpty(this.filtroCache)) {
      for (let firstIndex = 0; firstIndex < this.arrayFiltrosRecebidos.length; firstIndex++) {
        const element = this.arrayFiltrosRecebidos[firstIndex];
        for (let secondIndex = 0; secondIndex < Object.keys(Object.values(this.filtroCache)[0]).length; secondIndex++) {
          const subelement = Object.keys(Object.values(this.filtroCache)[0])[secondIndex];
          if (Array.isArray(element.model)) {
            let listaAux = []
            for (const key in this.filtroCache) {
              for (const prop in this.filtroCache[key]) {
                var index = element.model.indexOf(prop)
                if (index > -1) {
                  listaAux.push(this.filtroCache[key][element.model[index]])
                }
              }
              if (listaAux.length === 2) {
                element.selectedDate = [new Date(listaAux[0]), new Date(listaAux[1])]
                this.setValorFiltroRangeDate(element, [new Date(listaAux[0]), new Date(listaAux[1])])
              }
            }
          } else {
            if (element.model === subelement) {
              if (Array.isArray(this.filtroCache[this.tipoFiltro][element.model])) {
                for (let i = 0; i < this.filtroCache[this.tipoFiltro][element.model].length; i++) {
                  const _aux = this.filtroCache[this.tipoFiltro][element.model][i];
                  setTimeout(() => {
                    this.setValorFiltro(firstIndex, _aux, element, _aux)
                  }, 500);
                }
              } else {
                if (typeof (this.filtroCache[this.tipoFiltro][element.model]) === 'string') {
                  element.modelValue = this.filtroCache[this.tipoFiltro][element.model];
                  this.setValorFiltroInput(element, firstIndex, false);
                } else {
                  this.setValorFiltro(firstIndex, this.filtroCache[this.tipoFiltro][element.model], element, this.filtroCache[this.tipoFiltro][element.model])
                }
              }
            }
          }
        }
      }
    }
    if (localStorage.getItem('nome_filtro')) {
      let decode: any = SimpleCrypt.decodeDefault("szHYxd7tz", localStorage.getItem("nome_filtro"));
      this.filtros['nome_filtro'] = decode;
    }
  }

  findSecondIndex(valor, lista) {
    var index = lista.findIndex((e) => {
      return e.id === valor
    })

    return index
  }

  openDropdown(index, filtro): void {
    this.searchFilter = '';
    this.pagina = 1;

    var _dropdown = this.elem.nativeElement.querySelector('#dd-' + index);
    var _icon = this.elem.nativeElement.querySelector('#icon-' + index);
    var _outrosDropdowns = this.elem.nativeElement.querySelectorAll('.show-dropdown-filtros');
    var _iconsOutrosDropdowns = this.elem.nativeElement.querySelectorAll('.close-dropdown-icon');

    if ((!this.isRelatorios || this.isRelatorios !== 17)
      && filtro.nome !== this.translate.instant('MESAS') && filtro.nome !== this.translate.instant('EQUIPES')
    ) {
      _outrosDropdowns.forEach((drop) => {
        if (drop.id !== 'dd-' + index) {
          drop.classList.remove('show-dropdown-filtros');
        }
      });

      _iconsOutrosDropdowns.forEach((icon) => {
        if (icon.id !== 'icon-' + index) {
          icon.classList.remove('close-dropdown-icon');
        }
      });

      if (filtro.tipo !== 'filtro_avancado') {
        if (_dropdown.classList.contains('show-dropdown-filtros')) {
          _dropdown.classList.remove('show-dropdown-filtros');
          _icon.classList.remove('close-dropdown-icon');
        } else {
          _dropdown.classList.add('show-dropdown-filtros')
          _icon.classList.add('close-dropdown-icon');
        }
      }
    } else if (
      (this.isRelatorios && this.isRelatorios === 17)
      && filtro.nome !== this.translate.instant('OPERADOR') && filtro.nome !== this.translate.instant('EQUIPE')
    ) {
      _outrosDropdowns.forEach((drop) => {
        if (drop.id !== 'dd-' + index) {
          drop.classList.remove('show-dropdown-filtros');
        }
      });

      _iconsOutrosDropdowns.forEach((icon) => {
        if (icon.id !== 'icon-' + index) {
          icon.classList.remove('close-dropdown-icon');
        }
      });

      if (filtro.tipo !== 'filtro_avancado') {
        if (_dropdown.classList.contains('show-dropdown-filtros')) {
          _dropdown.classList.remove('show-dropdown-filtros');
          _icon.classList.remove('close-dropdown-icon');
        } else {
          _dropdown.classList.add('show-dropdown-filtros')
          _icon.classList.add('close-dropdown-icon');
        }
      }
    } else if ((this.tipoFiltroSelecionado == '__por_mesa_trabalho__' && filtro.nome === this.translate.instant('MESAS')) || (this.tipoFiltroSelecionado == "__por_equipes__" && filtro.nome === this.translate.instant('EQUIPES'))) {
      _outrosDropdowns.forEach((drop) => {
        if (drop.id !== 'dd-' + index) {
          drop.classList.remove('show-dropdown-filtros');
        }
      });

      _iconsOutrosDropdowns.forEach((icon) => {
        if (icon.id !== 'icon-' + index) {
          icon.classList.remove('close-dropdown-icon');
        }
      });

      if (filtro.tipo !== 'filtro_avancado') {
        if (_dropdown.classList.contains('show-dropdown-filtros')) {
          _dropdown.classList.remove('show-dropdown-filtros');
          _icon.classList.remove('close-dropdown-icon');
        } else {
          _dropdown.classList.add('show-dropdown-filtros')
          _icon.classList.add('close-dropdown-icon');
        }
      }
    } else if ((this.tipoFiltroSelecionado == '__por_operador__' && filtro.nome === this.translate.instant('OPERADOR')) ||
      (this.tipoFiltroSelecionado == "__por_equipe__" && filtro.nome === this.translate.instant('EQUIPE'))) {
      _outrosDropdowns.forEach((drop) => {
        if (drop.id !== 'dd-' + index) {
          drop.classList.remove('show-dropdown-filtros');
        }
      });

      _iconsOutrosDropdowns.forEach((icon) => {
        if (icon.id !== 'icon-' + index) {
          icon.classList.remove('close-dropdown-icon');
        }
      });

      if (filtro.tipo !== 'filtro_avancado') {
        if (_dropdown.classList.contains('show-dropdown-filtros')) {
          _dropdown.classList.remove('show-dropdown-filtros');
          _icon.classList.remove('close-dropdown-icon');
        } else {
          _dropdown.classList.add('show-dropdown-filtros')
          _icon.classList.add('close-dropdown-icon');
        }
      }
    }

    if (filtro.tipo === 'input') {
      document.getElementById('inputText' + index).focus();
    } else {
      if (document.getElementById('searchDropdown' + index) !== null) {
        document.getElementById('searchDropdown' + index).focus();
      }
    }

    if (filtro.tipo === 'filtro_avancado') {
      this.openModalFiltroAvancado()
    }

    if (filtro.model === 'filtro_cliente' || filtro.model === 'cliente_id' || filtro.model === 'clientes' || filtro.model === 'cliente') {
      this.filtrarApi('', filtro, index);
    } else {
      this.zone.runOutsideAngular(() => {
        setTimeout(() => {
          if (filtro.data !== undefined && filtro.tipo !== 'tree') {
            filtro.data.forEach(item => {
              item.text = item.text.normalize("NFD").replace(/\p{Diacritic}/gu, "");
              if (typeof (this.filtros[filtro.model]) === 'object') {
                if (this.filtros[filtro.model].indexOf(item.id) !== -1) {
                  var _opt = this.elem.nativeElement.querySelector('#opt-' + index + '-' + item.id);
                  var verify = document.getElementsByClassName(index + '-' + item.id);
                  if (_opt && verify.length === 0 && filtro.selecionados > 0) {
                    this.adicionaCheckDropdown(index, item.id, _opt);
                  }
                }
              } else if (typeof (this.filtros[filtro.model]) === 'number' || typeof (this.filtros[filtro.model]) === 'string') {
                if (this.filtros[filtro.model] === item.id) {
                  var _opt = this.elem.nativeElement.querySelector('#opt-' + index + '-' + item.id);
                  var verify = document.getElementsByClassName(index + '-' + item.id);
                  if (_opt && verify.length === 0 && filtro.selecionados > 0) {
                    this.adicionaCheckDropdown(index, item.id, _opt);
                  }
                }
              }
            });
          }
        }, 200);
      })
    }
  }

  openModalFiltroAvancado() {
    const modalRef = this.modalService.open(ModalFiltroAvancadoComponent, {
      backdrop: "static",
      windowClass: 'modal-custom-milvus',
      keyboard: true,
      scrollable: false,
      size: "grande",
      centered: true
    });

    modalRef.result.then((result) => {
      if (result) {
        Object.keys(result).forEach(key => {
          if (key !== 'nome_filtro' && key !== 'personalizado') {
            if (result[key] && result[key].id) {
              result[key] = parseInt(result[key].id);
            }
          } 
          
          // else if (key === 'personalizado') {
          //   if (result[key] && result[key][0] && result[key][0].id) {
          //     result[key] = parseInt(result[key][0].id);
          //   }
          // }
        });

        this.filtros = result;
        //this.closeAllDropdowns();
        //this.filtrosChange.emit(this.filtros);
        //this.arrayFiltrosRecebidos[0].selecionados = 1;
        localStorage.removeItem('nome_filtro')
        let encodedObjectAsString: string = SimpleCrypt.encodeDefault("szHYxd7tz", result.nome_filtro)
        localStorage.setItem('nome_filtro', encodedObjectAsString)

        if (this.tipoFiltro === 'listagemFiltroAvancadoTickets') {
          localStorage.removeItem('listagemTickets')
          let encodedObjectAsString: string = SimpleCrypt.encodeDefault("szHYxd7tz", result)
          localStorage.setItem('listagemTickets', encodedObjectAsString)
        }

        this.sendFilters()
        this.ngAfterContentInit()

        /* if (result.nome_filtro) {
          this.filtros['nome_filtro'] = result.nome_filtro
        } */
      } else {
        localStorage.removeItem('nome_filtro')
      }
    });
  }

  closeAllDropdowns(): void {
    var _outrosDropdowns = this.elem.nativeElement.querySelectorAll('.show-dropdown-filtros');
    var _iconsOutrosDropdowns = this.elem.nativeElement.querySelectorAll('.close-dropdown-icon');

    _outrosDropdowns.forEach((drop) => {
      drop.classList.remove('show-dropdown-filtros');
    });

    _iconsOutrosDropdowns.forEach((icon) => {
      icon.classList.remove('close-dropdown-icon');
    });
  }

  adicionaCheckDropdown(firstIndex, secondIndex, _opt): void {
    var _iconSelect = this.renderer.createElement('i');
    _iconSelect.classList.add('fas', 'fa-check', 'filtro-checked-milvus', firstIndex + '-' + secondIndex);
    _iconSelect.setAttribute('style', 'color: #56ce89; position: absolute; left: 20px; margin-top: 4px;');

    if (_opt) {
      _opt.prepend(_iconSelect);
    }
  }

  removeAllCheckDropdown(data, firstIndex, secondIndex?): void {
    for (let i = 0; i < data.length; i++) {
      let id = '#opt-' + firstIndex + '-' + (secondIndex ? secondIndex : data[i].id);
      var _eachOpt = this.elem.nativeElement.querySelector(id);
      if (_eachOpt !== null && _eachOpt.childNodes.length > 1) {
        _eachOpt.removeChild(_eachOpt.childNodes[0]);
      }
    }
  }

  logicaMesaEquipes(secondIndex?, valor?): void {
    if (this.tipoFiltroSelecionado === valor) {
      this.tipoFiltroSelecionado = ''
    } else {
      this.tipoFiltroSelecionado = valor
    }

    if (this.tipoFiltroSelecionado === '__por_mesa_trabalho__') {
      this.removeAllCheckDropdown(this.arrayFiltrosRecebidos[5].data, 5, secondIndex);
      this.arrayFiltrosRecebidos[5].modelValue = '';
      this.arrayFiltrosRecebidos[5].selecionados = 0;
      delete this.filtros['filtro_equipe'];
    } else if (this.tipoFiltroSelecionado === '__por_equipes__') {
      this.removeAllCheckDropdown(this.arrayFiltrosRecebidos[4].data, 4, secondIndex);
      this.arrayFiltrosRecebidos[4].modelValue = '';
      this.arrayFiltrosRecebidos[4].selecionados = 0;
      delete this.filtros['filtro_mesa_trabalho'];
    }

    if (this.tipoFiltroSelecionado === '__por_operador__') {
      this.removeAllCheckDropdown(this.arrayFiltrosRecebidos[8].data, 8, secondIndex);
      this.arrayFiltrosRecebidos[8].modelValue = '';
      this.arrayFiltrosRecebidos[8].selecionados = 0;
      delete this.filtros['filtro_equipe'];
    } else if (this.tipoFiltroSelecionado === '__por_equipe__') {
      this.removeAllCheckDropdown(this.arrayFiltrosRecebidos[2].data, 2, secondIndex);
      this.arrayFiltrosRecebidos[2].modelValue = '';
      this.arrayFiltrosRecebidos[2].selecionados = 0;
      delete this.filtros['tecnicos'];
      delete this.filtros['filtro_tecnico'];
    }
  }

  logicaMultiSingleSelect(firstIndex?, secondIndex?, filtro?, valor?): void {
    var _opt = this.elem.nativeElement.querySelector('#opt-' + firstIndex + '-' + secondIndex);
    if (filtro.multiSelect === true) {
      if (valor === '__all__' || (valor === 0 && filtro.model !== 'gravidade_id')) {
        if (this.filtros[filtro.model] === valor) {
          this.removeAllCheckDropdown(filtro.data, firstIndex, secondIndex);
          filtro.selecionados = 0;
          delete this.filtros[filtro.model];
        } else {
          this.removeAllCheckDropdown(filtro.data, firstIndex, secondIndex);
          this.adicionaCheckDropdown(firstIndex, secondIndex, _opt);
          filtro.selecionados = 1;
          this.filtros[filtro.model] = valor;
        }
      } else {
        if (_opt && _opt.childNodes.length === 1) {
          this.adicionaCheckDropdown(firstIndex, secondIndex, _opt);
          filtro.selecionados++;
          if (this.filtros[filtro.model] !== '__all__' && this.filtros[filtro.model] !== 0) {
            if (filtro.concatena === true) {
              if (this.filtros[filtro.model] === undefined || typeof (this.filtros[filtro.model]) !== 'object') {
                this.filtros[filtro.model] = [valor];
              } else {
                this.filtros[filtro.model].push(valor);
              }
            } else {
              this.filtros[filtro.model] = valor;
            }
          }
        } else {
          if (filtro.concatena === true) {
            if (_opt !== null) {
              _opt.removeChild(_opt.childNodes[0]);
              filtro.selecionados--;
            }
            if (this.filtros[filtro.model] !== '__all__' && this.filtros[filtro.model] !== 0) {
              if (this.filtros[filtro.model] !== undefined && this.filtros[filtro.model].length > 1) {
                const index = this.filtros[filtro.model].indexOf(valor);
                if (index > -1) {
                  this.filtros[filtro.model].splice(index, 1);
                }
              } else {
                delete this.filtros[filtro.model];
              }
            }
          } else {
            if (_opt !== null) {
              _opt.removeChild(_opt.childNodes[0]);
              filtro.selecionados--;
            }
            if (this.filtros[filtro.model] !== '__all__' && this.filtros[filtro.model] !== 0) {
              delete this.filtros[filtro.model];
            }
          }
        }
      }
    } else {
      // valor ja selecionado - esta removendo a selecao - validar se removeu o tipo=projeto
      if (this.filtros[filtro.model] === valor) {
        var checkIcons = this.elem.nativeElement.querySelectorAll('.filtro-checked-milvus');
        checkIcons.forEach(icon => {
          icon.parentNode.removeChild(icon);
        });
        filtro.selecionados = 0;
        delete this.filtros[filtro.model];

        // validar tipo=projeto foi removido do filtro
        if (filtro.model === 'filtro_tipo_chamado' && valor === 1) {
          this.updateMenuProjetos(false);
        }
      } else {
        // valor ainda nao selecionado - sendo selecionado agora - validar tipo=projeto
        if (filtro.model === 'filtro_tipo_chamado' && valor === 1) {
          this.updateMenuProjetos(true);
        }

        if (filtro.model === 'filtro_tipo_chamado' && valor !== 1) {
          this.updateMenuProjetos(false);
        }

        var checkIcons = this.elem.nativeElement.querySelectorAll('.filtro-checked-milvus');
        checkIcons.forEach(icon => {
          icon.parentNode.removeChild(icon);
        });
        filtro.selecionados = 1;
        this.removeAllCheckDropdown(filtro.data, firstIndex);

        this.adicionaCheckDropdown(firstIndex, secondIndex, _opt);

        if (this.filtros[filtro.model] !== undefined) {
          if (this.filtros[filtro.model][0] === valor) {
            this.removeAllCheckDropdown(filtro.data, firstIndex);
            delete this.filtros[filtro.model];
            filtro.selecionados = 0;
          } else {
            if (this.filtros[filtro.model].length > 1) {
              const index = this.filtros[filtro.model].indexOf(valor);
              if (index > -1) {
                this.filtros[filtro.model].splice(index, 1);
              }
            } else {
              delete this.filtros[filtro.model];
              if (this.filtros[filtro.model] == undefined) {
                this.filtros[filtro.model] = valor;
              }
            }
          }
        } else {
          this.filtros[filtro.model] = valor
        }
        this.filtros[filtro.model] = valor
      }
    }

    if (filtro.model === 'filtro_cliente') {
      if (Array.isArray(this.filtros['filtro_cliente']) && this.filtros['filtro_cliente'].length > 0) {
        this.updateUnidadeNegocio();
      } else {
        this.setDefaultValueMenuUnidade();
      }
    }
  }

  updateUnidadeNegocio() {
    //lógica para popular unidade de negócio
    this.newSubs = this.customerAddressService
      .getClienteEnderecoById({ cliente_id: this.filtros['filtro_cliente'] })
      .subscribe((result) => {
        result.dropdown.forEach((element) => {
          let descricao = element.descricao ? element.descricao : "";
          let logradouro = element.logradouro ? ' - ' + element.logradouro : "";
          let numero = element.numero ? ", " + element.numero : "";
          let complemento = element.complemento
            ? " - " + element.complemento
            : "";
          let cep = element.cep ? " - " + element.cep : "";
          let bairro = element.bairro ? ", " + element.bairro : "";
          let cidade = element.cidade ? " - " + element.cidade : "";
          let estado = element.estado ? " - " + element.estado : "";

          element.text = descricao + logradouro + numero + complemento + cep + bairro + cidade + estado;
        });
        const unidadeObj = this.arrayFiltrosRecebidos.find((el: any) => el.model === 'filtro_endereco_id');
        if (unidadeObj !== undefined) {
          const index = this.arrayFiltrosRecebidos.indexOf(unidadeObj);
          if (index) {
            this.arrayFiltrosRecebidos[index].data = result.dropdown;
            this.arrayFiltrosRecebidos[index].showInputText = true;
          }
        }
      });
  }

  updateMenuProjetos(showItem: boolean): void {
    // se for tipo=projeto
    if (showItem) {
      this.checkIfIsTypeProjectSelected();
    } else {
      this.hiddenMenuProjetos();
    }
  }

  hiddenMenuProjetos() {
    this.selectedTypeProjectFilter = false;
    let projectObj = this.arrayFiltrosRecebidos.find((el: any) => el.model === 'filtro_projeto');

    if (projectObj !== undefined) {
      const index = this.arrayFiltrosRecebidos.indexOf(projectObj);
      if (index) {
        this.arrayFiltrosRecebidos.splice(index, 1);
      }
    }

    // limpa o valor do filtro de projeto
    delete this.filtros['filtro_projeto'];
  }

  setDefaultValueMenuUnidade() {
    let unidadeObj = this.arrayFiltrosRecebidos.find((el: any) => el.model === 'filtro_endereco_id');
    if (unidadeObj !== undefined) {
      const index = this.arrayFiltrosRecebidos.indexOf(unidadeObj);
      if (index) {
        this.arrayFiltrosRecebidos[index].data = [{ id: '0', text: this.translate.instant("PRIMEIRO_SELECIONE_CLIENTE") }];
        this.arrayFiltrosRecebidos[index].showInputText = false;
        this.desmarcarTodos(index, this.arrayFiltrosRecebidos[index]);
      }
    }
  }

  defineCategoriaSecundaria(valor): void {
    this.newSubs = this.categoriasService.getDropDownCategoriaSecundaria({ is_ativo: true, categoria_primaria_id: valor })
      .subscribe(results => {
        switch (this.tipoFiltro) {
          case 'listagemTickets':
            if (this.arrayFiltrosRecebidos[6].selecionados > 0) {
              this.arrayFiltrosRecebidos[7].data = results.dropDown;

            } else {
              this.arrayFiltrosRecebidos[7].data = [];
            }
            break;
          case 'listagemTicketsKamban':
            if (this.arrayFiltrosRecebidos[4].selecionados > 0) {
              this.arrayFiltrosRecebidos[5].data = results.dropDown;
            } else {
              this.arrayFiltrosRecebidos[5].data = [];
            }
            break;
          case 'listagemModalConferenciaTickets':
            this.arrayFiltrosRecebidos[3].data = results.dropDown;
            break;
          case 'listagemConfiguracaoSlaCategoria':
            this.arrayFiltrosRecebidos[1].data = results.dropDown;
            break;
          case 'listagemConfiguracaoSlaCategoriaPrioridade':
            this.arrayFiltrosRecebidos[1].data = results.dropDown;
            break;
          case 'listagemConfiguracaoSlaCategoriaCliente':
            this.arrayFiltrosRecebidos[2].data = results.dropDown;
            break;
          case 'listagemConfiguracaoSlaCategoriaClientePrioridade':
            this.arrayFiltrosRecebidos[2].data = results.dropDown;
            break;
          case 'listagemRelatorio':
            this.arrayFiltrosRecebidos[4].data = results.dropDown;
            break;
          case 'listagemEditarClienteCategoria':
            this.arrayFiltrosRecebidos[1].data = results.dropDown;
            break;
        }
      }, error => {
        this.toastr.show(error, '', environment.toastrConfig('danger'));
      })
  }

  verificaClientesAdicionais(filtro, valor): void {
    if ((this.tipoFiltro === 'listagemDashboardDashboard' || this.tipoFiltro === 'listagemAtualizacaoAutomatica') && (filtro.model === 'cliente_id' || filtro.model === 'filtro_cliente' || filtro.model === 'clienteId')) {
      let index = filtro.data.findIndex(el => el.id === valor)
      let cliente = filtro.data[index]
      if (cliente) {
        if (this.additionalClients.length > 0) {
          let exists = this.additionalClients.findIndex(el => el.id === valor)
          if (exists === -1) {
            this.additionalClients.push(cliente)
          } else {
            this.additionalClients.splice(exists, 1)
          }
        } else {
          this.additionalClients.push(cliente)
        }
      }
    } else if (this.tipoFiltro === 'listagemTickets' && filtro.model === 'cliente') {
      let index = filtro.data.findIndex(el => el.id === valor)
      let cliente = filtro.data[index]
      if (cliente) {
        if (this.additionalClients.length > 0) {
          let exists = this.additionalClients.findIndex(el => el.id === valor)
          if (exists === -1) {
            this.additionalClients.push(cliente)
          } else {
            this.additionalClients.splice(exists, 1)
          }
        } else {
          this.additionalClients.push(cliente)
        }
      }
    }
  }

  setValorFiltro(firtsIndex?, secondIndex?, filtro?, valor?): void {
    // verifica se a unidade de negocio esta vazia
    if (filtro && filtro.model && filtro.model === 'filtro_endereco_id' && valor === '0') {
      return;
    }

    //verifica se é por mesa ou equipe e chama a função para essa lógica
    if (valor === '__por_mesa_trabalho__' || valor === '__por_equipes__' || valor === '__por_operador__' || valor === '__por_equipe__') {
      this.logicaMesaEquipes(secondIndex, valor);
    }

    //lógica para definir valor quando é multiploSelect ou não
    this.logicaMultiSingleSelect(firtsIndex, secondIndex, filtro, valor);

    //lógica com uma variante diferente da função para mesas e equipes, por enquanto mantive aqui mesmo
    if (this.filtros[filtro.model] !== undefined) {
      if (this.filtros[filtro.model][0] === '__por_mesa_trabalho__' && this.tipoFiltroSelecionado === '__por_mesa_trabalho__') {
        this.removeAllCheckDropdown(this.arrayFiltrosRecebidos[5].data, 5, secondIndex);
        this.arrayFiltrosRecebidos[5].modelValue = '';
        this.arrayFiltrosRecebidos[5].selecionados = 0;
        delete this.filtros[filtro.model];
      } else if (this.filtros[filtro.model][0] === '__por_equipes__' && this.tipoFiltroSelecionado === '__por_equipes__') {
        this.removeAllCheckDropdown(this.arrayFiltrosRecebidos[4].data, 4, secondIndex);
        this.arrayFiltrosRecebidos[4].modelValue = '';
        this.arrayFiltrosRecebidos[4].selecionados = 0;
        delete this.filtros[filtro.model];
      } else if (this.filtros[filtro.model][0] === '__por_operador__' && this.tipoFiltroSelecionado === '__por_operador__') {
        this.removeAllCheckDropdown(this.arrayFiltrosRecebidos[8].data, 8, secondIndex);
        this.arrayFiltrosRecebidos[8].modelValue = '';
        this.arrayFiltrosRecebidos[8].selecionados = 0;
        delete this.filtros[filtro.model];
      } else if (this.filtros[filtro.model][0] === '__por_equipe__' && this.tipoFiltroSelecionado === '__por_equipe__') {
        this.removeAllCheckDropdown(this.arrayFiltrosRecebidos[2].data, 2, secondIndex);
        this.arrayFiltrosRecebidos[2].modelValue = '';
        this.arrayFiltrosRecebidos[2].selecionados = 1;
        delete this.filtros[filtro.model];
      }
    }

    //lógica para popular categoria secundária das telas
    if (filtro.model === 'filtro_categoria_primaria' || filtro.model === 'categoria_primaria_id') {
      this.defineCategoriaSecundaria(valor);
    }

    //lógica para popular grupo de dispositivos
    if (filtro.model === 'clienteId') {
      this.newSubs = this.deviceService
        .getDropdownOfGroupsByCustomerId(valor)
        .subscribe((result) => {
          this.arrayFiltrosRecebidos[1].data = result.dropDown;
        });
    }


    //lógica para popular dispositivos
    if (filtro.model === 'clienteId') {
      this.newSubs = this.deviceService
        .getDropdownOfLinkeds({ cliente_id: valor, is_ativo: "1" })
        .subscribe((result) => {
          this.arrayFiltrosRecebidos[2].data = result.dropDown;
        });
    }

    //lógica para popular equipes
    if (filtro.model === 'filtro_equipe' && this.arrayFiltrosRecebidos[7].data.length <= 0) {
      this.newSubs = this.equipesService.getDropDownEquipes({ is_ativo: true })
        .subscribe(results => {
          this.arrayFiltrosRecebidos[7].data = results.dropDown;
        }, error => {
          this.toastr.show(error, '', environment.toastrConfig('danger'));
        })
    }

    if (filtro.model === 'filtro_mesa_trabalho' && this.arrayFiltrosRecebidos[4].data.length <= 0) {
      this.newSubs = this.mesaTrabalhoService
        .getDropDownMesas({ filtrar_mesas_tecnico: true })
        .subscribe(
          (results) => {
            this.arrayFiltrosRecebidos[4].data = results.dropDown;
            this.arrayFiltrosRecebidos[4].data.unshift({
              id: "__minhas_mesas__",
              text: this.translate.instant("MINHAS_MESAS"),
            });
            this.logicaMultiSingleSelect(firtsIndex, secondIndex, filtro, valor);
          },
          (error) => {
            this.toastr.show(error, "", environment.toastrConfig("danger"));
          }
        );
    }

    //lógica para adicionar mais clientes em certas telas
    this.verificaClientesAdicionais(filtro, valor);

    this.verificaVisibilidade(filtro);

    // executa filtro default operadores
    if (
      (this.isRelatorios && this.isRelatorios === 17) ||
      (this.tipoFiltro === 'listagemUsuarios')
    ) {
      this.filtrosChange.emit(this.filtros);
    }
  }

  setValueFilterCategory(category_value, filter): void {
    this.filtros[filter.model] = category_value;
    filter.selecionados = (category_value) ? 1 : 0;
  }

  setValorFiltroInput(filtro, id, isOk, keypress?): void {
    if (keypress && keypress.key === 'Enter') {
      if (filtro.modelValue === '') {
        filtro.selecionados = 0;
        delete this.filtros[filtro.model];
      } else {
        filtro.selecionados = 1;
        this.filtros[filtro.model] = filtro.modelValue;
      }

      var _drop = this.elem.nativeElement.querySelector('#dd-' + id);
      var _icon = this.elem.nativeElement.querySelector('#icon-' + id);
      _drop.classList.remove('show-dropdown-filtros');
      _icon.classList.remove('close-dropdown-icon');
      this.sendFilters();
    } else {
      if (filtro.modelValue === '') {
        filtro.selecionados = 0;
        delete this.filtros[filtro.model];
      } else {
        filtro.selecionados = 1;
        this.filtros[filtro.model] = filtro.modelValue;
      }

      if (isOk === true) {
        var _drop = this.elem.nativeElement.querySelector('#dd-' + id);
        var _icon = this.elem.nativeElement.querySelector('#icon-' + id);
        _drop.classList.remove('show-dropdown-filtros');
        _icon.classList.remove('close-dropdown-icon');
        this.sendFilters();
      }
    }
  }

  setValorFiltroPesquisa(filtro): void {
    if (filtro.modelValue === '') {
      filtro.selecionados = 0;
      delete this.filtros[filtro.model];
      this.sendFilters();
    } else {
      filtro.selecionados = 1;
      this.filtros[filtro.model] = filtro.modelValue;
      this.sendFilters();
    }
  }

  setValorFiltroDate(filtro, value): void {
    if (value) {
      let formatedDate = value.getFullYear() + "-" + (value.getMonth() + 1) + "-" + value.getDate();
      filtro.modelValue = formatedDate
      this.filtros[filtro.model] = filtro.modelValue;
      filtro.selecionados = 1;
    } else {
      filtro.modelValue = null;
      this.filtros[filtro.model] = filtro.modelValue;
      filtro.selecionados = 0;
    }
  }

  setValorFiltroRangeDate(filtro, value): void {
    if (value !== null) {
      let startFormatedDate = value[0].getFullYear() + "-" + (value[0].getMonth() + 1) + "-" + value[0].getDate();
      let endFormatedDate = value[1].getFullYear() + "-" + (value[1].getMonth() + 1) + "-" + value[1].getDate();
      this.filtros[filtro.model[0]] = startFormatedDate
      this.filtros[filtro.model[1]] = endFormatedDate
      filtro.selecionados = 1;
    } else {
      this.filtros[filtro.model[0]] = null
      this.filtros[filtro.model[1]] = null
      filtro.selecionados = 0;
    }
  }

  cleanFilters(): void {
    localStorage.removeItem('dashboard_filtros');
    this.filtros = {};
    this.filtroCache = {};

    this.arrayFiltrosRecebidos.forEach(filtro => {
      if (filtro.modelValue) {
        filtro.modelValue = '';
      }
      if (filtro.selectedDate) {
        filtro.selectedDate = null;
      }

      filtro.selecionados = 0;
      this.tipoFiltroSelecionado = '';

      if (filtro.tipo === 'tree') {
        this.emitEventToCategoriesTree.next();
      }

      //logica para resetar a lista quando o filtro tiver uma dependencia quando limpar o filtro
      if (filtro.hasDependecy && filtro.hasDependecy === true) {
        filtro.data = [];
      }
    });

    // remove menu projetos
    this.hiddenMenuProjetos();

    // seta opção default na unidade de negócio
    this.setDefaultValueMenuUnidade();

    var checkIcons = this.elem.nativeElement.querySelectorAll('.filtro-checked-milvus');
    checkIcons.forEach(icon => {
      icon.parentNode.removeChild(icon);
    });

    localStorage.removeItem('additionalClients');
    this.closeAllDropdowns();
    this.filtrosChange.emit(this.filtros);
    localStorage.removeItem('nome_filtro');

    // prevent cache from dashboard to tickets list with avanced filters
    if (this.tipoFiltro === 'listagemFiltroAvancadoTickets') {
      localStorage.removeItem('listagemTickets');
    }

    if (this.tipoFiltro === 'listagemDashboardDashboard') {
      localStorage.removeItem('listagemDashboardDashboard');
    }

    //this.reloadComponent(); 
  }

  sendFilters(): void {
    this.closeAllDropdowns();
    /* colocar regra de add aqui */
    if (this.tipoFiltro === 'listagemDashboardDashboard') {
      localStorage.removeItem('additionalClients')
      let encodedObjectAsString: string = SimpleCrypt.encodeDefault("szHYxd7tz", this.additionalClients)
      localStorage.setItem('additionalClients', encodedObjectAsString)
    } else if (this.tipoFiltro === 'listagemTickets') {
      localStorage.removeItem('additionalClients')
      let encodedObjectAsString: string = SimpleCrypt.encodeDefault("szHYxd7tz", this.additionalClients)
      localStorage.setItem('additionalClients', encodedObjectAsString)
    }
    this.filtrosChange.emit(this.filtros);
    this.salvarFiltros();
    //this.reloadComponent();
  }

  salvarFiltros() {
    if (this.tipoFiltro === 'listagemDashboardTicket') {
      this.localStorageService.storageItemSimple('listagemDashboardTicketFiltros', this.filtros)
    }
  }

  //sem uso aparentemente
  /*reloadComponent() {
    if (this.tipoFiltro === 'listagemFiltroAvancadoTickets' || this.tipoFiltro === 'listagemTickets' || this.tipoFiltro === 'listagemTicketsKamban') {
      //this.appRef.tick();      
    }
  }*/

  filtrarApiClientePaginado(search, filtro, indexFiltro): void {
    this.clienteService.getDropDownCliente({ filtro_body: { is_ativo: true, filtro: search } }, { total_registros: 50 })
      .subscribe(results => {
        filtro.data = results.dropDown.lista;
        if (this.additionalClients.length > 0 && this.tipoFiltro === 'listagemAtualizacaoAutomatica') {
          this.additionalClients.forEach(el => {
            if (filtro.data.find(cliente => cliente.id === el.id)) {
              //já existe cliente no dropdown
            } else {
              filtro.data.unshift(el)
            }
          });
        }

        if (localStorage.getItem('additionalClients')) {
          let addClient: any = SimpleCrypt.decodeDefault('szHYxd7tz', localStorage.getItem('additionalClients'))
          addClient.forEach(el => {
            if (filtro.data.find(cliente => cliente.id === el.id)) {
              //já existe cliente no dropdown
            } else {
              filtro.data.push(el)
            }
          });
        }

        if (localStorage.getItem('additionalClientsListagemDispositivo')) {
          let addClient: any = SimpleCrypt.decodeDefault('szHYxd7tz', localStorage.getItem('additionalClientsListagemDispositivo'))
          addClient.forEach(el => {
            if (filtro.data.find(cliente => cliente.id === el)) {
              //já existe cliente no dropdown
            } else {
              this.newSubs = this.clienteService.getById(addClient[0]).subscribe(
                {
                  next: (result) => {
                    if (result.cliente) {
                      filtro.data.unshift({
                        "id": result.cliente.id,
                        "text": result.cliente.nome_fantasia,
                        "is_esporadico": result.cliente.is_esporadico,
                      });
                    }
                  }, error: (error) => {
                    this.toastr.show(
                      error,
                      "",
                      environment.toastrConfig("danger")
                    );
                  }
                }
              )
            }
          });
        }

        if (results.dropDown.meta.paginate.last_page > 1) {
          filtro.data.push({ id: 'ver_mais', text: this.translate.instant('VER_MAIS') });
        }

        //verifica itens selecionados durante a filtragem
        this.zone.runOutsideAngular(() => {
          setTimeout(() => {
            filtro.data.forEach(item => {
              item.text = item.text.normalize("NFD").replace(/\p{Diacritic}/gu, "");

              if (typeof (this.filtros[filtro.model]) === 'object') {
                if (this.filtros[filtro.model].indexOf(item.id) !== -1) {
                  var _opt = this.elem.nativeElement.querySelector('#opt-' + indexFiltro + '-' + item.id);
                  var verify = document.getElementsByClassName(indexFiltro + '-' + item.id);
                  if (_opt && verify.length === 0) {
                    this.adicionaCheckDropdown(indexFiltro, item.id, _opt);
                  }
                }
              } else if (typeof (this.filtros[filtro.model]) === 'number' || typeof (this.filtros[filtro.model]) === 'string') {
                if (this.filtros[filtro.model] === item.id) {
                  var _opt = this.elem.nativeElement.querySelector('#opt-' + indexFiltro + '-' + item.id);
                  var verify = document.getElementsByClassName(indexFiltro + '-' + item.id);
                  if (_opt && verify.length === 0) {
                    this.adicionaCheckDropdown(indexFiltro, item.id, _opt);
                  }
                }
              } else if (this.filtroCache && typeof (this.filtroCache[this.tipoFiltro]) === 'object') {
                if ((this.filtroCache[this.tipoFiltro].cliente && this.filtroCache[this.tipoFiltro].cliente.indexOf(item.id) !== -1 && filtro.selecionados)) {
                  var _opt = this.elem.nativeElement.querySelector('#opt-' + indexFiltro + '-' + item.id);
                  var verify = document.getElementsByClassName(indexFiltro + '-' + item.id);
                  if (_opt && verify.length === 0) {
                    this.adicionaCheckDropdown(indexFiltro, item.id, _opt);
                  }
                }
              }
            });
          }, 200);
        })
      }, error => {
        this.toastr.show(error, '', environment.toastrConfig('danger'));
      })
  }

  filtrarApi(search, filtro, indexFiltro): void {
    if (filtro.model === 'filtro_cliente' || filtro.model === 'cliente_id' || filtro.model === 'clientes' || filtro.model === 'cliente' || filtro.model === 'clienteId') {
      this.filtrarApiClientePaginado(search, filtro, indexFiltro);
    } else {
      //verifica itens selecionados durante a filtragem
      filtro.data.forEach(item => {
        item.text = item.text.normalize("NFD").replace(/\p{Diacritic}/gu, "");
        if (typeof (this.filtros[filtro.model]) === 'object') {
          if (this.filtros[filtro.model].indexOf(item.id) !== -1) {
            var _opt = this.elem.nativeElement.querySelector('#opt-' + indexFiltro + '-' + item.id);
            var verify = document.getElementsByClassName(indexFiltro + '-' + item.id);
            if (_opt && verify.length === 0) {
              this.adicionaCheckDropdown(indexFiltro, item.id, _opt);
            }
          }
        } else if (typeof (this.filtros[filtro.model]) === 'number' || typeof (this.filtros[filtro.model]) === 'string') {
          if (this.filtros[filtro.model] === item.id) {
            var _opt = this.elem.nativeElement.querySelector('#opt-' + indexFiltro + '-' + item.id);
            var verify = document.getElementsByClassName(indexFiltro + '-' + item.id);
            if (_opt && verify.length === 0) {
              this.adicionaCheckDropdown(indexFiltro, item.id, _opt);
            }
          }
        }
      });
    }
  }

  verMaisDropdownClientes(filtro): void {
    this.pagina++;
    filtro.data.splice(filtro.data.length - 1, 1);
    this.newSubs = this.clienteService.getDropDownCliente({ is_ativo: true }, { total_registros: 50, pagina: this.pagina })
      .subscribe(results => {
        results.dropDown.lista.forEach(item => {
          filtro.data.push(item);
        });
        if (results.dropDown.meta.paginate.current_page !== results.dropDown.meta.paginate.last_page.toString()) {
          filtro.data.push({ id: 'ver_mais', text: this.translate.instant('VER_MAIS') });
        }
      }, error => {
        this.toastr.show(error, '', environment.toastrConfig('danger'));
      })
  }

  setFiltroAvancadoTickets() {
    this.arrayFiltrosRecebidos = [
      { nome: this.translate.instant('FILTRO_AVANCADO'), tipo: 'filtro_avancado' },
    ]
  }

  getDataFromTipoFiltro(): void {
    switch (this.tipoFiltro) {
      case 'listagemFiltroAvancadoTickets':
        this.setFiltroAvancadoTickets();
        break;
    }
  }

  verificaFiltroRelatorioFaturamentoSinteticoAnalitico() {
    if (this.tipoFiltro === 'filtroRelatorioFaturamentoSintetico') {
      return this.arrayFiltrosRecebidos[1].selecionados < 1 && this.arrayFiltrosRecebidos[6].selecionados < 1;
    } else if (this.tipoFiltro === 'filtroRelatorioFaturamentoAnalitico') {
      return this.arrayFiltrosRecebidos[7].selecionados < 1 && this.arrayFiltrosRecebidos[7].selecionados < 1;
    } else if (this.tipoFiltro === 'listagemLicencasSoftware') {
      return !(this.arrayFiltrosRecebidos[0].selecionados > 0 || this.arrayFiltrosRecebidos[2].selecionados > 0);
    }
    return false
  }

  verificaTipoFiltro() {
    return this.tipoFiltro === 'filtroRelatorioFaturamentoAnalitico' || this.tipoFiltro === 'filtroRelatorioFaturamentoSintetico'
  }

  verificaVisibilidade(filtro): boolean {
    if (this.isRelatorios) {
      switch (this.isRelatorios) {
        case 23:
          if (filtro.model !== 'clientes' && filtro.model !== 'dispositivos' && filtro.model !== 'unidade_negocio') {
            return false;
          } else {
            return true;
          }
        case 19:
          if (filtro.model === 'status' || filtro.model === 'unidade_negocio') {
            return false;
          } else {
            return true;
          }
        case 20:
          if (filtro.model === 'status') {
            return false;
          } else {
            return true;
          }
        case 14:
          if (filtro.model !== 'clientes') {
            return false;
          } else {
            return true;
          }
        case 22:
          if (filtro.model !== 'clientes' && filtro.model !== 'unidade_negocio') {
            return false;
          } else {
            return true;
          }
        case 24:
          if (filtro.model === 'dispositivos' || filtro.model === 'filtro_equipe' || filtro.model === 'tipoChamado') {
            return false;
          } else {
            return true;
          }
        case 27:
          if (filtro.model !== 'clientes' && filtro.model !== 'tecnicos' && filtro.model !== 'unidade_negocio') {
            return false;
          } else {
            return true;
          }
        case 25:
          if (filtro.model === 'tecnicos' || filtro.model === 'dispositivos' || filtro.model === 'categorias' || filtro.model === 'filtro_categoria_primaria' || filtro.model === 'filtro_equipe' || filtro.model === 'tipoChamado' || filtro.model === 'unidade_negocio') {
            return false;
          } else {
            return true;
          }
        case 17:
          if (filtro.model === 'filtro_equipe') {
            if (this.filtros['tecnicos']) {
              return false;
            } else {
              return true;
            }
          } else if (filtro.model === 'tecnicos') {
            if (this.filtros['tecnicos']) {
              return true;
            } else if (this.filtros['filtro_equipe']) {
              return false;
            } else {
              return true;
            }
          } else {
            return true;
          }
        case 4:
          if (filtro.model === 'unidade_negocio') {
            return false;
          } else {
            return true;
          }
        default:
          return true;
      }
    } else {
      return true;
    }
  }
}