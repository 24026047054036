import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { TokenService } from '../core/token/token.service';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BibliotecaService {

  private apiUrl = environment.apiGestorUrlLocal;
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', 'authorization': this.tokenService.getToken(), 'session': this.tokenService.getSessionUsuario() }),
    params: {}
  };

  constructor(private http: HttpClient, private tokenService: TokenService) { }

  adicionaArquivoBaseConhecimento(file): Observable<any> {
    this.httpOptions.params = "";
    return this.http.post(`${this.apiUrl}api/base-conhecimento/adicionaArquivo`, file,
      { headers: new HttpHeaders({ 'authorization': this.tokenService.getToken(), 'session': this.tokenService.getSessionUsuario() }) })
      .pipe(
        catchError(this.handleError)
      );
  }

  adicionarArtigoBaseConhecimento(payload): Observable<any> {
    this.httpOptions.params = "";
    return this.http.post(`${this.apiUrl}api/base-conhecimento/artigo`, payload, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  adicionarCategoriaBaseConhecimento(payload): Observable<any> {
    this.httpOptions.params = "";
    return this.http.post(`${this.apiUrl}api/base-conhecimento/categoria`, payload, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  alterarArtigoBaseConhecimento(payload): Observable<any> {
    this.httpOptions.params = "";
    return this.http.put(`${this.apiUrl}api/base-conhecimento/artigo/${payload.id}`, payload, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      )
  }

  alterarCategoriaBaseConhecimento(payload): Observable<any> {
    this.httpOptions.params = "";
    return this.http.put(`${this.apiUrl}api/base-conhecimento/categoria/${payload.id}`, payload, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      )
  }

  buscarArvoreArtigosBaseConhecimento(filtro?, params?): Observable<any> {
    this.httpOptions.params = (params) ? params : "";
    return this.http.get(`${this.apiUrl}api/base-conhecimento/arvore${(filtro) ? '?filtro=' + filtro : ""}`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  buscarTagsBaseConhecimento(params): Observable<any> {
    this.httpOptions.params = params;
    return this.http.get(`${this.apiUrl}api/tags`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  dropdownCategoriasBaseConhecimento(): Observable<any> {
    this.httpOptions.params = "";
    return this.http.get(`${this.apiUrl}api/base-conhecimento/categoria/dropdown`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  excluirCategoriaBaseConhecimento(id): Observable<any> {
    this.httpOptions.params = "";
    return this.http.delete(`${this.apiUrl}api/base-conhecimento/categoria/${id}`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  listagemArtigosBaseConhecimento(filtro): Observable<any> {
    this.httpOptions.params = "";
    return this.http.post(`${this.apiUrl}api/base-conhecimento/listagem-chamado`, filtro, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  obterArtigoBaseConhecimento(id): Observable<any> {
    this.httpOptions.params = "";
    return this.http.get(`${this.apiUrl}api/base-conhecimento/artigo/${id}`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  deletarArtigoBaseConhecimento(id): Observable<any> {
    this.httpOptions.params = "";
    return this.http.delete(`${this.apiUrl}api/base-conhecimento/artigo/${id}`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  somaTotalComentarios(id): Observable<any> {
    this.httpOptions.params = "";
    return this.http.put(`${this.apiUrl}api/base-conhecimento/total-comentarios/${id}`, '', this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('Ops! Ocorreu um erro:', error.error.message);
    } else {
      console.error(
        `Backend retornou o código ${error.status}, ` +
        `o body é: ${error.error}`);
    }
    return throwError(
      error.error);
  }
}
