import {
  HttpHeaders,
  HttpClient,
  HttpErrorResponse,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { TokenService } from "../core/token/token.service";
import { iGetFilasChat } from "../interfaces/dashboards/dashboards-user-status.interface";

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  private apiUrl = environment.apiGestorUrlLocal;
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', 'authorization': this.tokenService.getToken(), 'session': this.tokenService.getSessionUsuario() }),
    params: {}
  };

  constructor(private http: HttpClient, private tokenService: TokenService) { }

  obterChatTecnico(chat_id): Observable<any> {
    this.httpOptions.params = '';
    return this.http.get(`${this.apiUrl}api/chat/obter-chat-tecnico/${chat_id}`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  getChatsPorStatus(filtros, pagina, total_registros): Observable<any> {
    this.httpOptions.params = {};
    return this.http.post(`${this.apiUrl}api/chat/listagem-meus-chats?pagina=${pagina}&total=${total_registros}&is_paginate=true`, filtros, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  getChatConversa(id: any): Observable<any> {
    this.httpOptions.params = "";
    return this.http.get(`${this.apiUrl}api/chat/buscarConversaContato/${id}`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  getTabulacao(widgetId: any, mostrar_inativos: boolean): Observable<any> {
    this.httpOptions.params = { mostrar_inativos: mostrar_inativos };
    return this.http.get(`${this.apiUrl}api/tabulacao/dropdown/${widgetId}`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  getContadores(): Observable<any> {
    this.httpOptions.params = '';
    return this.http.get(`${this.apiUrl}api/chat/contadores-meus-chats`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  finalizarChat(data: any): Observable<any> {
    this.httpOptions.params = ''
    return this.http.put(`${this.apiUrl}api/chat/finalizar-chat`, data, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  adicionarAnexo(anx: any): Observable<any> {
    let httpoptions = { headers: new HttpHeaders({ 'authorization': this.tokenService.getToken(), 'session': this.tokenService.getSessionUsuario() }) }
    let formData = new FormData();
    formData.append('file', anx);
    return this.http.post(`${this.apiUrl}api/chat/anexo`, formData, httpoptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  adicionarAnexoAudio(anx: any): Observable<any> {
    let httpoptions = { headers: new HttpHeaders({ 'authorization': this.tokenService.getToken(), 'session': this.tokenService.getSessionUsuario() }) }
    let formData = new FormData();
    formData.append('file', anx);
    return this.http.post(`${this.apiUrl}api/chat/anexo`, formData, httpoptions)
      .pipe(
        catchError(this.handleError)
      );
  }


  getFilasChat(param: any): Observable<iGetFilasChat> {
    if (param) {
      this.httpOptions.params = param;
    } else {
      this.httpOptions.params = "";
    }
    return this.http.get<iGetFilasChat>(`${this.apiUrl}api/fila-atendimento/dropDownFilaAtendimento/${(param.widget_id) ? param.widget_id : '__all__'}`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  getTecnicosOnOff(): Observable<any> {
    this.httpOptions.params = '';
    return this.http.get(`${this.apiUrl}api/chat/listagemTecnicoOnOff`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  transferirFilas(params, idchat): Observable<any> {
    this.httpOptions.params = '';
    return this.http.put(`${this.apiUrl}api/chat/transferir-fila/` + idchat, params, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      )
  }

  transferirTecnico(params, idchat): Observable<any> {
    this.httpOptions.params = '';
    return this.http.put(`${this.apiUrl}api/chat/transferir-tecnico/` + idchat, params, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      )
  }

  iniciarAtendimento(chatId: string): Observable<any> {
    this.httpOptions.params = ''
    return this.http.put(`${this.apiUrl}api/chat/iniciar-atendimento/${chatId}`, '', this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  buscarChamadosCliente(clienteID: number): Observable<any> {
    this.httpOptions.params = '';
    return this.http.get(`${this.apiUrl}api/chat/dropDownChamados/${clienteID}`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  vincularChamado(chatID: string, data: any): Observable<any> {
    this.httpOptions.params = '';
    return this.http.put(`${this.apiUrl}api/chat/vincular-chamado/${chatID}`, data, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  associarContato(chatData: any): Observable<any> {
    this.httpOptions.params = '';
    return this.http.post(`${this.apiUrl}api/chat/associarChatContato`, chatData, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  iniciarConversaWhatsappChamado(payload): Observable<any> {
    this.httpOptions.params = "";
    return this.http.post(`${this.apiUrl}api/chat/iniciar-conversa-whatsapp`, payload, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  listAllContatos(params, payload): Observable<any> {
    this.httpOptions.params = params;
    return this.http.post(`${this.apiUrl}api/clienteContato/list`, payload, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  updateContato(payload): Observable<any> {
    this.httpOptions.params = "";
    return this.http.put(`${this.apiUrl}api/clienteContato`, payload, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  createContato(payload): Observable<any> {
    this.httpOptions.params = "";
    return this.http.post(`${this.apiUrl}api/clienteContato`, payload, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  getByTelefone(telefone): Observable<any> {
    this.httpOptions.params = "";
    return this.http.get(`${this.apiUrl}api/clienteContato/telefone/${telefone}`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  obterIntegracao(): Observable<any> {
    this.httpOptions.params = "";
    return this.http.get(`${this.apiUrl}api/whatsapp/obter-integracao`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  obterIntegracaoAtiva(): Observable<any> {
    this.httpOptions.params = "";
    return this.http.get(`${this.apiUrl}api/whatsapp/obter-integracao-ativa`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  finalizarChatTecnico(payload): Observable<any> {
    this.httpOptions.params = "";
    return this.http.put(`${this.apiUrl}api/chat/finalizar-chat`, payload, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  mensagemChatLida(id: any): Observable<any> {
    this.httpOptions.params = "";
    return this.http.put(`${this.apiUrl}api/chat/marcar-como-lido/${id}`, '', this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  finalizarChatEncerrarTicketTecnico(payload): Observable<any> {
    this.httpOptions.params = "";
    return this.http.put(`${this.apiUrl}api/chat/encerrar-ticket-finalizar-chat`, payload, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  finalizarChatCriaTicket(payload): Observable<any> {
    this.httpOptions.params = "";
    return this.http.post(`${this.apiUrl}api/chat/finalizar-chat-cria-ticket`, payload, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  // Typebot
  listarChatWorkflows(params, filtros): Observable<any> {
    this.httpOptions.params = params;
    return this.http.post(`${this.apiUrl}api/typebot/listar`, filtros, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  criarWorkflowChat(payload): Observable<any> {
    this.httpOptions.params = "";
    return this.http.post(`${this.apiUrl}api/typebot/criar`, payload, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  deletarWorkflowChat(id): Observable<any> {
    this.httpOptions.params = "";
    return this.http.delete(`${this.apiUrl}api/typebot/${id}`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  copiarWorkflow(id): Observable<any> {
    this.httpOptions.params = "";
    return this.http.post(`${this.apiUrl}api/typebot/duplicar/${id}`, null, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  editarWorkflow(payload): Observable<any> {
    this.httpOptions.params = "";
    return this.http.put(`${this.apiUrl}api/typebot/atualizar`, payload, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  obterWorkflow(id): Observable<any> {
    this.httpOptions.params = "";
    return this.http.get(`${this.apiUrl}api/typebot/${id}`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  dropDownWorkflowChat(params?): Observable<any> {
    this.httpOptions.params = params ? params : null;
    return this.http.post(`${this.apiUrl}api/typebot/dropdown`, null, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  vincularFilaWorkflow(payload): Observable<any> {
    this.httpOptions.params = null;
    return this.http.put(`${this.apiUrl}api/typebot/vincular-fila`, payload, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  dropdownNumerosWorkflow(filtros): Observable<any> {
    this.httpOptions.params = null;
    return this.http.post(`${this.apiUrl}api/typebot/integracao/dropdown-numeros`, filtros, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  dropdownWorkflowsWhatsapp(integracao_id): Observable<any> {
    this.httpOptions.params = null;
    return this.http.post(`${this.apiUrl}api/typebot/integracao/dropdown-workflows`, integracao_id, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  vincularFilaWhatsapp(payload): Observable<any> {
    this.httpOptions.params = null;
    return this.http.put(`${this.apiUrl}api/typebot/vincular-fila-wz`, payload, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  vincularChatContato(payload): Observable<any> {
    this.httpOptions.params = null;
    return this.http.put(`${this.apiUrl}api/chat/vincular-contato`, payload, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  aceitarCobrança(): Observable<any> {
    this.httpOptions.params = null;
    return this.http.post(`${this.apiUrl}api/typebot/aceitar-custo`, { is_aceito: true }, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  verificarAceiteCobrança(): Observable<any> {
    this.httpOptions.params = null;
    return this.http.get(`${this.apiUrl}api/typebot/aceitar-custo`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('Ops! Ocorreu um erro:', error.error.message);
    } else {
      console.error(
        `Backend retornou o código ${error.status}, ` +
        `o body é: ${error.error}`);
    }
    return throwError(
      error.error);
  }

}
