import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
declare let OpusMediaRecorder: any;

const workerOptions = {
  OggOpusEncoderWasmPath: 'https://cdn.jsdelivr.net/npm/opus-media-recorder@latest/OggOpusEncoder.wasm',
  WebMOpusEncoderWasmPath: 'https://cdn.jsdelivr.net/npm/opus-media-recorder@latest/WebMOpusEncoder.wasm'
};

@Component({
  selector: 'chat-viewer-audio',
  templateUrl: './chat-viewer-audio.component.html',
  styleUrls: ['./chat-viewer-audio.component.scss']
})
export class ChatViewerAudioComponent implements OnInit {

  record;
  recording = false;
  url;
  error;
  blob: any;

  constructor(
    private domSanitizer: DomSanitizer,
    public activeModal: NgbActiveModal,
    public translate: TranslateService,
  ) { }

  ngOnInit() {
    window.MediaRecorder = OpusMediaRecorder;
  }


  sanitize(url: string) {
    return this.domSanitizer.bypassSecurityTrustUrl(url);
  }

  initiateRecording() {
    this.record = null;
    this.url = null;
    this.recording = true;
    let mediaConstraints = {
      video: false,
      audio: true
    };
    navigator.mediaDevices.getUserMedia(mediaConstraints).then(this.successCallback.bind(this), (error) => this.warningOneDeviceNeed(error))
  }

  warningOneDeviceNeed(error) {
    Swal.fire({
      title: this.translate.instant('AVISO'),
      text: this.translate.instant('AVISO_CHAT_VIEWER_AUDIO'),
      icon: 'warning',
      showCancelButton: false,
      confirmButtonText: 'OK',
    }).then((result) => {
      this.recording = false;
    })
  }

  successCallback(stream) {
    var options = {
      mimeType: "audio/ogg",
    };
    this.record = new OpusMediaRecorder(stream, options, workerOptions);
    this.record.start();
    this.record.ondataavailable = (e) => {
      this.processRecording(e.data);
    };
  }

  stopRecording() {
    this.recording = false;
    this.record.stop();
    this.record.stream.getTracks().forEach(i =>  i.stop());
  }
  
  processRecording(blob) {    
    this.blob = blob;
    this.url = URL.createObjectURL(blob);
  }

  errorCallback(error) {
    this.error = '';
  }

  enviaAudio() {  
    var blob = new Blob([this.blob], { type: "audio/ogg" });      
       
    var result = {
      blob: blob,
      url: this.url,
    }        
    this.activeModal.close(result)
  }

  close() {
    this.activeModal.close();
  }
}