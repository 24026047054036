import { Component, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-button",
  templateUrl: "./button.component.html",
  styleUrls: ["./button.component.scss"],
  providers: [],
})
export class ButtonComponent {
  @Input() buttonAction: string = "custom";
  @Input() buttonClass?: string;
  @Input() buttonColor?: string = 'success';
  @Input() buttonDisabled?: boolean;
  @Input() buttonId?: string;
  @Input() buttonIcon?: string;
  @Input() buttonTooltip?: string;
  @Input() buttonTooltipPlacement?: string;
  @Input() buttonName?: string;
  @Input() buttonOptions?: any;
  @Input() buttonReadonly?: boolean;
  @Input() buttonSize: string = "small";
  @Input() buttonType: string = "base";
  @Output() buttonClicked = new EventEmitter<any>();

  onButtonClick() {
    this.buttonClicked.emit(true);
  }
}
