import { Component, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { ChatService } from 'src/app/services/chat.service';
import { ClienteContatoService } from 'src/app/services/cliente-contato.service';
import { ClienteService } from 'src/app/services/cliente.service';
import { environment } from 'src/environments/environment';
import { ChatContatoComponent } from '../chat-contato/chat-contato.component';

@Component({
  selector: 'app-modal-chat-contato',
  templateUrl: './modal-chat-contato.component.html',
  styleUrls: ['./modal-chat-contato.component.scss']
})
export class ModalChatContatoComponent implements OnInit, OnDestroy {

  @Input() chatData;
  listaContato: Array<Object> = [];
  listaCliente: any;
  configPagination = environment.getPaginationDefault();
  pagination;
  filtros = {
    filtro_body: {
    },
  };
  searchText: any;
  selector: string;
  newSubs: Subscription;

  constructor(
    public activeModal: NgbActiveModal,
    private toastr: ToastrService,
    public translate: TranslateService,
    private modalService: NgbModal,
    private chatService: ChatService,
    private clienteService: ClienteService,
    private contatoService: ClienteContatoService,
    private elemRef: ElementRef,
  ) {
    this.selector = elemRef.nativeElement.tagName.toLowerCase();
  }

  ngOnInit(): void {
    document.getElementsByTagName(this.selector)[0]['style']['zoom'] = '0.85'
    this.getClientes()
  }

  ngOnDestroy(): void {
    this.newSubs?.unsubscribe();
  }

  listagemContatos(filtros?): void {
    if (filtros) {
      this.filtros.filtro_body = filtros;
    }
    this.newSubs = this.contatoService.getListagemContatos(this.configPagination, this.filtros).subscribe(
      (result) => {
        //console.log(result.lista)
        result.lista.map(item => {
          item.nome = item.nome ? item.nome : item.descricao
          item.cliente_nome = this.listaCliente[item.cliente_id] ? this.listaCliente[item.cliente_id].text : "";
        })
        this.listaContato = result.lista;
        this.pagination = result.meta.paginate;
      },
      (error) => {
        console.log(error);
        this.toastr.show(error, "", environment.toastrConfig("danger"));
      }
    );
  }

  getClientes(): void {
    this.newSubs = this.clienteService.getClientes('')
      .subscribe(result => {
        let listaClientes = {}
        result.dropDown.forEach(element => {
          listaClientes[element.id] = element;
        });
        this.listaCliente = listaClientes
        this.listagemContatos()
      }, error => {
        this.toastr.show(error, '', environment.toastrConfig('danger'));
      })
  }

  paginarListagem(pagina): void {
    this.configPagination.pagina = pagina;
    this.listagemContatos();
  }

  filterBy(filtro): void {
    if (this.configPagination.order_by === filtro) {
      this.configPagination.is_descending = !this.configPagination
        .is_descending;
    } else {
      this.configPagination.order_by = filtro;
      this.configPagination.is_descending = true;
    }
    this.listagemContatos();
  }

  aplicarFiltros(filtros): void {
    this.listagemContatos(filtros);
  }

  buscarContatos(dados): void {
    let filtro = { "descricao": dados }
    //$scope.filtersContato.filtro_body.filtro_pesquisa = dados.filtro_pesquisa;
    this.listagemContatos(filtro);
  };

  novoContato(): void {
    const modalRef = this.modalService.open(ChatContatoComponent, {
      scrollable: false,
      windowClass: "modal-custom-milvus",
      size: "medio",
      keyboard: false,
      backdrop: "static",
    });
    modalRef.result.then((result) => {
      if (result.id) {
        this.associarContato(result);
      }
    })
  }

  associarContato(contato): void {
    let data = {
      "objAssociarChatContato": {
        "chat_id": this.chatData._id,
        "contato_id": contato.id
      }
    };
    this.newSubs = this.chatService.associarContato(data)
      .subscribe((res) => {
        this.toastr.show(this.translate.instant('CONTATO_ASSOCIADO_SUCESSO'), '', environment.toastrConfig('success'));
        this.close(contato);
      }, (error) => {
        console.log(error);
        this.toastr.show(error, '', environment.toastrConfig('danger'));
      })
  }

  close(result?) {
    this.activeModal.close(result);
  }

}
