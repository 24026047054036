import { Router } from '@angular/router';
import { UntypedFormArray, Validators } from '@angular/forms';
import { AbstractControl, UntypedFormBuilder } from '@angular/forms';
import { UntypedFormGroup } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { FiltroPadraoService } from './../../../services/filtro-padrao.service';
import { Component, ElementRef, OnInit, OnDestroy, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

export interface FiltroModel {
  id?: number;
  nome: string;
  tela: string;
  visibilidade_id: number;
  condicoes: CondicaoFiltro[];
};

interface CondicaoFiltro {
  entidade_id: number;
  campo_id: number;
  operador_id: number;
  valor: any;
  tipo_campo_id: number;
  campo_chave: string;
};

interface FiltroForm extends UntypedFormGroup {
  value: FiltroModel;
  controls: {
    id?: AbstractControl;
    nome: AbstractControl;
    tela: AbstractControl;
    visibilidade_id: AbstractControl;
    condicoes: AbstractControl;
  };
}

@Component({
  selector: 'app-modal-filtros',
  templateUrl: './modal-filtros.component.html',
  styleUrls: ['./modal-filtros.component.scss']
})
export class ModalFiltrosComponent implements OnInit, OnDestroy {
  @Input() filtro_id: number;
  selector: string;
  newSubs: Subscription;
  filtroForm: FiltroForm;
  create: boolean = true;
  condicoesRecebidas: any[] = [];

  constructor(
    public modal: NgbActiveModal,
    private elemRef: ElementRef,
    private translate: TranslateService,
    private filtrosService: FiltroPadraoService,
    private toastr: ToastrService,
    private fb: UntypedFormBuilder,
    private router: Router
  ) { this.selector = this.elemRef.nativeElement.tagName.toLowerCase(); }

  ngOnInit(): void {
    document.getElementsByTagName(this.selector)[0]['style']['zoom'] = '0.85';
    this.verificaCriacao();
  }

  ngOnDestroy(): void {
    this.newSubs?.unsubscribe();
  }

  verificaCriacao(): void {
    if (this.filtro_id) {
      this.create = false;
      this.newSubs = this.filtrosService.getFiltro(this.filtro_id).subscribe(
        (results) => {
          this.buildFormEdicao(results);
        }, (error) => {
          this.toastr.show(error.error_msg, '', environment.toastrConfig('danger'));
        }
      )
      this.buildFormCriacao();
    } else {
      this.buildFormCriacao();
    }
  }

  buildFormCriacao(): void {
    this.filtroForm = this.fb.group({
      nome: ['', Validators.required],
      tela: [''],
      visibilidade_id: [null, Validators.required],
      condicoes: this.fb.array([], Validators.required)
    }) as FiltroForm;
  }

  buildFormEdicao(filtro: FiltroModel): void {
    this.filtroForm = this.fb.group({
      nome: [filtro.nome, Validators.required],
      tela: [''],
      visibilidade_id: [filtro.visibilidade_id, Validators.required],
      condicoes: this.fb.array([], Validators.required)
    }) as FiltroForm;
    this.patchCondicoesFormEditar(filtro.condicoes);
  }

  novaCondicao(): void {
    const control = <UntypedFormArray>this.filtroForm.get('condicoes');
    control.push(this.patchCondicoesForm());
    this.condicoesRecebidas.push({
      entidade_id: null,
      campo_id: null,
      operador_id: null,
      valor: null,
      tipo_campo_id: null,
      campo_chave: null,
      data_dinamica: false,
      blockOperator: false
    });
  }

  patchCondicoesForm(): UntypedFormGroup {
    return this.fb.group({
      entidade_id: [undefined, Validators.required],
      campo_id: [undefined, Validators.required],
      operador_id: [undefined, Validators.required],
      valor: [undefined, Validators.required],
      tipo_campo_id: [null],
      campo_chave: [undefined],
      data_dinamica: [false],
      blockOperator: [false]
    });
  }

  patchCondicoesFormEditar(condicoes: any[]): void {
    const control = <UntypedFormArray>this.filtroForm.get('condicoes');
    condicoes.forEach(
      (condicao, index) => {
        this.condicoesRecebidas.push({
          entidade_id: condicao.entidade.id,
          campo_id: condicao.campo.id,
          operador_id: condicao.operador.id,
          valor: this.verificaValor(condicao.valor, condicao.campo.tipo_campo_id),
          tipo_campo_id: condicao.campo.tipo_campo_id,
          campo_chave: condicao.campo.chave,
          data_dinamica: this.verificaDataExata(condicao.valor, condicao.campo.tipo_campo_id),
          blockOperator: false
        });
        control.push(
          this.fb.group({
            entidade_id: [condicao.entidade.id, Validators.required],
            campo_id: [condicao.campo.id, Validators.required],
            operador_id: [condicao.operador.id, Validators.required],
            valor: [this.verificaValor(condicao.valor, condicao.campo.tipo_campo_id), Validators.required],
            tipo_campo_id: [condicao.campo.tipo_campo_id],
            campo_chave: [condicao.campo.chave],
            data_dinamica: [this.verificaDataExata(condicao.valor, condicao.campo.tipo_campo_id)],
            blockOperator: [false]
          })
        );
        this.verificaTipoDataExata(condicao.valor, condicao.campo.tipo_campo_id, control.controls[index]);
      }
    );
  }

  verificaValor(valor, tipo_campo_id): any {
    if (typeof (valor) === 'object') {
      return valor;
    } else if (tipo_campo_id === 7) {
      return { id: valor, text: '' };
    } else if (tipo_campo_id === 9) {
      return [valor];
    } else {
      return valor;
    }
  }

  verificaDataExata(valor, tipo_campo_id): boolean {
    if (tipo_campo_id === 3) {
      if (valor.includes('$')) {
        return true;
      }

      return false;
    }

    return false
  }

  verificaTipoDataExata(valor, tipo_campo_id, form): void {
    let _isDataExata = this.verificaDataExata(valor, tipo_campo_id);

    if (_isDataExata) {
      this.newSubs = this.filtrosService.getDropdownValores('$datas')
        .subscribe(
          (results) => {
            results.forEach(types => {
              if (types.chave === valor && types.tipo === 'periodo') {
                form.patchValue({ blockOperator: true });
              }
            });
          }
        );
    }
  }

  removeCondicao(indice: number): void {
    const configAlerta = {
      title: this.translate.instant('DESEJA_DELETAR_CONDICAO'),
      text: null,
      icon: 'error',
      confirmButtonText: this.translate.instant('SIM'),
      cancelButtonText: this.translate.instant('NAO'),
    };

    environment.disparaAlerta(configAlerta).then((result) => {
      if (result.value) {
        const control = <UntypedFormArray>this.filtroForm.get('condicoes');
        control.removeAt(indice);
        this.condicoesRecebidas.splice(indice, 1);
      }
    });
  }

  close(): void {
    if (this.filtroForm.touched) {
      const configAlert = {
        title: this.translate.instant('HA_ALTERACOES_NAO_SALVAS'),
        text: this.translate.instant('PROSSEGUIR_MESMO_ASSIM?'),
        icon: 'warning',
        confirmButtonText: this.translate.instant('SIM'),
        cancelButtonText: this.translate.instant('NAO'),
      };

      environment.disparaAlerta(configAlert).then((result) => {
        if (result.value) {
          this.modal.close();
        }
      });
    } else {
      this.modal.close();
    }
  }

  patchCampoCondicao(campo: any, condicao: UntypedFormGroup): void {
    condicao.patchValue({
      tipo_campo_id: (campo) ? campo.tipo_campo_id : null,
      campo_id: (campo) ? campo.id : null,
      campo_chave: (campo) ? campo.chave : undefined,
      valor: null
    });
  }

  checkCondicao(condicao): any {
    let _aux = [];

    condicao.map((cond) => {
      if (cond.tipo_campo_id === 7 && typeof (cond.valor) === 'object' && cond.valor.hasOwnProperty('id')) {
        cond.valor = cond.valor.id;
      }
      _aux.push(cond)
    })

    return _aux;
  }

  salvaFiltro(filtro: FiltroModel): void {
    let payload = filtro;
    payload.tela = this.router.url;

    payload.condicoes = this.checkCondicao(payload.condicoes);

    if (this.create) {
      this.criaFiltro(payload);
    } else {
      this.atualizaFiltro(payload);
    }
  }

  criaFiltro(payload: FiltroModel): void {
    this.newSubs = this.filtrosService.criarFiltro(payload).subscribe(
      (results) => {
        this.toastr.show(this.translate.instant('SALVO_SUCESSO'), '', environment.toastrConfig('success'));
        this.modal.close(results);
      }, (error) => {
        this.toastr.show(error.error_msg, '', environment.toastrConfig('danger'));
      }
    );
  }

  atualizaFiltro(payload: FiltroModel): void {
    this.newSubs = this.filtrosService.updateFiltro(this.filtro_id, payload).subscribe(
      (results) => {
        this.toastr.show(this.translate.instant('SALVO_SUCESSO'), '', environment.toastrConfig('success'));
        this.modal.close(results);
      }, (error) => {
        this.toastr.show(error.error_msg, '', environment.toastrConfig('danger'));
      }
    )
  }

  excluirFiltro(): void {
    const configAlert = {
      title: this.translate.instant('DESEJA_EXCLUIR_FILTRO'),
      text: null,
      icon: 'error',
      confirmButtonText: this.translate.instant('SIM'),
      cancelButtonText: this.translate.instant('NAO')
    };

    environment.disparaAlerta(configAlert).then(
      (result) => {
        if (result.isConfirmed) {
          this.newSubs = this.filtrosService.deleteFiltro(this.filtro_id).subscribe(
            (results) => {
              this.toastr.show(this.translate.instant('DELETADO_SUCESSO'), '', environment.toastrConfig('success'));
              this.modal.close({ type: 'delete', filter: this.filtro_id });
            }, (error) => {
              this.toastr.show(error.error_msg, '', environment.toastrConfig('danger'));
            }
          );
        }
      }
    )
  }

  trackByIndex(index): any {
    return index;
  }

  changeDateType(event, form): void {
    form.patchValue({
      data_dinamica: event.target.value === 'true'
    });
  }

  patchValueDinamicDate(value, form): void {
    if (value !== undefined) {
      form.patchValue({ valor: value.chave });

      if (value.tipo === 'periodo') {
        form.patchValue({ operador_id: 5, blockOperator: true });
      } else {
        form.patchValue({ blockOperator: false });
      }
    } else {
      form.patchValue({ valor: null, blockOperator: false });
    }
  }

  checkValue(event, tipo_campo_id) {
    if (tipo_campo_id === 7) {
      return { valor: { id: event, text: '' } };
    } else {
      return { valor: event }
    }
  }
}
