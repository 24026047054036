import { AfterViewInit, Component, ElementRef, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-modal-iframe',
    templateUrl: './modal-iframe.component.html',
    styleUrls: ['./modal-iframe.component.scss'],
})
export class ModalIframeComponent implements AfterViewInit {
    
    @Input() url!: string
    @Input() header!: string
    @ViewChild('iframe') iframe!: ElementRef;
    carregando: boolean = true;
    listener: any;

    constructor(
        private activeModal: NgbActiveModal,
        private elem: ElementRef,
        private renderer: Renderer2
    ) {
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            const modalContent = this.renderer.selectRootElement('.modal-content', true);
            const modalXl = this.renderer.selectRootElement('.modal-xl', true);
            const modalBody = this.renderer.selectRootElement('.modal-body', true);
            
            this.renderer.addClass(modalContent, 'modal-content-iframe');
            this.renderer.addClass(modalXl, 'modal-xl-iframe');
            this.renderer.removeClass(modalXl, 'modal-xl');
            this.renderer.addClass(modalBody, 'modal-body-iframe');
            (this.iframe.nativeElement as HTMLIFrameElement).src = this.url;
            this.listener = (this.iframe.nativeElement as HTMLIFrameElement).addEventListener('load', () => {
                this.carregando = false;
            })
        }, 700);
    }

    close(): void {
        (this.iframe.nativeElement as HTMLIFrameElement).removeEventListener('load', this.listener);
        this.activeModal.close();
    }

}
