import { MoedaPipe } from './moeda.pipe';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EllipsisPipe } from './elilipsis.pipe';
import { TableSearchPipe } from './tablefilter.pipe';
import { nullPipe } from './null.pipe'
import { removeAcentos } from './removeAcentos.pipe';
import { PhonePipe } from './phone.pipe';
import { BackslashPipe } from './backslash.pipe';
import { ByteConverterPipe } from './byteConverter.pipe';
import { TimeLeftPipe } from './time-left.pipe';
import { ConcatByCommaPipe } from './concat-by-comma.pipe';
import { StripHtmlPipe } from './stripHtml.pipe';

@NgModule({
  declarations: [
    TableSearchPipe, 
    EllipsisPipe,
    nullPipe,
    removeAcentos,
    PhonePipe,
    BackslashPipe,
    ByteConverterPipe,
    MoedaPipe,
    TimeLeftPipe,
    ConcatByCommaPipe,
    StripHtmlPipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    TableSearchPipe,
    EllipsisPipe,
    nullPipe,
    removeAcentos,
    PhonePipe,
    BackslashPipe,
    ByteConverterPipe,
    MoedaPipe,
    TimeLeftPipe,
    ConcatByCommaPipe,
    StripHtmlPipe
  ],
  providers: [
    TableSearchPipe, 
    EllipsisPipe,
    nullPipe,
    removeAcentos,
    PhonePipe,
    BackslashPipe,
    ByteConverterPipe,
    MoedaPipe,
    TimeLeftPipe,
    ConcatByCommaPipe,
    StripHtmlPipe
  ]
})
export class PipesModule { }
