import { CategorizacaoClientePadrao } from './../../../models/ticket-abertura.model';
import { Component, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { TokenService } from 'src/app/core/token/token.service';
import { ChamadosService } from 'src/app/services/chamados.service';
import { TarefaService } from 'src/app/services/tarefa.service';
import { UsuariosService } from 'src/app/services/usuarios.service';
import { environment } from 'src/environments/environment';
import swal from "sweetalert2";
declare let moment: any;
import { ModalTarefaHorasComponent } from "src/app/components/modais/modal-tarefa-horas/modal-tarefa-horas.component";
import { ModalFinalizarTarefaComponent } from '../modal-finalizar-tarefa/modal-finalizar-tarefa.component';
import { ModalReagendamentoComponent } from '../modal-reagendamento/modal-reagendamento.component';
import { Subscription } from 'rxjs';
import { IntegrationService } from 'src/app/services/integration.service';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { QuillEditorComponent } from 'ngx-quill';
import { QuillButtonOptionsModel } from 'src/app/models/quill/quillButtonOptions.model';
import { QuillConfigModel } from 'src/app/models/quill/quillConfig.model';
import { TicketService } from 'src/app/services/ticket.service';

@Component({
  selector: 'app-modal-tarefa',
  templateUrl: './modal-tarefa.component.html',
  styleUrls: ['./modal-tarefa.component.scss']
})
export class ModalTarefaComponent implements OnInit, OnDestroy {
  newTaskForm: UntypedFormGroup = new UntypedFormGroup({
    description: new UntypedFormControl("", Validators.required),
  });
  create = true;
  files: any = environment.getUploadConfig().text()
  tarefa;
  hoje: Date = new Date();
  fileSelected = [];
  uploadFileType: any = environment.getUploadConfig().docImg();
  uploadFileSize: any = environment.getUploadConfig().sizeMaxBytes;

  @Input() chamadoData?;
  @Input() info_tarefa?;
  @Input() minha_agenda_tarefa?;
  @Input() hasIntegrationGoogleSchedule?;
  @Input() hasIntegrationOutlookSchedule?;

  listagemTarefas = [];
  isFromChamado = false;
  visualizacao;
  configPaginationTarefas = environment.getPaginationDetalhes();
  configPaginationAssociadas = environment.getPaginationDetalhes();
  tarefasAssociadasTecnico = [];
  paginacaoTarefas;
  paginacaoAssociadas;
  horasExec;
  minutosExec;
  descricao = "";
  descricao_html = "";
  horasTotais: any;
  horasTask;
  selector: string;
  newSubs: Subscription;
  chamado: any;
  listGuests: Array<any> = [];
  local: string = '';
  createEvent: boolean = true;
  videoConf: boolean = false;
  linkMeet: any = `<span>${this.translate.instant('NAO_POSSUI')}</span>`;
  isEventGoogleSchedule: boolean = false;
  linkTeams: any = `<span>${this.translate.instant('NAO_POSSUI')}</span>`;
  isEventOutlookCalendar: boolean = false;
  destinatarios = [];
  quillConfigEditor: QuillConfigModel;
  editor: QuillEditorComponent;
  salvando: boolean = false;
  visualizaSelectsLaterais: boolean = true;

  constructor(
    public modal: NgbActiveModal,
    private toastr: ToastrService,
    public translate: TranslateService,
    private tarefaService: TarefaService,
    private _sanitizer: DomSanitizer,
    private chamadosService: ChamadosService,
    private usuarioService: UsuariosService,
    private tokenService: TokenService,
    private modalService: NgbModal,
    private elemRef: ElementRef,
    private integrationService: IntegrationService,
    private ticketService: TicketService
  ) {
    this.selector = this.elemRef.nativeElement.tagName.toLowerCase();
  }

  ngOnInit(): void {
    document.getElementsByTagName(this.selector)[0]['style']['zoom'] = '0.85';
    this.tarefa = {
      titulo: '',
      cliente_id: '',
      chamado_id: '',
      tecnico_id: '',
      tipo_tarefa_id: '',
      tempo_tarefa: '',
      data_agendamento: '',
      data_limite: '',
      descricao: '',
      descricao_html: '',
      file: [],
      hangout_link: '',
      teams_link: '',
      is_minha_agenda: false
    };
    this.setInfoTarefa();
    this.setMinhaAgendaTarefa(this.minha_agenda_tarefa);
    this.setChamadoData();

    if(this.info_tarefa || this.minha_agenda_tarefa) {
      this.newSubs = this.ticketService.getAberturaTicketPadrao()
        .subscribe({
          next: (results) => {
            if(results && results.categorizacaoClientePadrao) {
              this.visualizaSelectsLaterais = results.categorizacaoClientePadrao.solicitante_cliente;
            }
          }, error: (error) => {
            console.error(error);
            this.toastr.show(error && error.error_msg ? error.error_msg : error, '', environment.toastrConfig('danger'));
          }
        });
    }
  }

  validAuthTecnicoGoogle(tarefa) : void {    
    let params = {
      userId: tarefa.tecnico_id.id
    }
    this.newSubs = this.integrationService.validAuthTecnicoGoogle(params).subscribe(
      {
        next: (result) => {
          if (result && result.hasOwnProperty('active')) {
            this.hasIntegrationGoogleSchedule = result.active;
          }      
          if (!this.hasIntegrationGoogleSchedule) {            
            this.validAuthTecnicoOutlook(tarefa);
          }
        }, error: (error) => {
          console.error(error);          
        }
      }
    )
  }

  validAuthTecnicoOutlook(tarefa) : void {    
    let params = {
      userId: tarefa.tecnico_id.id
    }
    this.newSubs = this.integrationService.validAuthTecnicoOutlook(params).subscribe(
      {
        next: (result) => {
          if (result && result.hasOwnProperty('active')) {
            this.hasIntegrationOutlookSchedule = result.active;
          }      
        }, error: (error) => {
          console.error(error);          
        }
      }
    )
  }

  validTitle(): boolean {
    return this.tarefa.titulo.length < 4;
  }

  setChamadoData(): void {
    if (this.chamadoData) {    
      this.tarefa.cliente_id = (this.chamadoData.dados.cliente_id) 
        ? { id: this.chamadoData.dados.cliente_id.id, text: this.chamadoData.dados.cliente_id.text }
        : null;
      this.tarefa.chamado_id = this.chamadoData.dados.chamado_id;
      this.listaTarefas(this.chamadoData.dados.chamado_id);

      this.isFromChamado = true;
      if (this.chamadoData.tarefa) {
        if (this.chamadoData.somenteVisualizar) {
          this.visualizacao = true;
        } else {
          this.visualizacao = false;
        }
        this.create = false;
        this.newSubs = this.tarefaService.getTarefaById(this.chamadoData.tarefa.tarefa_id)
          .subscribe(results => {
            this.listGuests = results.tarefa.convidados ? results.tarefa.convidados.split(';') : [];
            this.tarefa.hangout_link = results.tarefa.hangout_link ? results.tarefa.hangout_link : '';
            this.tarefa.teams_link = results.tarefa.teams_link ? results.tarefa.teams_link : '';
            this.setLinkHangout();
            this.setLinkTeams();
            this.isEventGoogleSchedule = results.tarefa.kind ? this.checkKindGoogle(results.tarefa.kind) : false;
            this.isEventOutlookCalendar = results.tarefa.kind ? this.checkKindOutlook(results.tarefa.kind) : false;
            this.local = results.tarefa.local ? results.tarefa.local : '';
            this.tarefa.status_id = results.tarefa.status_id;
            this.tarefa.tarefa_id = results.tarefa.tarefa_id;
            this.tarefa.tecnico_id = { id: results.tarefa.tecnico_id, text: results.tarefa.nome_tecnico };
            this.tarefa.tipo_tarefa_id = { id: results.tarefa.tipo_tarefa_id, text: results.tarefa.tipo_descricao };
            this.tarefa.data_agendamento = (results.tarefa.data_agendamento !== null) ? new Date(results.tarefa.data_agendamento) : null;
            this.tarefa.data_limite = (results.tarefa.data_limite !== null) ? new Date(results.tarefa.data_limite) : null;
            this.tarefa.descricao = results.tarefa.descricao;
            this.tarefa.descricao_html = (results.tarefa.descricao_html !== null) ? results.tarefa.descricao_html : results.tarefa.descricao;
            //this.tarefa.descricao_html = results.tarefa.descricao;
            this.descricao = results.tarefa.descricao;
            this.newTaskForm.patchValue({
              description: (results.tarefa.descricao_html !== null) ? results.tarefa.descricao_html : results.tarefa.descricao
            });
            this.tarefa.data_criacao = new Date(results.tarefa.data_criacao);
            this.tarefa.horarios = results.tarefa.logsHorasTrabalhadas;
            let totalHoras = 0
            this.tarefa.horarios.forEach(h => {
              let teste = moment(h.horas_trabalhadas, 'h:mm').format('HH:mm:ss')
              totalHoras += moment.duration(teste).asSeconds()
            });
            this.tarefa.titulo = results.tarefa.titulo ? results.tarefa.titulo : ''
            this.secondsToHour(totalHoras)
            if (this.chamadoData.tarefa.tempo_tarefa) {
              this.tarefa.tempo_tarefa = this.chamadoData.tarefa.tempo_tarefa;
              this.horasTask = this.chamadoData.tarefa.tempo_tarefa;
            }

            this.buscarTarefasAgendadas();            
            this.checkIntegrations(this.tarefa);

            this.newSubs = this.tarefaService.getAnexosTarefa(this.tarefa.tarefa_id)
              .subscribe(results => {
                results.lista.forEach(file => {
                  var typeFile = file.file_name.substring(file.file_name.length - 3);
                  this.fileSelected.push({
                    name: file.file_name,
                    type: typeFile,
                    url: this._sanitizer.bypassSecurityTrustResourceUrl(file.link_file),
                    id: file.id
                  });
                })
              }, error => {
                console.error(error)
                this.toastr.show(error, '', environment.toastrConfig('danger'));
              });
          }, error => {
            console.error(error)
            this.toastr.show(error, '', environment.toastrConfig('danger'));
          })
      } else {
        this.visualizacao = false;
      }
    } else {
      this.visualizacao = false;
    }

    if (this.create === true && !this.minha_agenda_tarefa && !this.info_tarefa) {
      var user = this.tokenService.getUsuario();
      this.tarefa.tecnico_id = { id: user.id, text: user.nome };
      this.tarefa.data_agendamento = this.tarefa.data_agendamento === null || this.tarefa.data_agendamento === undefined || this.tarefa.data_agendamento === '' ? new Date() : this.tarefa.data_agendamento;
      this.checkIntegrations(this.tarefa);
    }


    this.setQuillEditorConfig();
  }

  setQuillEditorConfig() {
    this.quillConfigEditor = {
      magicUrl: true,
      imageDrop: true,
      ImageResize: { modules: ["Resize", "DisplaySize", "Toolbar"] },
      toolbar: {
        container: [
          ["bold", "italic", "underline", "strike"],
          [
            { align: [] },
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" },
          ],

          ["code-block", "link", "image"],
        ],
        handlers: { emoji: function () {} },
      },
      "toolbar-custom-button": new QuillButtonOptionsModel(),
      "emoji-toolbar": true,
      "emoji-textarea": false,
      "emoji-shortname": true,

      // mention: {
      //   allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
      //   onSelect: (item, insertItem) => {
      //     const editor = this.editor.quillEditor;
      //     insertItem(item);
      //     editor.insertText(editor.getLength() - 1, "", "user");
      //   },
      //   source: (searchTerm, renderList) => {
      //     const values = [
      //       { id: 1, value: "Fredrik Sundqvist" },
      //       { id: 2, value: "Patrik Sjölin" },
      //     ];

      //     if (searchTerm.length === 0) {
      //       renderList(values, searchTerm);
      //     } else {
      //       const matches = [];

      //       values.forEach((entry) => {
      //         if (
      //           entry.value.toLowerCase().indexOf(searchTerm.toLowerCase()) !==
      //           -1
      //         ) {
      //           matches.push(entry);
      //         }
      //       });
      //       renderList(matches, searchTerm);
      //     }
      //   },
      // },
      keyboard: {
        bindings: {
          // shiftEnter: {
          //   key: 13,
          //   shiftKey: true,
          //   handler: (range, context) => {
          //     // Handle shift+enter
          //     console.log("shift+enter")
          //   }
          // },
          enter: {
            key: 13,
            handler: (range, context) => {
              return true;
            },
          },
        },
      },
    };
  }
  
  checkImage(html: string): boolean {
    return html && html.match(/<img/) && html.match(/<img/).length > 0;
  }

  checkFields(formName: string): boolean {
    if (this.newTaskForm.get(formName).value) {
      let _cleanText = (this.newTaskForm.get(formName).value.text)
        ? this.newTaskForm.get(formName).value.text.replaceAll('/n', '')
        : this.newTaskForm.get(formName).value.replaceAll('/n', '');
      let _html = this.newTaskForm.get(formName).value && this.newTaskForm.get(formName).value.hasOwnProperty('html') ? this.newTaskForm.get(formName).value.html : '';
      if ((!_html || _cleanText.length <= 5) && !this.checkImage(_html)) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }
  
  setInfoTarefa(): void {
    if (this.info_tarefa) {      
      let tarefa_recebida_id = this.info_tarefa.Id || this.info_tarefa.tarefa_id;
      if (tarefa_recebida_id) {
        this.create = false;
        this.visualizacao = false
        this.isFromChamado = false
        this.newSubs = this.tarefaService.getTarefaById(tarefa_recebida_id)
          .subscribe(
            (results) => {
              this.listGuests = results.tarefa.convidados ? results.tarefa.convidados.split(';') : [];
              this.tarefa.hangout_link = results.tarefa.hangout_link ? results.tarefa.hangout_link : '';
              this.setLinkHangout();
              this.setLinkTeams();
              this.isEventGoogleSchedule = results.tarefa.kind ? this.checkKindGoogle(results.tarefa.kind) : false;
              this.isEventOutlookCalendar = results.tarefa.kind ? this.checkKindOutlook(results.tarefa.kind) : false;
              this.local = results.tarefa.local ? results.tarefa.local : '';
              this.tarefa.status_id = results.tarefa.status_id;
              this.tarefa.tarefa_id = results.tarefa.tarefa_id;
              this.tarefa.tecnico_id = { id: results.tarefa.tecnico_id, text: results.tarefa.nome_tecnico };
              this.tarefa.tipo_tarefa_id = { id: results.tarefa.tipo_tarefa_id, text: results.tarefa.tipo_descricao };
              this.tarefa.data_agendamento = (this.info_tarefa.StartTime !== null) ? new Date(this.info_tarefa.StartTime) : null;
              this.tarefa.descricao = results.tarefa.descricao;
              this.tarefa.descricao_html = (results.tarefa.descricao_html !== null) ? results.tarefa.descricao_html : results.tarefa.descricao;
              this.newTaskForm.patchValue({
                description: this.tarefa.descricao_html
              });
              this.descricao = results.tarefa.descricao;
              this.descricao_html = (results.tarefa.descricao_html !== null) ? results.tarefa.descricao_html : results.tarefa.descricao;
              this.tarefa.chamado_id = results.tarefa.chamado;
              this.tarefa.cliente_id = { id: results.tarefa.cliente_id, text: results.tarefa.nome_cliente };
              this.tarefa.horarios = results.tarefa.logsHorasTrabalhadas;
              let totalHoras = 0;
              this.tarefa.horarios.forEach(h => {
                let teste = moment(h.horas_trabalhadas, 'h:mm').format('HH:mm:ss')
                totalHoras += moment.duration(teste).asSeconds()
              });
              this.tarefa.titulo = results.tarefa.titulo ? results.tarefa.titulo : this.translate.instant('SEM_TITULO');
              this.secondsToHour(totalHoras)
              if (this.info_tarefa.TempoTarefa) {
                this.tarefa.tempo_tarefa = this.info_tarefa.TempoTarefa;
                this.horasTask = this.info_tarefa.TempoTarefa;
              }
              this.listaTarefas(this.tarefa.chamado_id);
              this.checkIntegrations(this.tarefa);
              this.buscarTarefasAgendadas();

              this.newSubs = this.tarefaService.getAnexosTarefa(this.tarefa.tarefa_id)
                .subscribe(results => {
                  results.lista.forEach(file => {
                    var typeFile = file.file_name.substring(file.file_name.length - 3);
                    this.fileSelected.push({
                      name: file.file_name,
                      type: typeFile,
                      url: this._sanitizer.bypassSecurityTrustResourceUrl(file.link_file)
                    });
                  })
                }, (error) => {
                  console.error(error);
                  this.toastr.show(error, '', environment.toastrConfig('danger'));
                });
            }, (error) => {
              console.error(error);
              this.toastr.show(error, '', environment.toastrConfig('danger'));
            })

      } else {
        this.create = true;
        this.visualizacao = false;
        this.isFromChamado = false;
        if (this.info_tarefa.TempoTarefa) {
          this.tarefa.tempo_tarefa = this.chamadoData.tarefa.tempo_tarefa;
          this.horasTask = this.chamadoData.tarefa.tempo_tarefa;
        }
        this.tarefa.tecnico_id = this.info_tarefa.EmployeeId;
        this.tarefa.data_agendamento = this.info_tarefa.StartTime;
        
        this.checkIntegrations(this.tarefa);

        this.newSubs = this.usuarioService.getUsuarioById(this.info_tarefa.EmployeeId ? this.info_tarefa.EmployeeId : this.tokenService.getUsuario().id).subscribe(
          (result) => {
            this.tarefa.tecnico_id = { id: result.usuario.id, text: result.usuario.nome };
          }, (error) => {
            this.toastr.show(error, '', environment.toastrConfig('danger'));
            console.error(error)
          }
        )
      }
    }
  }

  setMinhaAgendaTarefa(minha_agenda_tarefa: any): void {
    if (minha_agenda_tarefa) {
      if (minha_agenda_tarefa.hasOwnProperty('Id')) {
        this.create = false;
        this.visualizacao = false
        this.isFromChamado = false
        this.newSubs = this.tarefaService.getTarefaById(minha_agenda_tarefa.Id)
          .subscribe(
            (results) => {
              this.tarefa.is_minha_agenda = true;
              this.listGuests = results.tarefa.convidados ? results.tarefa.convidados.split(';') : [];
              this.tarefa.hangout_link = results.tarefa.hangout_link ? results.tarefa.hangout_link : '';
              this.setLinkHangout();
              this.setLinkTeams();
              this.isEventGoogleSchedule = results.tarefa.kind ? this.checkKindGoogle(results.tarefa.kind) : false;
              this.isEventOutlookCalendar = results.tarefa.kind ? this.checkKindOutlook(results.tarefa.kind) : false;
              this.local = results.tarefa.local ? results.tarefa.local : '';
              this.tarefa.status_id = results.tarefa.status_id;
              this.tarefa.tarefa_id = results.tarefa.tarefa_id;
              this.tarefa.tecnico_id = { id: results.tarefa.tecnico_id, text: results.tarefa.nome_tecnico };
              this.tarefa.tipo_tarefa_id = { id: results.tarefa.tipo_tarefa_id, text: results.tarefa.tipo_descricao };
              this.tarefa.data_agendamento = (minha_agenda_tarefa.StartTime !== null) ? minha_agenda_tarefa.StartTime : null;
              this.tarefa.descricao = results.tarefa.descricao;
              this.tarefa.descricao_html = (results.tarefa.descricao_html !== null) ? results.tarefa.descricao_html : results.tarefa.descricao;
              this.descricao = results.tarefa.descricao;
              this.newTaskForm.patchValue({
                description: (results.tarefa.descricao_html !== null) ? results.tarefa.descricao_html : results.tarefa.descricao
              });
              this.tarefa.chamado_id = results.tarefa.chamado
              this.tarefa.cliente_id = { id: results.tarefa.cliente_id, text: results.tarefa.nome_cliente }
              this.tarefa.horarios = results.tarefa.logsHorasTrabalhadas;
              let totalHoras = 0
              this.tarefa.horarios.forEach(h => {
                let teste = moment(h.horas_trabalhadas, 'h:mm').format('HH:mm:ss')
                totalHoras += moment.duration(teste).asSeconds()
              });
              this.tarefa.titulo = results.tarefa.titulo ? results.tarefa.titulo : ''
              this.secondsToHour(totalHoras);
              if (minha_agenda_tarefa.TempoTarefa) {
                this.tarefa.tempo_tarefa = minha_agenda_tarefa.TempoTarefa;
                this.horasTask = minha_agenda_tarefa.TempoTarefa;
              }
              this.listaTarefas(this.tarefa.chamado_id);              
              this.checkIntegrations(this.tarefa);
              this.newSubs = this.tarefaService.getAnexosTarefa(this.tarefa.tarefa_id)
                .subscribe(results => {
                  results.lista.forEach(file => {
                    var typeFile = file.file_name.substring(file.file_name.length - 3);
                    this.fileSelected.push({
                      name: file.file_name,
                      type: typeFile,
                      url: this._sanitizer.bypassSecurityTrustResourceUrl(file.link_file)
                    });
                  })
                }, (error) => {
                  console.error(error);
                  this.toastr.show(error, '', environment.toastrConfig('danger'));
                })
            }, (error) => {
              console.error(error);
              this.toastr.show(error, '', environment.toastrConfig('danger'));
            })

      } else {
        this.create = true;
        this.visualizacao = false;
        this.isFromChamado = false;
        if (minha_agenda_tarefa.TempoTarefa) {
          this.tarefa.tempo_tarefa = this.chamadoData.tarefa.tempo_tarefa;
          this.horasTask = this.chamadoData.tarefa.tempo_tarefa;
        }
        this.tarefa.data_agendamento = minha_agenda_tarefa.StartTime;        
        this.newSubs = this.usuarioService.getUsuarioById(minha_agenda_tarefa.EmployeeId ? minha_agenda_tarefa.EmployeeId : this.tokenService.getUsuario().id).subscribe(
          (result) => {
            this.tarefa.tecnico_id = { id: result.usuario.id, text: result.usuario.nome };
            this.checkIntegrations(this.tarefa);
          }, (error) => {
            this.toastr.show(error, '', environment.toastrConfig('danger'));
            console.error(error);
          }
        )
      }
    }
  }

  monitoraNovaTarefa() {
    var event = new CustomEvent("nova-tarefa-calendario", { "detail": "Example of an event" });
    document.dispatchEvent(event);
  }

  obterChamadoPorId(id) {
    this.newSubs = this.chamadosService.getChamadoById(id).subscribe(
      (result) => {
        this.chamado = result.chamado ? result.chamado : {}
      }, (error) => {
        console.error(error);
        this.toastr.show(error, '', environment.toastrConfig('danger'));
      }
    )
  }

  ngOnDestroy(): void {
    this.newSubs?.unsubscribe();
  }

  downloadAnexo(anexo) {
    var fileName = anexo.name;
    var a = document.createElement("a");

    document.body.appendChild(a);

    a.setAttribute('style', 'display: none');
    var fileURL = anexo.url.changingThisBreaksApplicationSecurity;
    a.href = fileURL;
    a.target = '_blank';
    a.download = fileName;
    document.body.appendChild(a);

    setTimeout(() => {
      a.click();
      window.URL.revokeObjectURL(fileURL);
    })
  }

  verificaHoras(event) {
    this.tarefa.tempo_tarefa = event;
  }

  onPaste(e) {
    return e.clipboardData.getData('Text') !== ''
  }

  triggerFileInput(): void {
    let fileInput = document.getElementById('fileInputTarefa')
    fileInput.click()
  }

  onFileDropped(event) {
    this.changeFile(event)
  }

  changeFile(file: any): void {
    if (file.length > 0 && file.length < 6) {
      for (let _file of file) {
        var mime = require('mime');
        var tipo = mime.getType(_file.name);

        if (tipo !== null) {
          tipo = tipo.split("/");
        } else {
          // evita problemas com ponto final (.) no meio do nome do arquivo
          let fileNameParts = file.name.split(".");
          let lastPart = fileNameParts[fileNameParts.length - 1];
          tipo[1] = lastPart;
        }

        tipo[1] = environment.convertUploadType(tipo[1]);

        var suportado = this.uploadFileType.indexOf(tipo[1]);

        if (_file && _file.size <= this.uploadFileSize && suportado !== -1) {
            var reader = new FileReader();
            reader.readAsDataURL(_file);
            reader.onload = (event) => {
              this.fileSelected.push({
                name: _file.name,
                type: _file.type,
                url: this._sanitizer.bypassSecurityTrustResourceUrl(event.target.result.toString())
              });
            }
            this.tarefa.file.push(_file);
        }
        else {
          this.toastr.show(this.translate.instant('ARQUIVO_NAO_SUPORTADO_OU_EXCEDEU_LIMITE'), '', environment.toastrConfig('danger'));
        }
      }
    } else if(file.length >= 6) {
      this.toastr.show(this.translate.instant('QNT_ANEXOS_EXCEDIDO'), '', environment.toastrConfig('danger'));
    }
  }

  excluirAnexo(index): void {
    swal
      .fire({
        title: this.translate.instant('DESEJA_DELETAR_ANEXO'),
        icon: "question",
        focusConfirm: true,
        showCancelButton: true,
        confirmButtonText: this.translate.instant("SIM"),
        cancelButtonText: this.translate.instant("NAO")
      })
      .then(result => {
        if (result.isConfirmed) {
          if (this.fileSelected[index].id) {
            this.newSubs = this.tarefaService.deleteAnexoTarefa(this.fileSelected[index].id)
              .subscribe(results => {
                this.fileSelected.splice(index, 1);
                this.tarefa.file.splice(index, 1);
              }, error => {
                console.error(error);
                this.toastr.show(error, '', environment.toastrConfig('danger'));
              })
          } else {
            this.fileSelected.splice(index, 1);
            this.tarefa.file.splice(index, 1);
          }
        }
      });
  }

  verificaSeDesativa(): boolean {
    if (this.visualizacao === false) {
      if (this.isFromChamado === true) {
        if ((this.tarefa.chamado_id && this.tarefa.chamado_id.id)
          && (this.tarefa.tecnico_id && this.tarefa.tecnico_id.id)
          && (this.tarefa.data_agendamento && this.tarefa.data_agendamento !== '')
          && (this.tarefa.tempo_tarefa && this.tarefa.tempo_tarefa !== '')
          && !this.checkFields('description')
          && this.tarefa.titulo && this.tarefa.titulo.length > 3) {
          return false
        } else {
          return true;
        }
      } else {
        if ((this.tarefa.tecnico_id && this.tarefa.tecnico_id.id)
          && (this.tarefa.data_agendamento && this.tarefa.data_agendamento !== '')
          && (this.tarefa.tempo_tarefa && this.tarefa.tempo_tarefa !== '')
          && !this.checkFields('description')
          && this.tarefa.titulo && this.tarefa.titulo.length > 3) {
          return false
        } else {
          return true;
        }
      }
    }

    return false;
  }

  getRandomNumber(min, max) {
    return Math.floor(Math.random() * (+max + 1 - +min)) + +min;
  }

  calculateFinalMeet(date): string {
    if (date) {
      return moment(date).add(moment.duration(this.tarefa.tempo_tarefa)).format("YYYY-MM-DD HH:mm:ss");
    }
  }

  setEventGoogleSchedule(formData: FormData, payload: any): void {
    if (formData) {
      formData.append('data_agendamento_google_inicial', moment.tz(this.tarefa.data_agendamento, this.tokenService.getUsuario().timezone).format("YYYY-MM-DDTHH:mm:ss"));
      formData.append('data_agendamento_google_final', moment.tz(this.calculateFinalMeet(this.tarefa.data_agendamento), this.tokenService.getUsuario().timezone).format("YYYY-MM-DDTHH:mm:ss"));
      formData.append('local', this.local);
      formData.append('convidados', JSON.stringify(this.destinatarios));
      formData.append('google_meet', this.videoConf.toString());
    }

    payload['data_agendamento_google_inicial'] = moment.tz(this.tarefa.data_agendamento, this.tokenService.getUsuario().timezone).format("YYYY-MM-DDTHH:mm:ss");
    payload['data_agendamento_google_final'] = moment.tz(this.calculateFinalMeet(this.tarefa.data_agendamento), this.tokenService.getUsuario().timezone).format("YYYY-MM-DDTHH:mm:ss");
    payload['local'] = this.local;
    payload['convidados'] = this.destinatarios;
    payload['google_meet'] = this.videoConf;
    payload['integracao_google'] = "true";
    payload['integracao_outlook'] = "false";
   }

  setEventOutlookCalendar(formData: FormData, payload: any): void {
    if (formData) {
      formData.append('data_agendamento_outlook_inicial', moment.tz(this.tarefa.data_agendamento, this.tokenService.getUsuario().timezone).format("YYYY-MM-DDTHH:mm:ss"));
      formData.append('data_agendamento_outlook_final', moment.tz(this.calculateFinalMeet(this.tarefa.data_agendamento), this.tokenService.getUsuario().timezone).format("YYYY-MM-DDTHH:mm:ss"));
      formData.append('local', this.local);
      formData.append('convidados', JSON.stringify(this.destinatarios));
      formData.append('outlook_teams', this.videoConf.toString());
    }

    payload['data_agendamento_outlook_inicial'] = moment.tz(this.tarefa.data_agendamento, this.tokenService.getUsuario().timezone).format("YYYY-MM-DDTHH:mm:ss");
    payload['data_agendamento_outlook_final'] = moment.tz(this.calculateFinalMeet(this.tarefa.data_agendamento), this.tokenService.getUsuario().timezone).format("YYYY-MM-DDTHH:mm:ss");
    payload['local'] = this.local;
    payload['convidados'] = this.destinatarios;
    payload['outlook_teams'] = this.videoConf;
    payload['integracao_google'] = "false";
    payload['integracao_outlook'] = "true";
  }

  salvarTarefa(): void {
    this.salvando = true;
    if (this.chamadoData) {
      if (this.chamadoData.dados.is_agendar) {
        this.agendarTarefa(this.tarefa);
        return;
      }
    }
    var formData = new FormData();
    this.tarefa.data_agendamento = new Date(this.tarefa.data_agendamento);

    let descriptions = {
      description: (this.newTaskForm.value.description && this.newTaskForm.value.description.hasOwnProperty('text')) ? this.newTaskForm.value.description.text : this.newTaskForm.value.description,
      description_html: (this.newTaskForm.value.description && this.newTaskForm.value.description.hasOwnProperty('html')) ? this.newTaskForm.value.description.html : this.newTaskForm.value.description
    };

    let payload = {
      tecnico_id: this.tarefa.tecnico_id.id,
      tempo_tarefa: this.tarefa.tempo_tarefa,
      data_agendamento: this.tokenService.convertDateTimezoneStockFormat(this.tarefa.data_agendamento),
      descricao: descriptions.description,
      descricao_html: descriptions.description_html,
      titulo: this.tarefa.titulo,
      integracao_google: this.hasIntegrationGoogleSchedule ? (this.hasIntegrationGoogleSchedule && this.createEvent).toString() : "false",
      integracao_outlook: this.hasIntegrationOutlookSchedule ? (this.hasIntegrationOutlookSchedule && this.createEvent).toString(): "false",
      tipo_tarefa_id: this.tarefa.tipo_tarefa_id && this.tarefa.tipo_tarefa_id.id ? this.tarefa.tipo_tarefa_id.id : null
    }

    formData.append('tecnico_id', this.tarefa.tecnico_id.id);
    formData.append('tempo_tarefa', this.tarefa.tempo_tarefa);
    formData.append('data_agendamento', this.tokenService.convertDateTimezoneStockFormat(this.tarefa.data_agendamento));
    formData.append('descricao', descriptions.description);
    formData.append('descricao_html', descriptions.description_html);
    formData.append('titulo', this.tarefa.titulo);
    formData.append('integracao_google', this.hasIntegrationGoogleSchedule ? (this.hasIntegrationGoogleSchedule && this.createEvent).toString() : "false");
    formData.append('integracao_outlook', this.hasIntegrationOutlookSchedule ? (this.hasIntegrationOutlookSchedule && this.createEvent).toString(): "false");
    formData.append('tipo_tarefa_id', this.tarefa.tipo_tarefa_id && this.tarefa.tipo_tarefa_id.id ? this.tarefa.tipo_tarefa_id.id : null);


    if (this.tarefa.tarefa_id) {
      formData.append('id', this.tarefa.tarefa_id);
      payload['id'] = this.tarefa.tarefa_id;
    }
    if (this.tarefa.cliente_id && this.tarefa.cliente_id.id) {
      formData.append('cliente_id', this.tarefa.cliente_id.id);
      payload['cliente_id'] = this.tarefa.cliente_id.id;
    } else {
      formData.append('cliente_id', null);
      payload['cliente_id'] = null;
    }
    if (this.tarefa.chamado_id && this.tarefa.chamado_id.id) {
      formData.append('chamado_id', this.tarefa.chamado_id.id);
      payload['chamado_id'] = this.tarefa.chamado_id.id;
    }   
    
    if(this.tarefa.file && this.tarefa.file.length > 0) {
      this.tarefa.file.forEach(file => {
        formData.append('file', file, file.name);
      });
    }

    if (this.create) {
      if ((this.createEvent)) {
        if (this.hasIntegrationGoogleSchedule) {
          this.setEventGoogleSchedule(formData, payload);          
        } else if (this.hasIntegrationOutlookSchedule) {
          this.setEventOutlookCalendar(formData, payload); 
        }
      }
      this.newSubs = this.tarefaService.adicionarNovaTarefa(payload)
        .subscribe(result => {
          this.toastr.show(this.translate.instant('SALVO_SUCESSO'), '', environment.toastrConfig('success'));
          if (result) {
            this.monitoraNovaTarefa();
            if (this.tarefa.file && this.tarefa.file.length > 0) {
              var _objTarefa = {
                tarefa: result.tarefa,
                file: this.tarefa.file
              };
              this.newSubs = this.tarefaService.uploadAnexoTarefa(_objTarefa)
                .subscribe(results => {                  
                  this.toastr.show(this.translate.instant('ARQUIVO_ANEXADO'), '', environment.toastrConfig('success'));
                  setTimeout(() => {
                    this.modal.close(result);
                  }, 500);
                }, error => {
                  this.toastr.show(error && error.error_msg ? error.error_msg : error, '', environment.toastrConfig('danger'));
                });
            } else {
              setTimeout(() => {
                this.modal.close(result);
              }, 500);
            }
          }
          
        }, error => {
          this.salvando = false;
          console.error(error);
          this.toastr.show(error && error.error_msg ? error.error_msg : error, '', environment.toastrConfig('danger'));
        })
    } else {
      if ((this.createEvent)) {
        if (this.hasIntegrationGoogleSchedule) {
          this.setEventGoogleSchedule(formData, payload);          
        } else if (this.hasIntegrationOutlookSchedule) {
          this.setEventOutlookCalendar(formData, payload);              
        }
      }
      if (this.listGuests.length > 0 && this.destinatarios.length === 0) {
        formData.append('convidados', JSON.stringify(this.listGuests));
        payload['convidados'] = this.listGuests;        
      }
      this.newSubs = this.tarefaService.alterarTarefa(payload)
        .subscribe(result => {
          this.toastr.show(this.translate.instant('SALVO_SUCESSO'), '', environment.toastrConfig('success'));
          if (this.tarefa.file && this.tarefa.file.length > 0) {
            var _objTarefa = {
              tarefa: { id: this.tarefa.tarefa_id, cliente_id: this.tarefa.cliente_id ? this.tarefa.cliente_id.id : null },
              file: this.tarefa.file
            };
            this.newSubs = this.tarefaService.uploadAnexoTarefa(_objTarefa)
              .subscribe(results => {
                setTimeout(() => {
                  this.modal.close(result);
                }, 500);
                this.toastr.show(this.translate.instant('ARQUIVO_ANEXADO'), '', environment.toastrConfig('success'));
              }, error => {
                this.salvando = false;
                console.error(error)
                this.toastr.show(error && error.error_msg ? error.error_msg : error, '', environment.toastrConfig('danger'));
              });
          } else {
            setTimeout(() => {
              this.salvando = false;
              this.modal.close(result);
            }, 500);
          }
        }, error => {
          this.salvando = false;
          console.error(error)
          this.toastr.show(error, '', environment.toastrConfig('danger'));
        })
    }
  }

  agendarTarefa(agendamento): void {
    var data_agendamento = this.tokenService.convertDateTimezoneStockFormat(agendamento.data_agendamento);
    var _agendamento = {
      chamado: {
        id_chamado: this.chamadoData.dados.chamado_id.id,
        cliente_id: this.chamadoData.dados.cliente_id ? this.chamadoData.dados.cliente_id.id : this.chamadoData.dados.cliente_id,
        data_agendamento: data_agendamento,
        descricao_agendamento: (this.newTaskForm.value.description && this.newTaskForm.value.description.hasOwnProperty('text')) ? this.newTaskForm.value.description.text : this.newTaskForm.value.description,
        descricao_agendamento_html: (this.newTaskForm.value.description && this.newTaskForm.value.description.hasOwnProperty('html')) ? this.newTaskForm.value.description.html : this.newTaskForm.value.description,
        titulo: agendamento.titulo,
        tecnico_id: agendamento.tecnico_id.id,
        tempo_tarefa: this.tarefa.tempo_tarefa,
        integracao_google: this.hasIntegrationGoogleSchedule ? (this.hasIntegrationGoogleSchedule && this.createEvent).toString() : "false",
        integracao_outlook: this.hasIntegrationOutlookSchedule ? (this.hasIntegrationOutlookSchedule && this.createEvent).toString() : "false",
        tipo_tarefa_id: agendamento.tipo_tarefa_id.id
      }
    };

    if (this.createEvent) {
      if (this.hasIntegrationGoogleSchedule) {
        this.setEventGoogleSchedule(null, _agendamento.chamado);       
      } else if (this.hasIntegrationOutlookSchedule) {
        this.setEventOutlookCalendar (null, _agendamento.chamado); 
      }
    }


    this.newSubs = this.chamadosService.updateAgendamento(_agendamento)
      .subscribe(results => {
        if (agendamento.file.length > 0) {
          agendamento.file.forEach(item => {
            var _objTarefa = {
              tarefa: results.tarefa,
              file: item
            };
            this.newSubs = this.tarefaService.uploadAnexoTarefa(_objTarefa)
              .subscribe(results => { }, error => {
                this.toastr.show(error, '', environment.toastrConfig('danger'));
              })
          })
          this.toastr.show(this.translate.instant('SALVO_SUCESSO'), '', environment.toastrConfig('success'));
          this.modal.close('criar');
        } else {
          this.toastr.show(this.translate.instant('SALVO_SUCESSO'), '', environment.toastrConfig('success'));
          this.modal.close('criar');
        }
      }, error => {
        this.salvando = false;
        console.error(error);
        this.toastr.show(error && error.error_msg ? error.error_msg : error, '', environment.toastrConfig('danger'));
      })
  }

  listaTarefas(chamado): void {
    if (chamado) {
      var filtro = {
        filtro_body: {
          chamado_id: chamado.id,
          cliente_id: this.tarefa.cliente_id ? this.tarefa.cliente_id.id : null,
          status_id: "__all__"
        }
      };
      this.newSubs = this.tarefaService.listaTarefasChamado(filtro, this.configPaginationAssociadas)
        .subscribe(results => {
          this.listagemTarefas = results.lista;
          this.paginacaoAssociadas = results.meta.paginate;
        }, error => {
          console.error(error);
          this.toastr.show(error, '', environment.toastrConfig('danger'));
        })
    } else {
      this.listagemTarefas = [];
    }
  }

  setChamdoId(event) : void {
    this.tarefa.chamado_id = event ? event : null;
  }

  setClienteId(event) : void {    
    this.tarefa.cliente_id = event; 
    this.tarefa.chamado_id = null;    
    this.listagemTarefas = [];
  }

  finalizarTarefa(tarefa_id): void {
    this.salvando = true;
    if (this.tarefa.chamado_id && this.tarefa.chamado_id.id) {
      const modalRef = this.modalService.open(ModalFinalizarTarefaComponent, {
        scrollable: false,
        windowClass: "modal-custom-milvus",
        size: "medio",
        keyboard: false,
        backdrop: "static",
      });

      var data = this.tarefa;
      modalRef.componentInstance.tarefa = data;
      modalRef.result.then(
        (result) => {
          if (result) {
            this.salvando = false;
            this.modal.close();
          }
        },
        (reason) => { }
      );
    } else {
      this.newSubs = this.tarefaService.finalizarTarefa({ tarefa: { id: this.tarefa.tarefa_id } })
        .subscribe(results => {
          this.toastr.show(this.translate.instant('FINALIZADO_SUCESSO'), '', environment.toastrConfig('success'));
          this.modal.close('finalizou');
        }, error => {
          this.salvando = false;
          this.toastr.show(error, '', environment.toastrConfig('danger'));
        })
    }
  }

  abrirModalAgendarTarefa(): void {
    this.salvando = true;
    swal
      .fire({
        title: this.translate.instant('DESEJA_REAGENDAR_TAREFA'),
        text: this.translate.instant('DESCRICAO_AGENDAR_TAREFA'),
        icon: "question",
        focusConfirm: true,
        showCancelButton: true,
        confirmButtonText: this.translate.instant("SIM"),
        cancelButtonText: this.translate.instant("NAO")
      })
      .then(result => {
        if (result.isConfirmed) {          
          var _obj = {
            tarefa_id: this.tarefa.tarefa_id,
            tecnico_id: this.tarefa.tecnico_id.id,
            tempo_tarefa: this.tarefa.tempo_tarefa,
            data_agendamento: moment(this.tarefa.data_agendamento).format('YYYY-MM-DD HH:mm'),
            descricao: (this.newTaskForm.value.description.text) ? this.newTaskForm.value.description.text : this.newTaskForm.value.description.replace(/(<([^>]+)>)/ig, ""),
            descricao_html: (this.newTaskForm.value.description.html) ? this.newTaskForm.value.description.html : this.newTaskForm.value.description,
            titulo: this.tarefa.titulo,
            integracao_google: this.isEventGoogleSchedule && this.createEvent ? "true" : "false",
            integracao_outlook: this.isEventOutlookCalendar && this.createEvent ? "true" : "false",
            tipo_tarefa_id: this.tarefa.tipo_tarefa_id && this.tarefa.tipo_tarefa_id.id ? this.tarefa.tipo_tarefa_id.id : null,
            cliente_id: this.tarefa.cliente_id,
            chamado_id: this.tarefa.chamado_id,
            local: this.local,
            convidados: this.listGuests,
            google_meet: this.tarefa.hangout_link ? true : false,
            html_link: this.tarefa.html_link ? true : false,
            is_minha_agenda: this.tarefa.is_minha_agenda,
            is_event_google_schedule: this.isEventGoogleSchedule,
            is_event_outlook_calendar: this.isEventOutlookCalendar,
            create_event: this.createEvent,
            has_integration: this.hasIntegrationGoogleSchedule
          };

          const modalRef = this.modalService.open(ModalReagendamentoComponent, {
            scrollable: false,
            windowClass: "modal-custom-milvus",
            size: "medio",
            keyboard: false,
            backdrop: "static",
          });

          var data = _obj;

          modalRef.componentInstance.tarefa = data;
          modalRef.componentInstance.esconde_selects = 
            this.chamadoData && this.chamadoData.dados.tipo_solicitante_id !== 1 ? true : false;

          modalRef.result.then(
            (result) => {
              this.salvando = false;
              if (result) {
                this.modal.close();
              }
            }
          );
        } else {
          this.salvando = false;
        }
      });
  }

  buscarTarefasAgendadas(event?): void {
    if (this.tarefa.tecnico_id !== '' && this.tarefa.tecnico_id !== null) {
      this.configPaginationTarefas.total_registros = 4;
      var _filtro = {
        filtro_body: {
          tecnico_id: this.tarefa.tecnico_id.id,
        }
      };

      if (this.tarefa.data_agendamento !== '' && this.tarefa.data_agendamento !== null) {
        _filtro.filtro_body['data_agendamento'] = this.tokenService.convertDateTimezoneStockFormat(this.tarefa.data_agendamento)
      }

      this.newSubs = this.tarefaService.buscarTarefasAgendadas(_filtro, this.configPaginationTarefas)
        .subscribe(results => {
          this.tarefasAssociadasTecnico = results.lista;
          this.paginacaoTarefas = results.meta.paginate;
        }, error => {
          console.error(error);
          this.toastr.show(error, '', environment.toastrConfig('danger'));
        }
        )
    }
  }

  paginarListagem(pagina): void {
    this.configPaginationTarefas.pagina = pagina;
    this.buscarTarefasAgendadas();
  }

  paginarAssociadas(pagina): void {
    this.configPaginationAssociadas.pagina = pagina;
    this.listaTarefas(this.tarefa.chamado_id);
  }

  splitName(name): string {
    if (name) {
      var _nome = name.split(' ');
      if (_nome.length > 1) {
        return _nome[0].charAt(0) + _nome[1].charAt(0);
      } else {
        return name.charAt(0);
      }
    }
  }

  deleteTarefa() {
    swal.fire({
      title: this.translate.instant('DESEJA_DELETAR_TAREFA'),
      icon: 'error',
      focusConfirm: true,
      showCancelButton: true,
      confirmButtonText: this.translate.instant("SIM"),
      cancelButtonText: this.translate.instant("NAO"),
    }).then((result) => {
      if (result.isConfirmed) {
        this.newSubs = this.tarefaService.excluirTarefa(this.tarefa.tarefa_id).subscribe(
          (result) => {
            this.toastr.show(this.translate.instant('DELETADO_SUCESSO'), '', environment.toastrConfig('success'));
            this.modal.close();
          }, (error) => {
            console.error(error);
            this.toastr.show(error, '', environment.toastrConfig('danger'));
          }
        )
      }
    });
  }

  verificaRequerido(valor): boolean {
    if (valor !== undefined && valor !== "") {
      return false;
    } else {
      return true;
    }
  }

  showInvalidEmail(): void {
    this.toastr.show(
      this.translate.instant("EMAIL_INVALIDO"),
      "",
      environment.toastrConfig("danger")
    );
  }

  limitaCampo(tipo): void {
    if (tipo === 'horas') {
      if (this.horasExec > 99) {
        this.horasExec = 99;
      }
    } else {
      if (this.minutosExec > 59) {
        this.minutosExec = 59;
      }
    }
  }

  adicionarHoras() {
    const modalRef = this.modalService.open(ModalTarefaHorasComponent, {
      backdrop: "static",
      scrollable: false,
      windowClass: 'modal-custom-milvus',
      keyboard: true,
      size: 'pequeno',
    });

    modalRef.componentInstance.task = this.tarefa
    modalRef.result.then((result) => {
      if (result) {
        let id = this.chamadoData.tarefa.tarefa_id ? this.chamadoData.tarefa.tarefa_id : this.info_tarefa.Id
        this.newSubs = this.tarefaService.getTarefaById(id).subscribe(
          result => {
            this.tarefa.horarios = result.tarefa.logsHorasTrabalhadas
          }, error => {
            this.toastr.show(error, '', environment.toastrConfig('danger'));
          }
        )
      }
    });
  }

  secondsToHour(s) {
    let horas = Math.floor(s / 3600);
    let minutos = Math.floor((s - (horas * 3600)) / 60);
    this.horasTotais = horas + ":" + minutos;
  }

  addGuest(destinatario): void {
    this.destinatarios = [];
    destinatario.forEach((contato) => {
      if (typeof contato === "string") {
        this.destinatarios.push(contato);
      } else {
        this.destinatarios.push(contato[0]);
      }
    });
  }

  setLinkHangout(): void {
    if (this.tarefa.hangout_link) {
      this.linkMeet = this._sanitizer.bypassSecurityTrustHtml(`<label>${this.translate.instant('LINK_VIDEO_CONFERENCIA')}</label><br><a style="color: #0046FA; text-decoration: underline;font-family: Poppins; font-size: 15px; font-weight: 500; line-height: 21px; letter-spacing: 0em; text-align: left;" href='${this.tarefa.hangout_link}' target='_blink'>${this.tarefa.hangout_link}</a>`);
    } else {
      this.linkMeet = this._sanitizer.bypassSecurityTrustHtml(`<label>${this.translate.instant('LINK_VIDEO_CONFERENCIA')}</label><br><span style="opacity: 0.7;">${this.translate.instant('NAO_POSSUI')}</span>`);
    }
  }

  setLinkTeams(): void {
    if (this.tarefa.teams_link) {
      this.linkMeet = this._sanitizer.bypassSecurityTrustHtml(`<label>${this.translate.instant('LINK_VIDEO_CONFERENCIA')}</label><br><a style="color: #0046FA; text-decoration: underline;font-family: Poppins; font-size: 15px; font-weight: 500; line-height: 21px; letter-spacing: 0em; text-align: left;" href='${this.tarefa.teams_link}' target='_blink'>${this.tarefa.teams_link}</a>`);
    } else {
      this.linkMeet = this._sanitizer.bypassSecurityTrustHtml(`<label>${this.translate.instant('LINK_VIDEO_CONFERENCIA')}</label><br><span style="opacity: 0.7;">${this.translate.instant('NAO_POSSUI')}</span>`);
    }
  }

  checkKindGoogle(kind): boolean {
    return kind.includes('calendar#event');
  }

  checkKindOutlook(kind): boolean {
    return kind.includes('outlook#calendar');
  }

  checkIfHasTitle(tarefaAssociada): string {
    return tarefaAssociada.titulo ? tarefaAssociada.titulo : this.translate.instant('SEM_TITULO');
  }

  checkIntegrations(tarefa): void {
    if (tarefa && tarefa.tecnico_id) {
      this.validAuthTecnicoGoogle(tarefa);
    } else {
      this.hasIntegrationGoogleSchedule = false;
      this.hasIntegrationOutlookSchedule = false;
    }
  }

  checkIfDisableTipoTarefa(): boolean {
    return (!this.create) && !(this.hasIntegrationGoogleSchedule && this.isEventGoogleSchedule) && !(this.hasIntegrationOutlookSchedule && this.isEventOutlookCalendar);
  }

  checkIfDisableCliente(): boolean {
    return (this.chamadoData || !this.create) && !(this.hasIntegrationGoogleSchedule && this.isEventGoogleSchedule) && !(this.hasIntegrationOutlookSchedule && this.isEventOutlookCalendar);
  }

  checkIfDisableRelacionarChamado(): boolean {
    if ((this.hasIntegrationGoogleSchedule && this.isEventGoogleSchedule) || (this.hasIntegrationOutlookSchedule && this.isEventOutlookCalendar)) {
      return !this.tarefa.cliente_id;
    } else {
      return !this.tarefa.cliente_id || this.chamadoData || !this.create;
    }
  }

  verificaVisualizacaoSelectCliente(): boolean {
    if(this.chamadoData) {
      if(this.chamadoData.dados.tipo_solicitante_id === 1) {
        return true;
      } else {
        return false;
      }
    } else {
      return this.visualizaSelectsLaterais;
    }
  }
}