<div style="text-align: center;" class="my-3 mx-4">
    <button class="btn btn-dark" (click)="close()"
        style="cursor: pointer; color: white;font-size: 25px; border-radius: 50%; border: none;">
        <i class="fas fa-trash"></i>
    </button>
    <button class="btn btn-danger" (click)="initiateRecording()" [disabled]="recording"
        style="cursor: pointer;  color: white; font-size: 25px; border-radius: 50%; border: none;">
        <i class="fas fa-microphone"></i>
    </button>
    <button class="btn btn-danger" (click)="stopRecording()" [disabled]="!recording"
        style="cursor: pointer; color: white;font-size: 25px; border-radius: 50%; border: none;">
        <i class="fas fa-pause"></i>
    </button>
    <button class="btn btn-success" (click)="enviaAudio()" [disabled]="!url"
        style="cursor: pointer;  color: white; font-size: 25px; border-radius: 50%; border: none;">
        <i class="fas fa-paper-plane"></i>
    </button>
</div>

<div style="text-align:center;" class="my-3 mx-2">
    <audio controls *ngIf="!url">
        <source [src]="sanitize(url)" type="audio/mpeg">
    </audio>
    <audio controls *ngIf="url">
        <source [src]="sanitize(url)" type="audio/mpeg">
    </audio>
</div>