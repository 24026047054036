import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { TokenService } from "../core/token/token.service";

@Injectable({
  providedIn: "root",
})
export class MonitoramentoService {
  private apiUrl = environment.apiGestorUrlLocal;
  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      authorization: this.tokenService.getToken(),
      session: this.tokenService.getSessionUsuario()
    }),
    params: {},
  };
  constructor(private tokenService: TokenService, private http: HttpClient) {}

  getListagemCondicoes(params, filtros): Observable<any> {
    this.httpOptions.params = params;
    return this.http
      .post(`${this.apiUrl}api/monitoramento-servicos/listagem`, filtros, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  createCondicao(payload): Observable<any> {
    this.httpOptions.params = {};
    return this.http
      .post(`${this.apiUrl}api/monitoramento-servicos/condicao`, payload, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  updateCondicao(payload): Observable<any> {
    this.httpOptions.params = {};
    return this.http
      .put(`${this.apiUrl}api/monitoramento-servicos/condicao`, payload, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  createPasso(payload): Observable<any> {
    this.httpOptions.params = {};
    return this.http
      .post(`${this.apiUrl}api/monitoramento-servicos/passo`, payload, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  updatePasso(payload): Observable<any> {
    this.httpOptions.params = {};
    return this.http
      .put(`${this.apiUrl}api/monitoramento-servicos/passo`, payload, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  getCondicaoById(id): Observable<any>{
    this.httpOptions.params = {};
    return this.http
      .get(`${this.apiUrl}api/monitoramento-servicos/condicao/${id}`, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  duplicarCondicao(id): Observable<any>{
    this.httpOptions.params = {};
    return this.http
      .post(`${this.apiUrl}api/monitoramento-servicos/duplicar/condicao/${id}`, '', this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  deletarCondicao(id): Observable<any>{
    this.httpOptions.params = {};
    return this.http
      .delete(`${this.apiUrl}api/monitoramento-servicos/condicao/${id}`, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  deletarPasso(id): Observable<any>{
    this.httpOptions.params = {};
    return this.http
      .delete(`${this.apiUrl}api/monitoramento-servicos/passo/${id}`, this.httpOptions)
      .pipe(catchError(this.handleError));
  }
  /* DROP DOWNS */
  getDropDownTipoCondicoes(): Observable<any> {
    this.httpOptions.params = '';
    return this.http
      .get(`${this.apiUrl}api/monitoramento-servicos/dropDownTipoCondicao`, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  getDropDownTipoAcao(): Observable<any> {
    this.httpOptions.params = '';
    return this.http
      .get(`${this.apiUrl}api/monitoramento-servicos/dropDownTipoAcao`, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  getDropDownGatilhoAcao(): Observable<any> {
    this.httpOptions.params = '';
    return this.http
      .get(`${this.apiUrl}api/monitoramento-servicos/dropDownGatilhoAcao`, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  getDropDownServicoes(params): Observable<any> {
    this.httpOptions.params = params;
    return this.http
      .get(`${this.apiUrl}api/monitoramento-servicos/dropdownServicos`, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  getDropDownScripts(): Observable<any> {
    this.httpOptions.params = '';
    return this.http
      .get(`${this.apiUrl}api/monitoramento-servicos/dropDownScripts`, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  getDropDownPeriodo(): Observable<any> {
    this.httpOptions.params = '';
    return this.http
      .get(`${this.apiUrl}api/monitoramento-servicos/dropDownPeriodo`, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error("Ops! Ocorreu um erro:", error.error.message);
    } else {
      console.error(
        `Backend retornou o código ${error.status}, ` +
          `o body é: ${error.error}`
      );
    }
    return throwError(error.error);
  }
}
