import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'null'})
export class nullPipe implements PipeTransform {
  transform(value: string): string {
    if(value === null || value === undefined || value === '') {
        return "---";
    }

    return value;
  }
}