import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { ChatService } from 'src/app/services/chat.service';
import { FilaAtendimentoService } from 'src/app/services/fila-atendimento.service';
import { environment } from 'src/environments/environment';
import { DetalhesChatHistoricoComponent } from '../detalhes-chat-historico/detalhes-chat-historico.component';
import { ChatViewerComponent } from '../chat-viewer/chat-viewer.component';
declare let moment: any;

@Component({
  selector: 'app-modal-historico-lista-chats',
  templateUrl: './modal-historico-lista-chats.component.html',
  styleUrls: ['./modal-historico-lista-chats.component.scss']
})
export class ModalHistoricoListaChatsComponent implements OnInit, OnDestroy {
  @Input() solicitantes_habilitados: any;
  @Input() chat_contato: any;
  arrayFiltrosHistoricoChat: Array<any> = [];
  listaHistoricoChat: Array<Object> = [];
  newSubs: Subscription;
  filtros = {
    filtro_body: {
    },
  };
  configPagination = environment.getPaginationDefault();
  pagination;
  pagination_inicial;

  constructor(
    private translate: TranslateService,
    private chatService: ChatService,
    private toastr: ToastrService,
    private filaAtendimentoService: FilaAtendimentoService,
    private modal: NgbActiveModal,
    private modalService: NgbModal
  ) {
    this.configPagination.total_registros = 6;
  }

  ngOnInit(): void {
    this.setFiltrosListagemHistoricoChat();
  }

  ngOnDestroy(): void {
    this.newSubs?.unsubscribe();
  }

  close(): void {
    this.modal.close();
  }

  setFiltrosListagemHistoricoChat() {
    this.arrayFiltrosHistoricoChat = [
      { nome: this.translate.instant('DATA_ATENDIMENTO'), tipo: 'date', model: 'data_atendimento', selecionados: 0, modelValue: '', selectedDate: null },
      { nome: this.translate.instant('FILA'), tipo: 'dropdown', model: 'fila', data: [], selecionados: 0, concatena: false, multiSelect: false, hasDependecy: true },
      { nome: 'Status', tipo: 'dropdown', model: 'status_chat_id', data: [], selecionados: 0, concatena: true, multiSelect: true },
    ];

    this.newSubs = this.chatService.getFilasChat({ mostrar_inativos: false })
      .subscribe({
        next: (results) => {
          this.arrayFiltrosHistoricoChat[1].data = results.dropDown ? results.dropDown : [];
        }, error: (error) => {
          console.error(error);
          this.toastr.show(error?.error_msg ? error.error_msg : error, '', environment.toastrConfig('danger'));
        }
      });

    this.arrayFiltrosHistoricoChat[2].data = [
      { id: "__all__", text: this.translate.instant('TODOS') },
      { id: "1", text: this.translate.instant('AG_ATENDIMENTO') },
      { id: "2", text: this.translate.instant('ATENDENDO') },
      { id: "3", text: this.translate.instant('FINALIZADO') },
      { id: "4", text: this.translate.instant('TRANSFERENCIA') },
      { id: "5", text: this.translate.instant('ERRO') }
    ];

    this.listagemHistoricoChat();
  }

  listagemHistoricoChat(filtros?): void {
    if (filtros) {
      this.formatFilterDate(filtros);
      this.filtros.filtro_body = filtros
    }

    this.filtros.filtro_body['contato'] = this.chat_contato.email || this.chat_contato.telefone || this.chat_contato.descricao;
    this.newSubs = this.filaAtendimentoService.getListagemHistoricoChat(this.configPagination, this.filtros)
      .subscribe({
        next: (result) => {
          this.listaHistoricoChat = result.lista;
          if (!filtros) {
            this.pagination = result.meta.paginate;
            this.pagination_inicial = result.meta.paginate;          
          } else {
            this.pagination = result.meta.paginate;
          }
        }, error: (error) => {
          console.log(error);        
          this.toastr.show(error?.error_msg ? error.error_msg : error, "", environment.toastrConfig("danger"));
        }
      });
  }

  formatFilterDate(filtro): void {
    if (filtro.hasOwnProperty('data_atendimento') && (filtro.data_atendimento !== null && filtro.data_atendimento !== '' && filtro.data_atendimento !== undefined)) {   
      filtro.data_atendimento = moment(filtro.data_atendimento).format('YYYY-MM-DD');
    } else {
      filtro.data_atendimento = '';
    }
  }

  aplicarFiltros(filtros): void {
    this.configPagination.pagina = 1;
    this.listagemHistoricoChat(filtros);
  }

  paginarListagem(pagina): void {
    this.configPagination.pagina = pagina;
    this.listagemHistoricoChat();
  }

  filterBy(filtro): void {
    if (this.configPagination.order_by === filtro) {
      this.configPagination.is_descending = !this.configPagination
        .is_descending;
    } else {
      this.configPagination.order_by = filtro;
      this.configPagination.is_descending = true;
    }
    this.listagemHistoricoChat();
  }

  capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  openDetailsModal(chat): void {
    const modalRef = this.modalService.open(DetalhesChatHistoricoComponent, {
      backdrop: "static",
      windowClass: 'modal-custom-milvus',
      keyboard: true,
      scrollable: false,
      size: "medio",
    });
    
    modalRef.componentInstance.chat = chat;
    modalRef.result.then((result) => {
    });
  }

  abrirModalChat(chat): void {
    const modalRef = this.modalService.open(ChatViewerComponent,
    {
      scrollable: false,
      windowClass: 'modal-custom-milvus',
      size: 'medio',
      keyboard: false,
      backdrop: 'static'
    });

    chat.selecionado = true;

    modalRef.componentInstance.historico_chat = chat;
    modalRef.componentInstance.apenas_historico = true; 
  }
}
