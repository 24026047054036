<div class="modal-content">
    <div class="modal-header">
        <h6 class="modal-title" id="modal-title-default">
            {{'FILTROS' | translate}} <{{filtro_avancado.nome_filtro}}>
        </h6>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="close()">
            <i class="mdi mdi-close"></i>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-12 my-2 form-group">
                <label [translate]="'NOME_FILTRO'"></label>
                <input type="text" class="form-control" [placeholder]="this.translate.instant('NOME')"
                    [ngClass]="{'required': filtro_avancado.nome_filtro === ''}"
                    [(ngModel)]="filtro_avancado.nome_filtro">
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-1">
                <button type="button" class="btn btn-outline-warning circled-btn custom-circle-btn"
                    style="color: #FFB346; border-color:#FFB346;">
                    <i class="mdi mdi-shuffle-variant"></i>
                </button>
            </div>
            <div class="col d-flex align-items-center form-group mb-0">
                <label class="mr-2 col-3" [translate]="'CONDICAO'"></label>
                <div style="width: 100%;" class="col-7">
                    <button type="button" class="btn btn-success badge-pill" (click)="openCondicao()">
                        {{'NOVA_CONDICAO' | translate}}
                    </button>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-1" [ngClass]="filtro.length > 0 ? 'stepper-line' : ''">

            </div>
            <div class="col">
                <div class="ml-6 mt-2" *ngFor="let item of filtro; let i = index">
                    <span>
                        {{item.campo.text}} {{'IGUAL' | translate}} {{change(item)}}
                    </span>
                    <button type="button" class="btn btn-sm btn-danger min-small-circled-btn" (click)="removeItem(i)"><i
                            class="far fa-trash-alt"></i></button>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-darkgray btn-icon-only rounded-circle text-12" (click)="close()">
            <i class="fas fa-arrow-left" style=" color: white;"></i>
        </button>
        <button class="btn btn-success btn-md badge-pill" (click)="salvar()" [disabled]="valid()">
            <i class="fas fa-save"></i> {{'SALVAR' | translate}}
        </button>
    </div>
</div>