<tag-input *ngIf="entidade !== 'categoriaTags'"
           [(ngModel)]='selectedValues' 
           [placeholder]="'E-mail'" 
           [secondaryPlaceholder]="'E-mail'"         
           [onAdding]="onAddingTag"
           (onAdd)="onAdding($event)"
           (onRemove)="onRemove($event)"
           theme="regular-theme"
           [ngStyle]="{'border-top': required === true && selectedValues.length === 0 ? '2px solid red' : ''}"
           name="tags"
           class="ng-invalid"
           id="tagsInput">
</tag-input>

<tag-input *ngIf="entidade === 'categoriaTags'"
           [(ngModel)]='selectedValuesCategoryTag' 
           [placeholder]="('ENTER_PARA_CONFIRMAR' | translate)" 
           [secondaryPlaceholder]="('ENTER_PARA_CONFIRMAR' | translate)"
           (onAdd)="onAddingCategoryTag($event)"
           (onRemove)="onRemoveCategoryTag($event)"
           theme="regular-theme"
           [ngStyle]="{'border-top': required === true && selectedValues.length === 0 ? '2px solid red' : ''}"
           name="tagsCategoria"
           class="ng-invalid"
           id="tagsInputCategoria">
</tag-input>