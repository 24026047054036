import { Component, ElementRef, Input, OnDestroy, OnInit, ChangeDetectorRef } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject, Subscription, debounceTime } from 'rxjs';
import { ChatService } from 'src/app/services/chat.service';
import { environment } from 'src/environments/environment';
import { ModalIniciarConversaComponent } from '../modal-iniciar-conversa/modal-iniciar-conversa.component';
import { ToastrService } from "ngx-toastr";
import { TranslateService } from "@ngx-translate/core";
import { PhonePipe } from 'src/app/shared/validators/pipes/phone.pipe';
import { ModalMsgStartChatWhatsappComponent } from '../modal-msg-start-chat-whatsapp/modal-msg-start-chat-whatsapp.component';
import { WhatsappService } from 'src/app/services/whatsapp.service';
import Swal from 'sweetalert2';
import { CountriesDDI } from "src/app/variables/countries-phones";
import { TicketService } from 'src/app/services/ticket.service';
import { FuncoesGlobaisService } from 'src/app/services/funcoes-globais.service';
import { ModalContatosComponent } from '../modal-contatos/modal-contatos.component';
import { DadosEmpresaService } from 'src/app/services/dados-empresa.service';
import { TokenService } from 'src/app/core/token/token.service';
@Component({
  selector: 'app-conversa-zap',
  templateUrl: './conversa-zap.component.html',
  styleUrls: ['./conversa-zap.component.scss']
})
export class ConversaZapComponent implements OnInit, OnDestroy {

  nome: string = '';
  telefone: string = '';
  celular: string = '';
  cliente: any = {
    id: ''
  };
  searchText: string = '';
  searchText$ = new Subject<string>();
  lista = []
  contatosPagination;
  contatosConfigPagination: any = environment.getPaginationDefault();
  has_contato;
  codigo_pais = {
    valor: '',
    id: null
  }

  @Input() mensagemConversa;
  selector: string;
  newSubs: Subscription;
  is_ddg: boolean = false;
  mascara: string = '';
  render: boolean = true;
  templatesMeta: Array<any> = [];
  solicitantes_habilitados: any = {
    solicitante_cliente: true,
    solicitante_contato: true,
    solicitante_operador: true
  };
  novoContato: any = {
    codigo_pais_id: '',
    codigo_pais_id_telefone: '',
    codigo_pais: '',
    codigo_pais_telefone: ''
  };
  permissao = {
    EditarCliente: this.tokenService.checkPermissions('@EditarCliente'),
    EditarContatoChat: this.tokenService.checkPermissions('@EditarContatoChat'),
    CriarNovoContatoChat: this.tokenService.checkPermissions('@CriarNovoContatoChat')
  };

  constructor(
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private chatService: ChatService,
    private elemRef: ElementRef,
    private toastr: ToastrService,
    public translate: TranslateService,
    private phonePipe: PhonePipe,
    private cdRef: ChangeDetectorRef,
    private whatsappService: WhatsappService,
    private ticketService: TicketService,
    private FuncoesGlobaisService: FuncoesGlobaisService,
    private dadosEmpresaService: DadosEmpresaService,
    private tokenService: TokenService
  ) {
    this.selector = elemRef.nativeElement.tagName.toLowerCase();
  }
  
  changeDdi(event) {
    if(event === undefined) {
      this.codigo_pais = { valor: '', id: 0 }
      this.telefone = ''
      return
    }
    this.render = false;
    this.codigo_pais.valor = event && event.valor ? event.valor : '';
    this.codigo_pais.id = event && event.id ? event.id : null;
  
    this.mascara = this.setMascara(this.telefone, event.valor) 
    this.cdRef.detectChanges(); 
    this.render = true;

  }

  ngOnInit(): void {
    document.getElementsByTagName(this.selector)[0]['style']['zoom'] = '0.85';
    this.contatosConfigPagination.total_registros = 4;
    this.listagemContato();
    this.verificaSolicitantesHabilitados();
    this.obterDados();
    
    this.searchText$.pipe(
      debounceTime(1000)
    ).subscribe(value => {
      this.filtrarApi(value)
    });
  }

  ngOnDestroy(): void {
    this.newSubs?.unsubscribe();
  }

  changePesquisa(value: string) {
    this.searchText$.next(value);
  }

  verificaSolicitantesHabilitados(): void {
    this.newSubs = this.ticketService.getAberturaTicketPadrao()
      .subscribe({
        next: (results) => {
          this.solicitantes_habilitados.solicitante_cliente = results.categorizacaoClientePadrao.solicitante_cliente;
          this.solicitantes_habilitados.solicitante_contato = results.categorizacaoClientePadrao.solicitante_contato;
          this.solicitantes_habilitados.solicitante_operador = results.categorizacaoClientePadrao.solicitante_operador;
        }, error: (error) => {
          console.error(error);
          this.toastr.show(error && error.error_msg ? error.error_msg : error, '', environment.toastrConfig('danger'));
        }
      });
  }

  checkDdg($event) {
    this.is_ddg = $event.target.checked
  }

  verificaDdg(telefone: string) {
    return telefone && telefone.includes('0800');
  }

  filtrarApi(filtro) {
    var payload = {
      filtro_body: {
        pesquisa: filtro
      }
    }
    this.contatosConfigPagination.total_registros = 4;
    this.contatosConfigPagination.pagina = 1
    this.newSubs = this.chatService.listAllContatos(this.contatosConfigPagination, payload).subscribe(
      (result) => {
        this.lista = result.lista
        this.contatosPagination = result.meta.paginate;
      }, (error) => {
        console.log(error);
      }
    )
  }

  listagemContato() {
    var payload = {
      filtro_body: {
        pesquisa: this.searchText
      }
    }
    this.contatosConfigPagination.total_registros = 4;
    this.newSubs = this.chatService.listAllContatos(this.contatosConfigPagination, payload).subscribe(
      (result) => {
        this.lista = this.FuncoesGlobaisService.regraDDG(result.lista)
        this.contatosPagination = result.meta.paginate;
      }, (error) => {
        console.log(error);
      }
    )
  }

  paginarListagem(pagina) {
    this.contatosConfigPagination.pagina = pagina;
    this.listagemContato();
  }

  close(result?) {
    this.activeModal.close();
  }

  salvarIniciarConversa() {
    var payload = {
      clienteContato: {
        "cliente_id": this.cliente && this.cliente.id ? this.cliente.id : null,
        "is_padrao": false,
        "descricao": this.nome,
        "celular": this.telefone,
        "telefone": this.telefone,
        "possui_whatsapp": true,
        "codigo_pais": this.codigo_pais.valor
      }
    };
    
    this.newSubs = this.chatService.createContato(payload).subscribe(
      (result) => {
        if (result) {
          this.has_contato = false;
          this.abrirConversa(result.clienteContato)
          this.listagemContato()
        }
      }, (error) => {
        console.log(error);
        this.toastr.show(error && error.error_msg ? error.error_msg : error, '', environment.toastrConfig('danger'));
      }
    )
  }

  abrirConversa(contato?, fromCrud?) {
    if(!fromCrud && this.verificaNumeros(contato)) return;
    
    this.newSubs = this.chatService.obterIntegracaoAtiva()
      .subscribe({
        next: (results) => {
          if(results) {
            const modalRef = this.modalService.open(ModalMsgStartChatWhatsappComponent, {
              scrollable: true,
              windowClass: 'modal-custom-milvus',
              size: 'medio',
              keyboard: true,
              backdrop: 'static',
              centered: true
            });
  
            let data = {
              provider_id: results.provider_id,
              number: contato.celular,
              integration_id: results.id
            };
            
            if(results.provider_id === 2) {
              data['message'] = results.mensagem_inicia_conversa_whatsapp
            };
  
            modalRef.componentInstance.data = data;
            modalRef.result.then(
              (result) => {
                if(result) {
                  var _contato = result;
  
                  if(results.provider_id === 4) {
                    _contato.from = results.numero;
                    _contato.numero_destino = contato.celular;
                    _contato.contato_id = contato.id;
  
                    this.newSubs = this.whatsappService.wppOfficialStartChat(results.id, _contato)
                      .subscribe({
                        next: (results) => {
                          this.activeModal.close(results.chat);
                        }, error: (error) => {
                          console.error(error);
                          this.toastr.show(error.error_msg ? error.error_msg : error, '', environment.toastrConfig('danger'));
                        }
                      });
                  } else {
                    _contato.numero_contato = contato.celular;
                    _contato.chamado_id = null;
                    _contato.nome_contato = contato.descricao;
                    _contato.contato_id = contato.id;
                    _contato.cliente_id = contato.cliente_id;
  
                    this.newSubs = this.chatService.iniciarConversaWhatsappChamado(_contato)
                    .subscribe({
                      next: (results) => {
                        this.activeModal.close(results.chat);
                      }, error: (error) => {
                        this.toastr.show(error, "", environment.toastrConfig("danger"));
                      }
                    });
                  }
                }
              }
            )
          } else {
            this.toastr.show(this.translate.instant('NAO_POSSUI_INTEGRACAO'), '', environment.toastrConfig('danger'));
          }
        }, error: (error) => {
          console.error(error);
          this.toastr.show(error.error_msg ? error.error_msg : error, '', environment.toastrConfig('danger'));
        }
      });
  }

  verificaTelefone() {
    var telefone = this.is_ddg ? '+55' + this.telefone : this.codigo_pais.valor + this.telefone;
    
    this.newSubs = this.chatService.getByTelefone(telefone).subscribe(
      (result) => {
        if (result) {
          this.toastr.show(this.translate.instant('CONTATO_JA_EXISTE'), "", environment.toastrConfig("warning"));
          this.has_contato = true;
        } else {
          this.toastr.show(this.translate.instant("NUMERO_NAO_CADASTRADO"), "", environment.toastrConfig("success"));
          this.has_contato = false;
        }
      }, (error) => {
        console.log(error);
      }
    )
  }

  valid() {
    return this.has_contato || this.nome === ''
  }

  setMascara(telefone, ddi) {
    return this.phonePipe.showMascaraInput(telefone, ddi)
  }

  validateCountryPhoneLength(phone: string) {
    if (this.codigo_pais.valor && phone) {
      let countryPhoneLength = 11; // default BR

      const country = CountriesDDI.filter(country => country.phone === this.codigo_pais.valor.toString());

      if (country.length > 0) {
        const len = country[0].phoneLength;

        if (len) {
          countryPhoneLength = Array.isArray(len) ? len[0] : len;
        }
      }
      
      return phone.length < countryPhoneLength;
    }
    return false;
  }

  abrirModalContato(contato?): void {
    const modalRef = this.modalService.open(ModalContatosComponent, {
      scrollable: false,
      windowClass: 'modal-custom-milvus',
      size: 'medio',
      keyboard: true,
      backdrop: 'static'
    });

    modalRef.componentInstance.solicitantes = this.solicitantes_habilitados;
    modalRef.componentInstance.contato = contato ? contato : this.novoContato;
    modalRef.componentInstance.origem_chat = true;
    modalRef.result.then(result => {
      if (result) {
        if(result.conversar) {
          this.abrirConversa(result.chat, true);
        } else {
          this.listagemContato();
        }
      }
    });
  }

  verificaNumeros(contato): boolean {
    if(!contato.celular && !contato.telefone) {
      this.mostraAviso(this.translate.instant('INICIAR_CONVERSA_AVISO_CONTATO'));
      return true;
    } else if(contato.celular && !contato.telefone) {
      if(!contato.app_celular || contato.app_celular.indexOf(1) === -1) {
        this.mostraAviso(this.translate.instant('SELECIONAR_WPP_PARA_CONVERSAR'));
        return true;
      } else {
        return false;
      }
    } else if(contato.telefone && !contato.celular) {
      if(!contato.app_telefone || contato.app_telefone.indexOf(1) === -1) {
        this.mostraAviso(this.translate.instant('SELECIONAR_WPP_PARA_CONVERSAR'));
        return true;
      } else {
        return false;
      }
    } else {
      if((!contato.app_celular || contato.app_celular.indexOf(1) === -1) && (!contato.app_telefone || contato.app_telefone.indexOf(1) === -1)) {
        this.mostraAviso(this.translate.instant('SELECIONAR_WPP_PARA_CONVERSAR'));
        return true;
      } else {
        return false;
      }
    }
  }

  mostraAviso(msg): void {
    Swal.fire({
      text: msg,
      icon: 'warning',
      confirmButtonText: 'Ok',
    });
  }

  obterDados() {
    this.newSubs = this.dadosEmpresaService.obterDados().subscribe(
      (result) => {
        if (result) {
          Object.keys(result.empresa).forEach((e) => {
            result.empresa[e] = (result.empresa[e] === null || result.empresa[e] === undefined) ? '' : result.empresa[e]
          })
        }
        this.novoContato.codigo_pais_id = result.empresa.codigo_pais_id ? result.empresa.codigo_pais_id : null;
        this.novoContato.codigo_pais = result.empresa.ddi ? result.empresa.ddi : null;
        this.novoContato.codigo_pais_id_telefone = result.empresa.codigo_pais_id ? result.empresa.codigo_pais_id : null;
        this.novoContato.codigo_pais_telefone = result.empresa.ddi ? result.empresa.ddi : null;
      },
      (error) => {
        this.toastr.show(error, "", environment.toastrConfig("danger"));
        console.log(error)
      }
    )
  }
}
