import Swal, { SweetAlertResult } from 'sweetalert2';

export const environment = {
  production: true,
  ambiente: 'prod',
  //Produção
  apiGestorUrlLocal: "https://api.milvus.com.br/", //jshint ignore: line
  apiGestorUrlNova: "https://managerapi.milvus.com.br/",
  apiVNC: "https://api.milvus.com.br/", //jshint ignore: line
  apiRealTimeUrl: "https://apirealtime.milvus.com.br/", //jshint ignore: line
  viewerVncUrl: "https://remote.viewer.milvus.com.br/vnc.html", //jshint ignore: line
  chatApi: "https://chat.api.milvus.com.br/", //jshint ignore: line
  visualizaChatApi: "https://chat.api.milvus.com.br/", //jshint ignore: line
  viewerVncUrlNova: "https://remote.novo.viewer.milvus.com.br/vnc.html", //jshint ignore: line
  scriptChatExterno: "https://chat.api.milvus.com.br/apichat/widget/script/aac44384/d9a334e02851cd93e3a79bb5525473793d5f3512aa74423ac48f3b9b8fa1131760a2989d8fb09de3532d673d25923be8aedf3ff87f91978c777fec431695ae152215fc6c09e191ffa40583bd8f8eec7f8284ce0ecbd1b6761c690ae668e7efa0c575606097",
  allowFiltroNovo: true,
  getPaginationDefault: (is_maior?) => {
    return {
        is_paginate: true,
        order_by: "id",
        is_descending: true,
        total_registros: (is_maior) ? environment.setItensPorPagina(is_maior) : environment.setItensPorPagina(),
        pagina: 1
    };
  },
  getPaginationSoftwaresDevice: (is_maior?) => {
    return {
      is_paginate: true,
      order_by: "uid",
      is_descending: true,
      total_registros: (is_maior) ? environment.setItensPorPagina(is_maior) : environment.setItensPorPagina(),
      pagina: 1
    };
  },
  getPaginationMeuPerfil: () => {
    return {
      is_paginate: true,
      order_by: "id",
      is_descending: true,
      total_registros: 6,
      pagina: 1
    };
  },
  getPaginationDetalhes: () => {
    return {
        is_paginate: true,
        order_by: "id",
        is_descending: true,
        total_registros: 4,
        pagina: 1
    };
  },
  getPaginationDefaultDashboardChat: (is_maior?) => {
    return {
      is_paginate: true,
      order_by: "id",
      is_descending: true,
      total_registros: (is_maior) ? environment.setItensPorPaginaDashboardChat(is_maior) : environment.setItensPorPaginaDashboardChat(),
      pagina: 1
    };
  },
  getPaginationDefaultAlertasTelemetria: () => {
    return {
      pagina: 1
    };
  },
  toastrConfig: (type) => {
    switch (type) {
      case 'success':
        return {
          timeOut: 3000,
          closeButton: false,
          enableHtml: true,
          tapToDismiss: true,
          titleClass: 'alert-title',
          positionClass: 'toast-top-center',
          toastClass: "ngx-toastr alert alert-dismissible alert-success alert-notify",
        }
        break;
      case 'danger':
        return {
          timeOut: 3000,
          closeButton: false,
          enableHtml: true,
          tapToDismiss: true,
          titleClass: 'alert-title',
          positionClass: 'toast-top-center',
          toastClass: "ngx-toastr alert alert-dismissible alert-danger alert-notify",
        }
        break;
      case 'warning':
        return {
          timeOut: 3000,
          closeButton: false,
          enableHtml: true,
          tapToDismiss: true,
          titleClass: 'alert-title',
          positionClass: 'toast-top-center',
          toastClass: "ngx-toastr alert alert-dismissible alert-warning alert-notify",
        }
        break;
      case 'info':
        return {
          timeOut: 3000,
          closeButton: false,
          enableHtml: true,
          tapToDismiss: true,
          titleClass: 'alert-title',
          positionClass: 'toast-top-center',
          toastClass: "ngx-toastr alert alert-dismissible alert-info alert-notify",
        }
        break;
    }
  },
  editorConfig: (toolbar) => {
    var _toolbar = [];
    switch (toolbar) {
      case 'basic':
        _toolbar = [
					['bold', 'italic'],
					['link', 'blockquote', 'code', 'image'],
					[{
						'list': 'ordered'
					}, {
						'list': 'bullet'
					}]
				];
        break;
    }
    return {
			modules: {
				toolbar: _toolbar
			},
			placeholder: '...',
			theme: 'snow'
		}
  },
  getUploadConfig: () => {
    return {
      sizeMaxBytes: 20971520,
      img: ['.png', '.gif', '.jpeg', '.jpg'],
      doc: ['.rtf', '.pdf', '.doc', '.docx', '.txt', '.xls', '.xlsx', '.xml', '.json', '.msg', '.zip', '.rar', '.csv', '.ret', '.rem', '.otp', '.odf', '.odb', '.oxt', '.rar', '.pptx', '.ppt', '.jfif', '.7z', '.pfx'],
      media: ['.mp3', '.mp4', '.mpeg', '.wav'],
      docImg: function () {
        return this.img.concat(this.doc, this.media);
      },
      text: function () {
        var esse = this.img.concat(this.doc, this.media);
        var text = "";
        esse.forEach(function (e, index) {
          if (index == esse.length - 1) {
            text += e;
          } else {
            text += e + ', ';
          }
        });
        return text;
      }
    }
  },
  convertUploadType: (mimeType) => {
    switch(mimeType) {
      case 'vnd.rar':
        return '.rar';
      case 'vnd.ms-excel':
        return '.xls';
      case 'text':
        return '.txt';
      case 'vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        return '.xlsx';
      case 'vnd.ms-powerpoint':
        return '.ppt';
      case 'vnd.openxmlformats-officedocument.presentationml.presentation':
        return '.pptx';
      case 'vnd.openxmlformats-officedocument.wordprocessingml.document':
        return '.docx';
      case mimeType.includes('wav'):
        return '.wav';
      case 'vnd.ms-outlook':
        return '.msg';
      case 'msword':
        return '.doc';
      case 'x-pkcs12':
        return '.pfx';
      case 'x-7z-compressed':
        return '.7z';
        case 'plain':
          return '.txt';
      default:
        return '.' + mimeType;
    };
  },
  setItensPorPagina: (is_maior?) => {
    var windowHeight = window.innerHeight;
    var itens = 0;
    //console.log(windowHeight);
    if(is_maior) {
      if (windowHeight >= 900) {
        itens = 10;
      } else if (windowHeight <= 899 && windowHeight >= 760) {
        itens = 7;
      } else if (windowHeight <= 759 && windowHeight >= 660) {
        itens = 4;
      } else {
        itens = 3;
      }
    } else {
      if (windowHeight >= 900) {
        itens = 12;
      } else if (windowHeight <= 899 && windowHeight >= 760) {
        itens = 11;
      } else if (windowHeight <= 759 && windowHeight >= 660) {
        itens = 6;
      } else {
        itens = 6;
      }
    }

    return itens;
  },
  setItensPorPaginaDashboardChat: (is_maior?) => {
    var windowHeight = window.innerHeight;
    var itens = 0;
    //console.log(windowHeight);
    if (is_maior) {
      if (windowHeight >= 900) {
        itens = 5;
      } else if (windowHeight <= 899 && windowHeight >= 760) {
        itens = 3;
      } else if (windowHeight <= 759 && windowHeight >= 660) {
        itens = 2;
      } else {
        itens = 1;
      }
    } else {
      if (windowHeight >= 900) {
        itens = 5;
      } else if (windowHeight <= 899 && windowHeight >= 760) {
        itens = 4;
      } else if (windowHeight <= 759 && windowHeight >= 660) {
        itens = 3;
      } else {
        itens = 3;
      }
    }

    return itens;
  },
  getHashUniversidade: () => {
    return "bZy82RCZG8W2XWHXZtVMTRaUbm9J7TSPUktuvMNGQpxPgAw7PzU8va8mKkkjJGZq9jwVPSYjNtSR3b3EfWmHqk3GsYuyLvjnjthaUVydkvY7SVxCuss2yRkEZjwn6atM"
  },
  getFontes: () => {
    var arrayFonts =
      [
        "Arial",
        "Bahnschrift",
        "Calibri",
        "Cambria",
        "Candara",
        "Comic Sans MS",
        "Consolas",
        "Constantia",
        "Corbel",
        "Courier New",
        "Ebrima",
        "Fabada",
        "Franklin Gothic Medium",
        "Gadugi",
        "Georgia",
        "Impact",
        "Javanese Text",
        "Leelawadee UI",
        "Malgun Gothic",
        "Microsoft Sans Serif",
        "Mongolian Baiti",
        "Nirmala UI",
        "Palatino Linotype",
        "Tahoma",
        "Times New Roman",
        "Trebuchet MS",
        "Verdana"
      ];

    return {
      arrayFonts: arrayFonts
    };
  },
  disparaAlerta(config): Promise<SweetAlertResult> {
    return Swal.fire({
      title: config.title,
      text: config.text,
      icon: config.icon,
      focusConfirm: true,
      showCancelButton: true,
      confirmButtonText: config.confirmButtonText,
      cancelButtonText: config.cancelButtonText,
    });
  }
};
