import { Component, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { TokenService } from 'src/app/core/token/token.service';
import { ChatService } from 'src/app/services/chat.service';
import { TarefaService } from 'src/app/services/tarefa.service';
import { environment } from 'src/environments/environment';
declare let moment: any;

@Component({
  selector: 'app-modal-hora-finalizar-chat',
  templateUrl: './modal-hora-finalizar-chat.component.html',
  styleUrls: ['./modal-hora-finalizar-chat.component.scss']
})
export class ModalHoraFinalizarChatComponent implements OnInit, OnDestroy {
  @Input() chatData;
  @Input() infoFinalizacao;
  tarefa;
  codigoChamado;
  opcao = 'apontar';
  finalizar = {
    is_externo: false,
    is_comercial: true,
    data_criacao: null,
    total_horas: ''
  };
  selector: string;
  tempo_tarefa: any;
  data_inicio_atendimento: any;
  newSubs: Subscription;

  constructor(
    public modal: NgbActiveModal,
    private tarefaService: TarefaService,
    private toastr: ToastrService,
    private translate: TranslateService,
    private elemRef: ElementRef,
    private chatService: ChatService,
    private tokenService: TokenService
  ) {
    this.selector = elemRef.nativeElement.tagName.toLowerCase();
  }

  ngOnInit(): void {
    document.getElementsByTagName(this.selector)[0]['style']['zoom'] = '0.85';
    this.codigoChamado = this.chatData.chamado_vinculado.codigo;

    var hoje: any = new Date();
    var data_inicio_atendimento: any = new Date(this.chatData.data_inicio_atendimento);

    this.finalizar.data_criacao = data_inicio_atendimento;
    this.data_inicio_atendimento = data_inicio_atendimento;

    var diferenca = moment(hoje).diff(moment(data_inicio_atendimento), 'seconds')
    //console.log('diferenca', diferenca);
    
    this.tempo_tarefa = this.converteSegundos(diferenca)
    //console.log('this.tempo_tarefa', this.tempo_tarefa);    
  }

  ngOnDestroy(): void {
    this.newSubs?.unsubscribe();
  }

  converteSegundos(segundos) {
    let sec_num = parseInt(segundos, 10);
    let hours = Math.floor(sec_num / 3600).toString();
    let minutes = Math.floor((sec_num - (+hours * 3600)) / 60).toString();
    let seconds = (sec_num - (+hours * 3600) - (+minutes * 60)).toString();
    if (+hours < 10) { hours = "0" + hours; }
    if (+minutes < 10) { minutes = "0" + minutes; }
    if (+seconds < 10) { seconds = "0" + seconds; }
    return hours + ':' + minutes;
  }

  setTotalHoras(data) {
    var hoje: any = new Date();
    var diff = Math.abs(hoje - data)    
    var hours = Math.floor(diff / 3600000);
    var minutes = Math.floor((diff - (hours * 3600000)) / 60000);
    return `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`;
  }

  setDataCriacao(data) { 
    return this.tokenService.convertDateTimezone(moment(data))
  }

  finalizarTarefa(): void {
    //console.log('this.chatData', this.chatData);
    var payload: any;
    if (this.opcao !== 'nao_apontar') {
      if (this.opcao === 'apontar') {
        payload = {
          chat_id: this.chatData._id,
          comentario: this.infoFinalizacao.comentario,
          tabulacao: this.infoFinalizacao.tabulacao.id.toString(),
          atendimento: {
            is_comercial: this.finalizar.is_comercial,
            is_externo: this.finalizar.is_externo,
            total_horas: this.setTotalHoras(this.data_inicio_atendimento),
            data_criacao: this.setDataCriacao(this.finalizar.data_criacao)
          }
        }
        //console.log('apontar', payload);
      } else {
        payload = {
          chat_id: this.chatData._id,
          comentario: this.infoFinalizacao.comentario,
          tabulacao: this.infoFinalizacao.tabulacao.id.toString(),
          atendimento: {
            is_comercial: this.finalizar.is_comercial,
            is_externo: this.finalizar.is_externo,
            total_horas: this.finalizar.total_horas,
            data_criacao: this.setDataCriacao(this.finalizar.data_criacao)
          }
        }
        //console.log('apontar_custom', payload);
      }
    } else {
      payload = {
        chat_id: this.chatData._id,
        comentario: this.infoFinalizacao.comentario,
        tabulacao: this.infoFinalizacao.tabulacao.id.toString()
      }
      //console.log('nao_apontar', payload);
    }
    this.newSubs = this.chatService.finalizarChatTecnico(payload).subscribe(
      (result) => {
        this.toastr.show(this.translate.instant('FINALIZADO_SUCESSO'), '', environment.toastrConfig('success'));
        this.modal.close('finalizado')
      }, (error) => {
        console.log(error)
        this.toastr.show(error, '', environment.toastrConfig('danger'));
      }
    )
  }

  verificaSeDesativa(): boolean {
    if (this.opcao === 'apontar_custom') {
      if ((this.finalizar.total_horas === null || this.finalizar.total_horas === '') || (this.finalizar.data_criacao === null || this.finalizar.data_criacao === '')) {
        return true;
      } else {
        return false;
      }
    } else {
      if (this.finalizar.data_criacao === null || this.finalizar.data_criacao === '') {
        return true;
      } else {
        return false;
      }
    }
  }

  zerarHoras(): void {
    this.finalizar.total_horas = '';
  }

}
