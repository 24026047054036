import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'concatByComma'
})
export class ConcatByCommaPipe implements PipeTransform {

  transform(value: unknown[], ...args: string[]): unknown {
    let _stringWithComma = '';

    if(typeof(value) === 'object') {
      let _key: string = args[0];
      let _eachItem = value.map(item => {
        return ' ' + item[_key];
      });
      _stringWithComma = (_eachItem.length > 0) ? _eachItem.toString() : '---';
    } else {
      _stringWithComma = value;
    }

    return _stringWithComma;
  }

}
