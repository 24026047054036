import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'removeAcentos' })
export class removeAcentos implements PipeTransform {
  /**
   * Transform
   *
   * @param {any[]} items
   * @param {string} searchText
   * @returns {any[]}
   */
 
  transform(value: any): any {
    if(value){
        var accent = [
          /[\300-\306]/g, /[\340-\346]/g, // A, a
          /[\310-\313]/g, /[\350-\353]/g, // E, e
          /[\314-\317]/g, /[\354-\357]/g, // I, i
          /[\322-\330]/g, /[\362-\370]/g, // O, o
          /[\331-\334]/g, /[\371-\374]/g, // U, u
          /[\321]/g, /[\361]/g, // N, n
          /[\307]/g, /[\347]/g, // C, c
      ],
      noaccent = ['A','a','E','e','I','i','O','o','U','u','N','n','C','c'];

    for (var i = 0; i < accent.length; i++){
      if(value !== undefined) {
        value = value.replace(accent[i], noaccent[i]);
      }
    }
    
    return value;
  }
  }
}