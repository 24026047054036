import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { TokenService } from '../core/token/token.service';
import { iGetDropDownUsuarios } from '../interfaces/dashboards/dashboards-dashboard.interface';
import { Usuario } from '../models/usuario.model';

@Injectable({
  providedIn: 'root'
})
export class UsuariosService {
  private apiUrl = environment.apiGestorUrlLocal;
  private apiUrlV2 = environment.apiGestorUrlNova;
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', 'authorization': this.tokenService.getToken(), 'session': this.tokenService.getSessionUsuario() }),
    params: {}
  };

  constructor(private tokenService: TokenService, private http: HttpClient) { }

  atualizaTipoListagem(payload): Observable<any> {
    this.httpOptions.params = '';
    return this.http.put(`${this.apiUrl}api/usuarios/atualizar-tipo-listagem`, payload, this.httpOptions)
    .pipe(
      catchError(this.handleError)
    );
  }

  createUsuario(payload): Observable<any> {
    this.httpOptions.params = '';
    return this.http.post(`${this.apiUrl}api/usuarios`, payload, this.httpOptions)
    .pipe(
      catchError(this.handleError)
    );
  }

  updateUsuario(payload): Observable<any> {
    this.httpOptions.params = '';
    return this.http.put(`${this.apiUrl}api/usuarios`, payload, this.httpOptions)
    .pipe(
      catchError(this.handleError)
    );
  }

  updateUsuarioAuthentication(payload): Observable<any> {
    this.httpOptions.params = '';
    return this.http.put(`${this.apiUrl}api/usuarios`, payload, this.httpOptions)
    .pipe(
      catchError(this.handleError)
    );
  }


  deleteUsuario(id): Observable<any> {
    this.httpOptions.params = '';
    return this.http.delete(`${this.apiUrl}api/usuarios/${id}`, this.httpOptions)
    .pipe(
      catchError(this.handleError)
    );
  }

  getDropDownUsuarios(params): Observable<iGetDropDownUsuarios> {
    this.httpOptions.params = params;
    return this.http.get<iGetDropDownUsuarios>(`${this.apiUrl}api/usuarios/dropdownlist/`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  getListagemUsuarios(params, filtro): Observable<any> {
    this.httpOptions.params = params;
    return this.http.post(`${this.apiUrl}api/usuarios/listGrid`, filtro, this.httpOptions)
    .pipe(
      catchError(this.handleError)
    );
  }
  
  getUsuarioById(id): Observable<any> {    
    this.httpOptions.params = "";
    return this.http.get(`${this.apiUrl}api/usuarios/${id}`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  atualizarStatusChat(status): Observable<any> {
    this.httpOptions.params = "";
    return this.http.put(`${this.apiUrl}api/usuarios/atualizar-status-chat`, status, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  verificarUsuario(payload): Observable<any> {
    this.httpOptions.params = "";
    return this.http.post(`${this.apiUrl}api/usuarios/verifica/username`, payload, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  getDropDownUsuariosPerfil(): Observable<any> {
    this.httpOptions.params = '';
    return this.http.get(`${this.apiUrl}api/usuarios/dropdownlist/perfil`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  getDropDownUsuarioTelaInicial(): Observable<any> {
    this.httpOptions.params = '';
    return this.http.get(`${this.apiUrl}api/usuarios/tela/dropdownlist-novo`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  getUsuarioGeolocalizacao(payload): Observable<any> {
    this.httpOptions.params = '';
    return this.http.post(`${this.apiUrl}api/usuario-geolocalizacao/historico`, payload, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  getUsuarioFoto(id): Observable<any> {
    this.httpOptions.params = '';
    return this.http.get(`${this.apiUrl}api/usuarios/foto/${id}`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  updateUsuarioFoto(id, payload): Observable<any> {
    this.httpOptions.params = '';
    return this.http.put(`${this.apiUrl}api/usuarios/foto-usuario/${id}`, payload, {
      headers: new HttpHeaders({'authorization': this.tokenService.getToken(), 'session': this.tokenService.getSessionUsuario()})})
      .pipe(
        catchError(this.handleError)
      );
  }

  removeMeuPerfilFoto(id): Observable<any> {
    this.httpOptions.params = '';
    return this.http.delete(`${this.apiUrl}api/usuarios/foto/${id}`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  updateMeuPerfilFoto(payload): Observable<any> {
    this.httpOptions.params = '';
    return this.http.put(`${this.apiUrl}api/usuarios/foto`, payload, {
      headers: new HttpHeaders({'authorization': this.tokenService.getToken(), 'session': this.tokenService.getSessionUsuario()})})
      .pipe(
        catchError(this.handleError)
      );
  }

  alterarSenha(payload): Observable<any> {
    this.httpOptions.params = '';
    return this.http.put(`${this.apiUrl}api/usuarios/alteraSenha`, payload, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  getEmailSignature(id): Observable<any> {
    this.httpOptions.params = '';
    return this.http.get(`${this.apiUrl}api/usuario/assinatura/${id}`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  updateEmailSignature(payload): Observable<any> {
    this.httpOptions.params = '';
    return this.http.put(`${this.apiUrl}api/usuario/assinatura`, payload, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  dropDownUsersToMention(customer_id): Observable<any> {
    this.httpOptions.params = '';
    return this.http.get(`${this.apiUrl}api/chamadolog/mencao-dropdown/${customer_id}`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  sairDispositivoConfiavel(dispositivo_id): Observable<any> {
    this.httpOptions.params = null;
    return this.http.put(`${this.apiUrl}api/tornar-nao-confiavel/${dispositivo_id}`, null, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  resetarSenhaOperador(id): Observable<any> {
    this.httpOptions.params = null;
    return this.http.get(`${this.apiUrl}api/usuarios/reset-senha/${id}`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  dropDownNovoTipoOperadores(): Observable<any> {
    this.httpOptions.params = null;
    return this.http.get(`${this.apiUrl}api/usuarios/dropdown-tipo-usuario`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  contadoresNovoTipoOperadores(): Observable<any> {
    this.httpOptions.params = null;
    return this.http.get(`${this.apiUrl}api/usuarios/contadores-usuario-plano`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  acessarFeatureBase(data: any): Observable<any> {
    return this.http.post<string>(`${this.apiUrlV2}manager/accounts/login-feature-base`, data, this.httpOptions)
    .pipe(
      catchError(this.handleError)
    );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('Ops! Ocorreu um erro:', error.error.message);
    } else {
      console.error(
        `Backend retornou o código ${error.status}, ` +
        `o body é: ${error.error}`);
    }
    return throwError(
      error.error);
  }
}
