<div class="modal-content">
    <div class="modal-header">
        <h6 class="modal-title" id="modal-title-default">
            {{'FILTRO_AVANCADO' | translate}}
        </h6>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="close()">
            <i class="mdi mdi-close"></i>
        </button>
    </div>
    <div class="modal-body">
        <div class="card">
            <div class="card-header pb-0">
                <div class="row">
                    <div class="col-8 d-flex justify-content-start mx-auto my-auto"
                        style="font-size: 18px; font-weight: 700; font-style: normal">
                        {{'MEUS_FILTROS' | translate}}
                    </div>

                    <div class="col-4 d-flex justify-content-end">
                        <input type="search" style="border-radius: 1rem;" class="form-control"
                            [placeholder]="this.translate.instant('BUSCAR')" [(ngModel)]="searchFilter" (ngModelChange)="filtrarApi($event)">
                        <button type="button" class="btn btn-success badge-pill ml-2"
                            (click)="openModalNovoFiltroAvancado()">
                            <span [translate]="'NOVO'"></span>
                        </button>
                    </div>
                </div>
            </div>
            <div class="card-body pb-0">
                <div class="row my-3" *ngFor="let item of listaFiltrosAvancado">
                    <div class="col-6 d-flex justify-content-start">
                        <span>{{item.nome_filtro}}</span>
                    </div>
                    <div class="col-6 d-flex justify-content-end">
                        <div class="my-auto" container=".modal" [ngbTooltip]="'FAVORITO'| translate">
                            <i class="fas fa-star" style="font-size: 24px; cursor: pointer;" role="button"
                                (click)="favoritar(item)"
                                [ngClass]="{'first': item.is_favorito === true, 'second': item.is_favorito === false}"></i>
                        </div>

                        <button type="button" class="btn btn-sm btn-darkgray btn-icon-only rounded-circle"
                            (click)="openModalEditarFiltroAvancado(item)" container=".modal" [ngbTooltip]="'EDITAR' | translate">
                            <i class="fas fa-pencil-alt" style=" color: white;"></i>
                        </button>

                        <button type="button" class="btn btn-sm btn-danger btn-icon-only rounded-circle"
                            (click)="removerFiltroAvancado(item.id)" container=".modal" [ngbTooltip]="'REMOVER' | translate">
                            <i class="fas fa-trash-alt" style=" color: white;"></i>
                        </button>

                        <button type="button" class="btn btn-success btn-sm badge-pill"
                            (click)="selecionaFiltro(item.filtro,item.nome_filtro)">
                            <span>{{'SELECIONE' | translate}}</span>
                        </button>
                    </div>
                </div>
            </div>
            <div class="card-footer pt-0">
                <pagination *ngIf="listaFiltrosAvancado.length > 0" [meta]="pagination"
                    (pageChange)="paginarListagem($event)"></pagination>
            </div>
        </div>
    </div>
</div>