import { QuillButtonOptionsModel } from "./quillButtonOptions.model";
import { QuillImageResizeModel } from "./quillImageResize.model";
import { QuillMagicUrlModel } from "./quillMagicUrl.model";
import { QuillMentionModel } from "./quillMention.model";
import { QuillToolbarCompleteModel } from "./quillToolbarComplete.model";
import { QuillToolbarCustomModel } from "./quillToolbarCustom.model";

export class QuillConfigModel {
  theme?: string;
  magicUrl?: boolean | QuillMagicUrlModel;
  whitelist?: any;
  import?: any;
  imageDrop?: boolean;
  ImageResize?: QuillImageResizeModel;
  syntax?: boolean;
  toolbar?: QuillToolbarCompleteModel | QuillToolbarCustomModel | string;
  mention?: QuillMentionModel;
  "toolbar-custom-button": QuillButtonOptionsModel;
  "emoji-toolbar"?: boolean;
  "emoji-textarea"?: boolean;
  "emoji-shortname"?: boolean;
  style?: string;
  required?: boolean;
  placeholder?: string;
  keyboard?: any;
  clipboard?: any;
}
