import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MilvusTreePadraoComponent } from './milvus-tree-padrao.component';
import { FormsModule } from '@angular/forms';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { MatTreeModule } from '@angular/material/tree';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacySliderModule as MatSliderModule } from '@angular/material/legacy-slider';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [MilvusTreePadraoComponent],
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    NgbModule,
    Ng2SearchPipeModule,
    MatSliderModule,
    MatButtonModule,
    MatTreeModule,
    MatIconModule,
  ],
  exports: [MilvusTreePadraoComponent]
})
export class MilvusTreePadraoModule { }
