import { IQuillButton } from "src/app/interfaces/quill/quillButton.interface";

export class QuillButtonOptionsModel {
  toolbarKey: "item";
  isToggled: boolean;
  button: IQuillButton;
  items: any;
  clickHandler: any;
  collapseByClick: boolean;
  saveSelections: boolean;
}
