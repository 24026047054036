<!-- <nav id="navbar-main" class="navbar navbar-horizontal navbar-transparent navbar-main navbar-expand-lg navbar-light" style="background-color: black !important;">
  <div class="container" style="margin-top: 25px; margin-left: 210px;">
    <a class="navbar-brand" [routerLink]="['/dashboards/dashboard']">
      <img src="assets/img/brand/Milvus_logo_completo_branco.png" />
    </a>

  </div>
</nav> -->
<router-outlet></router-outlet>
<footer id="footer" *ngIf="checkLocation()">
  <div class="py-2" style="background-color: #ffffff">
    <div class="container">
      <div class="row align-items-center justify-content-xl-between">
        <div class="col-xl-12 text-center text-muted">
          ®2021 <span [translate]="'COPYRIGHT'"></span>
        </div>
      </div>
    </div>
  </div>
</footer>