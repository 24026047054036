import { Component, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { TokenService } from 'src/app/core/token/token.service';
import { ClienteContatoService } from 'src/app/services/cliente-contato.service';
import { environment } from 'src/environments/environment';
import { ModalContatosComponent } from '../modal-contatos/modal-contatos.component';
import { DadosEmpresaService } from 'src/app/services/dados-empresa.service';
import { FuncoesGlobaisService } from 'src/app/services/funcoes-globais.service';

@Component({
  selector: 'app-modal-lista-contatos-cliente',
  templateUrl: './modal-lista-contatos-cliente.component.html',
  styleUrls: ['./modal-lista-contatos-cliente.component.scss']
})
export class ModalListaContatosClienteComponent implements OnInit, OnDestroy {
  @Input() contatoData;
  configPagination = environment.getPaginationDefault();
  pagination;
  listaContatos = [];
  listaSelecionados = [];
  selector: string;
  filtros: any = {
    filtro_body: null
  };
  permissao: any = {
    EditarCliente: this.tokenService.checkPermissions('@EditarCliente'),
    CriarNovoContatoTicket: this.tokenService.checkPermissions('@CriarNovoContatoTicket'),
    EditarContatoTicket: this.tokenService.checkPermissions('@EditarContatoTicket')
  };
  newSubs: Subscription;
  arrayFiltrosModalContatos: Array<any> = [];
  cliente_id: number = null;
  novoContato: any = {
    codigo_pais_id: '',
    codigo_pais_id_telefone: '',
    codigo_pais: '',
    codigo_pais_telefone: ''
  }

  constructor(
    public modal: NgbActiveModal,
    private toastr: ToastrService,
    public translate: TranslateService,
    private clienteContatoService: ClienteContatoService,
    private modalService: NgbModal,
    private elem: ElementRef,
    private tokenService: TokenService,
    private dadosEmpresaService: DadosEmpresaService,
    private FuncoesGlobaisService: FuncoesGlobaisService
  ) {
    this.selector = this.elem.nativeElement.tagName.toLowerCase();
  }

  ngOnInit(): void {    
    document.getElementsByTagName(this.selector)[0]['style']['zoom'] = '0.85';
    if (this.contatoData.cliente) {
      this.cliente_id = this.contatoData.cliente.id ? this.contatoData.cliente.id : this.contatoData.cliente;
    }
    this.filtros.filtro_body = { cliente_id: this.cliente_id, tipo_solicitante_id: this.contatoData.tipo_solicitante_id, solicitante_id: this.contatoData.solicitante_id };
    this.verificaSelecionados(this.contatoData.contatosSelecionados);
    this.setFiltroModalContatos();
    this.obterDados();
  }

  ngOnDestroy(): void {
    this.newSubs?.unsubscribe();
  }

  listarContatos(pagination, filtros): void {
    this.newSubs = this.clienteContatoService.getListagemContatos(pagination, filtros)
      .subscribe(results => {
        this.listaContatos = this.FuncoesGlobaisService.regraDDG(results.lista)     
        this.pagination = results.meta.paginate;
      }, error => {
        console.log(error)
        this.toastr.show(error, '', environment.toastrConfig('danger'));
      })
  }

  setFiltroModalContatos(): void {
    this.arrayFiltrosModalContatos = [
      { nome: this.translate.instant('NOME'), tipo: 'input', model: 'nome', selecionados: 0, concatena: false, modelValue: '' },
      { nome: 'Email', tipo: 'input', model: 'email', selecionados: 0, concatena: false, modelValue: '' },
      { nome: this.translate.instant('CELULAR'), tipo: 'input', model: 'celular', selecionados: 0, concatena: false, modelValue: '' }
    ];

    if(this.contatoData.tipo_solicitante_id === 1) {
      this.arrayFiltrosModalContatos.push({ nome: this.translate.instant('PADRAO'), tipo: 'dropdown', data: [{ id: 'true', text: this.translate.instant('SIM') },
      { id: 'false', text: this.translate.instant('NAO') },], model: 'is_padrao', selecionados: 0, concatena: false, modelValue: '' });
    } else {
      this.arrayFiltrosModalContatos.splice(1, 0, { nome: 'CPF', tipo: 'input', model: 'cpf', selecionados: 0, concatena: false, modelValue: '' });
    }

    this.listarContatos(this.configPagination, this.filtros);
  }

  aplicarFiltros(filtros): void {
    this.filtros.filtro_body = filtros;
    this.filtros.filtro_body['cliente_id'] = this.cliente_id;
    this.filtros.filtro_body['tipo_solicitante_id'] = this.contatoData.tipo_solicitante_id;
    this.filtros.filtro_body['solicitante_id'] = this.contatoData.solicitante_id;
    this.configPagination.pagina = 1;
    
    this.listarContatos(this.configPagination, this.filtros);
  }

  verificaSelecionados(selecionados): void {
    if(selecionados[0] === undefined) {
      return 
    }
    selecionados = Object.values(selecionados)
    selecionados.forEach((contato) => {
      this.listaSelecionados.push(contato)
    })
  }

  abrirModalNovoContato(contato?): void {
    const modalRef = this.modalService.open(ModalContatosComponent, {
      scrollable: false,
      windowClass: 'modal-custom-milvus',
      size: 'medio',
      keyboard: true,
      backdrop: 'static',
      centered: true
    });
    
    modalRef.componentInstance.tipo_solicitante_id = this.contatoData.tipo_solicitante_id;
    modalRef.componentInstance.contato = contato ? contato : this.novoContato;
    modalRef.componentInstance.cliente_id = this.cliente_id;
    modalRef.result.then(result => {
      if (result) {
        this.listarContatos(this.configPagination, this.filtros);
      }
    });
  }

  paginarListagem(pagina): void {
    this.configPagination.pagina = pagina;
    
    this.listarContatos(this.configPagination, this.filtros);
  }

  onValueChange(event: any, contato: any) {
    if (event) {
      var index = this.listaSelecionados.findIndex(x => x.id === contato.id)
      if (index === -1) {
        this.listaSelecionados.push(contato)
      }
    } else {
      var index = this.listaSelecionados.findIndex(x => x.id === contato.id)
      if (index !== -1) {
        this.listaSelecionados.splice(index, 1)
      }
    }
  }

  selectAllItens() {
    var _checkboxes = this.elem.nativeElement.querySelectorAll(
      ".custom-control-input"
    );
    var _value = _checkboxes[0].checked;
    _checkboxes.forEach((checkbox) => {
      if (checkbox.checked !== _value) {
        checkbox.checked = _value;
      }
    });

    if (_value) {
      this.listaContatos.forEach(e => {
        var index = this.listaSelecionados.findIndex(x => x.id === e.id)
        if (index === -1) {
          this.listaSelecionados.push(e)
        }
      })
    } else {
      this.listaContatos.forEach(e => {
        var index = this.listaSelecionados.findIndex(x => x.id === e.id)
        if (index !== -1) {
          this.listaSelecionados.splice(index, 1)
        }
      })
    }
  }

  close(): void {
    this.modal.close();
  }

  verificaCheck(contato) {
    var index = this.listaSelecionados.findIndex(x => x.id === contato.id)
    return index !== -1
  }

  confirmar() {
    this.modal.close(this.listaSelecionados);
  }

  obterDados() {
    this.newSubs = this.dadosEmpresaService.obterDados().subscribe(
      (result) => {
        if (result) {
          Object.keys(result.empresa).forEach((e) => {
            result.empresa[e] = (result.empresa[e] === null || result.empresa[e] === undefined) ? '' : result.empresa[e]
          })

        }
        this.newSubs = this.clienteContatoService.dropDownCodigoDdiPais()
          .subscribe({
            next: (results) => {
              let codigo_pais = results.dropDown.filter(item => item.id === result.empresa.codigo_pais_id);
              this.novoContato.codigo_pais_id = result.empresa.codigo_pais_id ? result.empresa.codigo_pais_id : null;
              this.novoContato.codigo_pais = codigo_pais[0].valor ? codigo_pais[0].valor : null;
              this.novoContato.codigo_pais_id_telefone = result.empresa.codigo_pais_id ? result.empresa.codigo_pais_id : null;
              this.novoContato.codigo_pais_telefone = codigo_pais[0].valor ? codigo_pais[0].valor : null;
            }, error: (error) => {
              console.error(error);
              this.toastr.show(error?.error_msg ? error.error_msg : error, '', environment.toastrConfig('danger'));
            }
          })
      },
      (error) => {
        this.toastr.show(error, "", environment.toastrConfig("danger"));
        console.log(error)
      }
    )
  }

}
