import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { TokenService } from "../core/token/token.service";
import { iChatGptIntegracaoTicket } from "../interfaces/chat-gpt/chat-gpt-suggestion.interface";

@Injectable({
  providedIn: "root",
})
export class ChatGPTService {
  private apiUrl = environment.apiGestorUrlLocal;

  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      authorization: this.tokenService.getToken(),
      session: this.tokenService.getSessionUsuario()
    }),
    params: {},
  };

  constructor(private tokenService: TokenService, private http: HttpClient) {}

  getSuggestion(id: number, payload: any): Observable<any> {
    this.httpOptions.params = "";
    return this.http
      .post(`${this.apiUrl}api/chatGpt/inicial/${id}`, payload, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  likeOrDislikeSuggestion(id: number, payload: any): Observable<any> {
    this.httpOptions.params = "";
    return this.http
      .put(`${this.apiUrl}api/chatGpt/like/${id}`, payload, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  useSuggestion(id: NumberConstructor): Observable<any> {
    this.httpOptions.params = "";
    return this.http
      .put(`${this.apiUrl}api/chatGpt/utilizado/${id}`, {}, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  refreshSuggestion(id: number, payload: any): Observable<any> {
    this.httpOptions.params = "";
    return this.http
      .post(`${this.apiUrl}api/chatGpt/sugestao/nova/${id}`, payload, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  buildCommentSugestionQuestion(payload: any): Observable<any> {
    this.httpOptions.params = "";
    return this.http
      .post(`${this.apiUrl}api/chatGpt/sugestao/nova-sugestao-questao`, payload, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  searchSuggestion(query: any): Observable<any> {
    this.httpOptions.params = "";
    return this.http
      .post(`${this.apiUrl}api/chatGpt/sugestao`, query, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  getSuggestionListByTicketId(id: number): Observable<any> {
    this.httpOptions.params = "";
    return this.http
      .get(`${this.apiUrl}api/chatGpt/sugestoes/${id}`, this.httpOptions)
      .pipe(catchError(this.handleError));
  }
  
  getChatGPTCommentSuggestion(chamado_id: number, texto: string): Observable<any> {
    this.httpOptions.params = {
      chamado_id,
      texto,
    };
    return this.http
      .get(`${this.apiUrl}api/chatGpt/comment-sugestao-de-texto/`, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  getChatGPTCommentSuggestionModal(chamado_id: number, texto: string): Observable<any> {
    this.httpOptions.params = null;
    let _payload = {
      chamado_id,
      texto,
    }
    return this.http
      .post(`${this.apiUrl}api/chatGpt/comment-sugestao-de-texto-modal/`, _payload, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  getChatGptIntegracaoSugestaoTicket(chamado_id: number): Observable<iChatGptIntegracaoTicket> {
    this.httpOptions.params = null;
    return this.http.get<iChatGptIntegracaoTicket>(`${this.apiUrl}api/configuracoes/integracao/openai-sugestao/${chamado_id}`, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error("Ops! Ocorreu um erro:", error.error.message);
    } else {
      console.error(
        `Backend retornou o código ${error.status}, ` +
          `o body é: ${error.error}`
      );
    }
    return throwError(error.error);
  }
}
