import { Component, OnInit, Input, ElementRef } from '@angular/core';
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-detalhes-chat-historico',
  templateUrl: './detalhes-chat-historico.component.html',
  styleUrls: ['./detalhes-chat-historico.component.scss']
})
export class DetalhesChatHistoricoComponent implements OnInit {
  @Input() chat;
  selector: string;
  constructor(public activeModal: NgbActiveModal,
    public translate: TranslateService,
    private elemRef: ElementRef,
  ) {
    this.selector = elemRef.nativeElement.tagName.toLowerCase();
  }

  ngOnInit(): void {
    document.getElementsByTagName(this.selector)[0]['style']['zoom'] = '0.85'
  }

  close(result?) {
    this.activeModal.close(result);
  }

}
