<div class="input-group {{uniqueID}}" *ngIf="disabledHours && !is_range && !inline">
    <input type="text" #dp="bsDatepicker" placeholder="dd/MM/YYYY" [(ngModel)]="selectedValue" class="form-control" bsDatepicker [bsConfig]="{ isAnimated: true, containerClass: 'theme-dark-blue', dateInputFormat: 'DD/MM/YYYY', adaptivePosition: true, language: 'es', showWeekNumbers: false, customTodayClass: 'custom-today-class' }" (ngModelChange)="emitValue(selectedValue)" [container]="'.' + uniqueID" [ngClass]="{'required': verificaRequerido()}" [minDate]="dataMinima" [maxDate]="dataMaxima" disabled="{{(disabled) ? disabled : 'false'}}">
</div>

<div class="input-group {{uniqueID}}" *ngIf="!disabledHours && !readonly && !is_range && !inline">
    <input type="text" #dp="bsDatepicker" placeholder="dd/MM/YYYY HH:mm" [(ngModel)]="selectedValue" class="form-control" bsDatepicker [bsConfig]="{ isAnimated: true, containerClass: 'theme-dark-blue', dateInputFormat: 'DD/MM/YYYY HH:mm', adaptivePosition: false, language: 'es', showWeekNumbers: false, customTodayClass: 'custom-today-class' }" (ngModelChange)="emitValue(selectedValue)" [ngClass]="{'required': verificaRequerido()}" [minDate]="dataMinima" [maxDate]="dataMaxima" disabled="{{(disabled) ? disabled : 'false'}}" [container]="'.' + uniqueID">
    <div class="input-group-append" *ngIf="!disabled ">
        <span class="input-group-text">
            <div class="dropdown" dropdown>
                <i class="far fa-clock" style="font-size: 16px; color: gray; cursor: pointer;" id="dropdownMenuButton" dropdownToggle></i>
                <!-- <div aria-labelledby="dropdownMenuButton" *dropdownMenu class="dropdown-menu" style="max-height: 200px; overflow-y: auto; margin-left: -160px; margin-top: 11px;">
                    <a #H0000 class="dropdown-item" href="javascript:void(0)" (click)="setHours('00', '00', H0000)">00:00</a>
                    <a #H0030 class="dropdown-item" href="javascript:void(0)" (click)="setHours('00', '30', H0030)">00:30</a>
                    <a #H0100 class="dropdown-item" href="javascript:void(0)" (click)="setHours('01', '00', H0100)">01:00</a>
                    <a #H0130 class="dropdown-item" href="javascript:void(0)" (click)="setHours('01', '30', H0130)">01:30</a>
                    <a #H0200 class="dropdown-item" href="javascript:void(0)" (click)="setHours('02', '00', H0200)">02:00</a>
                    <a #H0230 class="dropdown-item" href="javascript:void(0)" (click)="setHours('02', '30', H0230)">02:30</a>
                    <a #H0300 class="dropdown-item" href="javascript:void(0)" (click)="setHours('03', '00', H0300)">03:00</a>
                    <a #H0330 class="dropdown-item" href="javascript:void(0)" (click)="setHours('03', '30', H0330)">03:30</a>
                    <a #H0400 class="dropdown-item" href="javascript:void(0)" (click)="setHours('04', '00', H0400)">04:00</a>
                    <a #H0430 class="dropdown-item" href="javascript:void(0)" (click)="setHours('04', '30', H0430)">04:30</a>
                    <a #H0500 class="dropdown-item" href="javascript:void(0)" (click)="setHours('05', '00', H0500)">05:00</a>
                    <a #H0530 class="dropdown-item" href="javascript:void(0)" (click)="setHours('05', '30', H0530)">05:30</a>
                    <a #H0600 class="dropdown-item" href="javascript:void(0)" (click)="setHours('06', '00', H0600)">06:00</a>
                    <a #H0630 class="dropdown-item" href="javascript:void(0)" (click)="setHours('06', '30', H0630)">06:30</a>
                    <a #H0700 class="dropdown-item" href="javascript:void(0)" (click)="setHours('07', '00', H0700)">07:00</a>
                    <a #H0730 class="dropdown-item" href="javascript:void(0)" (click)="setHours('07', '30', H0730)">07:30</a>
                    <a #H0800 class="dropdown-item" href="javascript:void(0)" (click)="setHours('08', '00', H0800)">08:00</a>
                    <a #H0830 class="dropdown-item" href="javascript:void(0)" (click)="setHours('08', '30', H0830)">08:30</a>
                    <a #H0900 class="dropdown-item" href="javascript:void(0)" (click)="setHours('09', '00', H0900)">09:00</a>
                    <a #H0930 class="dropdown-item" href="javascript:void(0)" (click)="setHours('09', '30', H0930)">09:30</a>
                    <a #H1000 class="dropdown-item" href="javascript:void(0)" (click)="setHours('10', '00', H1000)">10:00</a>
                    <a #H1030 class="dropdown-item" href="javascript:void(0)" (click)="setHours('10', '30', H1030)">10:30</a>
                    <a #H1100 class="dropdown-item" href="javascript:void(0)" (click)="setHours('11', '00', H1100)">11:00</a>
                    <a #H1130 class="dropdown-item" href="javascript:void(0)" (click)="setHours('11', '30', H1130)">11:30</a>
                    <a #H1200 class="dropdown-item" href="javascript:void(0)" (click)="setHours('12', '00', H1200)">12:00</a>
                    <a #H1230 class="dropdown-item" href="javascript:void(0)" (click)="setHours('12', '30', H1230)">12:30</a>
                    <a #H1300 class="dropdown-item" href="javascript:void(0)" (click)="setHours('13', '00', H1300)">13:00</a>
                    <a #H1330 class="dropdown-item" href="javascript:void(0)" (click)="setHours('13', '30', H1330)">13:30</a>
                    <a #H1400 class="dropdown-item" href="javascript:void(0)" (click)="setHours('14', '00', H1400)">14:00</a>
                    <a #H1430 class="dropdown-item" href="javascript:void(0)" (click)="setHours('14', '30', H1430)">14:30</a>
                    <a #H1500 class="dropdown-item" href="javascript:void(0)" (click)="setHours('15', '00', H1500)">15:00</a>
                    <a #H1530 class="dropdown-item" href="javascript:void(0)" (click)="setHours('15', '30', H1530)">15:30</a>
                    <a #H1600 class="dropdown-item" href="javascript:void(0)" (click)="setHours('16', '00', H1600)">16:00</a>
                    <a #H1630 class="dropdown-item" href="javascript:void(0)" (click)="setHours('16', '30', H1630)">16:30</a>
                    <a #H1700 class="dropdown-item" href="javascript:void(0)" (click)="setHours('17', '00', H1700)">17:00</a>
                    <a #H1730 class="dropdown-item" href="javascript:void(0)" (click)="setHours('17', '30', H1730)">17:30</a>
                    <a #H1800 class="dropdown-item" href="javascript:void(0)" (click)="setHours('18', '00', H1800)">18:00</a>
                    <a #H1830 class="dropdown-item" href="javascript:void(0)" (click)="setHours('18', '30', H1830)">18:30</a>
                    <a #H1900 class="dropdown-item" href="javascript:void(0)" (click)="setHours('19', '00', H1900)">19:00</a>
                    <a #H1930 class="dropdown-item" href="javascript:void(0)" (click)="setHours('19', '30', H1930)">19:30</a>
                    <a #H2000 class="dropdown-item" href="javascript:void(0)" (click)="setHours('20', '00', H2000)">20:00</a>
                    <a #H2030 class="dropdown-item" href="javascript:void(0)" (click)="setHours('20', '30', H2030)">20:30</a>
                    <a #H2100 class="dropdown-item" href="javascript:void(0)" (click)="setHours('21', '00', H2100)">21:00</a>
                    <a #H2130 class="dropdown-item" href="javascript:void(0)" (click)="setHours('21', '30', H2130)">21:30</a>
                    <a #H2200 class="dropdown-item" href="javascript:void(0)" (click)="setHours('22', '00', H2200)">22:00</a>
                    <a #H2230 class="dropdown-item" href="javascript:void(0)" (click)="setHours('22', '30', H2230)">22:30</a>
                    <a #H2300 class="dropdown-item" href="javascript:void(0)" (click)="setHours('23', '00', H2300)">23:00</a>
                    <a #H2330 class="dropdown-item" href="javascript:void(0)" (click)="setHours('23', '30', H2330)">23:30</a>
                </div> -->
                <div aria-labelledby="dropdownMenuButton" *dropdownMenu class="dropdown-menu" style="max-height: 200px; overflow-y: none; margin-left: -160px; margin-top: 11px;">
                    <div style="display: flex; justify-content: space-evenly;">
                        <div>{{'HORAS' | translate}}</div>
                        <div>:</div>
                        <div>{{'MINUTOS' | translate}}</div>
                    </div>
                    <div style="display: flex;">
                        <div style="max-height: 168px; overflow-y: auto; width: 96px; text-align: center; padding: 0px 7px 0px 7px;">
                            <a #H00 class="dropdown-item drop-horas" href="javascript:void(0)" (click)="setHours('00', H00); $event.stopPropagation()">00h</a>
                            <a #H01 class="dropdown-item drop-horas" href="javascript:void(0)" (click)="setHours('01', H01); $event.stopPropagation()">01h</a>
                            <a #H02 class="dropdown-item drop-horas" href="javascript:void(0)" (click)="setHours('02', H02); $event.stopPropagation()">02h</a>
                            <a #H03 class="dropdown-item drop-horas" href="javascript:void(0)" (click)="setHours('03', H03); $event.stopPropagation()">03h</a>
                            <a #H04 class="dropdown-item drop-horas" href="javascript:void(0)" (click)="setHours('04', H04); $event.stopPropagation()">04h</a>
                            <a #H05 class="dropdown-item drop-horas" href="javascript:void(0)" (click)="setHours('05', H05); $event.stopPropagation()">05h</a>
                            <a #H06 class="dropdown-item drop-horas" href="javascript:void(0)" (click)="setHours('06', H06); $event.stopPropagation()">06h</a>
                            <a #H07 class="dropdown-item drop-horas" href="javascript:void(0)" (click)="setHours('07', H07); $event.stopPropagation()">07h</a>
                            <a #H08 class="dropdown-item drop-horas" href="javascript:void(0)" (click)="setHours('08', H08); $event.stopPropagation()">08h</a>
                            <a #H09 class="dropdown-item drop-horas" href="javascript:void(0)" (click)="setHours('09', H09); $event.stopPropagation()">09h</a>
                            <a #H10 class="dropdown-item drop-horas" href="javascript:void(0)" (click)="setHours('10', H10); $event.stopPropagation()">10h</a>
                            <a #H11 class="dropdown-item drop-horas" href="javascript:void(0)" (click)="setHours('11', H11); $event.stopPropagation()">11h</a>
                            <a #H12 class="dropdown-item drop-horas" href="javascript:void(0)" (click)="setHours('12', H12); $event.stopPropagation()">12h</a>
                            <a #H13 class="dropdown-item drop-horas" href="javascript:void(0)" (click)="setHours('13', H13); $event.stopPropagation()">13h</a>
                            <a #H14 class="dropdown-item drop-horas" href="javascript:void(0)" (click)="setHours('14', H14); $event.stopPropagation()">14h</a>
                            <a #H15 class="dropdown-item drop-horas" href="javascript:void(0)" (click)="setHours('15', H15); $event.stopPropagation()">15h</a>
                            <a #H16 class="dropdown-item drop-horas" href="javascript:void(0)" (click)="setHours('16', H16); $event.stopPropagation()">16h</a>
                            <a #H17 class="dropdown-item drop-horas" href="javascript:void(0)" (click)="setHours('17', H17); $event.stopPropagation()">17h</a>
                            <a #H18 class="dropdown-item drop-horas" href="javascript:void(0)" (click)="setHours('18', H18); $event.stopPropagation()">18h</a>
                            <a #H19 class="dropdown-item drop-horas" href="javascript:void(0)" (click)="setHours('19', H19); $event.stopPropagation()">19h</a>
                            <a #H20 class="dropdown-item drop-horas" href="javascript:void(0)" (click)="setHours('20', H20); $event.stopPropagation()">20h</a>
                            <a #H21 class="dropdown-item drop-horas" href="javascript:void(0)" (click)="setHours('21', H21); $event.stopPropagation()">21h</a>
                            <a #H22 class="dropdown-item drop-horas" href="javascript:void(0)" (click)="setHours('22', H22); $event.stopPropagation()">22h</a>
                            <a #H23 class="dropdown-item drop-horas" href="javascript:void(0)" (click)="setHours('23', H23); $event.stopPropagation()">23h</a>
                        </div>
                        <div style="max-height: 168px; overflow-y: auto; width: 95px; text-align: center; padding: 0px 7px 0px 7px;">
                            <a #M00 class="dropdown-item drop-minutos" href="javascript:void(0)" (click)="setMinutes('00', M00)">00m</a>
                            <a #M05 class="dropdown-item drop-minutos" href="javascript:void(0)" (click)="setMinutes('05', M05)">05m</a>
                            <a #M10 class="dropdown-item drop-minutos" href="javascript:void(0)" (click)="setMinutes('10', M10)">10m</a>
                            <a #M15 class="dropdown-item drop-minutos" href="javascript:void(0)" (click)="setMinutes('15', M15)">15m</a>
                            <a #M20 class="dropdown-item drop-minutos" href="javascript:void(0)" (click)="setMinutes('20', M20)">20m</a>
                            <a #M25 class="dropdown-item drop-minutos" href="javascript:void(0)" (click)="setMinutes('25', M25)">25m</a>
                            <a #M30 class="dropdown-item drop-minutos" href="javascript:void(0)" (click)="setMinutes('30', M30)">30m</a>
                            <a #M35 class="dropdown-item drop-minutos" href="javascript:void(0)" (click)="setMinutes('35', M35)">35m</a>
                            <a #M40 class="dropdown-item drop-minutos" href="javascript:void(0)" (click)="setMinutes('40', M40)">40m</a>
                            <a #M45 class="dropdown-item drop-minutos" href="javascript:void(0)" (click)="setMinutes('45', M45)">45m</a>
                            <a #M50 class="dropdown-item drop-minutos" href="javascript:void(0)" (click)="setMinutes('50', M50)">50m</a>
                            <a #M55 class="dropdown-item drop-minutos" href="javascript:void(0)" (click)="setMinutes('55', M55)">55m</a>
                        </div>
                    </div>
                </div>
            </div>
        </span>
    </div>
</div>

<div class="input-group {{uniqueID}}" *ngIf="!disabledHours && readonly && !is_range && !inline">
    <input readonly style="cursor: pointer;" type="text" #dp="bsDatepicker" placeholder="dd/MM/YYYY HH:mm" [(ngModel)]="selectedValue" class="form-control" bsDatepicker [bsConfig]="{ isAnimated: true, containerClass: 'theme-dark-blue', dateInputFormat: 'DD/MM/YYYY HH:mm', adaptivePosition: true, language: 'es', showWeekNumbers: false, customTodayClass: 'custom-today-class' }" (ngModelChange)="emitValue(selectedValue)" [container]="'.' + uniqueID" [ngClass]="{'required': verificaRequerido()}" [minDate]="dataMinima" [maxDate]="dataMaxima" disabled="{{(disabled) ? disabled : 'false'}}">
    <div class="input-group-append" *ngIf="!disabled ">
        <span class="input-group-text">
            <div class="dropdown" dropdown>
                <i class="far fa-clock" style="font-size: 16px; color: gray; cursor: pointer;" id="dropdownMenuButton" dropdownToggle></i>
                <div aria-labelledby="dropdownMenuButton" *dropdownMenu class="dropdown-menu" style="max-height: 200px; overflow-y: none; margin-left: -160px; margin-top: 11px;">
                    <div style="display: flex; justify-content: space-evenly;">
                        <div>{{'HORAS' | translate}}</div>
                        <div>:</div>
                        <div>{{'MINUTOS' | translate}}</div>
                    </div>
                    <div style="display: flex;">
                        <div style="max-height: 168px; overflow-y: auto; width: 96px; text-align: center; padding: 0px 7px 0px 7px;">
                            <a #H00 class="dropdown-item drop-horas" href="javascript:void(0)" (click)="setHours('00', H00); $event.stopPropagation()">00h</a>
                            <a #H01 class="dropdown-item drop-horas" href="javascript:void(0)" (click)="setHours('01', H01); $event.stopPropagation()">01h</a>
                            <a #H02 class="dropdown-item drop-horas" href="javascript:void(0)" (click)="setHours('02', H02); $event.stopPropagation()">02h</a>
                            <a #H03 class="dropdown-item drop-horas" href="javascript:void(0)" (click)="setHours('03', H03); $event.stopPropagation()">03h</a>
                            <a #H04 class="dropdown-item drop-horas" href="javascript:void(0)" (click)="setHours('04', H04); $event.stopPropagation()">04h</a>
                            <a #H05 class="dropdown-item drop-horas" href="javascript:void(0)" (click)="setHours('05', H05); $event.stopPropagation()">05h</a>
                            <a #H06 class="dropdown-item drop-horas" href="javascript:void(0)" (click)="setHours('06', H06); $event.stopPropagation()">06h</a>
                            <a #H07 class="dropdown-item drop-horas" href="javascript:void(0)" (click)="setHours('07', H07); $event.stopPropagation()">07h</a>
                            <a #H08 class="dropdown-item drop-horas" href="javascript:void(0)" (click)="setHours('08', H08); $event.stopPropagation()">08h</a>
                            <a #H09 class="dropdown-item drop-horas" href="javascript:void(0)" (click)="setHours('09', H09); $event.stopPropagation()">09h</a>
                            <a #H10 class="dropdown-item drop-horas" href="javascript:void(0)" (click)="setHours('10', H10); $event.stopPropagation()">10h</a>
                            <a #H11 class="dropdown-item drop-horas" href="javascript:void(0)" (click)="setHours('11', H11); $event.stopPropagation()">11h</a>
                            <a #H12 class="dropdown-item drop-horas" href="javascript:void(0)" (click)="setHours('12', H12); $event.stopPropagation()">12h</a>
                            <a #H13 class="dropdown-item drop-horas" href="javascript:void(0)" (click)="setHours('13', H13); $event.stopPropagation()">13h</a>
                            <a #H14 class="dropdown-item drop-horas" href="javascript:void(0)" (click)="setHours('14', H14); $event.stopPropagation()">14h</a>
                            <a #H15 class="dropdown-item drop-horas" href="javascript:void(0)" (click)="setHours('15', H15); $event.stopPropagation()">15h</a>
                            <a #H16 class="dropdown-item drop-horas" href="javascript:void(0)" (click)="setHours('16', H16); $event.stopPropagation()">16h</a>
                            <a #H17 class="dropdown-item drop-horas" href="javascript:void(0)" (click)="setHours('17', H17); $event.stopPropagation()">17h</a>
                            <a #H18 class="dropdown-item drop-horas" href="javascript:void(0)" (click)="setHours('18', H18); $event.stopPropagation()">18h</a>
                            <a #H19 class="dropdown-item drop-horas" href="javascript:void(0)" (click)="setHours('19', H19); $event.stopPropagation()">19h</a>
                            <a #H20 class="dropdown-item drop-horas" href="javascript:void(0)" (click)="setHours('20', H20); $event.stopPropagation()">20h</a>
                            <a #H21 class="dropdown-item drop-horas" href="javascript:void(0)" (click)="setHours('21', H21); $event.stopPropagation()">21h</a>
                            <a #H22 class="dropdown-item drop-horas" href="javascript:void(0)" (click)="setHours('22', H22); $event.stopPropagation()">22h</a>
                            <a #H23 class="dropdown-item drop-horas" href="javascript:void(0)" (click)="setHours('23', H23); $event.stopPropagation()">23h</a>
                        </div>
                        <div style="max-height: 168px; overflow-y: auto; width: 95px; text-align: center; padding: 0px 7px 0px 7px;">
                            <a #M00 class="dropdown-item drop-minutos" href="javascript:void(0)" (click)="setMinutes('00', M00); $event.stopPropagation()">00m</a>
                            <a #M05 class="dropdown-item drop-minutos" href="javascript:void(0)" (click)="setMinutes('05', M05); $event.stopPropagation()">05m</a>
                            <a #M10 class="dropdown-item drop-minutos" href="javascript:void(0)" (click)="setMinutes('10', M10); $event.stopPropagation()">10m</a>
                            <a #M15 class="dropdown-item drop-minutos" href="javascript:void(0)" (click)="setMinutes('15', M15); $event.stopPropagation()">15m</a>
                            <a #M20 class="dropdown-item drop-minutos" href="javascript:void(0)" (click)="setMinutes('20', M20); $event.stopPropagation()">20m</a>
                            <a #M25 class="dropdown-item drop-minutos" href="javascript:void(0)" (click)="setMinutes('25', M25); $event.stopPropagation()">25m</a>
                            <a #M30 class="dropdown-item drop-minutos" href="javascript:void(0)" (click)="setMinutes('30', M30); $event.stopPropagation()">30m</a>
                            <a #M35 class="dropdown-item drop-minutos" href="javascript:void(0)" (click)="setMinutes('35', M35); $event.stopPropagation()">35m</a>
                            <a #M40 class="dropdown-item drop-minutos" href="javascript:void(0)" (click)="setMinutes('40', M40); $event.stopPropagation()">40m</a>
                            <a #M45 class="dropdown-item drop-minutos" href="javascript:void(0)" (click)="setMinutes('45', M45); $event.stopPropagation()">45m</a>
                            <a #M50 class="dropdown-item drop-minutos" href="javascript:void(0)" (click)="setMinutes('50', M50); $event.stopPropagation()">50m</a>
                            <a #M55 class="dropdown-item drop-minutos" href="javascript:void(0)" (click)="setMinutes('55', M55); $event.stopPropagation()">55m</a>
                        </div>
                    </div>
                </div>
            </div>
        </span>
    </div>
</div>

<div class="input-group {{uniqueID}}" *ngIf="is_range && !inline">
    <input type="text" placeholder="dd/MM/YYYY" [(ngModel)]="selectedValue" class="form-control" bsDaterangepicker [bsConfig]="{ isAnimated: true, containerClass: 'theme-dark-blue', dateInputFormat: 'DD/MM/YYYY', adaptivePosition: true, language: 'es', showWeekNumbers: false, customTodayClass: 'custom-today-class' }" (ngModelChange)="emitValue(selectedValue)" [container]="'.' + uniqueID" [ngClass]="{'required': verificaRequerido()}" [minDate]="dataMinima" [maxDate]="dataMaxima" disabled="{{(disabled) ? disabled : 'false'}}">
</div>

<div class="input-group {{uniqueID}}" *ngIf="is_range && inline">
    <bs-daterangepicker-inline bsDaterangepicker
        [bsConfig]="{ isAnimated: true, containerClass: 'theme-dark-blue', selectFromOtherMonth: true, adaptivePosition: false, showWeekNumbers: false, customTodayClass: 'custom-today-class' }"
        [(bsValue)]="selectedValue" (bsValueChange)="emitValue(selectedValue)" [container]="'.' + uniqueID" [minDate]="dataMinima" [maxDate]="dataMaxima">
    </bs-daterangepicker-inline>
</div>

<div class="input-group {{uniqueID}}" *ngIf="!is_range && inline">
    <bs-datepicker-inline bsDatepicker
        [bsConfig]="{ isAnimated: true, containerClass: 'theme-dark-blue', adaptivePosition: true, showWeekNumbers: false, customTodayClass: 'custom-today-class' }"
        [(bsValue)]="selectedValue" (bsValueChange)="emitValue(selectedValue)" [container]="'.' + uniqueID" [minDate]="dataMinima" [maxDate]="dataMaxima">
    </bs-datepicker-inline>
</div>