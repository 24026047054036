<div class="modal-content" style="zoom: 0.85;">
    <div class="modal-header">
        <h6 class="modal-title" id="modal-title-default">{{'HISTORICO_CHAT' | translate}}</h6>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="close()">
            <i class="mdi mdi-close"></i>
        </button>
    </div>
    <div class="modal-body">
        <filtros [tipoFiltro]="'listagemHistoricoChat'" [arrayFiltros]="arrayFiltrosHistoricoChat" (filtrosChange)="aplicarFiltros($event)"></filtros>
        <div class=" row" style="margin-top: -12px;">
            <div class=" col-xl-12">
                <div class=" table-responsive table-listagem">
                    <table class=" table align-items-center table-flush" style="margin-bottom: 0; overflow: hidden;">
                        <thead class=" thead-light">
                            <tr>
                                <th class="w-30" style="padding-left: 18px; padding-top: 9px; padding-bottom: 9px;" (click)="filterBy('data_atendimento')">
                                    <div style="display: flex; align-items: center; cursor: pointer;">
                                            <div style="display: inline-grid;">
                                                <span [translate]="'DATA_ATENDIMENTO'"></span>
                                            </div>
                                            <div style="display: inline-grid; margin-left: 10px;">
                                                <i class="fas fa-caret-up" style="color: #3f73e9;"></i>
                                                <i class="fas fa-caret-down" style="color: #3f73e9;"></i>
                                            </div>
                                    </div>
                                </th>
                                <th class="w-20" style="padding-top: 9px; padding-bottom: 9px;">
                                    <div style="display: flex; align-items: center;">
                                            <div style="display: inline-grid;">
                                                <span [translate]="'CONTATO'"></span>
                                            </div>
                                    </div>
                                </th>
                                <th class="w-20" style="padding-top: 9px; padding-bottom: 9px;">
                                    <div style="display: flex; align-items: center;">
                                            <div style="display: inline-grid;">
                                                <span [translate]="'FILA'"></span>
                                            </div>
                                    </div>
                                </th>
                                <th class="w-20" style="padding-top: 9px; padding-bottom: 9px;" (click)="filterBy('status_chat_id')">
                                    <div style="display: flex; align-items: center; cursor: pointer;">
                                            <div style="display: inline-grid;">
                                                <span [translate]="'STATUS'"></span>
                                            </div>
                                            <div style="display: inline-grid; margin-left: 10px;">
                                                <i class="fas fa-caret-up" style="color: #3f73e9;"></i>
                                                <i class="fas fa-caret-down" style="color: #3f73e9;"></i>
                                            </div>
                                    </div>
                                </th>
                                <th class="w-10" style="padding-top: 9px; padding-bottom: 9px;"></th>
                            </tr>
                        </thead>
                        <tbody class=" list">
                            <tr *ngFor="let chat of listaHistoricoChat">
                                <td class="w-10" style="padding-left: 18px">{{chat.data_inicio_atendimento ? (chat.data_inicio_atendimento |
                                    date:'dd/MM/yyyy HH:mm') : ('NAO_POSSUI' | translate) }}</td>
                                <td class="w-20">{{chat.nome_contato | ellipsis: 80 | null}}</td>
                                <td class="w-10">{{chat.fila_atendimento ? chat.fila_atendimento.descricao : ('NAO_POSSUI' |
                                    translate)}}</td>
                                
                                <td class="w-10">{{capitalize(chat.status) | translate}}</td>
                                <td class="d-flex justify-content-end">
                                    <button type="button" class="btn btn-sm btn-darkgray btn-sm-rounded-milvus" 
                                        (click)="openDetailsModal(chat)" container="body" [ngbTooltip]="('DETALHES' | translate)">
                                        <i class="fas fa-eye"></i>
                                    </button>
                                    <button type="button" class="btn btn-sm btn-warning btn-sm-rounded-milvus"
                                        (click)="abrirModalChat(chat)" container="body" [ngbTooltip]="('ABRIR_CHAT' | translate)">
                                        <i class="fas fa-comment"></i>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <td colspan="8" style="font-size: 16px; padding: 0 14px 0 18px;">
                                    <pagination *ngIf="listaHistoricoChat.length > 0" [listagem]="'sim'" [meta]="pagination" (pageChange)="paginarListagem($event)">
                                    </pagination>
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>