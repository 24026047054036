import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MinhaAgendaPortuguesComponent } from './minha-agenda-portugues.component';
import { HttpClient } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { HttpLoaderFactory } from 'src/app/app.module';
import { PipesModule } from 'src/app/shared/validators/pipes/pipes.module';
import { DayService, WeekService, WorkWeekService, MonthService, AgendaService, MonthAgendaService, TimelineViewsService, TimelineMonthService, ScheduleModule, DragAndDropService } from '@syncfusion/ej2-angular-schedule';

import { FiltrosModule } from '../filtros/filtros.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,    
    ReactiveFormsModule,
    CollapseModule.forRoot(),
    PerfectScrollbarModule,
    BsDropdownModule.forRoot(),
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    PipesModule,
    NgbModule,
    ScheduleModule,
    FiltrosModule,
  ],
  declarations: [
    MinhaAgendaPortuguesComponent
  ],
  exports: [
    MinhaAgendaPortuguesComponent,
  ],
  providers: [
    DayService, WeekService, WorkWeekService, MonthService, AgendaService, MonthAgendaService, TimelineViewsService, TimelineMonthService, DragAndDropService
  ]
})
export class MinhaAgendaPortuguesModule { }
