import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NoHtmlFormatterComponent } from './no-html-formatter.component';
import { PipesModule } from 'src/app/shared/validators/pipes/pipes.module';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
  }

@NgModule({
  imports: [
    CommonModule,        
    TranslateModule.forChild({
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient],
        },
      }),
    PipesModule
  ],
  declarations: [
    NoHtmlFormatterComponent
  ],
  exports: [
    NoHtmlFormatterComponent
  ],
  providers: [
  ]
})
export class NoHtmlFormatterModule { }
