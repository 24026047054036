<div class="modal-content">
    <div class="modal-header">
        <h6 class="modal-title" id="modal-title-default" [translate]="'CONFIGURAR_FILTROS'"></h6>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modal.close()">
            <i class="mdi mdi-close"></i>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-12">
                <span [translate]="'TXT_MODAL_FILTROS_1'"></span>
            </div>
            <div class="col-12 mt-2">
                <span [translate]="'TXT_MODAL_FILTROS_2'"></span>
            </div>
            <div class="col-12 mt-2">
                <span [translate]="'TXT_MODAL_FILTROS_3'"></span>
            </div>
        </div>
        <div #filtrosNovos class="row collapseFiltros" [ngClass]="{'filtroEscolhido': usaFiltroNovo}">
            <div id="filtroNovo" class="col-12 collapseHeader" (click)="collapseOrUndoNovo(filtrosNovos)">
                <label class="radio-box">
                    <input type="radio" name="filtroNovoRadio"
                        [checked]="usaFiltroNovo"
                        [(ngModel)]="usaFiltroNovo"
                        [value]="true"
                        (click)="guardaFiltroEscolhido(usaFiltroNovo)">
                    <span class="checkmark"></span>
                </label>
                <span [translate]="'UTILIZAR_FILTRO_NOVO'"></span>
            </div>
            <div class="col-12 mt-2 pb-2">
                <img src="../../../../assets/img/general/Filtro Novo.png" width="100%" style="padding-bottom: 10px">
                <span [translate]="'TXT_MODAL_FILTROS_4'"></span>
            </div>
            <div class="col-12 mt-2">
                <div class="col-12 pt-2 pb-2" style="display: flex; justify-content: center;">
                    <img src="../../../../assets/img/general/gif-filtro-novo-criar.gif" width="60%">
                </div>
                <span class="pt-2" [translate]="'TXT_MODAL_FILTROS_5'"></span>
            </div>
            <div class="col-12 mt-2">
                <span class="pb-2" [translate]="'TXT_MODAL_FILTROS_6'"></span>
            </div>
        </div>
        <div #filtrosAntigos class="row collapseFiltros" [ngClass]="{'filtroEscolhido': !usaFiltroNovo}">
            <div id="filtroAntigo" class="col-12 collapseHeader" (click)="collapseOrUndoAntigo(filtrosAntigos)">
                <label class="radio-box">
                    <input type="radio" name="filtroAntigoRadio"
                        [checked]="!usaFiltroNovo"
                        [(ngModel)]="usaFiltroNovo"
                        [value]="false"
                        (click)="guardaFiltroEscolhido(usaFiltroNovo)">
                    <span class="checkmark"></span>
                </label>
                <span [translate]="'UTILIZAR_FILTRO_CLASSICO'"></span>
            </div>
            <div class="col-12 mt-2 pb-2">
                <img src="../../../../assets/img/general/Filtro Antigo.png" width="100%" style="padding-bottom: 10px">
                <span class="pt-2 pb-2" [translate]="'TXT_MODAL_FILTROS_7'"></span>
                <div class="col-12 pt-2 pb-2" style="display: flex; justify-content: center;">
                    <img src="../../../../assets/img/general/gif-filtro-antigo.gif" width="60%">
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-success btn-md badge-pill" (click)="abreModalFeedback()" [translate]="'ENVIAR_FEEDBACK'"></button>
        <button type="button" class="btn btn-info btn-md badge-pill" (click)="modal.close(true)" [translate]="'APLICAR'"></button>
    </div>
</div>