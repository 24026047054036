import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'hourpicker-padrao',
  templateUrl: './hourpicker-padrao.component.html',
  styleUrls: ['./hourpicker-padrao.component.scss']
})
export class HourpickerPadraoComponent implements OnInit {
  hourValue;
  dropHoras = [];
  @Input() set model (value) {
    if(value && value !== null) {
      this.hourValue = value;
    }
  }
  @Input() required;
  @Input() disabled;
  @Input() is24;
  @Input() isSLA;
  @Input() isDropup;
  @Input() dropSpecialCharacters: boolean;
  @Output() hourChange = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
    this.dropSpecialCharacters = this.dropSpecialCharacters ? true : false;
    if(this.is24) {
      for(var i = 0; i < 24; i++) {
        if(i < 10) {
          this.dropHoras.push("0" + i);
        } else {
          this.dropHoras.push(i.toString());
        }
      }
    } else {
      for(var i = 0; i < 100; i++) {
        if(i < 10) {
          this.dropHoras.push("0" + i);
        } else {
          this.dropHoras.push(i.toString());
        }
      }
    }
  }

  emitValue(value): void {
    let hr = '00:00';
    if (value) {
      if (this.isSLA) {
        hr = this.buildSLAHour(value);
        this.hourChange.emit(hr);
      } else {
        hr = value[0] + value[1] + ":" + value[2] + value[3];
        this.hourChange.emit(hr);
      }
    } else {
      this.hourChange.emit(hr);
    }
  }

  // HHHH:MM - 6 characters
  buildSLAHour(value: any): string {
    let formated = value;
    let strHr = '00';

    if (value.length < 3) {
      strHr = value.toString().padStart(2, '0');
      formated = strHr + ':00';
      return formated;
    } else if (value.length === 3) {
      strHr = value.toString().slice(0, 2);
      let min = value.toString().slice(2, 3);
      formated = strHr + ':' + min + '0';
      return formated;
    } else if (value.length === 4) {
      strHr = value.toString().slice(0, 2);
      let min = value.toString().slice(2, 4);
      formated = strHr + ':' + min;
      return formated;
    } else if (value.length === 5) {
      strHr = value.toString().slice(0, 3);
      let min = value.toString().slice(3, 5);
      formated = strHr + ':' + min;
      return formated;
    } else if (value.length === 6) {
      strHr = value.toString().slice(0, 4);
      let min = value.toString().slice(4, 6);
      formated = strHr + ':' + min;
      return formated;
    }
    return formated;
  }

  verificaRequerido(): boolean {
    if(this.required === true) {
      if(this.hourValue) {
        if(this.hourValue !== '' && this.hourValue !== null && this.hourValue.length > 3) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  setHours(hour, elem): void {
    if(this.hourValue === undefined || this.hourValue === '' || this.hourValue.length < 5) {
      this.hourValue = hour + "00";
    } else {
      this.hourValue = hour + this.hourValue[3] + this.hourValue[4];
    }

    var dropHoras = document.getElementsByClassName('drop-horas');
    for(var i = 0; i < dropHoras.length; i++) {
      dropHoras[i].classList.remove('hora-selecionada');
    }
    elem.classList.add('hora-selecionada');
    this.emitValue(this.hourValue);
  }

  setMinutes(min, elem): void {
    if(this.hourValue === undefined || this.hourValue === '' || this.hourValue.length < 2) {
      this.hourValue = "00" + min;
    } else {
      this.hourValue = this.hourValue[0] + this.hourValue[1] + min;
    }

    var dropHoras = document.getElementsByClassName('drop-minutos');
    for(var i = 0; i < dropHoras.length; i++) {
      dropHoras[i].classList.remove('hora-selecionada');
    }
    elem.classList.add('hora-selecionada');
    this.emitValue(this.hourValue);
  }

  // limit max hours (1 year) -- 8760 hours
  formatHourSla(){
    // HHHH:MM - 6 characters
    if(this.hourValue && this.hourValue.length === 6){
      let hr = this.hourValue;
      hr = hr.slice(0, 4);
      if(hr > 8760){
        this.hourValue = '8760:00'
      }
    }
  }
}
