import { ModalEnviarFeedbackComponent } from './modal-enviar-feedback/modal-enviar-feedback.component';
import { TranslateService } from '@ngx-translate/core';
import { LocalstorageService } from 'src/app/core/localstorage/localstorage.service';
import { ElementRef } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-modal-config-filtros',
  templateUrl: './modal-config-filtros.component.html',
  styleUrls: ['./modal-config-filtros.component.scss']
})

export class ModalConfigFiltrosComponent implements OnInit{
  selector: string;
  collapseFiltroNovo: boolean = true;
  collapseFiltroAntigo: boolean = true;
  usaFiltroNovo: boolean = true;

  constructor(
    public modal: NgbActiveModal,
    private elemRef: ElementRef,
    private storageService: LocalstorageService,
    private translate: TranslateService,
    private modalService: NgbModal
  ) { this.selector = this.elemRef.nativeElement.tagName.toLowerCase(); }

  ngOnInit(): void {
    document.getElementsByTagName(this.selector)[0]["style"]["zoom"] = "0.85";
    this.verificaFiltroNovo();
  }

  verificaFiltroNovo(): void {
    const _tipoFiltroSalvo = this.storageService.getStoredItemSimple('usa_filtro_novo');
    this.usaFiltroNovo = (_tipoFiltroSalvo !== null) ? _tipoFiltroSalvo : false;
  }

  openCollapseDiv(tipo: string): void {
    switch(tipo) {
      case 'novo':
        document.getElementById('filtroNovo').click();
        break;
      case 'antigo':
        document.getElementById('filtroAntigo').click();
        break;
    }
  }

  guardaFiltroEscolhido(valor): void {
    const valueToStorage = (valor !== null) ? !valor : false;
    this.storageService.storageItemSimple('usa_filtro_novo', valueToStorage);
    this.mostraAlertaFeedback();
  }

  mostraAlertaFeedback(): void {
    let _enviouFb = this.storageService.getStoredItemSimple('enviou_fb_filtros');
    if(this.usaFiltroNovo === true && !_enviouFb) {
      const configAlerta = {
        title: this.translate.instant('DESEJA_ENVIAR_FEEDBACK_FILTROS'),
        text: this.translate.instant('TEXTO_ALERTA_FEEDBACK_FILTROS'),
        icon: 'warning',
        confirmButtonText: this.translate.instant('ENVIAR_FEEDBACK'),
        cancelButtonText: this.translate.instant('MAIS_TARDE'),
      };
  
      environment.disparaAlerta(configAlerta).then((result) => {
        if (result.value) {
          this.abreModalFeedback();
        }
      });
    }
  }

  collapseOrUndoNovo(block): void {
    if(this.collapseFiltroNovo) {
      this.collapseFiltroNovo = false;
      block.style.height = block.scrollHeight + 'px';
    } else {
      this.collapseFiltroNovo = true;
      block.style.height = '50px';
    }
  }

  collapseOrUndoAntigo(block): void {
    if(this.collapseFiltroAntigo) {
      this.collapseFiltroAntigo = false;
      block.style.height = block.scrollHeight + 'px';
    } else {
      this.collapseFiltroAntigo = true;
      block.style.height = '50px';
    }
  }

  abreModalFeedback(): void {
    const modalRef = this.modalService.open(ModalEnviarFeedbackComponent, {
      windowClass: 'modal-custom-milvus',
      keyboard: true,
      scrollable: true,
      size: 'medio',
      centered: true
    });

    modalRef.result.then(
      (result) => {
        if(result) {
          this.storageService.storageItemSimple('enviou_fb_filtros', true);
        }
      }
    )
  }
}
