import { Deserializable } from "./deserializable.model";

export class Usuario implements Deserializable {
    id: number;
    empresa_id: number;
    empresa: any
    token: string;
    nome: string;
    celular: string;
    telefone: string;
    username: string;
    password: string;
    foto: string;
    is_admin: boolean;
    is_ativo: boolean;
    descricao: string;
    data_criacao: string;
    data_alteracao: string;
    sobrenome: string;
    tela_inicial_id: number;
    foto_path: string;
    email: string;
    is_dash_personalizada: boolean;
    timezone: string;
    idioma: number;
    is_rd: boolean;
    is_rd_stagio: boolean;
    is_excluido: boolean;
    data_exclusao: string;
    is_online_chat: boolean;
    alerta_chat: boolean;
    is_usuario_integracao: boolean;
    is_integracao_bc: boolean;
    ramal: string;
    pode_conferir: boolean;
    visualiza_apenas_minhas_mesas: boolean;
    atribuir_ticket_outras_mesas: boolean;
    is_apenas_chat: boolean;
    perfis: Array<any>;
    listaIsNew:unknown;
    ultimo_login: Date;
    AccessToken: string;
    tipo_listagem: string;
    static LJHHHASDF: string;
    static LHHHASDFJ: string;
    possui_workflows: boolean;
    tipo_usuario_id: number|null;
    tipo_atendimento_id: number|null|undefined;
    forma_cobranca_id: number|null;

    getCodeOperator(): string {
        return Usuario.LJHHHASDF;
    }

    getCodePasswordVault(): string {
        return Usuario.LHHHASDFJ;
    }

    deserialize(input: any) {
        Object.assign(this, input);
        return this;
    }
}