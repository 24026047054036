import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { TokenService } from 'src/app/core/token/token.service';
import { FiltroAvancadoService } from 'src/app/services/filtroavancado.service';
import { environment } from 'src/environments/environment';
import { CondicaoFiltroAvancadoComponent } from '../condicao-filtro-avancado/condicao-filtro-avancado.component';
import { DatePipe } from '@angular/common';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-modal-novo-filtro-avancado',
  templateUrl: './modal-novo-filtro-avancado.component.html',
  styleUrls: ['./modal-novo-filtro-avancado.component.scss']
})
export class ModalNovoFiltroAvancadoComponent implements OnInit, OnDestroy {

  payload = {
    nome_filtro: '',
    is_favorito: false,
    filtro: {
    }
  }
  filtro = []
  selector: string;
  newSubs: Subscription;

  constructor(
    public activeModal: NgbActiveModal,
    public translate: TranslateService,
    private filtroAvancadoService: FiltroAvancadoService,
    private toastr: ToastrService,
    private tokenService: TokenService,
    private modalService: NgbModal,
    private elemRef: ElementRef,
  ) {
    this.selector = this.elemRef.nativeElement.tagName.toLowerCase();
  }

  ngOnInit(): void {
    document.getElementsByTagName(this.selector)[0]['style']['zoom'] = '0.85';
  }

  ngOnDestroy(): void {
    this.newSubs?.unsubscribe();
  }

  close(result?) {
    this.activeModal.close();
  }

  valid() {
    return this.payload.nome_filtro === '' || this.filtro.length < 1
  }

  salvar() {
    for (let i = 0; i < this.filtro.length; i++) {
      const element = this.filtro[i];
      if (!element.campo.atributo.includes('personalizado')) {
        if (Array.isArray(element.campo.atributo)) {
          for (let i = 0; i < element.campo.atributo.length; i++) {
            element.valor[i] = this.tokenService.convertDateTimezone(element.valor[i])
            this.payload.filtro[element.campo.atributo[i]] = this.tokenService.convertDateTimezone(element.valor[i])
          }
        } else {
          this.payload.filtro[element.campo.atributo] = {}
          element.valor.id = element.valor.id ? parseInt(element.valor.id) : parseInt(element.campo.id);
          this.payload.filtro[element.campo.atributo] = element.valor;
          this.payload.filtro[element.campo.atributo].label = element.campo.text;
          this.payload.filtro[element.campo.atributo].atributo = element.campo.atributo;
        }
      } else {
        this.payload.filtro['personalizado-' + element.campo.id] = element.valor
      }
    }

    this.newSubs = this.filtroAvancadoService.adicionarFiltro(this.payload).subscribe(
      (result) => {
        this.toastr.show(this.translate.instant('SALVO_SUCESSO'), '', environment.toastrConfig('success'));
        this.close()
      },
      (error) => {
        this.toastr.show(error, '', environment.toastrConfig('danger'));
        console.log(error)
      }
    )
  }

  change(item) {
    const datepipe: DatePipe = new DatePipe('en-US')
    return Array.isArray(item.valor) ? datepipe.transform(item.valor[0], 'dd-MM-YYYY') + ' | ' + datepipe.transform(item.valor[1], 'dd-MM-YYYY') : item.valor === "true" ? this.translate.instant('SIM') : item.valor === "false" ? this.translate.instant('NAO') : item.valor
  }

  verificaCampo(lista: Array<any>, result) {
    return lista.findIndex(x => 
      (x.campo.atributo === result.campo.atributo) && (x.campo.text === result.campo.text)
      || x.campo.atributo === 'categorias' && (result.campo.atributo === 'categoria_primaria' || result.campo.atributo === 'categoria_secundaria') 
    );
  }

  openCondicao() {
    const modalRef = this.modalService.open(CondicaoFiltroAvancadoComponent, {
      backdrop: "static",
      windowClass: 'modal-custom-milvus',
      keyboard: true,
      scrollable: false,
      size: "lg",
    });
    
    modalRef.componentInstance.filtroLista = this.filtro;

    modalRef.result.then((result) => {
      if (result && result.campo) {      
        if (Array.isArray(result.campo.atributo)) {
          for (let i = 0; i < result.campo.atributo.length; i++) {
            result.valor[i] = this.tokenService.convertDateTimezone(result.valor[i])
            this.payload.filtro[result.campo.atributo[i]] = this.tokenService.convertDateTimezone(result.valor[i])
          }
          this.filtro.push(result)
        } else {          
          if (this.verificaCampo(this.filtro, result) > -1) {
            this.filtro[this.verificaCampo(this.filtro, result)] = result            
          } else {            
            if (result.campo.chave === '@Categorias') {
              this.payload.filtro['categoria_primaria'] = Number(result.valor.primary);
              this.payload.filtro['categoria_secundaria'] = Number(result.valor.id);
            } else {
              this.payload.filtro[result.campo.atributo] = result.valor.id;
            }
            this.filtro.push(result);                   
          }
        }       
      }
    });
  }

  removeItem(pos) {
    this.filtro.splice(pos, 1);
  }
}