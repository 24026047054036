import { Component, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { TokenService } from 'src/app/core/token/token.service';
import { ChamadosService } from 'src/app/services/chamados.service';
import { ClienteContatoService } from 'src/app/services/cliente-contato.service';
import { ClienteEnderecoService } from 'src/app/services/cliente-endereco.service';
import { ClienteService } from 'src/app/services/cliente.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-modal-infos-cliente-chamado',
  templateUrl: './modal-infos-cliente-chamado.component.html',
  styleUrls: ['./modal-infos-cliente-chamado.component.scss']
})
export class ModalInfosClienteChamadoComponent implements OnInit, OnDestroy {
  @Input() clienteData;
  infosCliente;
  clienteEndereco;
  clienteContato;
  clienteContrato;
  selector: string;
  newSubs: Subscription;
  permissao = {
    EditarCliente: this.tokenService.checkPermissions('@EditarCliente')
  };

  constructor(
    private clienteService: ClienteService,
    private clienteEnderecoService: ClienteEnderecoService,
    private clienteContatoService: ClienteContatoService,
    private chamadosService: ChamadosService,
    public modal: NgbActiveModal,
    private toastr: ToastrService,
    public translate: TranslateService,
    private elemRef: ElementRef,
    private route: Router,
    private tokenService: TokenService
    ) {
      this.selector = elemRef.nativeElement.tagName.toLowerCase();
    }

  ngOnInit(): void {
    document.getElementsByTagName(this.selector)[0]['style']['zoom'] = '0.85';
    this.getInfosCliente();
  }

  ngOnDestroy(): void {
    this.newSubs?.unsubscribe();
  }

  goToCliente(id) {
    window.open(`registration/customers/${id}`, '_blank');
    //this.modal.close(id)
  }

  getInfosCliente(): void {
    this.newSubs = this.clienteService.getById(this.clienteData)
      .subscribe(results => {
        if(results) {
          this.infosCliente = results.cliente;   
          //console.log(this.infosCliente);                 
        }
        this.getEnderecoCliente();
      }, error => {})
  }

  getEnderecoCliente(): void {
    this.newSubs = this.clienteEnderecoService.getEnderecoCliente(this.clienteData)
      .subscribe(results => {
        if(results) {
          this.clienteEndereco = results.clienteEndereco
        }
        this.getContatoCliente();
      }, error => {})
  }

  getContatoCliente(): void {
    this.newSubs = this.clienteContatoService.getContatoCliente(this.clienteData)
      .subscribe(results => {
        if(results) {
          this.clienteContato = results.clienteContato;
        }
        this.getContratoCliente();
      }, error => {})
  }

  getContratoCliente(): void {
    this.newSubs = this.chamadosService.validaAberturaChamado(this.clienteData)
      .subscribe(results => {
        if(results) {
          this.clienteContrato = results;
        }
      }, error => {})
  }

  copyToClipboard(valor): void {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = valor;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.toastr.show(this.translate.instant('COPIADO_SUCESSO'), "", environment.toastrConfig("success"));
  }
}
