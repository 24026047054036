<div class="modal-content modal-milvus">
    <div class="modal-header-milvus align-items-center">
        <div class="col-6 header-col">
            <span class="modal-title" id="modal-title-default">{{'ASSOCIAR_CONTATO' | translate}}</span>
        </div>
        <div class="col-6 header-col">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="close()">
                <i class="mdi mdi-close"></i>
            </button>
        </div>
    </div>
    <div class="modal-body">
        <div class=" container-fluid mt-1 mb-2">
            <div class="row">
                <div class="col-6">
                    <input class="form-control mt-2 mb-2" style="border-radius: 10px;"  placeholder="{{ 'PESQUISAR' | translate }}" type="text" name="search" (ngModelChange)="buscarContatos($event)"
                    [(ngModel)]="searchText" />
                </div>
            </div>
            <div class=" row">
                <div class=" col-xl-12">
                    <div class=" table-responsive table-listagem">
                        <table class=" table align-items-center table-flush">
                            <thead class=" thead-light">
                                <tr>
                                    <th class="sort th" data-sort="nome" scope="col">{{'NOME' | translate}}</th>
                                    <th class="sort th" data-sort="cliente" scope="col">{{'CLIENTE' | translate}}</th>
                                    <th class="sort th" data-sort="idContato" scope="col">{{'ID_CONTATO' | translate}}</th>
                                    <th class="sort th" data-sort="numero" scope="col">{{'NUMERO' | translate}}</th>
                                    <th class="sort th" data-sort="email" scope="col">{{'EMAIL' | translate}}</th>
                                    <th scope="col"></th>
                                </tr>
                            </thead>
                            <tbody class=" list">
                                <tr *ngFor="let contato of listaContato">
                                    <td class="w-15">{{contato.nome}}</td>
                                    <td class="w-15">{{contato.cliente_nome | null}}</td>
                                    <td class="w-12">{{contato.id}}</td>
                                    <td class="w-15">{{contato.telefone | mask:'(00) 0000-0000' | null}}</td>
                                    <td class="w-15">{{contato.email}}</td>
                                    <td class="text-right">
                                        <button class="btn badge-pill btn-purple" (click)="associarContato(contato)">
                                            <span [translate]="'ASSOCIAR'"></span>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <pagination *ngIf="listaContato.length > 0" [meta]="pagination" (pageChange)="paginarListagem($event)"></pagination>
        </div>
    </div>
    <div class="modal-footer modal-footer-milvus">
        <button class="btn badge-pill btn-mv-primary" (click)="novoContato()">
            <span [translate]="'NOVO_CONTATO'"></span>
        </button>
    </div>
</div>