import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { NgSelectConfig } from '@ng-select/ng-select';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { ChatService } from 'src/app/services/chat.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'select-tecnico',
  templateUrl: './select-tecnicosonoff.component.html',
  styleUrls: ['./select-tecnicosonoff.component.scss']
})

export class SelectTecnicoOnOffComponent implements OnInit, OnDestroy {

  @Input() entidade: string;
  // @Input() params: string;
  @Input() returnObj: boolean;
  @Input() model: any;
  @Output() selectChange = new EventEmitter();
  ctrl: any = {
    selectedValue: {},
    itens: []
  };
  // ctrl: any;
  @Input() disabled: boolean;
  @Input() set params(value: string) {
    this.getDropDownDispositivos({"cliente_id":value});
  };
  newSubs: Subscription;

  constructor(
    private toastr: ToastrService,
    private selectConfig: NgSelectConfig,
    public translate: TranslateService,
    private chatService: ChatService
  ) {
    this.selectConfig.notFoundText = this.translate.instant('NENHUM_ITEM_ENCONTRADO');
    this.selectConfig.bindValue = 'value';
  }

  ngOnInit(): void {
    this.getDropDownDispositivos();
    if(this.model) {
      this.setaModel(this.model);
    }
  }

  ngOnDestroy(): void {
    this.newSubs?.unsubscribe();
  }

  emitValue(value): void {
    if(this.returnObj === true) {
      var valueToNumber = Number(value);
      var itemObj = this.ctrl['itens'].filter(item => {
        return item.id === valueToNumber;
      });

      this.selectChange.emit(itemObj[0]);
    } else {
      this.selectChange.emit(value);
    }
  }

  setaModel(valor) {
    this.ctrl['selectedValue'] = valor.id;
  }

  getDropDownDispositivos(value?): void {
    this.newSubs = this.chatService.getTecnicosOnOff()
      .subscribe(results => {
        let data = results.listagemTecnicoOnOff.map((item) => {
          return { id: item.id, text: item.nome, status: item.is_online_chat }
        })
        this.ctrl['itens'] = data;
      }, error => {
        console.log(error);
        this.toastr.show(error, '', environment.toastrConfig('danger'));
      })
  }

}
