<ng-container>
  <!-- Sidenav -->
  <div class="" (window:resize)="isMobile($event)">
    <app-sidebar *ngIf="isAuth" [rotaIniciada]="rotaIniciada"
      [ngClass]="{ 'sidenav fixed-left': isMobileResolution === false }"></app-sidebar>
    <div id="d932kdds9fd9v">
      <!-- Top navbar -->
      <app-navbar *ngIf="isAuth"></app-navbar>
      <!-- Pages -->
      <router-outlet></router-outlet>
    </div>
  </div>
</ng-container>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.42)"
  template="<img width='250' src='assets/img/brand/GIF_LOADING-MILVUS_v3.gif' />"></ngx-spinner>