import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'moeda'
})
export class MoedaPipe implements PipeTransform {

  transform(value, tipo): string {
    var _valor = value.toString();
    _valor = _valor.replace(/\D/g, '');
    if(_valor.length > 0) {
      if(_valor.length > 2 && _valor.length < 7) {
        _valor = [_valor.slice(0, _valor.length - 2), ",", _valor.slice(_valor.length - 2)].join('');
      } else if(_valor.length > 6 && _valor.length < 9) {
        _valor = [_valor.slice(0, _valor.length - 5), ".", _valor.slice(_valor.length - 5)].join('');
        _valor = [_valor.slice(0, _valor.length - 2), ",", _valor.slice(_valor.length - 2)].join('');
      } else if(_valor.length > 8 && _valor.length < 10) {
        _valor = [_valor.slice(0, _valor.length - 8), ".", _valor.slice(_valor.length - 8)].join('');
        _valor = [_valor.slice(0, _valor.length - 5), ".", _valor.slice(_valor.length - 5)].join('');
        _valor = [_valor.slice(0, _valor.length - 2), ",", _valor.slice(_valor.length - 2)].join('');
      } else if(_valor.length > 9) {
        _valor = _valor.slice(0, _valor.length - 1);
        _valor = [_valor.slice(0, _valor.length - 8), ".", _valor.slice(_valor.length - 8)].join('');
        _valor = [_valor.slice(0, _valor.length - 5), ".", _valor.slice(_valor.length - 5)].join('');
        _valor = [_valor.slice(0, _valor.length - 2), ",", _valor.slice(_valor.length - 2)].join('');
      }

      if(tipo) {
        var _tipo = tipo.toLowerCase();
  
        if(_tipo === 'real') {
          _valor = "R$ " + _valor;
        } else if(_tipo === 'dolar') {
          _valor = "$ " + _valor;
        }
      }
    }
    return _valor;
  }

}
