import { Component, OnInit, Input, ElementRef, OnDestroy } from "@angular/core";
import { UntypedFormBuilder, Validators, UntypedFormGroup } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ClienteContatoService } from "src/app/services/cliente-contato.service";
import { ToastrService } from "ngx-toastr";
import { environment } from "src/environments/environment";
import { Subscription } from "rxjs";
import { QuillButtonOptionsModel } from "src/app/models/quill/quillButtonOptions.model";
import { QuillEditorComponent } from "ngx-quill";
import { QuillConfigModel } from "src/app/models/quill/quillConfig.model";

@Component({
  selector: "app-chat-contato",
  templateUrl: "./chat-contato.component.html",
  styleUrls: ["./chat-contato.component.scss"],
})
export class ChatContatoComponent implements OnInit, OnDestroy {
  @Input() contato;
  contatoForm: UntypedFormGroup;
  selector: string;
  is_ddg: boolean = false;
  newSubs: Subscription;
  quillConfigEditor: QuillConfigModel;
  editor: QuillEditorComponent;
  textEditorDisabledImage = true;

  constructor(
    public activeModal: NgbActiveModal,
    private _fb: UntypedFormBuilder,
    private contatoService: ClienteContatoService,
    private toastr: ToastrService,
    private elemRef: ElementRef,
  ) {
    this.selector = elemRef.nativeElement.tagName.toLowerCase();    
  }

  checkDdg($event) {
    this.is_ddg = $event.target.checked
  }

  ngOnInit(): void {
    document.getElementsByTagName(this.selector)[0]['style']['zoom'] = '0.85'
    this.bulidForm();
    this.setQuillEditorConfig();
    if (this.contato !== undefined) {
      //console.log("detalhes");
      //console.log(this.contato)
      //this.patchValue(this.contato);
    } else {
      //console.log("criando");
    }  
    this.eventPaste();  
  }

  ngOnDestroy(): void {
    this.textEditorDisabledImage = false;
    document.removeEventListener('paste', this.eventPaste, false);  
    this.newSubs?.unsubscribe();
  }

  close(result?) {
    //console.log(this.contatoForm.value);
    this.activeModal.close(result);
  }

  eventPaste() {
    let _this = this;
    document.addEventListener('paste', function(event) {   
      var text = event.clipboardData.getData(
        "text/html"
      );
      const parser = new DOMParser();
      const doc = parser.parseFromString(text, "text/html");
      var image = doc.querySelector("img");
      if (image && _this.textEditorDisabledImage) {
        event.preventDefault();
        return;
      }    
    }, false);
  }

  bulidForm() {
    this.contatoForm = this._fb.group({
      cliente: ["", Validators.required],
      descricao: ["", Validators.required],
      email: ["", [Validators.email]],
      telefone: [""],
      celular: [""],
      whatsapp: ["", Validators.required],
      padrao: ["", Validators.required],
      observacao: [""],
    });
  }

  onSubmit() {
    let basicPayload = {
      clienteContato: {
        celular: this.contatoForm.get("celular").value,
        cliente_id: this.contatoForm.get("cliente").value.id,
        descricao: this.contatoForm.get("descricao").value,
        email: this.contatoForm.get("email").value,
        is_padrao: this.contatoForm.get("padrao").value,
        observacao:	this.contatoForm.get('observacao').value && this.contatoForm.get('observacao').value.html ? this.contatoForm.get('observacao').value.html : this.contatoForm.get('observacao').value,
        //operadora_id: "null",
        possui_whatsapp: this.contatoForm.get("whatsapp").value,
        telefone: this.contatoForm.get("telefone").value,
      },
    };

    this.newSubs = this.contatoService.createClienteContato(basicPayload).subscribe(
      (result) => {
        this.close();
      },
      (error) => {
        this.toastr.show(error, "", environment.toastrConfig("danger"));
      }
    );
    //console.log(basicPayload);
  }

  onSubmitSet() {
    let basicPayload = {
      clienteContato: {
        celular: this.contatoForm.get("celular").value,
        cliente_id: this.contatoForm.get("cliente").value.id,
        descricao: this.contatoForm.get("descricao").value,
        email: this.contatoForm.get("email").value,
        is_padrao: this.contatoForm.get("padrao").value,
        observacao:	this.contatoForm.get('observacao').value && this.contatoForm.get('observacao').value.html ? this.contatoForm.get('observacao').value.html : this.contatoForm.get('observacao').value,
        //operadora_id: "null",
        possui_whatsapp: this.contatoForm.get("whatsapp").value,
        telefone: this.contatoForm.get("telefone").value,
      },
    };

    this.newSubs = this.contatoService.createClienteContato(basicPayload).subscribe(
      (result) => {
        //console.log(result);
        this.close(result.clienteContato);
      },
      (error) => {
        this.toastr.show(error, "", environment.toastrConfig("danger"));
      }
    );
    //console.log(basicPayload);
  }

  verificaRequerido(campo): boolean {
    if (this.contatoForm.get(campo).value !== '') {
      return false;
    } else {
      return true;
    }
  }

  setQuillEditorConfig() {
    this.quillConfigEditor = {
      magicUrl: true,
      imageDrop: false,
      ImageResize: { modules: ["Resize", "DisplaySize", "Toolbar"] },
      toolbar: {
        container: [
          ["bold", "italic", "underline", "strike"],
          /* [
            { align: [] },
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" },
          ], */

          //["code-block", "link", "image"],
        ],
        handlers: { emoji: function () {} },
      },
      "toolbar-custom-button": new QuillButtonOptionsModel(),
      "emoji-toolbar": true,
      "emoji-textarea": false,
      "emoji-shortname": true,

      // mention: {
      //   allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
      //   onSelect: (item, insertItem) => {
      //     const editor = this.editor.quillEditor;
      //     insertItem(item);
      //     editor.insertText(editor.getLength() - 1, "", "user");
      //   },
      //   source: (searchTerm, renderList) => {
      //     const values = [
      //       { id: 1, value: "Fredrik Sundqvist" },
      //       { id: 2, value: "Patrik Sjölin" },
      //     ];

      //     if (searchTerm.length === 0) {
      //       renderList(values, searchTerm);
      //     } else {
      //       const matches = [];

      //       values.forEach((entry) => {
      //         if (
      //           entry.value.toLowerCase().indexOf(searchTerm.toLowerCase()) !==
      //           -1
      //         ) {
      //           matches.push(entry);
      //         }
      //       });
      //       renderList(matches, searchTerm);
      //     }
      //   },
      // },
      keyboard: {
        bindings: {
          // shiftEnter: {
          //   key: 13,
          //   shiftKey: true,
          //   handler: (range, context) => {
          //     // Handle shift+enter
          //     console.log("shift+enter")
          //   }
          // },
          enter: {
            key: 13,
            handler: (range, context) => {
              return true;
            },
          },
        },
      },
    };
  }
}
