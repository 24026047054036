import { Component, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { ChamadosService } from 'src/app/services/chamados.service';
import { environment } from 'src/environments/environment';
declare const google: any;

@Component({
  selector: 'app-modal-mapa-operador',
  templateUrl: './modal-mapa-operador.component.html',
  styleUrls: ['./modal-mapa-operador.component.scss']
})
export class ModalMapaOperadorComponent implements OnInit, OnDestroy {
  @Input() mapaData;
  filtros;
  localizacaoCliente;
  localizacoesTecnicos;
  selector: string;
  newSubs: Subscription;

  constructor(
    public modal: NgbActiveModal,
    private toastr: ToastrService,
    public translate: TranslateService,
    private chamadosService: ChamadosService,
    private elemRef: ElementRef,
  ) {
    this.selector = elemRef.nativeElement.tagName.toLowerCase();
  }

  ngOnInit(): void {
    document.getElementsByTagName(this.selector)[0]['style']['zoom'] = '0.85';
    this.filtros = {
      filtro: {
        cliente_id: this.mapaData.cliente,
        filtro_distancia: 30000,
        regra_permissao: true
      }
    };

    this.criaMapa();
  }

  ngOnDestroy(): void {
    this.newSubs?.unsubscribe();
  }

  criaMapa(bool?): void {
    if (bool !== undefined) {
      this.filtros.filtro.regra_permissao = bool;
    }

    this.newSubs = this.chamadosService.obterLocalizacaoTecnicos(this.filtros)
      .subscribe(results => {
        this.localizacaoCliente = results.lista.cliente;
        this.localizacoesTecnicos = results.lista.tecnicos;
        this.setConfigGoogleMaps()
      }, error => {
        console.log(error)
        this.toastr.show(this.translate.instant('CLIENTE_SEM_INFOS_ENDERECO'), '', environment.toastrConfig('danger'));
      })
  }

  setConfigGoogleMaps() {
    setTimeout(() => {
      let map = document.getElementById('map-default');
      var myLatlng = new google.maps.LatLng(this.localizacaoCliente.latitude, this.localizacaoCliente.longitude);

      var mapOptions = {
        zoom: 14,
        scrollwheel: true,
        center: myLatlng,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        styles: [
          { "featureType": "administrative", "elementType": "labels.text.fill", "stylers": [{ "color": "#444444" }] },
          { "featureType": "landscape", "elementType": "all", "stylers": [{ "color": "#f2f2f2" }] },
          { "featureType": "poi", "elementType": "all", "stylers": [{ "visibility": "on" }] },
          { "featureType": "road", "elementType": "all", "stylers": [{ "saturation": 50 }, { "lightness": 30 }] },
          { "featureType": "road.highway", "elementType": "all", "stylers": [{ "visibility": "on" }] },
          { "featureType": "road.arterial", "elementType": "labels.icon", "stylers": [{ "visibility": "off" }] },
          { "featureType": "transit", "elementType": "all", "stylers": [{ "visibility": "off" }] },
          { "featureType": "water", "elementType": "all", "stylers": [{ "color": '#5e72e4' }, { "visibility": "on" }] }]
      }

      map = new google.maps.Map(map, mapOptions);

      var marker = new google.maps.Marker({
        position: myLatlng,
        map: map,
        animation: google.maps.Animation.DROP,
        title: this.localizacaoCliente.nome_fantasia,
        icon: 'assets/img/general/mv-icon-map-cliente.png'
      });

      this.localizacoesTecnicos.forEach(tecnico => {
        var marker2 = new google.maps.Marker({
          position: new google.maps.LatLng(tecnico.latitude, tecnico.longitude),
          map: map,
          animation: google.maps.Animation.DROP,
          title: tecnico.nome_operador,
          icon: 'assets/img/general/mv-icon-map-disponivel.png'
        });
      })

      var contentString = '<div class="info-window-content"><h2>Argon Dashboard</h2>' +
        '<p>A beautiful Dashboard for Bootstrap 4. It is Free and Open Source.</p></div>';

      var infowindow = new google.maps.InfoWindow({
        content: contentString
      });
    }, 500);
  }

}
