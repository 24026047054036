import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { TokenService } from '../core/token/token.service';

@Injectable({
  providedIn: 'root'
})
export class FilaAtendimentoService {
  private apiUrl = environment.apiGestorUrlLocal;
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', 'authorization': this.tokenService.getToken(), 'session': this.tokenService.getSessionUsuario() }),
    params: {}
  };
  constructor(private http: HttpClient, private tokenService: TokenService) { }

  getListagemHistoricoChat(params, filtros): Observable<any> {
    this.httpOptions.params = params;
    filtros.filtro_body = {
      ...filtros.filtro_body,
      usuario_id: this.tokenService.getUsuario().id,
      utiliza_regra_minhas_filas: false,
      status_chat_id: filtros.filtro_body.status_chat_id ? filtros.filtro_body.status_chat_id : "__all__",
    }
    return this.http.post(`${this.apiUrl}api/fila-atendimento/listagemChatPorStatus`, filtros, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  getFilaAtendimentoList(id): Observable<any> {
    this.httpOptions.params = '';
    return this.http.get(`${this.apiUrl}api/fila-atendimento/${id}`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  getFilabyId(id): Observable<any> {
    this.httpOptions.params = '';
    return this.http.get(`${this.apiUrl}api/fila-atendimento/obter/${id}`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  createFilaAtendimento(payload): Observable<any> {
    this.httpOptions.params = '';
    return this.http.post(`${this.apiUrl}api/fila-atendimento`, payload, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  adicionarUsuarioFila(payload): Observable<any> {
    this.httpOptions.params = '';
    return this.http.post(`${this.apiUrl}api/fila-atendimento/usuario`, payload, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  removerUsuarioFila(payload): Observable<any> {
    this.httpOptions.params = '';
    return this.http.request('delete', `${this.apiUrl}api/fila-atendimento/usuario/excluirRelacao`, {
      body: payload, 
      headers: this.httpOptions.headers
    })
      .pipe(
        catchError(this.handleError)
      );
  }

  editarFila(payload): Observable<any> {
    this.httpOptions.params = '';
    return this.http.put(`${this.apiUrl}api/fila-atendimento`, payload, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  deleteFila(id): Observable<any> {
    this.httpOptions.params = '';
    return this.http.delete(`${this.apiUrl}api/fila-atendimento/${id}`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('Ops! Ocorreu um erro:', error.error.message);
    } else {
      console.error(
        `Backend retornou o código ${error.status}, ` +
        `o body é: ${error.error}`);
    }
    return throwError(
      error.error);
  }
}
