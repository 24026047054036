import { HttpHeaders, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { TokenService } from '../core/token/token.service';

@Injectable({
    providedIn: 'root'
})
export class ChecklistService {
    private apiUrl = environment.apiGestorUrlLocal;
    httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json', 'authorization': this.tokenService.getToken(), 'session': this.tokenService.getSessionUsuario() }),
        params: {}
    };

    constructor(private http: HttpClient, private tokenService: TokenService) { }

    // POST
    getListagemChecklist(params, filtros): Observable<any> {
        this.httpOptions.params = params;
        return this.http.post(`${this.apiUrl}api/checklist/listagem`, filtros, this.httpOptions)
            .pipe(
                catchError(this.handleError)
            )
    }

    getCampoChecklist(filtros): Observable<any> {
        this.httpOptions.params = "";
        return this.http.post(`${this.apiUrl}api/checklist/campo/listagem`, filtros, this.httpOptions)
            .pipe(
                catchError(this.handleError)
            )
    }

    saveChecklist(payload): Observable<any> {
        this.httpOptions.params = "";
        return this.http.post(`${this.apiUrl}api/checklist`, payload, this.httpOptions)
            .pipe(
                catchError(this.handleError)
            )
    } 

    saveCampo(payload): Observable<any> {
        this.httpOptions.params = "";
        return this.http.post(`${this.apiUrl}api/checklist/campo`, payload, this.httpOptions)
            .pipe(
                catchError(this.handleError)
            )
    }

    // GET
    getObterChecklist(id): Observable<any> {
        this.httpOptions.params = "";
        return this.http.get(`${this.apiUrl}api/checklist/` + id, this.httpOptions)
            .pipe(
                catchError(this.handleError)
            )
    }

    dropdownCampos(params): Observable<any> {
        this.httpOptions.params = params;
        return this.http.get(`${this.apiUrl}api/checklist/campo/dropdown`, this.httpOptions)
            .pipe(
                catchError(this.handleError)
            );
    }

    getDropdownOfGroups(): Observable<any> {
        this.httpOptions.params = "";
        return this.http.get(`${this.apiUrl}api/checklist/grupo-campo/dropdown`, this.httpOptions)
            .pipe(
                catchError(this.handleError)
            );
    }

    dropdown(): Observable<any> {
        this.httpOptions.params = "";
        return this.http.get(`${this.apiUrl}api/checklist/dropdown`, this.httpOptions)
            .pipe(
                catchError(this.handleError)
            );
    }

    // PUT
    atualizaCampo(filtros): Observable<any> {
        this.httpOptions.params = "";
        return this.http.put(`${this.apiUrl}api/checklist/campo`, filtros, this.httpOptions)
            .pipe(
                catchError(this.handleError)
            )
    }

    atualizaChecklist(filtros): Observable<any> {
        this.httpOptions.params = "";
        return this.http.put(`${this.apiUrl}api/checklist`, filtros, this.httpOptions)
            .pipe(
                catchError(this.handleError)
            )
    }   
    
    save(payload): Observable<any> {
        this.httpOptions.params = "";
        return this.http.put(`${this.apiUrl}api/checklist`, payload, this.httpOptions)
            .pipe(
                catchError(this.handleError)
            )
    }

    // DELETE
    removeCampo(id): Observable<any> {
        this.httpOptions.params = "";
        return this.http.delete(`${this.apiUrl}api/checklist/campo/` + id, this.httpOptions)
            .pipe(
                catchError(this.handleError)
            )
    }

    removeChecklist(id): Observable<any> {
        this.httpOptions.params = "";
        return this.http.delete(`${this.apiUrl}api/checklist/` + id, this.httpOptions)
            .pipe(
                catchError(this.handleError)
            )
    }

    adicionarGrupo(grupo): Observable<any> {
        this.httpOptions.params = "";
        return this.http.post(`${this.apiUrl}api/checklist/grupo-campo`, grupo, this.httpOptions)
            .pipe(
                catchError(this.handleError)
            );
    }

    excluirChecklistChamado(checklist_id): Observable<any> {
        this.httpOptions.params = "";
        return this.http.delete(`${this.apiUrl}api/chamado-checklist/${checklist_id}`, this.httpOptions)
            .pipe(
                catchError(this.handleError)
            );
    }

    adicionarChecklistChamado(payload): Observable<any> {
        this.httpOptions.params = "";
        return this.http.post(`${this.apiUrl}api/chamado-checklist`, payload, this.httpOptions)
            .pipe(
                catchError(this.handleError)
            );
    }

    imprimirChecklist(chamado_id): Observable<any> {
        this.httpOptions.params = "";
        return this.http.get(`${this.apiUrl}api/chamados/ckecklist/${chamado_id}`, {
          headers: new HttpHeaders({ 'Content-Type': 'application/json', 'authorization': this.tokenService.getToken(), 'session': this.tokenService.getSessionUsuario() }),
          responseType: 'blob'
        })
          .pipe(
            catchError(this.handleError)
          );
    }

    private handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            console.error('Ops! Ocorreu um erro:', error.error.message);
        } else {
            console.error(
                `Backend retornou o código ${error.status}, ` +
                `o body é: ${error.error}`);
        }
        return throwError(
            error.error);
    }

}
