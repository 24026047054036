import { RouteInfo, SidebarRoutes } from './../../models/sidebar-routes.model';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from "@angular/core";
import { ActivatedRoute, NavigationEnd, Route, Router, RouterModule, Routes } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { Subscription, filter } from "rxjs";
import { TokenService } from "src/app/core/token/token.service";
import { environment } from "src/environments/environment";
import { BackslashPipe } from "../../shared/validators/pipes/backslash.pipe";
import { resolve } from 'path';

var misc: any = {
  sidebar_mini_active: true,
};

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"]
})
export class SidebarComponent implements OnInit, OnDestroy {
  public menuItems: any[];
  public auxMenuItems: any[];
  public isCollapsed = true;
  modo_tv = false;
  urlAtual;
  telaInicial;
  selector: string;
  searchFilter = "";
  newSubs: Subscription;
  mouseExpanded: boolean = false;
  ambiente;
  routesConfig = [];
  ROUTES: RouteInfo[] = new SidebarRoutes(this.tokenService).rotasPorPerfil();
  @ViewChild('inputSearch') inputSearch: ElementRef;

  @ViewChildren('childItem', { read: ElementRef }) childItem: QueryList<ElementRef>;
  @ViewChild("input") input: ElementRef;
  searchFocus: boolean = false;
  mouseControl: boolean = false;

  constructor(
    private elemRef: ElementRef,
    private router: Router,
    public translate: TranslateService,
    private tokenService: TokenService,
    private modalService: NgbModal,
    private route: ActivatedRoute,
  ) {
    this.newSubs = this.route.queryParams.subscribe((params) => {
      this.modo_tv = params["modo-tv"] !== undefined ? true : false;
    });
    this.selector = this.elemRef.nativeElement.tagName.toLowerCase();
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd)
      )
      .subscribe((event: NavigationEnd) => {
      });
  }

  ngOnDestroy(): void {
    this.newSubs?.unsubscribe();
  }

  filtrarMenuESubMenu(searchString) {
    if (searchString.length > 2) {
      this.menuItems = [];
      let arr = this.auxMenuItems;
      const filterByTitle = (arr, searchString) => {
        const filteredArray = [];
      
        arr.forEach(item => {
          const newItem = { ...item };
          if (newItem.children) {
            newItem.children = filterByTitle(newItem.children, searchString);
            if (newItem.children.length > 0 || this.translate.instant(newItem.title).toLowerCase().includes(searchString.toLowerCase())) {
              filteredArray.push(newItem);
            }
          } else if (this.translate.instant(newItem.title).toLowerCase().includes(searchString.toLowerCase())) {
            filteredArray.push(newItem);
          }
        });
        this.menuItems = filteredArray;
        return filteredArray;
      };
      filterByTitle(arr, searchString);
    } else {
      this.menuItems = this.auxMenuItems;
    }
  } 

  ngOnInit() {
    this.ambiente = environment.ambiente;
    this.menuItems = this.ROUTES.filter((menuItem) => menuItem);
    // this.tokenService.checkPermissions(permissao);
    if(this.menuItems) {
      this.verificaWorkflowChat(this.menuItems);
      // Revisar as permissões antes de validar esta função
      // this.loadAllRoutes(this.router.config).then(async (rotas) => {
      //   this.routesConfig = rotas;
      //   if (!this.tokenService.getUsuario().is_admin) {
      //     const menu = await this.permissoesMenu(rotas);
      //   }
      // });
    }


    this.auxMenuItems = JSON.parse(
      JSON.stringify(this.ROUTES.filter((menuItem) => menuItem))
    );

    this.newSubs = this.router.events.subscribe((event) => {
      this.modalService.dismissAll();
      if (this.searchFilter) {
        this.searchFilter = "";
        this.filtrarMenuESubMenu("");
      }
      this.isCollapsed = true;
      this.onMouseLeaveSidenav();
    });

    if (this.tokenService.getUsuario()) {
      switch (this.tokenService.getUsuario().tela_inicial_id) {
        case 1:
          this.telaInicial = "/dashboards/main";
          break;
        case 2:
          this.telaInicial = "/dashboards/ticket";
          break;
        case 3:
          this.telaInicial = "/dashboards/sla";
          break;
        case 5:
          this.telaInicial = "/dashboards/chat";
          break;
        case 6:
          this.telaInicial = "/dashboards/tasks";
          break;
        case 7:
          this.telaInicial = "/dashboards/link-monitoring";
          break;
        case 8:
          this.telaInicial = "/dashboards/vulnerability";
          break;
        case 9:
          this.telaInicial = "/dashboards/inventory";
          break;
        case 10:
          this.telaInicial = "/dashboards/technician";
          break;
        case 11:
          this.telaInicial = "/dashboards/tables";
          break;
        case 12:
          this.telaInicial = "/dashboards/internal-classification";
          break;
        case 14:
          this.telaInicial = "/dashboards";
          break;
        case 15:
          this.telaInicial = "/dashboards/users-status";
          break;
        default:
          this.telaInicial = "/dashboards";
          break;
      }
    }
  }

  async loadAllRoutes(routes: Routes, parentPath: string = ''): Promise<any[]> {
    let allRoutes: any[] = [];

    const loadChildRoutes = async (route: Route, path: string): Promise<any[]> => {
      if (route.loadChildren) {
        const loadedModule = await (route.loadChildren as () => Promise<any>)();
        const childRoutes = loadedModule['ɵinj'].imports
          .filter((provider: any) =>  provider.ngModule && provider.ngModule === RouterModule     )
          .map((cmp: any) => {
            const cmpRoutes = cmp.providers[0].useValue.map((selector: any) => ({
              path: `${selector.path}`,
              children: selector.children,
            }));
            return cmpRoutes;
          })
          .reduce((acc: any, curr: any) => acc.concat(curr), []);

        const loadedChildRoutes = await this.loadAllRoutes(childRoutes, path);
        return loadedChildRoutes;
      }
      return [];
    };

    for (let route of routes) {
      const path = parentPath + (route.path ? `/${route.path}` : '');

      const childRoutes = await loadChildRoutes(route, path);
      allRoutes = [...allRoutes, ...childRoutes];

      if (!route.loadChildren) {
        allRoutes.push({ path, data: route.data });
        if (route.children) {
          const childRoutes = await this.loadAllRoutes(route.children, path);
          allRoutes = [...allRoutes, ...childRoutes];
        }
      }
    }
    this.routesConfig = allRoutes;
    return allRoutes;
  }

  async permissoesMenu(rotas) {
  let arr = this.menuItems;
  let routes = rotas.filter(r => r.data !== undefined);
  let parentPath = '';
  let groupPath = '';
  let linkPath = '';
  let fullPath = '';

  const filterByTitle = (arr, routes) => {
    const filteredArray = [];
    
    arr.forEach(item => {
      parentPath = item.type === 'sub' ? item.path : parentPath;
      groupPath = item.type === 'group' && item.path !== '' ? item.path : '';
      linkPath = item.type === 'link' && item.path !== '' ? item.path : '';
      fullPath = parentPath + `${groupPath !== '' ? '/' + groupPath : ''}` + `${linkPath !== '' ? '/' + linkPath : ''}`;
      fullPath = fullPath.replace(/\/+/g, '/');
      if (fullPath.endsWith('/')) {
        fullPath = fullPath.slice(0, -1);
      }
      const newItem = { ...item };
      const checkRota = routes.find(p => p.path.split('/:')[0] === fullPath);
      if (newItem.children) {
        newItem.children = filterByTitle(newItem.children, routes);
        // Verifica a permissão dos filhos
        if (newItem.children.length > 0 || (checkRota && this.tokenService.checkPermissions(checkRota.data.permission))) {
          filteredArray.push(newItem);
        }
      } else if (checkRota && this.tokenService.checkPermissions(checkRota.data.permission)) {
        filteredArray.push(newItem);
      }
    });

    return filteredArray;
  };

  this.menuItems = filterByTitle(arr, routes);
}


  ngAfterViewInit() {
    this.inputSearch.nativeElement.classList.add("hidden");
  }

  searchBlur(): void {
    this.searchFocus = false;
    if(!this.mouseControl) {
      this.onMouseLeaveSidenav();
    } 
  }

  onMouseEnterSidenav() {
    this.mouseControl = true;
    if(!this.searchFocus) {
      this.mouseExpanded = true;
  
      if (!document.body.classList.contains("g-sidenav-pinned")) {
        document.body.classList.add("g-sidenav-show");
      }
  
      const sidenavToggler =
        document.getElementsByClassName("sidenav-toggler")[0];
      if (sidenavToggler) {
        sidenavToggler.classList.add("hidden");
      }
  
      const iconSearch = document.getElementsByClassName("icon-search")[0];
      if (iconSearch) {
        iconSearch.classList.add("hidden");
      }
  
      const inputSearch = document.getElementsByClassName("input-search")[0];
      if (inputSearch) {
        inputSearch.classList.remove("hidden");
      }
    }
  }

  onMouseLeaveSidenav() {
    this.mouseControl = false;
    if(!this.searchFocus) {
      this.mouseExpanded = false;
  
      if (!document.body.classList.contains("g-sidenav-pinned")) {
        document.body.classList.remove("g-sidenav-show");
      }
  
      const sidenavToggler =
        document.getElementsByClassName("sidenav-toggler")[0];
      if (sidenavToggler) {
        sidenavToggler.classList.remove("hidden");
      }
  
      const iconSearch = document.getElementsByClassName("icon-search")[0];
      if (iconSearch) {
        iconSearch.classList.remove("hidden");
      }
  
      const inputSearch = document.getElementsByClassName("input-search")[0];
      if (inputSearch) {
        inputSearch.classList.add("hidden");
      }
    }
  }

  minimizeSidebar() {
    const sidenavToggler =
      document.getElementsByClassName("sidenav-toggler")[0];
    const body = document.getElementsByTagName("body")[0];
    if (body.classList.contains("g-sidenav-pinned")) {
      misc.sidebar_mini_active = true;
    } else {
      misc.sidebar_mini_active = false;
    }
    if (misc.sidebar_mini_active === true) {
      body.classList.remove("g-sidenav-pinned");
      body.classList.add("g-sidenav-hidden");
      sidenavToggler.classList.remove("active");
      misc.sidebar_mini_active = false;
    } else {
      body.classList.add("g-sidenav-pinned");
      body.classList.remove("g-sidenav-hidden");
      sidenavToggler.classList.add("active");
      misc.sidebar_mini_active = true;
    }
  }

  expandItens(collapsed, list: any[]): void {
    list.forEach((item) => {
      if (item.path === collapsed.path) {
        item.isCollapsed = !item.isCollapsed;
      } else {
        item.isCollapsed = true;
      }
    });
  }

  trackByIndex(index, item): any {
    return index;
  }

  verificaVisualizacao(): boolean {
    return this.mouseExpanded;
  }

  verificaWorkflowChat(menuItems): void {
    let chatMenu = menuItems.find(menu => menu.path === "/chat");
    if(!this.verificaVisualizaWorkflowChat(chatMenu.children[2].children[3].permission)) {
      chatMenu.children[2].children.splice(3, 1);
      chatMenu.is_new = false;
    }
  }

  verificaVisualizaWorkflowChat(permissao): boolean {
    return this.tokenService.checkPermissions(permissao);
  }
}
