import {
  Component,
  OnInit,
  Input,
  ElementRef,
  ViewChild,
  QueryList,
  ViewChildren,
  OnDestroy,
  AfterViewInit,
  Renderer2,
  ChangeDetectorRef,
  HostListener
} from "@angular/core";
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from "@angular/forms";
import { ChatGPTService } from "src/app/services/chat-gpt.service";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { environment } from "src/environments/environment";
import { TokenService } from "src/app/core/token/token.service";
import { ChatService } from "src/app/services/chat.service";
import { WebsocketService } from "src/app/core/websocket/websocket.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ChatFinalizarComponent } from "../chat-finalizar/chat-finalizar.component";
import { DetalhesContatoChatComponent } from "../detalhes-contato-chat/detalhes-contato-chat.component";
import { TranslateService } from "@ngx-translate/core";
import { ModalTransferenciaTicketComponent } from "../modal-transferencia-ticket/modal-transferencia-ticket.component";
import { ModalChatConciliarComponent } from "../modal-chat-conciliar/modal-chat-conciliar.component";
import { ModalChatContatoComponent } from "../modal-chat-contato/modal-chat-contato.component";
import { ConversaZapComponent } from "../conversa-zap/conversa-zap.component";
import Swal from "sweetalert2";
import { SimpleCrypt } from "ngx-simple-crypt";
import { ModalAberturaTicketComponent } from "../modal-abertura-ticket/modal-abertura-ticket.component";
import { BehaviorSubject, debounceTime, Subject, Subscription } from "rxjs";
import { ChatViewerAudioComponent } from "../chat-viewer-audio/chat-viewer-audio.component";
import { Router } from "@angular/router";
import { DomSanitizer } from "@angular/platform-browser";
import { EllipsisPipe } from "src/app/shared/validators/pipes/elilipsis.pipe";
import { WhatsappService } from "src/app/services/whatsapp.service";
import { ModalContatosComponent } from "../modal-contatos/modal-contatos.component";
import { TicketService } from "src/app/services/ticket.service";
import { IntegrationService } from "src/app/services/integration.service";
import { ModalHistoricoListaChatsComponent } from "../modal-historico-lista-chats/modal-historico-lista-chats.component";
import { indexOf } from "@ckeditor/ckeditor5-utils";
import { ModalMsgStartChatWhatsappComponent } from "../modal-msg-start-chat-whatsapp/modal-msg-start-chat-whatsapp.component";
declare let moment: any;

@Component({
  selector: "app-chat-viewer",
  templateUrl: "./chat-viewer.component.html",
  styleUrls: ["./chat-viewer.component.scss"],
})
export class ChatViewerComponent implements OnInit, AfterViewInit, OnDestroy {
  createAnswerToChat: UntypedFormGroup = new UntypedFormGroup({
    chatGPT: new UntypedFormControl("", Validators.required),
    description: new UntypedFormControl("", Validators.required),
  });
  @Input() whatsAppContato;
  @Input() contato;
  @Input() historico_chat;
  @Input() provider_id;
  @Input() apenas_historico;
  @Input() chatGPTSuggestion;
  @Input() allowChatGPT;
  @Input() closeChatGPTModal = true;
  chatGPTSuggestionCopy: any;
  contatoForm: UntypedFormGroup;
  tokenData: any;
  searchText: string;
  searchText$ = new Subject<string>();
  listaChats: any = [];
  chatSelecionado: any;
  mensagensChat: any;
  mensagem: any;
  reenviarMensagem: any;
  socketsAbertos: any = [];
  contadores: any;
  lockedScroll: boolean = false;
  chatSelecionadoSocket: any;
  showEmoji: boolean = false;
  _this = this;
  statusSelecionado: any = 2;
  locked: boolean = true;
  chatAtivoId: string = "";
  loading: boolean;
  conectado: any;
  reconectando: any;
  tentativas = 0;
  tooMany: any;
  permissao = {
    VisualizarTickets: this.tokenService.checkPermissions("@VisualizarTickets"),
  };
  focus = true;
  selector: string;
  dataAtual = new Date();
  hasIntegracao = false;
  newSubs: Subscription;
  arrayFilasExistentes: Array<number> = [];
  traducaoEmoji = {
    search: this.translate.instant("BUSCAR"),
    emojilist: this.translate.instant("LISTA_EMOJIS"),
    notfound: this.translate.instant("NENHUM_EMOJI_ENCONTRADO"),
    clear: this.translate.instant("LIMPAR"),
    categories: {
      search: this.translate.instant("RESULTADOS_PESQUISA"),
      recent: this.translate.instant("USADOS_RECENTEMENTE"),
      people: this.translate.instant("EXPRESSOES_PESSOAS"),
      nature: this.translate.instant("ANIMAIS_NATUREZA"),
      foods: this.translate.instant("COMIDAS_BEBIDAS"),
      activity: this.translate.instant("ATIVIDADE"),
      places: this.translate.instant("VIAGEM_LUGARES"),
      objects: this.translate.instant("OBJETOS"),
      symbols: this.translate.instant("SIMBOLOS"),
      flags: this.translate.instant("BANDEIRAS"),
      custom: this.translate.instant("CUSTOMIZADOS"),
    },
  };
  lastMsgSendStatus: boolean;
  tooManyMsg;
  seeMoreTranscriptionUniqueIds = [];
  transcriptionUniqueIds = [];
  transcriptions = [];
  uploadFileType = environment.getUploadConfig().docImg();
  uploadFileSize = environment.getUploadConfig().sizeMaxBytes;
  socketRealTime: any;
  solicitantes_habilitados: any = {
    solicitante_cliente: true,
    solicitante_contato: true,
    solicitante_operador: true
  };

  paginaChats: number = 1;
  offSetChats: number = 15;
  filtroChat: any;
  carregandoChats: boolean = false;
  totalRegistrosChats!: number;

  statusChange$ = new BehaviorSubject<number>(2);

  @ViewChild("scrollMe") private myScrollContainer: ElementRef;
  @ViewChildren("messages") messages: QueryList<any>;
  @ViewChild("fileInput") fileInput: ElementRef;
  @ViewChild("chatList") chatList: ElementRef;
  isModalOpen: boolean = false;

  constructor(
    public activeModal: NgbActiveModal,
    private toastr: ToastrService,
    public translate: TranslateService,
    private tokenService: TokenService,
    private chatService: ChatService,
    private websocketService: WebsocketService,
    private modalService: NgbModal,
    private elemRef: ElementRef,
    private router: Router,
    private sanitizer: DomSanitizer,
    private ellipsisPipe: EllipsisPipe,
    private whatsappService: WhatsappService,
    private ticketService: TicketService,
    private chatGPTService: ChatGPTService,
    private integrationService: IntegrationService,
    private renderer: Renderer2,
    private cd: ChangeDetectorRef
  ) {
    this.selector = this.elemRef.nativeElement.tagName.toLowerCase();
    this.searchText$
    .pipe(
      debounceTime(1000)
    )
    .subscribe(value => {
      this.getChatsSearchName(value)
    });

    this.newSubs = this.statusChange$
      .subscribe({
        next: value => {
          if (this.statusSelecionado === value) {
            return;
          }
          if (value) {
            this.statusSelecionado = value;
            this.listaChats = [];
            this.paginaChats = 1;
            this.searchText = '';
            this.totalRegistrosChats = undefined;

            value == 3 ? this.closeChatGPTModal = false : this.closeChatGPTModal = true

            if (this.tokenData && this.tokenData.id) {
              this.loadChats();
            }
          }
        }
      })
  }

  @HostListener('scroll', ['$event'])
  onScrollChatList(event: any): void {
    const scrollPosition = this.chatList.nativeElement.scrollTop;
    const scrollHeight = this.chatList.nativeElement.scrollHeight;
    const offsetHeight = this.chatList.nativeElement.offsetHeight;

    if (!this.totalRegistrosChats) {
      return
    }

    if (this.listaChats.length === this.totalRegistrosChats) {
      return
    }

    if (scrollPosition + offsetHeight >= scrollHeight - 100) {
      if (!this.carregandoChats) {
        this.loadChats(false, true);
      }
    } else if (scrollPosition === 0) {
      console.log('Chegou ao topo do chat');
    }
  }

  onSearchTermChange(value: string) {
    this.searchText$.next(value);
  }

  handleClick(event: MouseEvent) {
    const emojiSelector = document.querySelector(".emoji-selector");
    if (emojiSelector && !emojiSelector.contains(event.target as Node)) {
      this.showEmoji = false;
    }
  }

  eventListenerNovaConversa(): void {
    document.addEventListener('nova_conversa', (data) => {
      this.checkContadores(data['detail']);
      this.verificaNovaMensagem();
    });
  }

  eventListenerNovaMensagemChatClient(): void {
    var _this = this;
    this.socketRealTime.on("nova_mensagem_chat_client", function (data) {
      _this.checkContadores(data);
      _this.updateChatList(data);
    });

    // document.addEventListener('nova_mensagem_chat_client', (data) => {
    //   this.checkContadores(data['detail']);
    //   this.updateChatList(data['detail']);
    // });
  }

  eventListenerAtualizacaoChat(): void {
    document.addEventListener('atualizacao_chat', (data) => {
      this.checkContadores(data['detail']);
    });
  }

  eventListenerChatFinalizado(): void {
    document.addEventListener('chat_finalizado', (data) => {
      this.checkContadores(data['detail']);
    });
  }

  eventListenerRespMsgSent(): void {
    document.addEventListener('msg_sent', (response) => {
      this.formatBodyMsg(response['detail'], 'interno');
      setTimeout(() => {
        this.goToBottom();
      }, 100);
    });
  }

  eventListenerTranscription = (event): void => {
    if (this.seeMoreTranscriptionUniqueIds.includes(event.target.id)) {
      let see_more_tag = document.getElementById(event.target.id);
      let tag_index = this.seeMoreTranscriptionUniqueIds.indexOf(event.target.id);
      let transcription_tag = document.getElementById(this.transcriptionUniqueIds[tag_index]);

      if (transcription_tag.innerHTML) {
        transcription_tag.innerHTML = '';
        see_more_tag.innerHTML = this.translate.instant('VER_MAIS');
      } else {
        transcription_tag.innerHTML = this.transcriptions[tag_index];
        see_more_tag.innerHTML = this.translate.instant('VER_MENOS');
      }
    }
  }

  ngOnDestroy(): void {
    this.newSubs?.unsubscribe();
    //document.removeAllListeners();
    document.removeEventListener('nova_conversa', this.eventListenerNovaConversa);
    document.removeEventListener('nova_mensagem_chat_client', this.eventListenerNovaMensagemChatClient);
    document.removeEventListener('atualizacao_chat', this.eventListenerAtualizacaoChat);
    document.removeEventListener('chat_finalizado', this.eventListenerChatFinalizado);
    document.removeEventListener('msg_sent', this.eventListenerRespMsgSent);
    document.removeEventListener('click', this.eventListenerTranscription);
    //this.socketRealTime.off('nova_mensagem_chat_client');
    this.fecharSockets();
  }

  ngOnInit(): void {
    this.listaChats = [];


    if (!this.apenas_historico) {
      this.socketRealTime = this.websocketService.getSocket();
    }

    document.getElementsByTagName(this.selector)[0]["style"]["zoom"] = "0.85";
    this.verificaIntegracao();
    this.verificaNovaMensagem();
    this.tokenData = this.tokenService.getAllInfos();
    this.loadChats();
    // comentado para analisar impacto e necessidade do evento de login do socket, DEIXAR ESSE TRECHO COMENTADO
    //this.loadContadores();
    // if (!this.historico_chat)
    //   this.websocketService.connectSocketChat(this.tokenData);
    this.scrollToBottom();
    if (this.whatsAppContato) {
      this.iniciaConversaWhatsApp(this.whatsAppContato);
      this.abrirChat(this.whatsAppContato);
    }

    if (this.historico_chat) {
      this.chatAtivoId = this.historico_chat._id;

      if (
        this.historico_chat.status.toLowerCase() ===
        this.translate.instant("A_FAZER").toLowerCase()
      ) {
        this.statusSelecionado = 1;
      } else if (
        this.historico_chat.status.toLowerCase() ===
        this.translate.instant("FINALIZADO").toLowerCase()
      ) {
        this.statusSelecionado = 3;
      } else {
        this.statusSelecionado = 2;
      }
      this.abrirChat(this.historico_chat);
    }

    this.verificaSolicitantesHabilitados();
  }

  ngAfterViewInit() {
    if (!this.apenas_historico) {
      this.eventListenerNovaConversa();
      this.eventListenerNovaMensagemChatClient();
      this.eventListenerAtualizacaoChat();
      this.eventListenerChatFinalizado();
      document.addEventListener('click', this.eventListenerTranscription);
      setTimeout(() => {
        const modalButtons = this.elemRef.nativeElement.querySelectorAll('.modal button');

        modalButtons.forEach((button: HTMLElement) => {
          if (button.hasAttribute('aria-hidden')) {
            this.renderer.removeAttribute(button, 'aria-hidden');
          }
        });

        this.chatList.nativeElement.scrollTop = this.chatList.nativeElement.scrollHeight;

      }, 1000)
    }
  }

  verificaSolicitantesHabilitados(): void {
    this.newSubs = this.ticketService.getAberturaTicketPadrao()
      .subscribe({
        next: (results) => {
          this.solicitantes_habilitados = {
            solicitante_cliente: results.categorizacaoClientePadrao.solicitante_cliente,
            solicitante_contato: results.categorizacaoClientePadrao.solicitante_contato,
            solicitante_operador: results.categorizacaoClientePadrao.solicitante_operador
          };
        }, error: (error) => {
          console.error(error);
          this.toastr.show(error?.error_msg ? error.error_msg : error, '', environment.toastrConfig('danger'));
        }
      });
  }

  checkContadores(data) {
    if (data && data.contadores) {
      this.contadores = data.contadores;
    }
  }

  updateChatList(chatData): void {
    let chatModificado;
    let indiceAntigoChatModificado;
    this.listaChats.forEach((chatEl, index) => {
      if (chatEl._id === chatData.chat_id) {
        chatModificado = chatEl;
        indiceAntigoChatModificado = index;
      }
    });

    setTimeout(() => {
      if (!this.chatSelecionado || this.chatSelecionado._id !== chatData.chat_id) {
        chatModificado.ultima_mensagem = chatData.msg;
        chatModificado.data_mensagem = this.tokenService.convertDateTimezoneToView(new Date());
      }

      if (this.statusSelecionado === 2) {
        this.listaChats.splice(indiceAntigoChatModificado, 1);
        this.listaChats.unshift(chatModificado);
      }
    }, 500);
  }

  setFirstMsgList(chat_id, last_msg): void {
    let _listChatsWithoutSelectedChat = this.listaChats.filter(list => {
      return list._id !== chat_id;
    });

    this.chatSelecionado.ultima_mensagem = last_msg;
    this.chatSelecionado.data_mensagem = this.tokenService.convertDateTimezoneToView(new Date());
    this.chatSelecionado.selecionado = true;
    _listChatsWithoutSelectedChat.unshift(this.chatSelecionado);
    this.listaChats = _listChatsWithoutSelectedChat;
  }

  atualizaDataInteracao(data_mensagem): void {
    this.chatSelecionado.data_mensagem = data_mensagem;
  }

  verificaNovaMensagem() {
    if (localStorage.getItem("nova_mensagem")) {
      localStorage.removeItem("nova_mensagem");
    }

    let encodedObjectAsString: string = SimpleCrypt.encodeDefault(
      "szHYxd7tz",
      this.contadores && this.contadores.ag_atendimento > 0 ? "true" : "false"
    );
    localStorage.setItem("nova_mensagem", encodedObjectAsString);
  }

  openMic(chatSelecionado: any) {
    const modalRef = this.modalService.open(ChatViewerAudioComponent, {
      scrollable: false,
      size: "sm",
      keyboard: false,
      backdrop: "static",
      centered: true,
    });

    modalRef.result.then((result) => {
      if (result) {
        var file = new File(
          [result.blob],
          "audio_" + new Date().getTime() + ".ogg",
          { type: 'audio/ogg', lastModified: Date.now() }
        );
        if (file && file.size <= 20000000) {
          this.newSubs = this.chatService.adicionarAnexoAudio(file).subscribe(
            async (res) => {
              this.autoMsg(res, "anexo", chatSelecionado);
            },
            (error) => {
              console.log(error);
            }
          );
        } else {
          this.toastr.show(
            this.translate.instant("ARQUIVO_NAO_SUPORTADO_OU_EXCEDEU_LIMITE"),
            "",
            environment.toastrConfig("danger")
          );
        }
      }
    });
  }
  checkStatusIdChamadoVinculado() {
    return this.chatSelecionado.chamado_vinculado.status_id === 1 || this.chatSelecionado.chamado_vinculado.status_id === 2
      || this.chatSelecionado.chamado_vinculado.status_id === 3
  }

  encerrarCriarTicket(chatSelecionado) {
    if (!chatSelecionado.contato?.id) {
      this.abrirModalContato(true, 'encerrarCriar');
    } else {
      const modalRef = this.modalService.open(ModalAberturaTicketComponent, {
        backdrop: "static",
        windowClass: "modal-custom-milvus",
        keyboard: false,
        scrollable: true,
        size: "grande-encerrar-chat",
      });

      modalRef.componentInstance.chatData = chatSelecionado;

      modalRef.result.then((result) => {
        if (result && result.acao === "associar") {
          this.loadChats();
          this.chatAtivoId = chatSelecionado._id;
          this.chatSelecionado.chamado_vinculado = result.chamado;
          this.chatSelecionado.chamado_id = result.chamado.id;
        } else if (result && result.acao === "finalizar") {
          this.chatSelecionadoSocket.connect();
          if (this.chatSelecionadoSocket.connected) {
            this.chatSelecionadoSocket.emit("chat_finalizado");
            this.chatSelecionadoSocket.close();
          } else {
            this.websocketService.finishChat(
              this.chatSelecionado,
              this.tokenData
            );
          }
          this.chatAtivoId = "";
          this.chatSelecionado = {};
          delete this.mensagensChat;
          this.chatSelecionado.status = "Finalizado";
          this.chatSelecionado.status_id = 3;
          this.chatAtivoId = chatSelecionado._id;
          this.changeStatus(3);
          this.loadChats();
        }
      });
    }
  }

  getChatsSearchName(termo: string) {
    let dados = {
      filtro_body: {
        status_chat_id: this.statusSelecionado,
        pesquisa: termo ? termo : '',
      },
    };
    this.newSubs = this.chatService
      .getChatsPorStatus(dados, this.paginaChats, this.offSetChats)
      .subscribe((res) => {
        this.listaChats = res.lista;

        // adicionar marcador para selecionado
        this.listaChats = this.listaChats.map((v) => ({
          ...v,
          selecionado: false,
        }));
      });
  }

  openModalConversaZap() {
    this.newSubs = this.chatService.obterIntegracaoAtiva().subscribe(
      (result) => {
        if (result) {
          const modalRef = this.modalService.open(ConversaZapComponent, {
            backdrop: "static",
            windowClass: "modal-custom-milvus",
            keyboard: true,
            scrollable: false,
            size: "grande",
          });

          modalRef.componentInstance.mensagemConversa =
            result.mensagem_inicia_conversa_whatsapp;

          modalRef.result.then((results) => {
            if (results) {
              this.loadChats();
              this.chatAtivoId = results._id;
              this.abrirChat(results);
            }
          });
        } else {
          this.toastr.show(this.translate.instant('NAO_POSSUI_INTEGRACAO'), '', environment.toastrConfig('danger'));
        }
      },
      (error) => {
        console.log(error);
        this.toastr.show(error, '', environment.toastrConfig('danger'));
      }
    );
  }

  verificaIntegracao() {
    this.newSubs = this.chatService.obterIntegracaoAtiva().subscribe(
      (result) => {
        result ? (this.hasIntegracao = true) : (this.hasIntegracao = false);

        this.provider_id = result.provider_id;
      },
      (error) => {
        console.log(error);
        this.toastr.show(error, '', environment.toastrConfig('danger'));
      }
    );
  }

  loadChats(onlyChats?, fromScroll?): void {
    let dados = {
      filtro_body: {
        usuario_id: this.tokenData.id,
        status_chat_id: this.statusSelecionado
          ? this.statusSelecionado
          : "__all__",
        utiliza_regra_minhas_filas: false,
      },
    };

    // if (this.historico_chat) {
    //   if (
    //     this.historico_chat.status.toLowerCase() ===
    //     this.translate.instant("A_FAZER").toLowerCase()
    //   ) {
    //     dados.filtro_body.status_chat_id = 1;
    //   } else if (
    //     this.historico_chat.status.toLowerCase() ===
    //     this.translate.instant("FINALIZADO").toLowerCase()
    //   ) {
    //     dados.filtro_body.status_chat_id = 3;
    //   } else {
    //     dados.filtro_body.status_chat_id = 2;
    //   }
    // }

    if (this.tokenData && this.tokenData.id) {
      this.getChatsLoadChats(dados, fromScroll);
    }

    if (!onlyChats) {
      this.loadContadores();
    }

    this.scrollToBottom();
  }

  getChatsLoadChats(dados, fromScroll?) {
    this.carregandoChats = true;
    this.newSubs = this.chatService
      .getChatsPorStatus(dados, this.paginaChats, this.offSetChats)
      .subscribe((res) => {
        this.totalRegistrosChats = res.meta.paginate.total;
        res.lista.forEach(
          chat => {
            let chatExistente = this.listaChats.findIndex(c => c._id === chat._id);
            if(chatExistente !== -1 && this.listaChats.length) {
              this.listaChats[chatExistente] = chat;
            } else {
              this.listaChats.push(chat);
            }
          }
        )
        this.listaChats = this.listaChats.filter( item => item.status_id === this.statusSelecionado)
        this.chatAtivoId = this.chatAtivoId
          ? this.chatAtivoId
          : this.chatSelecionado && this.chatSelecionado._id
            ? this.chatSelecionado._id
            : "";

        if (this.chatAtivoId && this.listaChats.length) {
          this.listaChats.filter(chat => {
            if (chat._id === this.chatAtivoId) {
              this.chatSelecionado = chat;
            }
          });
        }

        this.chatAtivoId = this.chatAtivoId
          ? this.chatAtivoId
          : this.chatSelecionado && this.chatSelecionado._id
            ? this.chatSelecionado._id
            : "";
        // adicionar marcador para selecionado
        this.listaChats = this.listaChats.map((v) => ({
          ...v,
          selecionado: false,
        }));
        if (this.chatAtivoId) {
          // marca o selecionado e recarrega
          let pos = this.listaChats.findIndex((item) => {
            return item._id === this.chatAtivoId;
          });
          if (pos > -1) {
            this.listaChats[pos].selecionado = true;
          }
          this.chatAtivoId = "";
        }
        if(fromScroll) {
          this.paginaChats++;
        }
        this.carregandoChats = false;
      });
  }

  loadContadores() {
    this.newSubs = this.chatService.getContadores().subscribe((rt) => {
      this.contadores = rt;

      this.verificaNovaMensagem();
    });
  }

  formatDateList(input: any): any {
    if (input) {
      let dt = input.split(" ");
      let hr = dt[1].split(":");
      return hr[0] + ":" + hr[1];
    } else {
      return "";
    }
  }

  async abrirChat(chat: any, fromStartService?: boolean) {
    this.obterIntegracaoChatGPT()
    this.mensagem = "";
    let _oldChatId = (this.chatSelecionado) ? this.chatSelecionado._id : null;
    const chat_id = chat._id;

    if ((_oldChatId !== chat_id) || fromStartService) {
      if (this.chatSelecionado) {
        this.chatSelecionado = {};
      }

      if (this.chatSelecionadoSocket && Object.keys(this.chatSelecionadoSocket).length > 0) {
        this.chatSelecionadoSocket.disconnect();
      }

      this.chatSelecionado = chat;
      chat.selecionado = true;

      if (chat_id) {
        localStorage.setItem("@milvus:chatAberto", chat_id);
        this.chatAtivoId = chat_id;
      }

      if (this.listaChats) {
        this.listaChats.forEach((element) => {
          if (element._id === chat_id) {
            element.selecionado = true;
          } else {
            element.selecionado = false;
          }
        });
      }

      if (chat_id) {
        this.newSubs = this.chatService
          .getChatConversa(chat_id)
          .subscribe((msgs) => {
            this.mensagensChat = msgs.resp.listaMensagens;
            this.mensagensChat.forEach(msg => {
            if (msg.body || msg.msg) {
              this.formatBodyMsg(msg, (msg.enviado_por === 'robo' || msg.enviado_por === 'tecnico' || msg.is_tranferencia) ? 'interno' : 'externo');
            }
            });
            this.goToBottom();
            this.scrollToBottom();
            this.lockedScroll = true;
            if (chat.possui_mensagens_nao_lidas) {
            this.chatService.mensagemChatLida(chat_id).subscribe((result) => {
              this.listaChats.forEach((element) => {
                if (element._id === chat._id) {
                  element.possui_mensagens_nao_lidas = false;
                }
              });
            });
            }
          });
      }

      if (chat.status_id === 2 && !this.apenas_historico) {
        const socket_new = await this.websocketService.connectChat(
          chat,
          this.tokenData
        );
        this.chatSelecionadoSocket = socket_new;
        this.tooMany = this.websocketService.tooMany;
        this.conectado = this.chatSelecionadoSocket.connected;

        this.chatSelecionadoSocket.on("receive", (msg: any) => {
          this.notificarAtualizar(msg);
          this.tooMany = this.websocketService.tooMany;
          this.listaChats.forEach(chatEl => {
            if (chatEl._id === chat_id) {
              chatEl.ultima_mensagem = msg.msg;
            }
          });
        });

        this.chatSelecionadoSocket.on("connect", () => {
          this.conectado = this.chatSelecionadoSocket.connected;
          this.tooMany = this.websocketService.tooMany;
        });

        this.chatSelecionadoSocket.on("connected", () => {
          this.tooMany = this.websocketService.tooMany;
          this.tooManyMsg = undefined;
          this.tentativas = 0;
          console.log("Chat - " + this.conectado);
        });

        this.chatSelecionadoSocket.on("disconnect", (msg: any) => {
          this.conectado = false;
          console.log("Chat - " + this.conectado);
        });

        this.chatSelecionadoSocket.on("tooMany", (msg: any) => {
          this.conectado = false;
          this.chatSelecionadoSocket.close();
          this.tooManyMsg = this.translate.instant('CONECTADO_OUTRA_ABA');
        });

        this.chatSelecionadoSocket.on('reconnect', () => {
          this.tentativas = 0;
          this.conectado = true;
          console.log("Chat - " + this.conectado);
        });

        this.chatSelecionadoSocket.on('reconnecting', (attempt) => {
          this.reconectando = true;
          this.tentativas = attempt;
          console.log("Chat - " + this.conectado);
        });

        this.eventListenerRespMsgSent();

        this.chatSelecionadoSocket.on("chat_finalizado", (data: any) => {
          this.notificarChatFinalizado(data);
          this.statusSelecionado = 3;
          this.chatAtivoId = data.chat_id;
          this.loadChats();
          this.chatSelecionado.status = "Finalizado";
          this.chatSelecionado.status_id = 3;
        });
        setTimeout(() => {
          this.goToBottom();
        }, 500);

        this.chatSelecionadoSocket.on("resp_msg_sent", (msg: any) => {
          if(this.provider_id === 4){
            const msgIndex = this.mensagensChat
              .map((m, index) => ({ m, index }))
              .reverse()
              .find(item => item.m.id === msg.message_id || item.m.id === undefined)?.index;
            
            if (msgIndex !== undefined) {
              this.mensagensChat[msgIndex].status_mensagem = msg.status_mensagem;
              this.mensagensChat[msgIndex].error_msg = msg.error_msg;
              this.mensagensChat[msgIndex].error_code = msg.error_code;

              this.formatBodyMsg(this.mensagensChat[msgIndex], 'interno');

              setTimeout(() => {
              this.goToBottom();
              }, 100);
            }

            if (msg.status_mensagem === 4 && msg.showModal_template && !this.isModalOpen) {
              this.abrirModalEnvioWhatsApp(true);
            }
            
          } else {
            const msgIndex = this.mensagensChat
              .map((m, index) => ({ m, index }))
              .reverse()
              .find(item => item.m.id === msg.message_id || item.m.id === undefined)?.index;
            
            if (msgIndex !== undefined) {
              this.mensagensChat[msgIndex].status_mensagem = msg.status_mensagem;
              this.mensagensChat[msgIndex].status_envio_id = msg.status_envio_id;
              this.mensagensChat[msgIndex].error_msg = msg.error_msg;
              this.mensagensChat[msgIndex].error_code = msg.error_code;

              this.formatBodyMsg(this.mensagensChat[msgIndex], 'interno');

              setTimeout(() => {
              this.goToBottom();
              }, 100);
            }
          }
        });
      }
    }
  }

  abrirModalEnvioWhatsApp(isToShowSpecificMessageFromMeta: boolean) {
    this.isModalOpen = true;
    this.newSubs = this.chatService.obterIntegracaoAtiva().subscribe({
      next: (results) => {
        if (results) {
          const modalRef = this.modalService.open(ModalMsgStartChatWhatsappComponent, {
            scrollable: true,
            windowClass: 'modal-custom-milvus',
            size: 'medio',
            keyboard: true,
            backdrop: 'static',
            centered: true,
          });

          let data = {
            provider_id: results.provider_id,
            integration_id: results.id
          };

          modalRef.componentInstance.data = data;
          modalRef.componentInstance.templateMessage = true;

          if (isToShowSpecificMessageFromMeta) {
            modalRef.componentInstance.isToShowSpecificMessageFromMeta = true;
          }

          modalRef.result.then(
            (result) => {
              if (result) {
                // Captura o conteúdo do template selecionado
                this.mensagem = result.template_content;

                // Chama o método para enviar a mensagem
                this.enviarMsg();
              }
              this.isModalOpen = false; // Reset the flag when modal is closed
            },
            (reason) => {
              console.log('Modal dismissed: ', reason);
              this.isModalOpen = false; // Reset the flag when modal is dismissed
            }
          );
        } else {
          this.toastr.show(this.translate.instant('NAO_POSSUI_INTEGRACAO'), '', environment.toastrConfig('danger'));
          this.isModalOpen = false; // Reset the flag if there's no integration
        }
      },
      error: (error) => {
        console.error(error);
        this.toastr.show(error.error_msg ? error.error_msg : error, '', environment.toastrConfig('danger'));
        this.isModalOpen = false; // Reset the flag on error
      }
    });
  }

  notificarAtualizar(msg: any) {
    if (msg.chat_id === this.chatSelecionado._id) {
      if (msg !== this.mensagensChat[this.mensagensChat.length - 1]) {
        this.mensagensChat.push(msg);
      } else if (msg.body.includes("http")) {
        this.mensagensChat.push(msg);
      }
      this.formatBodyMsg(msg, 'externo', true);

      this.locked = true;
      this.scrollToBottom();
    }
    this.chatAtivoId = this.chatSelecionado._id ? this.chatSelecionado._id : "";
  }

  abrirInfoContato(chat: any) {
    const modalRef = this.modalService.open(DetalhesContatoChatComponent, {
      scrollable: false,
      windowClass: "modal-custom-milvus",
      size: "medio",
      keyboard: false,
      backdrop: "static",
    });
    modalRef.componentInstance.chatData = chat;
    modalRef.result.then((result) => { });
  }

  associarContato(chat: any) {
    this.chatAtivoId = chat._id;
    const modalRef = this.modalService.open(ModalChatContatoComponent, {
      scrollable: false,
      windowClass: "modal-custom-milvus",
      size: "grande",
      keyboard: false,
      backdrop: "static",
    });
    modalRef.componentInstance.chatData = chat;
    modalRef.result.then((result) => {
      if (result) {
        this.loadChats();
        this.chatSelecionado.nome_contato = result.descricao;
        this.chatSelecionado.telefone = result.telefone;
      }
    });
  }

  async enviarMsg() {
    if (this.mensagem) {
      let fixedMessage = this.mensagem.trim();
      if (fixedMessage !== "" && fixedMessage !== undefined) {
        this.reenviarMensagem = this.mensagem;
        var _msg = {
          user: this.tokenData.nome,
          enviado_por: "tecnico",
          tipo: "texto",
          img: this.tokenData.foto,
          msg: this.mensagem,
          token_widget: this.chatSelecionado.widget.token_widget,
          nome: this.tokenData.nome,
          data: new Date(),
          message: this.mensagem,
          body: this.mensagem,
          origem: 'gestor',
          formatedBody: null
        };


        this.websocketService.addSocketMsgChat(_msg, this.chatSelecionadoSocket, (mesagemAtualizada) => {
          console.log('Mensagem retornada:', mesagemAtualizada);
          this.mudouStatusMsg(mesagemAtualizada);
          this.formatBodyMsg(mesagemAtualizada, 'interno');
          if (this.mensagensChat.length) {
            this.mensagensChat.push(mesagemAtualizada);
          } else {
            this.mensagensChat = [mesagemAtualizada];
          }
          this.chatAtivoId = this.chatSelecionado._id;
          this.setFirstMsgList(this.chatSelecionado._id, this.mensagem);
          this.mensagem = "";
          this.locked = true;
          this.myScrollContainer.nativeElement.scrollTop =
            this.myScrollContainer.nativeElement.scrollHeight + 20;
          this.atualizaDataInteracao(
            this.tokenService.convertDateTimezoneToView(new Date())
          );
        });
      }
    }
  }

  addEmoji(emojidata) {
    this.mensagem = this.mensagem
      ? this.mensagem + emojidata.emoji.native
      : emojidata.emoji.native;
  }

  close(result?) {
    this.tokenData = {};
    this.fecharSockets();
    localStorage.setItem("@milvus:chatAberto", "");
    this.activeModal.close();
  }

  fecharSockets(): void {
    this.socketsAbertos = [];
    if (
      this.chatSelecionadoSocket &&
      this.chatSelecionadoSocket.hasOwnProperty("connected")
    ) {
      this.chatSelecionadoSocket.disconnect();
      this.chatSelecionadoSocket = {};
    }
  }

  scrollToBottom(): void {
    if (this.locked) {
      try {
        this.myScrollContainer.nativeElement.scrollTop =
          this.myScrollContainer.nativeElement.scrollHeight + 20;
      } catch (err) { }
    }
  }

  goToBottom(): void {
    this.myScrollContainer.nativeElement.scroll({
      top: this.myScrollContainer.nativeElement.scrollHeight,
      left: 0,
      behavior: "smooth",
    });
  }

  onScroll(event) {
    this.locked = false;
  }

  splitName(name): string {
    var _nome = name.split(" ");
    if (_nome.length > 1) {
      return _nome[0].charAt(0) + _nome[1].charAt(0);
    } else {
      return name.charAt(0);
    }
  }

  getTooMany(): void {
    return this.websocketService.tooMany;
  }

  abrirModalFinalizar(chat: any): void {
    if (
      chat.fila_atendimento &&
      chat.fila_atendimento.associar_chamado_obrigatorio &&
      !chat.chamado_id &&
      (this.solicitantes_habilitados.solicitante_cliente || this.solicitantes_habilitados.solicitante_contato)
    ) {
      Swal.fire({
        title: this.translate.instant("AVISO"),
        text: this.translate.instant("INFO_CHAT_DEVE_ESTAR_ANEXADO"),
        icon: "warning",
        showCancelButton: false,
        confirmButtonText: "OK",
      });
    } else {
      if (!chat.contato?.id) {
        this.abrirModalContato(true, 'encerrar');
      } else {
        const modalRef = this.modalService.open(ChatFinalizarComponent, {
          scrollable: false,
          windowClass: "modal-custom-milvus",
          size: "medio",
          keyboard: false,
          backdrop: "static",
        });

        modalRef.componentInstance.chatData = chat;
        modalRef.componentInstance.socketChat = this.chatSelecionadoSocket;
        modalRef.componentInstance.solicitantes_habilitados = this.solicitantes_habilitados;

        modalRef.result.then((result) => {
          if (result === "finalizado") {
            this.chatSelecionadoSocket.connect();
            if (this.chatSelecionadoSocket.connected) {
              this.chatSelecionadoSocket.emit("chat_finalizado");
              this.chatSelecionadoSocket.close();
            } else {
              this.websocketService.finishChat(
                this.chatSelecionado,
                this.tokenData
              );
            }
            this.chatAtivoId = "";
            this.chatSelecionado = {};
            delete this.chatSelecionado;
            delete this.mensagensChat;
          }
          this.listaChats = [];
          this.loadChats();
        });
      }
    }
  }

  transferirChat(chatSelecionado): void {
    const modalRef = this.modalService.open(ModalTransferenciaTicketComponent, {
      scrollable: false,
      windowClass: "modal-custom-milvus",
      size: "lg",
      keyboard: false,
      backdrop: "static",
    });

    modalRef.componentInstance.chatData = chatSelecionado;

    modalRef.result.then((result) => {
      if (result) {
        if (this.chatSelecionadoSocket) {
          this.chatSelecionadoSocket.close();
          this.chatSelecionadoSocket = {};
        }

        this.chatAtivoId = "";
        this.chatSelecionado = {};
        delete this.chatSelecionado;
        delete this.mensagensChat;
      }

      this.listaChats = [];
      this.loadChats();
    });
  }

  conciliarTicket(chatSelecionado): void {
    if (!chatSelecionado.contato?.id) {
      this.abrirModalContato(true, 'associar');
    } else {
      const modalRef = this.modalService.open(ModalChatConciliarComponent, {
        scrollable: false,
        windowClass: "modal-custom-milvus",
        size: "lg",
        keyboard: false,
        backdrop: "static",
      });

      modalRef.componentInstance.chatData = chatSelecionado;

      modalRef.result.then((result) => {
        if (result) {
          this.chatAtivoId = chatSelecionado._id;
          this.chatSelecionado.chamado_vinculado = result;
          this.chatSelecionado.chamado_id = result.id;
          this.loadChats();

          if (result && result.id && result.fechar) {
            this.router.navigate(["/tickets/details/", result.id]);
          }
        }
      });
    }
  }

  iniciarAtendimento(chat): void {
    this.chatAtivoId = chat._id;
    this.newSubs = this.chatService.iniciarAtendimento(chat._id).subscribe(
      (res) => {
        this.toastr.show(
          this.translate.instant("ATENDIMENTO_INICIADO"),
          "",
          environment.toastrConfig("success")
        );
        this.chatSelecionado.status = "Em Atendimento";
        this.statusSelecionado = 2;
        this.loadChats();
        this.obterChatTecnico(this.chatAtivoId);
      },
      (error) => {
        this.toastr.show(error, "", environment.toastrConfig("danger"));
      }
    );
  }

  obterChatTecnico(chat_id) {
    this.newSubs = this.chatService.obterChatTecnico(chat_id).subscribe(
      (result) => {
        this.chatSelecionado = result.chat;
        this.abrirChat(result.chat, true);
      },
      (error) => {
        console.log(error);
        this.toastr.show(error, "", environment.toastrConfig("danger"));
      }
    );
  }

  clickAnexo() {
    let fileInput = document.getElementById("fileInput");
    fileInput.click();
  }

  async changeFile(file: any, chatSelecionado: any) {
    if (file.length > 0 && file.length < 6) {
      for (let i = 0; i < file.length; i++) {
        var mime = require('mime');
        var tipo = mime.getType(file[i].name);

        if (tipo !== null) {
          tipo = tipo.split("/");
        } else {
          let ext = file[i].name.split('.');
          tipo = ext;
        }

        tipo[1] = environment.convertUploadType(tipo[1]);

        var suportado = this.uploadFileType.indexOf(tipo[1]);
        if (file[i] && file[i].size <= this.uploadFileSize && suportado !== -1) {
          this.chatService.adicionarAnexo(file[i]).subscribe(
            async (res) => {
              this.autoMsg(res, "anexo", chatSelecionado);
            },
            (error) => {
              console.log(error);
            }
          );
        } else {
          this.toastr.show(
            this.translate.instant("ARQUIVO_NAO_SUPORTADO_OU_EXCEDEU_LIMITE"),
            "",
            environment.toastrConfig("danger")
          );
        }
      }
    } else if (file.length >= 6) {
      this.toastr.show(this.translate.instant('QNT_ANEXOS_EXCEDIDO'), '', environment.toastrConfig('danger'));
    }
  }

  async autoMsg(msg: any, type: any, chatSelecionado?: any) {
    if (chatSelecionado && chatSelecionado._id !== this.websocketService.chatTela) {
      this.toastr.show("CHAT_SELECIONADO_INVALIDO", "", environment.toastrConfig("danger"));
      return;
    }

    if (chatSelecionado._id !== this.chatSelecionado._id) {
      this.toastr.show("CHAT_SELECIONADO_INVALIDO", "", environment.toastrConfig("danger"));
      return;
    }

    if (this.chatSelecionado._id !== this.websocketService.chatTela) {
      this.toastr.show("CHAT_SELECIONADO_INVALIDO", "", environment.toastrConfig("danger"));
      return;
    }

    var _msg = {
      user: this.tokenData.nome,
      enviado_por: "tecnico",
      tipo: type,
      img: this.tokenData.foto,
      msg: msg,
      message_id: msg.message_id,
      token_widget: this.chatSelecionado.widget.token_widget,
      nome: this.tokenData.nome,
      data: new Date(),
      message: msg,
      origem: 'gestor',
      body: msg,
    };

    this.websocketService.addMsgChat(_msg, (mesagemAtualizada) => {
      console.log('Mensagem retornada:', mesagemAtualizada);
      this.mudouStatusMsg(mesagemAtualizada);
      this.formatBodyMsg(mesagemAtualizada, 'interno');
      if (this.mensagensChat.length) {
        this.mensagensChat.push(mesagemAtualizada);
      } else {
        this.mensagensChat = [mesagemAtualizada];
      }
      this.chatAtivoId = this.chatSelecionado._id;
      this.setFirstMsgList(this.chatSelecionado._id, this.mensagem);
      this.mensagem = "";
      this.locked = true;
      this.myScrollContainer.nativeElement.scrollTop =
        this.myScrollContainer.nativeElement.scrollHeight + 20;
      this.atualizaDataInteracao(
        this.tokenService.convertDateTimezoneToView(new Date())
      );
    });

    // await this.websocketService.addMsgChat(_msg, null);

    // this.mensagensChat.push(_msg);
    // this.formatBodyMsg(_msg, 'interno');

    // this.chatAtivoId = this.chatSelecionado._id;
    // this.mensagem = "";
    // this.locked = true;
    // this.setFirstMsgList(this.chatSelecionado._id, msg);
    // this.scrollToBottom();
    // this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight + 20;
  }

  changeStatus(idStatus: any) {
    this.statusChange$.next(idStatus)
  }

  reenviarMsg() {
    if (this.reenviarMensagem !== "" && this.reenviarMensagem !== undefined) {
      var _msg = {
        user: this.tokenData.nome,
        enviado_por: "tecnico",
        tipo: "texto",
        img: this.tokenData.foto,
        msg: this.reenviarMensagem,
        token_widget: this.chatSelecionado.widget.token_widget,
        nome: this.tokenData.nome,
        data: new Date(),
        origem: 'gestor',
        message: this.reenviarMensagem,
        body: this.reenviarMensagem,
      };

      this.websocketService.addSocketMsgChat(_msg, this.chatSelecionadoSocket, null);
      this.mensagensChat.push(_msg);
      this.formatBodyMsg(_msg, 'interno');
      this.chatAtivoId = this.chatSelecionado._id;
      this.mensagem = "";
      this.locked = true;
      this.setFirstMsgList(this.chatSelecionado._id, this.reenviarMensagem);
      this.scrollToBottom();
      this.myScrollContainer.nativeElement.scrollTop =
        this.myScrollContainer.nativeElement.scrollHeight + 20;
    }
  }

  formatLinkOrText(array) {
    var _msg: string = "";
    array.forEach((element) => {
      if (element.indexOf("http") !== -1) {
        _msg +=
          "<a target='_blank' href='" + element + "'>" + element + " </a>";
      } else {
        _msg += element + " ";
      }
    });
    return _msg.trimRight();
  }

  openHistorico(chat): void {
    const modalRef = this.modalService.open(ModalHistoricoListaChatsComponent, {
      backdrop: 'static',
      scrollable: false,
      windowClass: 'modal-custom-milvus',
      keyboard: true,
      size: 'grande'
    });

    modalRef.componentInstance.solicitantes_habilitados = this.solicitantes_habilitados;
    modalRef.componentInstance.chat_contato = chat.contato;
  }

  openTicket(ticket): void {
    var url = location.origin + "/tickets/details/" + ticket.id;
    window.open(url, "_blank");
  }

  iniciaConversaWhatsApp(contato): void {
    if (this.provider_id !== 4) {
      this.newSubs = this.chatService
        .iniciarConversaWhatsappChamado(contato)
        .subscribe(
          (results) => {
            this.chatAtivoId = results.chat._id;
            this.loadChats();
            this.abrirChat(results.chat);
          },
          (error) => {
            this.toastr.show(error, "", environment.toastrConfig("danger"));
          }
        );
    } else {
      let _payload = {
        from: contato.from,
        numero_destino: contato.numero_destino,
        template_name: contato.template_name,
        language: contato.language,
        contato_id: contato.id || contato.contato_id,
        chamado_id: contato.chamado_id
      };

      this.newSubs = this.whatsappService.wppOfficialStartChat(contato.integracao_id, contato)
        .subscribe({
          next: (results) => {
            this.chatAtivoId = results.chat._id;
            this.loadChats();
            this.abrirChat(results.chat);
          }, error: (error) => {
            console.error(error);
            this.toastr.show(error.error_msg ? error.error_msg : error, '', environment.toastrConfig('danger'));
          }
        });
    }
  }

  notificarChatFinalizado(chatData: any): void {
    let chat_id = chatData.chat_id;
    let locate = this.listaChats.findIndex((x) => {
      return x._id === chat_id;
    });
    if (locate !== -1) {
      this.listaChats[locate].status = "Finalizado";
      this.listaChats[locate].status_id = 3;
    }
  }

  marcarLido(chatSelecionado) {
    let chat = this.listaChats.find((el) => el._id === chatSelecionado._id);
    if (chat.possui_mensagens_nao_lidas) {
      this.chatService.mensagemChatLida(chat._id).subscribe((result) => {
        //removendo status de não lido
        this.listaChats.forEach((element) => {
          if (element._id === chat._id) {
            element.possui_mensagens_nao_lidas = false;
          }
        });
      });
    }
  }

  onEnterKeyPress(event: any) {
    event.preventDefault();
  }

  autoGrow(key, chatBox, chatMsgs, fromButton?) {
    setTimeout(() => {
      if (fromButton) {
        chatBox.style.height = "auto";
        chatMsgs.style.bottom = "193px";
      } else {
        if (
          key.key === "Enter" &&
          key.shiftKey === false &&
          key.altKey === false
        ) {
          if (this.mensagem == "\n") {
            return (this.mensagem = "");
          }
          this.enviarMsg();
          chatBox.style.height = "auto";
          chatMsgs.style.bottom = "193px";
        } else {
          chatBox.style.height = "auto";
          chatBox.style.height = chatBox.scrollHeight + "px";

          if (chatBox.scrollHeight > 50) {
            chatMsgs.style.bottom = 193 + (chatBox.clientHeight - 50) + "px";
          } else {
            chatMsgs.style.bottom = "193px";
          }
        }
      }
    }, 10);
  }

  geraClasseFila(fila): string {
    if (fila && fila.id) {
      const indexFila = this.arrayFilasExistentes.indexOf(fila.id);
      if (indexFila !== -1) {
        return "fila-" + indexFila;
      } else {
        this.arrayFilasExistentes.push(fila.id);
        return "fila-" + (this.arrayFilasExistentes.length - 1);
      }
    }
  }

  formatBodyMsg(msg, source, newMsg?): void {

    let messageParsed = msg.body || msg.msg || '';
    messageParsed = (messageParsed !== '') ? messageParsed.split(".") : messageParsed;

    let horario = "";
    let mensagemErro = msg.erro_envio ?
      `<div class="d-flex align-items-center gap-2">
        <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M9.70312 2.04297C9.76562 1.9375 9.87891 1.875 10 1.875C10.1211 1.875 10.2344 1.9375 10.2969 2.04297L18.043 14.7656C18.0977 14.8555 18.125 14.957 18.125 15.0586C18.125 15.3711 17.8711 15.625 17.5586 15.625H2.44141C2.12891 15.625 1.875 15.3711 1.875 15.0586C1.875 14.9531 1.90234 14.8516 1.95703 14.7656L9.70312 2.04297ZM8.10156 1.06641L0.355469 13.7891C0.121094 14.1719 0 14.6094 0 15.0586C0 16.4062 1.09375 17.5 2.44141 17.5H17.5586C18.9062 17.5 20 16.4062 20 15.0586C20 14.6094 19.875 14.1719 19.6445 13.7891L11.8984 1.06641C11.4961 0.40625 10.7773 0 10 0C9.22266 0 8.50391 0.40625 8.10156 1.06641ZM11.25 13.125C11.25 12.7935 11.1183 12.4755 10.8839 12.2411C10.6495 12.0067 10.3315 11.875 10 11.875C9.66848 11.875 9.35054 12.0067 9.11612 12.2411C8.8817 12.4755 8.75 12.7935 8.75 13.125C8.75 13.4565 8.8817 13.7745 9.11612 14.0089C9.35054 14.2433 9.66848 14.375 10 14.375C10.3315 14.375 10.6495 14.2433 10.8839 14.0089C11.1183 13.7745 11.25 13.4565 11.25 13.125ZM10.9375 5.9375C10.9375 5.41797 10.5195 5 10 5C9.48047 5 9.0625 5.41797 9.0625 5.9375V9.6875C9.0625 10.207 9.48047 10.625 10 10.625C10.5195 10.625 10.9375 10.207 10.9375 9.6875V5.9375Z" fill="#FF0000"/>
        </svg>
        <div>Erro: ${msg.erro_envio}</div>
      </div></br>
      `
      : "";

    if (mensagemErro) {
      let mensagemTotal = mensagemErro + messageParsed
      msg.body = mensagemTotal;
    }

    if (source === 'interno') {
      horario +=
        '<div class="text-left"><span class="msg-time" style="font-size: 10px;margin-top: 6px;">' +
        moment(msg.data_mensagem ? msg.data_mensagem : msg.data).format('DD/MM/YYYY HH:mm:ss') +
        "</span>";
      horario += this.setSendAndRead(msg);
    } else {
      let formatedHour;
      if (msg.tipo === 'anexo' && newMsg) {
        console.log('msg anexo', msg, newMsg);
        formatedHour = moment().format('DD/MM/YYYY HH:mm:ss');
      } else {
        formatedHour = moment(msg.data_mensagem).format('DD/MM/YYYY HH:mm:ss');
      }
      horario += '<div class=" text-right"><span class="msg-time" style="font-size: 10px;margin-top: 6px;">' +
        formatedHour +
        "</span>";
    }

    if (msg.status_mensagem === 4 && msg.error_msg) {
      const errorHtml = `
        <div class="error-message">
          <div class="error-header">
            <i class="fa-regular fa-triangle-exclamation"></i>
            <span>Erro: ${msg.error_code} - ${msg.error_msg}</span>
          </div>
          <div>
            <span class="msg-body">${messageParsed}</span>
            <br/>
            <span class="msg-time">${horario}</span>
          </div>
        </div>
      `;
      msg.formatedBody = this.sanitizer.bypassSecurityTrustHtml(errorHtml);
      return;
    }

    if (messageParsed.length > 1) {
      msg.formatedBody = this.sanitizer.bypassSecurityTrustHtml(this.setMediaMsg((msg.body) ? msg.body : msg.msg, messageParsed, source, msg) + horario);
    } else {
      msg.formatedBody = this.sanitizer.bypassSecurityTrustHtml(((msg.body) ? msg.body : msg.msg) + horario);
    }
    this.mensagensChat
      .forEach(
        mensagem => {
          if (mensagem.message_id === msg.message_id) {
            mensagem = { ...mensagem, ...msg };
          }
        }
      )
  }

  transcriptAudio(msg): string {
    let returnedHtml =
      '<div class="transcription-audio"><span><img class="pr-2" src="assets/img/general/icon_openai_milvus.png" width="50">' +
      this.translate.instant('RESUMO_AUDIO') + '</span>' +
      '<span>' + msg.resumo_audio + '</span>' +
      '<span class="pt-2">' + this.translate.instant('TRANSCRICAO_COMPLETA') + '<span class="ml-2" id="' + this.generateUniqueIdTranscription(msg) + '"></span>' + '<span id="' +
      this.generateUniqueIdSeeMore() + '" class="see-more-transcription pl-2">' + this.translate.instant('VER_MAIS') + '</span></div>';
    return returnedHtml;
  }

  generateUniqueIdSeeMore(): string {
    let uniqueId = 'smtc' + Math.random().toString(36).substring(2);
    this.seeMoreTranscriptionUniqueIds.push(uniqueId);
    return uniqueId;
  }

  generateUniqueIdTranscription(msg): string {
    let uniqueId = 'tc' + Math.random().toString(36).substring(2);
    this.transcriptionUniqueIds.push(uniqueId);
    this.transcriptions.push(msg.transcricao_audio);
    return uniqueId;
  }

  setSendAndRead(msg): string {
    // status_envio_id
    const audioExtensions = [".mp3", ".wav", ".ogg", ".flac", ".aac", ".m4a"];
    if (msg && msg.message && msg.tipo === 'anexo' && audioExtensions.some(extension => msg.message.endsWith(extension))) {
      switch (msg.status_envio_id) {
        case 0:
          return '<span class="error-time">&nbsp&nbspx</span></div>';
        case 1:
          return '<span class="read-time"><i class="fa-regular fa-clock" style="font-size: 12px; margin-left: 8px;"></i></span></div>';
        case 2:
          return '<span class="send-time">&nbsp&nbsp✓</span></div>';
        case 3:
          return '<span class="send-time">&nbsp&nbsp✓✓</span></div>';
        case 4:
          return '<span class="send-time">&nbsp&nbsp✓✓</span></div>';
        case 5:
          return '<span class="check-time">&nbsp&nbsp✓✓</span></div>';
        default:
          return '<span class="send-time">&nbsp&nbsp✓</span></div>';
      }
    } else if(this.provider_id === 4) {
      switch (msg.status_mensagem) {
        case 1:
          return '<span class="send-time">&nbsp&nbsp✓</span></div>';
        case 2:
          return '<span class="check-time">&nbsp&nbsp✓✓</span></div>';
        case 3:
          return '<span class="check-time">&nbsp&nbsp✓✓</span></div>';
        case 4:
          return '<span class="error-time">&nbsp&nbspx</span></div>';
        case 5:
          return '<span class="send-time">&nbsp&nbsp✓✓</span></div>';
        default:
          return "</div>";
      }
    } else {
      switch (msg.status_envio_id) {
        // case 1:
        //   return '<span class="read-time"><i class="fa-regular fa-clock" style="font-size: 12px; margin-left: 8px;"></i></span></div>';
        case 1:
          return '<span class="send-time">&nbsp&nbsp✓</span></div>';
        case 3:
          return '<span class="send-time">&nbsp&nbsp✓✓</span></div>';
        case 4:
          return '<span class="check-time">&nbsp&nbsp✓✓</span></div>';
        case 5:
          return '<span class="check-time">&nbsp&nbspx</span></div>';
        default:
          return "</div>";
      }
    }
  }

  mudouStatusMsg(msg): void {
    if (msg.message_id) {
      this.mensagensChat.forEach(m => {
        if (m.message_id === msg.message_id) {
          m = { ...m, ...msg };
          this.formatBodyMsg(m, 'interno')
        }
      });
    }
  }

  trackById(index: number, item: any): number {
    return item.id; // Aqui você retorna o identificador único de cada item
  }

  setMediaMsg(msg_body, messageParsed, source, msg): string {
    let format = messageParsed[messageParsed.length - 1];
    let linkcheck = msg_body.split("https:");
    let linkcheckS = msg_body.split("http:");
    switch (format) {
      case "png":
      case "jpg":
      case "jpeg":
      case "bmp":
        return (
          '<a target="_blank" href="' +
          msg_body +
          '"><img width="400" height="300"  src="' +
          msg_body +
          '" /></a>'
        );
      case "opus":
      case "mp3":
      case "ogg":
      case "wav":
      case "oga":
      case "mpeg":
        if (source !== 'interno' && msg.resumo_audio && msg.transcricao_audio) {
          return (
            "<audio controls><source src=" +
            msg_body +
            ' type="audio/mpeg"></audio><br><a target="_blank" href="' +
            msg_body +
            '">download </a>' + this.transcriptAudio(msg)
          );
        } else {
          return (
            "<audio controls><source src=" +
            msg_body +
            ' type="audio/mpeg"></audio><br><a target="_blank" href="' +
            msg_body +
            '">download </a>'
          );
        }
      case "mp4":
        if (msg_body.includes('audio_')) {
          return (
            "<audio controls><source src=" +
            msg_body +
            ' type="audio/mp4"></audio><br><a target="_blank" href="' +
            msg_body +
            '">download </a>'
          );
        } else {
          return (
            '<video width="400" height="300" controls><source src="' +
            msg_body +
            '" type="video/mp4"></video><br><a target="_blank" href="' +
            msg_body +
            '">download </a>'
          );
        }
      case "zip":
      case "rar":
      case "msi":
        if (linkcheck.length > 1 || linkcheckS.length > 1) {
          return (
            '<a target="_blank" href="' +
            msg_body +
            '">' +
            this.translate.instant("CLIQUE_AQUI_BAIXAR") +
            "</a>"
          );
        } else {
          return msg_body;
        }
      default:
        let index = msg_body.indexOf("http");
        if (index !== -1) {
          msg_body
            .split(" ")
            .filter((item) => item.includes("http"))
            .forEach((element) => {
              msg_body = msg_body.replace(element, `<a target="_blank" href="${element}">${element}</a>`);
            });
          return msg_body;
        } else {
          return msg_body;
        }
    }
  }

  formatLastMsg(last_msg): string {
    if (!last_msg) {
      return this.ellipsisPipe.transform(last_msg, 25);
    }

    if (
      last_msg.includes('.opus') ||
      last_msg.includes('.mp3') ||
      last_msg.includes('.ogg') ||
      last_msg.includes('.wav') ||
      last_msg.includes('.oga') ||
      last_msg.includes('.mpeg')
    ) {
      return '<i class="fas fa-music mr-2"></i><span>Audio</span>';
    } else if (last_msg.includes('.mp4')) {
      return '<i class="fas fa-video mr-2"></i><span>Video</span>';
    } else if (
      last_msg.includes('.zip') ||
      last_msg.includes('.rar') ||
      last_msg.includes('.msi')
    ) {
      return '<i class="fas fa-file mr-2"></i><span>' + this.translate.instant('ARQUIVO') + '</span>';
    } else if (
      last_msg.includes('.png') ||
      last_msg.includes('.jpg') ||
      last_msg.includes('.jpeg') ||
      last_msg.includes('.bmp')
    ) {
      return '<i class="fas fa-image mr-2"></i><span>' + this.translate.instant('IMAGEM') + '</span>';
    } else {
      return this.ellipsisPipe.transform(last_msg, 25);
    }
  }

  abrirModalContato(multiplo?, continuacao?): void {
    const modalRef = this.modalService.open(ModalContatosComponent, {
      scrollable: false,
      windowClass: 'modal-custom-milvus',
      size: 'medio',
      keyboard: true,
      backdrop: 'static'
    });

    let contato = this.chatSelecionado.contato;
    if (this.chatSelecionado.cliente) {
      contato.cliente = this.chatSelecionado.cliente;
    }

    modalRef.componentInstance.add_via_chat = true;
    modalRef.componentInstance.contato = { ...contato, chat_id: this.chatSelecionado._id };
    modalRef.componentInstance.solicitantes = this.solicitantes_habilitados;
    if (multiplo) {
      if (continuacao) {
        modalRef.componentInstance.multiplo = continuacao === 'encerrarCriar'
          ? this.chatSelecionado.contato_multiplos_tickets
            ? this.chatSelecionado.contato_multiplos_tickets
            : this.chatSelecionado.contato_multiplos
          : this.chatSelecionado.contato_multiplos;
      } else {
        modalRef.componentInstance.multiplo = this.chatSelecionado.contato_multiplos;
      }
    }

    modalRef.result.then(results => {
      if (results) {
        this.chatSelecionado.contato = results.chat;
        if (results.cliente) {
          this.chatSelecionado.cliente = results.cliente;
          this.chatSelecionado.cliente_id = results.cliente.id;
        }

        this.listaChats.forEach(chat => {
          if (chat._id === this.chatSelecionado._id) {
            chat.contato = results.chat;
          }
        });

        if (continuacao) {
          setTimeout(() => {
            switch (continuacao) {
              case 'encerrar':
                this.abrirModalFinalizar(this.chatSelecionado);
                break;
              case 'encerrarCriar':
                this.encerrarCriarTicket(this.chatSelecionado);
                break;
              case 'associar':
                this.conciliarTicket(this.chatSelecionado);
                break;
            }
          }, 1200);
        }
      }
    });
  }

  verificaVisibilidadeConciliacao(chat): boolean {
    if (!this.solicitantes_habilitados.solicitante_cliente && !this.solicitantes_habilitados.solicitante_contato) {
      return false;
    } else if (chat.contato?.id) {
      return chat.cliente_id ? true : false;
    } else {
      return this.permissao.VisualizarTickets && !chat.chamado_id;
    }
  }

  obterIntegracaoChatGPT() {
    this.newSubs = this.integrationService.getChatGPTIntegrationStatus().subscribe(
      {
        next: (result) => {
          this.allowChatGPT = !result.resposta_para_chat
        }, error: (error) => {
          console.error(error);
        }
      }
    )
  }

  generateChatGPT() {
    let payload = {
      texto: this.createAnswerToChat.controls.chatGPT.value,
      isChat: true
    };
    this.newSubs = this.chatGPTService.searchSuggestion(payload).subscribe({
      next: (result) => {
        this.toastr.show(
          result.mensagem,
          "",
          environment.toastrConfig("success")
        );


        this.chatGPTSuggestion = result.data.texto_html.replaceAll('\n', '<br>');

        this.chatGPTSuggestionCopy = result.data.texto;
      },
      error: (error) => {
        console.error(error);
        this.toastr.show(error, "", environment.toastrConfig("danger"));
      },
    });
  }

  copy() {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = this.chatGPTSuggestionCopy;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.toastr.show(this.translate.instant('COPIADO_SUCESSO'), '', environment.toastrConfig('success'));
  }

  retornaMascaraNum(numero): string {
    if(numero.length === 11) {
      return '(00) 0 0000-0000';
    } else {
      return '(00) 0000-0000';
    }
  }
}
