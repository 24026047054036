<div
  [formGroup]="chatGPTFormGroup"
  class="d-flex align-items-center justify-content-between gap-2"
>
  <app-input
    [inputFormGroup]="chatGPTFormGroup"
    [inputFormControlName]="chatGPTFormControlName"
    [formControlName]="chatGPTFormControlName"
    [inputId]="chatGPTId"
    [inputName]="chatGPTName"
    inputType="base"
    (inputValueChange)="onFormChange($event.target, chatGPTFormControlName)"
    class="chat-gpt"
    type="text"
    [ngClass]="{ required: chatGPTRequired }"
    [inputAutocomplete]="chatGPTAutocomplete"
    [inputPlaceholder]="'DESCREVA_NECESSIDADE' | translate"
  ></app-input>
  <!-- <input
      [formControlName]="chatGPTFormControlName"
      [id]="chatGPTId"
      [name]="chatGPTName"
      type="text"
      class="form-control"
      [ngClass]="{'required': chatGPTRequired}"
      [autocomplete]="chatGPTAutocomplete"
      [placeholder]="chatGPTPlaceholder"
      (change)="onFormChange($event.target, chatGPTFormControlName)"
    /> -->
  <app-button
    *ngIf="!aiChat"
    buttonId="chatGPTButton"
    container="body" [ngbTooltip]="translate.instant('PESQUISAR')"
    buttonType="icon"
    buttonAction="custom"
    buttonClass="btn btn-success btn-md-rounded-milvus"
    (buttonClicked)="onButtonClick()"
  >
    <img src="assets/img/icons/svg/openai.svg" class="open-ai" />
  </app-button>
    <img container="body" [ngbTooltip]="translate.instant('PESQUISAR')" (click)="onButtonClick()" *ngIf="aiChat" src="assets/img/icons/common/milvus_openai_chat.png" class="open-ai-chat" />
</div>
