<label *ngIf="labelType === 'base'" [for]="labelFor">
  {{ labelName | translate }}
</label>

<label *ngIf="labelType === 'prefix'" [for]="labelFor">
  {{ labelName | translate : { moeda: labelOptions } }}
  <span
    class="badge badge-pill rounded-circle btn-tutorial"
    [placement]="labelTooltipPlacement"
    container="body" ngbTooltip="{{ labelTooltip | translate }}"
    tooltipClass="custom-bottom-tooltip"
    >{{labelIcon}}</span
  >
</label>

<label *ngIf="labelType === 'suffix'" [for]="labelFor">
  {{ labelName | translate : { moeda: labelOptions } }}
  <span
    class="badge badge-pill rounded-circle btn-tutorial"
    [placement]="labelTooltipPlacement"
    container="body" ngbTooltip="{{ labelTooltip | translate }}"
    tooltipClass="custom-bottom-tooltip"
    >{{labelIcon}}</span
  >
</label>
